import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Tooltip,
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  OpenInNew as OpenInNewIcon,
  Check as CheckIcon
} from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function VerifyAndDiscover (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    frontEndUrl,
    openUrl,
    component,
    verifyInput,
    componentIndex
  } = props

  return (
    <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
      <Grid item xs={6} md={6}>
        <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
          <Grid item xs={12} style={{ display: 'flex', direction: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Tooltip title={t('tabs.traceability.inputs.goToDiscover')} placement='top-end'>
              <span>
                <Button
                  className="inputCardButton"
                  size='small' onClick={() => openUrl(`${frontEndUrl}/gtin/${component.productId}`)} >
                  <OpenInNewIcon fontSize='small' />
                  <span>{t('tabs.traceability.inputs.search')}</span>
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} md={6}>
        <Grid container direction='row' justify='flex-end' alignItems='flex-end'>
          <Grid item xs={12} style={{ display: 'flex', direction: 'row', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
            <Tooltip title={t('tabs.traceability.inputs.verifyInputs')} placement='top-end'>
              <Button className="inputCardButton" size='small' onClick={() => verifyInput(component, trackIndex, componentIndex)} aria-label="verifica input">
                <CheckIcon></CheckIcon>
                <span>{t('tabs.traceability.inputs.verify')}</span>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

  )
}

VerifyAndDiscover.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  openUrl: PropTypes.func.isRequired,
  verifyInput: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  frontEndUrl: PropTypes.string.isRequired,
  componentIndex: PropTypes.number.isRequired
}
export default VerifyAndDiscover
