import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Fab,
  makeStyles
} from '@material-ui/core'
import {
  Add as AddIcon
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

function InsertDocumentComponent (props) {
  const classes = useStyles()
  const { trackIndex, uploadDocument, isModifyEnabled } = props
  const { t } = useTranslation('lotDetails')

  return (
    <Grid item xs={12} style={{ width: '100%' }}>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography variant="h6" style={{ marginRight: '10px' }}>
          {t('tabs.traceability.inputs.documents')}
        </Typography>
        <input
          className={classes.input}
          id={`add-new-document-${trackIndex}`}
          type="file" multiple
          accept="image/png, image/jpeg, application/pdf"
          onChange={(e) => uploadDocument(e, trackIndex)}
          style={{ display: 'none' }}
          disabled={!isModifyEnabled} />
        <label htmlFor={`add-new-document-${trackIndex}`}>
          <Fab
            classes={{ primary: classes.fabPrimary }}
            size="small"
            component="span"
            color="primary"
            aria-label={t('tabs.traceability.inputs.addDocument')}
            disabled={!isModifyEnabled}>
            <AddIcon />
          </Fab>
        </label>
      </div>
    </Grid>

  )
}

InsertDocumentComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired
}
export default InsertDocumentComponent
