import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function MultipleSelect (props) {
  const classes = useStyles()
  let supplysName = []
  if (props.resourceSupplychains) {
    supplysName = props.resourceSupplychains.map(supply => supply.name)
  }
  const [supplychainsName, setSupplychainsName] = useState(supplysName)

  useEffect(() => {
    let supplysName = []
    if (props.resourceSupplychains) {
      supplysName = props.resourceSupplychains.map(supply => supply.name)
    }
    setSupplychainsName(supplysName)
  }, [props.resourceSupplychains])

  const handleChange = (event) => {
    setSupplychainsName(event.target.value)
    props.handleChangeSelect(event.target.value)
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">{props.label}</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={supplychainsName}
          onChange={handleChange}
          input={<Input />}
          disabled={!props.isModifyEnabled}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {props.supplychainsId.map((supplychainId) => {
            const supplychainName = props.allSupplychainIdNameMap[supplychainId]
            return (
              <MenuItem key={supplychainId} value={supplychainName}>
                <Checkbox checked={supplychainsName.indexOf(supplychainName) > -1} />
                <ListItemText primary={supplychainName} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

MultipleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  supplychainsId: PropTypes.array.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  resourceSupplychains: PropTypes.array.isRequired,
  allSupplychainIdNameMap: PropTypes.object.isRequired
}
export default MultipleSelect
