import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import CardModalLotClassic from './CardModalLotClassic.jsx'
import NewCardModalLotSupplychain from './NewCardModalLotSupplychain'
import {
  withStyles,
  withWidth
  // Switch
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'

import { returnProductByUuid, returnSomeProducts } from '../../utils/products'
import { deepCloneObj } from '../../utils/utils'
import { returnAllSupplychainForResource, returnAllSupplychains } from '../../utils/supplychains'

// import ErrorIcon from '@material-ui/icons/Error'

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiFormLabel-root': {
      fontSize: '16px'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    margin: 'auto',
    width: '80%',
    height: '70%',
    outline: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primary: {
    color: theme.primary
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  // chipPrimary: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primary,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  // chipPrimaryDark: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primaryDark,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})
/* Questo componente è solo un layer intermedio per decidere se aprire la modal per la supplychain o quella classica.
Sono stati creati due componenti separati per non rompere nulla */
class CardModalLot extends React.Component {
  constructor (props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    this.phaseCompleted = this.phaseCompleted.bind(this)
    this.isThisLotComplete = this.isThisLotComplete.bind(this)
    this.supplyChainPreparation = this.supplyChainPreparation.bind(this)
  }

  returnDefaultState () {
    return {
      isLoadingInfo: true,
      lot: JSON.parse(JSON.stringify(this.props.lot)),
      product: {
        uuid: '',
        traceability: [],
        product_information: {
          traceability: []
        }
      },
      supplychainsId: []
    }
  }

  async componentDidMount () {
    await this.supplyChainPreparation()
    this.setState({ isLoadingInfo: false })
  }

  async supplyChainPreparation () {
    const lot = deepCloneObj(this.state.lot)
    lot.traceability.forEach(track => {
      // controllo se ha lo status
      if (!Object.prototype.hasOwnProperty.call(track, 'status')) {
        track.status = this.phaseCompleted(track) ? 'completed' : 'draft'
      }
    })
    let productResponse = {}
    /* ci prendiamo il prodotto per vedere poi prendere le supplychain legate al prodotto */
    if (lot.productId) {
      productResponse = await returnProductByUuid(lot.productId)
    } else {
      const productParams = {
        filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
      }
      productResponse = await returnSomeProducts(productParams)
    }

    const product = productResponse.data.data

    /* nel caso complete le supplychain le devo prendere dal lotto. Anche nel caso draft esse vengono prese dal lotto. */
    const allSupplychains = await returnAllSupplychainForResource(product.uuid)
    if (allSupplychains.data.data.length > 0) {
      lot.supplychainsId = allSupplychains.data.data.map(value => value.supplychainId)
    } else {
      lot.supplychainsId = []
    }
    /* mi ritorno tutte le supplychain per creare una mappa uuid -> nome da mettere nella select */
    const allSupplychainsDocsResource = await returnAllSupplychains(lot.supplychainsId)
    const allSupplychainIdNameMap = allSupplychainsDocsResource
      .data.data
      .reduce((acc, supply) => {
        acc[supply.uuid] = supply.name
        return acc
      }, {})
    const supplychainsId = deepCloneObj(lot.supplychainsId)

    this.setState({ product, lot, supplychainsId, allSupplychainIdNameMap })
  }

  phaseCompleted (track) {
    if (
      (
        track.completedAt &&
        track.startedAt &&
        Array.isArray(track.inputs) &&
        track.inputs.length > 0 &&
        track.inputs.every(input => (input.lotNumber && (input.productId && input.productId.indexOf('supplychain') === -1) && (input.uom && input.uom.indexOf('supplychain') === -1)))
      ) &&
      (
        ((track.eventType !== 'observation_shipping') && (track.eventType !== 'observation_receiving') && (track.eventType !== 'transformation') && (track.location.facilityId && track.location.facilityId.indexOf('supplychain') === -1)) ||
        ((track.eventType === 'transformation') && track.outputs.length > 0 && track.outputs.every(output => (output.lotNumber && (output.productId && output.productId.indexOf('supplychain') === -1) && (output.uom && output.uom.indexOf('supplychain') === -1))) && (track.location.facilityId && track.location.facilityId.indexOf('supplychain') === -1)) ||
        (((track.eventType === 'observation_shipping') || (track.eventType === 'observation_receiving')) && (Array.isArray(track.locationFrom) && track.locationFrom.every(fac => fac.facilityId && fac.facilityId.indexOf('supplychain') === -1)) && (Array.isArray(track.locationTo) && track.locationTo.every(fac => fac.facilityId && fac.facilityId.indexOf('supplychain') === -1)))
      )
    ) {
      return true
    }
  }

  // funzione che controlla se tutte le fasi sono complete
  isThisLotComplete (tracks) {
    return tracks.every(track => { return this.phaseCompleted(track) })
  }

  render () {
    // creo variabile che sarà true se siamo da mobile
    if (!this.state.isLoadingInfo) {
      if (this.state.supplychainsId.length > 0) {
        return (
          <NewCardModalLotSupplychain
            setSnackbar={this.props.setSnackbar}
            lot={this.state.lot}
            lotNumber={this.props.lotNumber}
            open={this.props.open}
            onCloseModal={this.props.onCloseModal}
            productsIdNameMap = {this.props.productsIdNameMap}
            frontEndUrl={this.props.frontEndUrl}
            product={this.state.product} // viene passato il prodotto per far in modo di prendere le supplychain
            allSupplychainIdNameMap={this.state.allSupplychainIdNameMap}
          />
        )
      } else {
        return (
          <CardModalLotClassic
            setSnackbar={this.props.setSnackbar}
            isFels={this.props.isFels}
            lot={this.state.lot}
            lotNumber={this.props.lotNumber}
            open={this.props.open}
            onCloseModal={this.props.onCloseModal}
            productsIdNameMap = {this.props.productsIdNameMap}
            frontEndUrl={this.props.frontEndUrl}
          />
        )
      }
    } else { // i dati del prodotto non sono ancora disponibili
      return (
        <>
        </>
      )
    }
  }
}

CardModalLot.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  lotNumber: PropTypes.string,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('lotDetails')(CardModalLot))))
