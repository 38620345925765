import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Chip
} from '@material-ui/core'
import {
  InsertDriveFile
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  chipContainer: { display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' },
  chipLabel: { overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }
}))

function DocumentComponent (props) {
  const classes = useStyles()
  const { track, trackIndex, openMedia, isModifyEnabled, deleteDocument } = props

  return (
    <Grid item xs={12} style={{ width: '100%', margin: '20px 0' }}>
      <div className={classes.chipContainer}>
        {(track.documents.length > 0 && track.documents[0].fileUrl) &&
      track.documents.map((t, documentIndex) =>
        <Chip title={t.name || 'documento'} classes={{ colorPrimary: classes.chipPrimary }} size="small"
          key={`doc-${documentIndex}`}
          icon={<InsertDriveFile />}
          color="primary"
          label={<span className={classes.chipLabel}>{t.name || 'documento'}</span>}
          onClick={() => openMedia(t.fileUrl)}
          onDelete={() => isModifyEnabled ? deleteDocument(trackIndex, documentIndex) : ''}
        />
      )
        }
      </div>
    </Grid>

  )
}

DocumentComponent.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  openMedia: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  deleteDocument: PropTypes.func.isRequired

}
export default DocumentComponent
