/* Componente Padre che forma il blocco productId, LotNumber, Quantity,Uom */
import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function ProductComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')
  const {
    trackIndex,
    componentIndex,
    component,
    isModifyEnabled,
    componentChanged,
    product,
    products,
    type
  } = props

  return (
    <Grid item xs={12} md={6}>
      <InputLabel shrink htmlFor={`input-product-${componentIndex}`}>
        {t('tabs.traceability.inputs.materialInputs.product')}
      </InputLabel>
      <Select
        id={`${type}-product-${trackIndex}-${componentIndex}`}
        name={'productId'}
        fullWidth
        displayEmpty
        value={component.productId}
        onChange={(e) => componentChanged(e, trackIndex, componentIndex)}
        disabled={!isModifyEnabled}>
        <MenuItem value={'THIS'} key={'product-THIS'}><em>{product.name}</em></MenuItem>
        {products
          .sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            // names must be equal
            return 0
          })
          .map((t, productIndex) => {
            if (t.companyData && t.companyData.name) {
              return (<MenuItem value={t.uuid} key={`product-${productIndex}`}>{t.name} ({t.companyData.name})</MenuItem>)
            } else {
              return (<MenuItem value={t.uuid} key={`product-${productIndex}`}>{t.name}</MenuItem>)
            }
          })}
      </Select>
    </Grid>
  )
}

ProductComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  component: PropTypes.object.isRequired, // input or output
  isModifyEnabled: PropTypes.bool.isRequired,
  componentChanged: PropTypes.func.isRequired, // inputChanged or outputChanged
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired // input or output

}
export default ProductComponent
