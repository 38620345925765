import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  withStyles,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  Tab,
  Tabs,
  Fab,
  Link,
  Chip,
  CardMedia,
  withWidth,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  InputLabel,
  Select,
  MenuItem
  // Switch
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import QRCode from 'qrcode.react'
import { withTranslation } from 'react-i18next'

import { replaceLot, deleteLot } from '../../utils/lots'
// import { returnAllFacilities } from '../../utils/facilities'
import { returnProductByUuid, returnSomeProducts } from '../../utils/products'
import { deepCloneObj, checkFileSize, generateUuid, loadFile, ItLocalizedUtils, locale, urlTobase64 } from '../../utils/utils'

import configuration from '../../configuration.js'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Info from '@material-ui/icons/Info'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import AccountsService from '../../components/Accounts.service'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DownLoadIcon from '@material-ui/icons/CloudDownload'
import CheckIcon from '@material-ui/icons/CheckCircle'
import TimeIcon from '@material-ui/icons/AccessTime'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddIcon from '@material-ui/icons/Add'
// import ErrorIcon from '@material-ui/icons/Error'

import {
  amber,
  green
  // red
} from '@material-ui/core/colors'

import { InsertDriveFile, ArrowBack, ArrowForward, Visibility, VisibilityOff } from '@material-ui/icons'
import moment from 'moment'

const getLastLotnumber = lot => {
  const { traceability } = lot

  if (!traceability || !traceability.length) {
    return
  }

  if (Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
    const outputs = traceability[traceability.length - 1].outputs
    return outputs[outputs.length - 1].lotNumber
  }

  const inputs = traceability[traceability.length - 1].inputs
  return inputs[inputs.length - 1].lotNumber
}

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiFormLabel-root': {
      fontSize: '16px'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    margin: 'auto',
    width: '80%',
    height: '70%',
    outline: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primary: {
    color: theme.primary
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  // chipPrimary: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primary,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  // chipPrimaryDark: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primaryDark,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModalLotClassic extends React.Component {
  constructor (props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    // this.onDragEnd = this.onDragEnd.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.updateSelectionStart = this.updateSelectionStart.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.changePhaseTab = this.changePhaseTab.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteLot = this.deleteLot.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.timestampChanged = this.timestampChanged.bind(this)
    this.deadlineTimestampChanged = this.deadlineTimestampChanged.bind(this)
    this.changeShowDeadline = this.changeShowDeadline.bind(this)
    this.startTimestampChanged = this.startTimestampChanged.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.concatDescription = this.concatDescription.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.toogleAlreadyCompletedDialog = this.toogleAlreadyCompletedDialog.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.setAllLotsAreSameCheckbox = this.setAllLotsAreSameCheckbox.bind(this)
    this.completeAllInputs = this.completeAllInputs.bind(this)
    /* funzione che governa lo stato del lotto */
    this.lotStatusChange = this.lotStatusChange.bind(this)
    /* funzione che governa la select dello stato per il lotto */
    this.changeLotStatusValue = this.changeLotStatusValue.bind(this)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward () {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward () {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  returnDefaultState () {
    return {
      filesToLoad: [],
      language: ls.get('i18nextLng'),
      isConfirmDialogOpened: false,
      isShowPhase: (this.props.lot.traceability && this.props.lot.traceability.length > 0) ? this.props.lot.traceability[0].showPhase : true,
      isSaveDialogOpen: false,
      isAlreadyCompletedDialogOpen: false,
      someInfoAreMissing: false,
      tabNumber: 0,
      phaseTabNumber: 0,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      modified: false,
      open: this.props.open,
      inputRef: [],
      selectionStart: '',
      chipData: [{ key: 0, label: '#{data}' }, { key: 1, label: '#{azienda}' }, { key: 2, label: '#{localita}' }],
      isCompleted: false,
      lot: JSON.parse(JSON.stringify(this.props.lot)),
      /* la variabile di stato che tiene il controllo dello stato del lotto. Lo stato del lotto può essere:
      - aggiornato automaticamente man mano che si compilano le fasi
      - può essere aggiornato forzatamente (NB: questo tipo di aggiornamento si può fare solo quando il lotto può effettivamente passare da bozza ad attivo
      (ossia tutte le fasi complete) e, una volta che il lotto è stato salvato come attivo,
      non si può più far tornare a bozza
      */
      lotStatus: this.props.lot.status,
      product: {
        uuid: '',
        traceability: [],
        product_information: {
          traceability: []
        }
      },
      productsIdNameMap: this.props.productsIdNameMap,
      allLotsAreSameCheckbox: false
    }
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  changeLotStatusValue (isCompleted) {
    /* decommentare se si vuole riattivare la vecchia logica, ossia che il lotto passava automaticamente ad attivo. */
    // if (isCompleted) {
    //   return 'active'
    // } else {
    //   return 'draft'
    // }
    return 'draft'
  }

  changePhaseTab (phaseTabNumber) {
    const lot = this.state.lot
    this.setState({ phaseTabNumber, isShowPhase: lot.traceability[phaseTabNumber].showPhase })
  }

  closeSaveDialog () {
    if (!this.state.isModifing) {
      this.setState({ isSaveDialogOpen: false })
    }
  }

  setAllLotsAreSameCheckbox (event, inputIndex) {
    this.setState({
      allLotsAreSameCheckbox: event.target.checked
    })
    if (event.target.checked) {
      this.props.setSnackbar(this.props.t('notifications.automaticCompile'), 'warning')
      this.completeAllInputs(inputIndex)
    }
  }

  completeAllInputs (inputIndex) {
    const lot = deepCloneObj(this.state.lot)
    lot.traceability.forEach((step, stepIndex) => {
      if (stepIndex !== 0) {
        if (Array.isArray(step.inputs) && step.inputs.length > 0) {
          step.inputs.forEach(input => {
            input.lotNumber = lot.traceability[0].inputs[inputIndex].lotNumber
            input.quantity = lot.traceability[0].inputs[inputIndex].quantity
          })
        }
        const isComplete = this.isThisPhaseCompleted(step)
        if (isComplete) {
          step.status = 'completed'
        } else {
          step.status = 'draft'
        }
      }
    })

    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({
      lot: {
        ...this.state.lot,
        traceability: lot.traceability
      },
      isCompleted,
      lotStatus
    })
  }

  async componentDidMount () {
    const lot = this.state.lot
    const language = this.state.language === 'en-US' ? 'en' : 'it'
    lot.traceability.forEach(track => {
      // controllo se ha lo status
      if (!Object.prototype.hasOwnProperty.call(track, 'status')) {
        track.status = this.isThisPhaseCompleted(track) ? 'completed' : 'draft'
      }
    })
    // controllo se è un draft e abilito modifiche
    const isModifyEnabled = lot.status === 'draft'
    /* isCompleted, dopo che la modal è stata montata, viene aggiornato con il valore corretto in base a se ogni fase ha il valore completed */
    const isCompleted = lot.traceability.every(track => track.status === 'completed')
    /* dopo che la modal è stata montata, lotStatus viene aggiornato a active o draft in base a se le fasi sono tutte complete.
    NB: se il lotto, che viene passato come props, ha lo stato ad active la select non si vede (in quanto, una volta che il lotto è stato reso attivo, non posso tornare a bozza)
    */
    const lotStatus = this.changeLotStatusValue(isCompleted)

    console.log('isCompleted', isCompleted)

    const tabNumber = lot.status === 'draft' ? 2 : 0

    let productResponse = {}
    if (lot.productId) {
      productResponse = await returnProductByUuid(lot.productId)
    } else {
      const productParams = {
        filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
      }
      productResponse = await returnSomeProducts(productParams)
    }
    const product = productResponse.data.data
    console.log('product => ', product)
    console.log('productResponse.data => ', productResponse.data)
    const inputRef = lot.traceability.map(x => React.createRef())
    this.setState({
      lot,
      language,
      lotStatus,
      product,
      isModifyEnabled,
      tabNumber,
      isCompleted,
      inputRef
    })
    console.log('NEW this.state =>', this.state)
  }

  async componentDidUpdate (prevPros, prevState) {
    if (this.state.isCompleted && (prevState.isCompleted !== this.state.isCompleted) && this.props.lot.status !== 'active') { this.props.setSnackbar(this.props.t('notifications.mantainDraft'), 'success') }
  }
  /*
  async componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        console.log('this.props => ', this.props)
        const lot = this.state.lot
        console.log('current lot => ', lot)

        lot.traceability.forEach(track => {
          // controllo se ha lo status
          if (!Object.prototype.hasOwnProperty.call(track, 'status')) {
            track.status = this.isThisPhaseCompleted(track) ? 'completed' : 'draft'
          }
        })

        // controllo se è un draft e abilito modifiche
        const isModifyEnabled = lot.status === 'draft'
        const isCompleted = lot.status !== 'draft'
        const tabNumber = lot.status === 'draft' ? 2 : 0

        let productResponse = {}
        if (lot.productId) {
          productResponse = await returnProductByUuid(lot.productId)
        } else {
          const productParams = {
            filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
          }
          productResponse = await returnSomeProducts(productParams)
        }
        // const productResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
        //   }
        // })

        const product = productResponse.data.data
        console.log('product => ', product)
        console.log('productResponse.data => ', productResponse.data)

        const companiesResponse = await returnAllCompanies()

        const companies = companiesResponse.data.data
        const companiesIds = companies.map(company => company.companyId)

        const productsParams = {
          filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        }
        const productsResponse = await returnSomeProducts(productsParams)
        // const productsResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        //   }
        // })

        const products = productsResponse.data.data
        const productsIdNameMap = products.reduce((acc, product) => {
          acc[product.uuid] = product.name
          return acc
        }, {})
        if (product) {
          productsIdNameMap[product.uuid] = product.name
        }
        this.setState({ lot, product, productsIdNameMap, isModifyEnabled, tabNumber, isCompleted })
        console.log('NEW this.state =>', this.state)
      }

      this.setState({ open: this.props.open })
    }
  }
*/

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false })
  }

  updateSelectionStart (index) {
    this.setState({
      selectionStart: this.state.inputRef[index].current.selectionStart
    }, () => {
      console.log('selectionStart:::::', this.state.inputRef[index].current.selectionStart)
    })
  }

  // funzione che viene lanciata alla chiusara della dialog
  closeModal (forceClose, shouldIForceRefresh) {
    if (this.state.isModifyEnabled || this.state.modified) {
      // console.log('dentro primo if')
      if (forceClose === true) {
        // console.log('dentro primo force clse, forceClose => ', forceClose)
        this.setState(this.returnDefaultState())
        this.props.onCloseModal(shouldIForceRefresh)
      } else {
        this.setState({ isSaveDialogOpen: true, saveFromClose: true, modified: false })
      }
    } else {
      console.log('dentro secondo if')
      this.setState(this.returnDefaultState())
      // this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.onCloseModal(shouldIForceRefresh)
    }
  }

  deleteDocument (trackIndex, documentIndex) {
    const lot = this.state.lot
    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const filesToLoadIndex = filesToLoad
      .findIndex((file) => file.order === documentIndex && file.type === 'document' && file.index === trackIndex)

    if (filesToLoadIndex > -1) {
      filesToLoad.splice(filesToLoadIndex, 1)
    }
    filesToLoad
      .filter((file, index) => (file.type === 'document' && file.index === trackIndex && documentIndex <= file.order))
      .forEach(file => {
        console.log('doc in filest to load', file)
        file.order = file.order - 1
      })
    lot.traceability[trackIndex].documents.splice(documentIndex, 1)
    lot.traceability[trackIndex].documents
      .filter((doc, index) => documentIndex <= index)
      .forEach(doc => {
        // console.log('doc', doc)
        doc.order = doc.order - 1
      })
    // console.log('this.state.files', this.state.filesToLoad)
    this.setState({ lot, filesToLoad: filesToLoad }
      // () => {
      //   console.log('this.state.files', this.state.filesToLoad)
      // }
    )
  }

  // DEPRECATED
  deleteImage (trackIndex, mediaIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].media.splice(mediaIndex, 1)
    this.setState({ lot })
  }

  async deleteLot () {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    const lot = this.state.lot
    try {
      lot.status = 'deleted'
      await deleteLot(lot)
      // await axios.put(`${configuration.apiBaseUrl}/lots/${this.state.lot.uuid}`, { status: 'deleted' }, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      this.setState({ isDeleting: false })
      this.closeModal(true, true)
    } catch (e) {
      this.setState({ isDeleting: false })
      console.error(`Unable to delete lot with uuid ${lot.uuid}: `, e)
      this.props.setSnackbar(this.props.t('notifications.deleteLotError'), 'error')
      // alert('Impossibile eliminare il lotto')
    }
  }

  enableOrDisableModify () {
    if (this.state.isModifyEnabled === true) {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, modified: true })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
  }

  // funzione che controlla la completezza di una fase
  isThisPhaseCompleted (track) {
    if (track.completedAt !== '' && track.startedAt !== '' && !track.inputs.some(input => input.lotNumber === '')) {
      if (track.outputs) {
        if (!track.outputs.some(output => output.lotNumber === '')) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  // funzione che controlla se tutte le fasi sono complete
  isThisLotComplete (tracks) {
    return tracks.every(track => { return this.isThisPhaseCompleted(track) })
    // let count = 0
    // const max = tracks.length
    // tracks.forEach(track => {
    //   if (this.isThisPhaseCompleted(track)) {
    //     count += 1
    //   }
    // })
    // if (count === max) {
    //   return true
    // } else {
    //   return false
    // }
  }

  // funzione che cambia i valori dei campi di input
  inputChanged ({ target }, trackIndex, inputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].inputs[inputIndex][target.name] = target.value
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let allLotsAreSameCheckbox = this.state.allLotsAreSameCheckbox
    if (trackIndex === 0) {
      if (allLotsAreSameCheckbox) {
        allLotsAreSameCheckbox = false
        this.props.setSnackbar(this.props.t('notifications.deleteAutomaticCompile'), 'warning')
      }
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus, allLotsAreSameCheckbox })
  }

  handleVisibility (trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].showPhase = !lot.traceability[trackIndex].showPhase
    this.setState({ lot, isShowPhase: lot.traceability[trackIndex].showPhase }, () => {
      console.log('LOT post handle click:', this.state.lot)
    })
  }

  // funzione che cambia i valori dei campi dgli output
  outputChanged ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, lotStatus, isCompleted })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  async openMedia (url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
        <head>
          <title>Trusty | Documenti</title>
        </head>
        <body style="margin: 0px;">
          <iframe width="100%" height="100%" src="${file}"></iframe>
        </body>
      </html>
      `)
    } catch (e) {
      this.props.setSnackbar('Impossibile visualizzare il documento!', 'error')
    }
  }

  // funzione che salva le modifiche
  async saveModification () {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    const updateObject = deepCloneObj(this.state.lot)
    // const updateObject = Object.keys(this.state.lot).reduce((acc, k) => {
    //   acc[k] = this.state.lot[k]
    //   return acc
    // }, {})

    delete updateObject._id
    updateObject.updatedAt = Date.now()
    // controllo che tutto sia settato bene
    /* caso in cui il lotto è attivo e provo a cancellare qualche informazione. TODO: da verificare se è voluto che si possa cambiare
    qualche info dopo che il lotto è stato reso attivo */
    if (!this.state.isCompleted && updateObject.status === 'active') {
      this.setState({ isModifing: false })
      return this.toogleAlreadyCompletedDialog()
    } else if (this.state.isCompleted && this.state.lotStatus === 'active' && updateObject.status === 'draft') {
      /* viene preso il lot number inserito nell'ultima fase, se contiene solo la fase di input viene preso da lì altrimenti se contiene quella di output da li */
      updateObject.lotNumber = updateObject.traceability[updateObject.traceability.length - 1].inputs[updateObject.traceability[updateObject.traceability.length - 1].inputs.length - 1].lotNumber
      // se ci sono gli output nell'ultima fase segno quello come lot number
      if (updateObject.traceability[updateObject.traceability.length - 1].outputs && updateObject.traceability[updateObject.traceability.length - 1].outputs.length) {
        updateObject.lotNumber = updateObject.traceability[updateObject.traceability.length - 1].outputs[updateObject.traceability[updateObject.traceability.length - 1].outputs.length - 1].lotNumber
      }
      updateObject.status = 'active'
    }

    try {
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedTraceability = Object.assign([], this.state.lot.traceability)
      // console.log('filesToLoad => ', filesToLoad)
      // console.log('updateObject => ', updateObject)
      for (const file of filesToLoad) {
        const { blob, name, index, order, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `lots/${this.state.lot.uuid}/${fileUuid}.${extention}`
        if (section === 'traceability' && type === 'document') {
          const url = await loadFile(blob, filename)
          if (updatedTraceability && updatedTraceability[index] && updatedTraceability[index].documents && updatedTraceability[index].documents[order] && updatedTraceability[index].documents[order].fileUrl) {
            updatedTraceability[index].documents[order].fileUrl = url
          }
          file.loaded = true
        }
      }

      updateObject.traceability = updatedTraceability

      // elimino l'oggetto opentimestamps per evitare di sovrascrivere l'url aggiornato dall'opentimestamps worker
      delete updateObject.opentimestamps
      // controllo l'expirationDate
      if (!updateObject.expirationDate || updateObject.expirationDate === '') {
        delete updateObject.expirationDate
      }

      console.log('updateObject => ', updateObject)
      const responseObject = await replaceLot(updateObject)
      // const responseObject = await axios.put(`${configuration.apiBaseUrl}/lots/${updateObject.uuid}`, updateObject, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      // const lot = responseObject.data && responseObject.data.data ? responseObject.data.data : this.state.lot
      const lot = responseObject.data.data
      lot.updatedAt = updateObject.updatedAt
      this.setState({ isModifing: false, lot, filesToLoad: [] })
      await this.props.onCloseModal(true)
      this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success')
      // alert('Modifica avvenuta con successo')
    } catch (e) {
      this.setState({ isModifing: false })
      console.error('Unable to modify lot, error => ', e)
      this.props.setSnackbar(this.props.t('notifications.editsError'), 'error')
      // alert('Impossibile modificare il lotto')
    }
  }

  // funzione che viene lanciata alla chiusura della dialog salvando le modifiche
  async confirmSave () {
    if (!this.state.isCompleted && this.state.lot.status === 'active') {
      this.toogleAlreadyCompletedDialog()
      return this.setState({ isSaveDialogOpen: false })
    } else {
      await this.saveModification()
      this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
      this.closeSaveDialog()
      // this.props.onCloseModal(true)
    }
  }

  confirmClose () {
    if (!this.state.isModifing) {
      this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
      this.closeSaveDialog()
      this.props.onCloseModal(false)
    }
  }

  startTimestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].startedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].startedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  timestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].completedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].completedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  lotStatusChange (event) {
    this.setState({
      lotStatus: event.target.value
      // lot: {
      //   ...this.state.lot,
      //   status: event.target.value
      // }
    }, () => {
      console.log('ciao ciao ', this.state.lotStatus)
    })
  }

  deadlineTimestampChanged (date) {
    console.log('date = ', date)
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      date.setHours(0, 0, 0, 0)
      lot.expirationDate = date.toISOString()
    } else {
      lot.expirationDate = null
    }

    this.setState({ lot })
  }

  // DEPRECATED
  // funzione che cambia il valore di mostra / nascondi data
  changeShowDeadline () {
    const lot = this.state.lot
    console.log('lot.lot_information.showDeadline => ', lot.lot_information.showDeadline)
    lot.lot_information.showDeadline = !lot.lot_information.showDeadline
    this.setState({ lot })
    console.log('new this.state.lot.lot_information =>', this.state.lot.lot_information)
  }

  preventDefault = (event) => event.preventDefault();

  async uploadDocument ({ target }, trackIndex) {
    const lot = this.state.lot
    const documents = lot.traceability[trackIndex].documents
    const candidateMaxOrder = documents.length - 1
    const maxOrder = candidateMaxOrder
    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)
        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(blob)
        })

        documents.push({
          order: maxOrder + 1,
          fileUrl: fileContent,
          type: 'other',
          description: '',
          name: fileName
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index: trackIndex,
          order: maxOrder + 1,
          loaded: false,
          name: fileName
        }

        filesToLoad.push(singleFile)

        this.setState({ lot, filesToLoad: filesToLoad })
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooLarge'), 'error')
    }
  }

  async uploadImage ({ target }, trackIndex) {
    const lot = this.state.lot
    const traceability = lot.traceability
    try {
      for (const file of target.files) {
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(file)
        })

        traceability[trackIndex].media.push({
          // url: fileContent,
          original: fileContent,
          cropped: fileContent,
          croppingRect: {
            x: 1,
            y: 1,
            width: 1,
            height: 1
          }
          // order: traceability[trackIndex].media.length
        })
      }

      this.setState({ lot })
    } catch (e) {
      console.error('Error while reading file: ', e)
      this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      // alert('Errore durante la lettura del file')
    }
  }

  valueChanged ({ target }, trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex][target.name] = target.value
    this.setState({ lot })
  }

  concatDescription (t, trackIndex, cursorPosition) {
    const lot = this.state.lot
    const description = lot.traceability[trackIndex].description
    const text = description.slice(0, cursorPosition) + t + description.slice(cursorPosition, description.length)
    lot.traceability[trackIndex].description = text
    this.setState({ lot })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  downloadDocument (name, url) {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode () {
    // creo link fittizio
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre la modal dell'impossibilità di lasciare campi vuoti
  toogleAlreadyCompletedDialog () {
    const isAlreadyCompletedDialogOpen = this.state.isAlreadyCompletedDialogOpen
    this.setState({ isAlreadyCompletedDialogOpen: !isAlreadyCompletedDialogOpen })
    // console.log('isAlreadyCompletedDialogOpen => ', isAlreadyCompletedDialogOpen)
    // console.log('this.state.isAlreadyCompletedDialogOpen => ', this.state.isAlreadyCompletedDialogOpen)
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <React.Fragment>
        {/* <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.closeModal}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.state.open}>
          <div className={classes.content}> */}

        {/* dialog conferma eliminazione */}
        <Dialog className='lotModal' open={this.state.isConfirmDialogOpened} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.confirmDelete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('dialog.confirmDelete.description')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.closeConfirmDialog} color="secondary" autoFocus>
              {this.props.t('dialog.confirmDelete.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteLot} color="primary">
              {this.state.isDeleting
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader> : this.props.t('dialog.confirmDelete.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog confirm saved changes */}
        <Dialog className='lotModal' open={this.state.isSaveDialogOpen} aria-labelledby="Comfirm save">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.confirmClose.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color="primary" autoFocus>
              {this.props.t('dialog.confirmClose.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color="primary">
              {this.props.t('dialog.confirmClose.buttons.closeWithoutSave')}
            </Button>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.confirmSave} color="secondary" disabled={!!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader> : this.props.t('dialog.confirmClose.buttons.closeAndSave')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog warning can't save a complete lot as draft */}
        <Dialog className='lotModal' open={this.state.isAlreadyCompletedDialogOpen} aria-labelledby="Impossibile procedere" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.cannotProceed.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.cannotProceed.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.toogleAlreadyCompletedDialog} color="primary">
              {this.props.t('dialog.cannotProceed.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='lotModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          PaperProps={{ square: true }}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <DialogTitle classes={{ root: classes.titleMobile }} disableTypography={true} className={classes.navBar}>
              <Typography style={{ width: '95%' }} noWrap={true} variant="h6" component="h2">
                {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              </Typography>
              <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            : <DialogTitle disableTypography={true} className={classes.navBar}>
              <Typography noWrap={true} variant="h6" component="h2">
                {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              </Typography>
              <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.closeButton}>
                {!AccountsService.isFelsineo(this.user) &&
                <>
                  {this.state.isModifyEnabled && <Tooltip title="Disabilita modifiche"><IconButton color="inherit" aria-label="allow-modify" onClick={() => this.enableOrDisableModify()}>
                    <LockOpen />
                  </IconButton></Tooltip>}
                  {!this.state.isModifyEnabled &&
                <Tooltip title="Modifica dati">
                  <IconButton color="inherit" aria-label="disallow-modify" onClick={() => this.enableOrDisableModify()}>
                    <Lock />
                  </IconButton>
                </Tooltip>}
                </>
                }

                <Tooltip title="Elimina lotto">
                  <IconButton onClick={() => this.openConfirmDialog()} aria-label="delete product">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Chiudi / Salva modifiche">
                  <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal} style={{ marginRight: 0 }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </DialogTitle>
          }
          <Paper elevation={4} square>
            <Tabs classes={{ indicator: classes.tabIndicator }} variant="scrollable" value={this.state.tabNumber} onChange={this.changeTab} indicatorColor="primary">
              <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
              <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.timestamps.title')} />
              <Tab classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.traceability.title')} (${this.state.lot && this.state.lot.traceability ? this.state.lot.traceability.length : 0})`} />
            </Tabs>
            {this.state.tabNumber === 2 ? <Paper elevation={0} position="static" square>
              <Box style={{ width: '100%' }} display="flex">
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.actionButtonContainer}>
                  <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                </Box>
                <Box id="chipContainer" className={classes.chipContainer}>
                  {this.state.lot.traceability.map((track, trackIndex) => <Chip
                    classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }}
                    key={`subtab-${trackIndex}`}
                    color="primary"
                    label={track.name ? `#${trackIndex + 1} ${track.name}` : this.props.t('generalPhase')}
                    onClick={() => this.changePhaseTab(trackIndex)} />)}
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.actionButtonContainer}>
                  <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                </Box>
              </Box>
            </Paper> : ''}
          </Paper>
          <DialogContent classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }}>
            {/* GENERALE */}
            <div component="div" hidden={this.state.tabNumber !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tabpanel-0" className={`${classes.tabContainer} tabContent`}>
              <Grid style={{ height: '100%' }} container direction="row" alignItems="center" justify="center" spacing={2}>
                <Grid item md={6} sm={10} xs={10}>
                  <Typography variant="body1" style={{ marginBottom: 20, marginTop: !isSmall ? '-16px' : '' }}>
                    {this.props.t('tabs.general.description')}.
                  </Typography>
                  {/* <Typography variant="overline"><b>{this.props.t('tabs.general.inputs.expirationDate')}</b></Typography> */}
                  <Box display="flex" alignItems="center">
                    {/* <Tooltip title={this.state.lot.lot_information.showDeadline ? 'Mostra scadenza' : 'Scadenza nascosta' }>
                      <Switch
                        checked={this.state.lot.lot_information.showDeadline}
                        onChange={this.changeShowDeadline}
                        value={this.state.lot.lot_information.showDeadline}
                        color="primary"
                        inputProps={{ 'aria-label': 'Mostra/Nascondi data scadenza' }}
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Tooltip> */}
                    <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
                      <KeyboardDatePicker
                        style={{ marginTop: '2px !important', marginLeft: 16 }}
                        className={classes.w100}
                        margin="normal"
                        id="deadlineTimestamp"
                        label={this.props.t('tabs.general.inputs.expirationDate')}
                        format="dd/MM/yyyy"
                        cancelLabel={this.props.t('tabs.general.inputs.dateCancel')}
                        value={this.state.lot.expirationDate ? this.state.lot.expirationDate : null}
                        onChange={(date) => this.deadlineTimestampChanged(date)}
                        KeyboardButtonProps={{
                          'aria-label': this.props.t('tabs.general.inputs.expirationDate')
                        }}
                        disabled={!this.state.isModifyEnabled}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  <Typography variant="body2" style={{ paddingLeft: 16 }}>{this.props.t('tabs.general.inputs.expirationDateInfo')}</Typography>
                </Grid>
                {this.props.lot.status !== 'active' &&
                <Grid item md={2} sm={2} xs={2}>
                  <InputLabel id="demo-simple-select-label">Stato</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.lotStatus}
                    onChange={this.lotStatusChange}
                    disabled={!this.state.isCompleted || this.props.lot.status === 'active'}
                  >
                    <MenuItem value={'draft'}>{this.props.t('tabs.general.inputs.draft')}</MenuItem>
                    <MenuItem value={'active'}>{this.props.t('tabs.general.inputs.active')}</MenuItem>
                  </Select>
                </Grid>
                }

                <Grid item md={4} sm={12} xs={12}>
                  {!AccountsService.isFelsineo(this.user) &&
                  <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                      <QRCode size={180} value={`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(this.state.lot.lotNumber)}`} />
                      <QRCode value={`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(getLastLotnumber(this.state.lot))}`} size={512} style={{ display: 'none' }} id="hiddenCanvas" />
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                      <Grid container direction="row" alignItems="center" justify="flex-start">
                        <Grid item xs={12}>
                          <Tooltip title="Apri pagina pubblica">
                            <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.openQrcodeUrl(`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(this.state.lot.lotNumber)}`)} color="primary" size="small" style={{ margin: '10px 5px' }}>
                              <ExitToAppIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Scarica QR code">
                            <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.downloadQrcode()} color="primary" size="small" style={{ margin: '10px 5px' }}>
                              <GetAppIcon />
                            </Fab>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  }
                </Grid>
              </Grid>

            </div>
            {/* OPENTIMESTAMPS */}
            <div className={`${classes.tabContainer} tabContent`} component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tabpanel-1">
              <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ marginBottom: 2 }}>
                    {this.props.t('tabs.timestamps.description')}.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{this.props.t('tabs.timestamps.table.status')}</TableCell>
                          <TableCell align="center">{this.props.t('tabs.timestamps.table.txHash')}</TableCell>
                          <TableCell align="center">{this.props.t('tabs.timestamps.table.date')}</TableCell>
                          <TableCell align="right">{this.props.t('tabs.timestamps.table.actions')}</TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0
                        ? <TableBody>
                          {this.state.lot.opentimestamps.map((timestamp, index) => (
                            <TableRow key={index}>
                              <TableCell scope="row">
                                {timestamp.verified
                                  ? <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <CheckIcon style={{ marginRight: '8px', color: green[700] }} />
                                    <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.verified')}</Typography>
                                  </Box>
                                  : timestamp.filename === '' ? <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                                    <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.waitingCreation')}</Typography>
                                  </Box> : <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                                    <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.waitingValidation')}</Typography>
                                  </Box>
                                }
                              </TableCell>
                              <TableCell align="center">
                                {timestamp.transactionId && timestamp.transactionId !== ''
                                  ? <>
                                    <Link href ={`${configuration.blockchainExplorer}/${this.state.language}/btc/tx/${timestamp.transactionId}`} target="_blank" color="inherit">
                                      {timestamp.transactionId
                                        .slice(0, 3)}...{timestamp.transactionId
                                        .slice(61, 64)}`
                                    </Link>
                                  </>
                                  : `${this.props.t('tabs.timestamps.table.txHashNotPresent')}`
                                }
                              </TableCell>
                              <TableCell align="center">
                                {timestamp.timestamp && timestamp.timestamp !== ''
                                  ? moment(timestamp.timestamp).format('DD/MM/YYYY')
                                  : moment(this.state.lot.updatedAt).format('DD/MM/YYYY')
                                }
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title={this.props.t('tabs.timestamps.table.pdfTooltip')}>
                                  <span>
                                    <Fab classes={{ primary: classes.fabPrimary }} disabled={!(this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].filename)} onClick={() => this.openMedia(this.state.lot.opentimestamps[index].filename) } color="primary" size="small" style={{ margin: '10px 5px' }}>
                                      <DownLoadIcon />
                                    </Fab>
                                  </span>
                                </Tooltip>
                                <Tooltip title={this.props.t('tabs.timestamps.table.otsTooltip')}>
                                  <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].ots ? this.downloadDocument('ots_file', this.state.lot.opentimestamps[index].ots) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                    <GetAppIcon />
                                  </Fab>
                                </Tooltip>
                                <Tooltip title={this.props.t('tabs.timestamps.table.infoTooltip')}>
                                  <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].ots && this.state.lot.lotNumber ? this.openQrcodeUrl(`/opentimestamps/?filename=${this.state.lot.opentimestamps[index].ots}&lotNum=${this.state.lot.lotNumber}`) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                    <ExitToAppIcon />
                                  </Fab>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        : <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={3}>
                              {this.props.t('tabs.timestamps.table.noData')}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            {/* FASI */}
            <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-2" aria-labelledby="simple-tabpanel-2" className={`${classes.tabContainer} tabContent`}>
              {this.state.lot.traceability.map((track, trackIndex) => <div key={`tab-traceability-${trackIndex}`} component="div" hidden={this.state.phaseTabNumber !== trackIndex} id={`simple-phasetabpanel-${trackIndex}`} aria-labelledby={`simple-phasetabpanel-${trackIndex}`} className={classes.tabContainer}>
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                  <Grid container direction='row' justify='flex-start' alignItems='center'>
                    <Grid item xs={10}>
                      <Typography variant="h5">
                        {track.name ? track.name : this.props.t('tabs.traceability.generalName')}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => this.handleVisibility(trackIndex)} disabled={!this.state.isModifyEnabled}>
                        {this.state.isShowPhase
                          ? (<><Tooltip title={this.props.t('tabs.traceability.inputs.tooltipVisibility.hide')}><Visibility></Visibility></Tooltip></>)
                          : (<><Tooltip title={this.props.t('tabs.traceability.inputs.tooltipVisibility.show')}><VisibilityOff></VisibilityOff></Tooltip></>)}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ width: '100%' }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={4}>
                      <Grid item xs={12} md={5}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <CardMedia style={{ height: '280px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} title="immagini della fase" image={track.media[0] ? track.media[0].original : '/images/img-placeholder.png'}>
                            </CardMedia>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                              <Typography variant="h6" style={{ marginRight: '10px' }}>
                                {this.props.t('tabs.traceability.inputs.documents')}
                              </Typography>
                              <input className={classes.input} id={`add-new-document-${trackIndex}`} type="file" multiple accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => this.uploadDocument(e, trackIndex)} style={{ display: 'none' }} disabled={!this.state.isModifyEnabled} />
                              <label htmlFor={`add-new-document-${trackIndex}`}>
                                <Fab classes={{ primary: classes.fabPrimary }} size="small" component="span" color="primary" aria-label={this.props.t('tabs.traceability.inputs.addDocument')} disabled={!this.state.isModifyEnabled}>
                                  <AddIcon />
                                </Fab>
                              </label>
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%', margin: '20px 0' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                              {(track.documents.length > 0 && track.documents[0].fileUrl) &&
                                track.documents.map((t, documentIndex) => <Chip title={t.name || 'documento'} classes={{ colorPrimary: classes.chipPrimary }} size="small"
                                  key={`doc-${documentIndex}`}
                                  icon={<InsertDriveFile />}
                                  color="primary"
                                  label={<span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}>{t.name || 'documento'}</span>}
                                  onClick={() => this.openMedia(t.fileUrl)}
                                  onDelete={() => this.state.isModifyEnabled ? this.deleteDocument(trackIndex, documentIndex) : ''}
                                />)
                              }
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Grid container direction="column" justify="flex-start" alignItems="center">
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                  <KeyboardDatePicker
                                    className={classes.w100}
                                    required
                                    margin="normal"
                                    id={`startTimestamp-${trackIndex}`}
                                    label={this.props.t('tabs.traceability.inputs.startedAt')}
                                    format="dd/MM/yyyy"
                                    cancelLabel={this.props.t('tabs.traceability.inputs.dateCancel')}
                                    value={track.startedAt}
                                    onChange={(date) => this.startTimestampChanged(date, trackIndex)}
                                    KeyboardButtonProps={{
                                      'aria-label': this.props.t('tabs.traceability.inputs.startedAt')
                                    }}
                                    disabled={!this.state.isModifyEnabled}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                  <KeyboardDatePicker
                                    className={classes.w100}
                                    margin="normal"
                                    required
                                    id={`timestamp-${trackIndex}`}
                                    label={this.props.t('tabs.traceability.inputs.completedAt')}
                                    format="dd/MM/yyyy"
                                    cancelLabel={this.props.t('tabs.traceability.inputs.dateCancel')}
                                    value={track.completedAt}
                                    onChange={(date) => this.timestampChanged(date, trackIndex)}
                                    KeyboardButtonProps={{
                                      'aria-label': this.props.t('tabs.traceability.inputs.completedAt')
                                    }}
                                    disabled={!this.state.isModifyEnabled}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </Grid>
                          {this.props.isFels
                            ? <>
                              <Paper component="ul" className={classes.root}>
                                {this.state.chipData.map((data) => {
                                  return (
                                    <li key={data.key}>
                                      <Chip
                                        label={data.label}
                                        disabled={!this.state.isModifyEnabled}
                                        onClick={() =>
                                          this.concatDescription(data.label, trackIndex, this.state.selectionStart)
                                        }
                                        className={classes.chip}
                                      />
                                    </li>
                                  )
                                })}
                              </Paper>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                margin="dense"
                                id="description"
                                onSelect={() => this.updateSelectionStart(trackIndex)}
                                inputRef={this.state.inputRef[trackIndex]}
                                name="description"
                                value={track.description}
                                label="Descrizione"
                                onChange={(e) => this.valueChanged(e, trackIndex)}
                                disabled={!this.state.isModifyEnabled}
                                type="text" fullWidth rowsMax="12" rows="4" multiline />
                              <p>{this.state.selectionStart}</p>
                            </>
                            : <TextField InputLabelProps={{ shrink: true }} margin="dense" id="description" name="description" value={track.description} label="Descrizione" onChange={(e) => this.valueChanged(e, trackIndex)}
                              disabled={!this.state.isModifyEnabled}
                              type="text" fullWidth rowsMax="12" rows="4" multiline />
                          }
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              <Grid item xs={12} md={(Array.isArray(track.outputs) && track.outputs.length > 0) ? 6 : 12}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <Typography style={{ margin: '20px 0 10px 0' }} variant="h6">
                                      {this.props.t('tabs.traceability.inputs.inputMaterials')}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    {track.inputs.map((input, inputIndex) => (
                                      <div key={`input-${inputIndex}`}>
                                        <Typography variant="body2">
                                          {`${inputIndex + 1})`} {input.productId ? this.state.productsIdNameMap[input.productId] : 'fase'}
                                        </Typography>
                                        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                          <Grid item xs={12} md={6}>
                                            <TextField required InputLabelProps={{ shrink: true }} id="lotNumber" name="lotNumber" label={this.props.t('tabs.traceability.inputs.lotNumber')} type="text" fullWidth value={input.lotNumber} margin="normal"
                                              onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} disabled={!this.state.isModifyEnabled} />
                                          </Grid>
                                          <Grid item xs={12} md={6}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                              <Grid item xs={8}>
                                                <TextField InputLabelProps={{ shrink: true }} id="quantity" name="quantity" label={this.props.t('tabs.traceability.inputs.quantity')} type="number" min="0" fullWidth value={input.quantity} margin="normal"
                                                  onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} disabled={!this.state.isModifyEnabled} />
                                              </Grid>
                                              <Grid item xs={2} style={{ marginBottom: '10px' }}>
                                                <Tooltip title={this.props.t('tabs.traceability.inputs.uomInfo')} placement="top-end"><Info fontSize="small" /></Tooltip>{input.uom ? input.uom : ''}
                                              </Grid>
                                              {trackIndex === 0 &&
                                                      <Grid item md={1} xs={1}>
                                                        <Tooltip title={this.props.t('tabs.traceability.inputs.allLotsAreSameCheckbox')} placement='top-end'>
                                                          <Checkbox
                                                            disabled={!this.state.isModifyEnabled}
                                                            checked={this.state.allLotsAreSameCheckbox}
                                                            onChange={(event) => this.setAllLotsAreSameCheckbox(event, inputIndex) }
                                                            size="small"
                                                            inputProps={{ 'aria-label': 'checkbox with small size' }}
                                                          />
                                                        </Tooltip>
                                                      </Grid>
                                              }
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {(Array.isArray(track.outputs) && track.outputs.length > 0) && (
                                <Grid item xs={12} md={6}>
                                  <Typography style={{ margin: '20px 0 10px 0' }} variant="h6">
                                    {this.props.t('tabs.traceability.inputs.outputMaterials')}
                                  </Typography>
                                  {track.outputs.map((output, outputIndex) => (
                                    <div key={`output-${outputIndex}`}>
                                      <Typography variant="body2">
                                        {`${outputIndex + 1})`} {output.productId ? this.state.productsIdNameMap[output.productId] : 'fase'}
                                      </Typography>
                                      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                        <Grid item xs={12} md={6}>
                                          <TextField required InputLabelProps={{ shrink: true }} id="lotNumber" name="lotNumber" label={this.props.t('tabs.traceability.inputs.lotNumber')} type="text" fullWidth value={output.lotNumber} margin="normal"
                                            onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} disabled={!this.state.isModifyEnabled} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <Grid container direction="row" justify="flex-start" alignItems="flex-end" spacing={2}>
                                            <Grid item xs={6}>
                                              <TextField InputLabelProps={{ shrink: true }} id="quantity" name="quantity" label={this.props.t('tabs.traceability.inputs.quantity')} type="number" min="0" fullWidth value={output.quantity} margin="normal"
                                                onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} disabled={!this.state.isModifyEnabled} />
                                            </Grid>
                                            <Grid style={{ marginBottom: '10px' }} item xs={3}>
                                              <Tooltip title={this.props.t('tabs.traceability.inputs.uomInfo')} placement="top-end"><Info fontSize="small" /></Tooltip>{output.uom ? output.uom : ''}</Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>)}
            </div>
          </DialogContent>
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <DialogActions classes={{ root: classes.mobileActions }}>
              <Button startIcon={<DeleteIcon />} onClick={() => this.openConfirmDialog()} color="white" aria-label="delete lot">
                {this.props.t('buttons.delete')}
              </Button>
              {this.state.isModifyEnabled && <Button startIcon={<LockOpen />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="allow-modify" title={this.props.t('buttons.lockEdits')} onClick={() => this.enableOrDisableModify()}>
                {this.props.t('buttons.close')}
              </Button>}
              {!this.state.isModifyEnabled && <Button startIcon={<Lock />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="disallow-modify" title={this.props.t('buttons.unlockEdits')} onClick={() => this.enableOrDisableModify()}>
                {this.props.t('buttons.edit')}
              </Button>}
            </DialogActions>
            : ''}
        </Dialog>
      </React.Fragment>
    )
  }
}

CardModalLotClassic.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  lotNumber: PropTypes.string,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('lotDetails')(CardModalLotClassic))))
