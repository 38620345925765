import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import LocationComponent from '../StepUIProductComponent/LocationComponent/LocationComponent'
import TitleWithAddButton from '../StepUIProductComponent/AdditionalBlocks/TitleWithAddButton'
import Title from '../StepUIProductComponent/AdditionalBlocks/Title'
import InputProductComponent from '../StepUIProductComponent/InputComponents/InputProductComponent'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   tabContainer: {
//     padding: theme.spacing(2),
//     height: '100%'
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function CommissionStepProduct (props) {
  const {
    track,
    trackIndex,
    isModifyEnabled,
    addInput,
    deleteInput,
    locationChanged,
    inputChanged,
    facilities,
    product,
    products,
    translation
  } = props
  // const classes = useStyles()

  return (
    <Grid item xs={12} style={{ width: '100%' }} className="inputContainer">
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container direction="column" justify="flex-start" alignItems="flex-start">
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} style={{ width: '100%' }}>
                <Title
                  translation={translation}
                ></Title>
                <LocationComponent
                  track={track}
                  trackIndex={trackIndex}
                  locationChanged={locationChanged}
                  isModifyEnabled={isModifyEnabled}
                  facilities={facilities}
                  translation={translation}
                >
                </LocationComponent>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ width: '100%' }}>
              <TitleWithAddButton
                trackIndex={trackIndex}
                isModifyEnabled={isModifyEnabled}
                addComponent={addInput}
                translation={translation}
                position={'Inputs'}
              ></TitleWithAddButton>
              {track.inputs.map((input, inputIndex) => {
                return (
                  <InputProductComponent
                    key={`${input.productId}-${inputIndex}-${trackIndex}`}
                    track={track}
                    trackIndex={trackIndex}
                    inputIndex={inputIndex}
                    deleteInput={deleteInput}
                    input={input}
                    isModifyEnabled={isModifyEnabled}
                    inputChanged={inputChanged}
                    product={product}
                    products={products}
                    translation={translation}
                    type={'materialInputs'}
                  ></InputProductComponent>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

CommissionStepProduct.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  locationChanged: PropTypes.func.isRequired,
  addInput: PropTypes.func.isRequired,
  inputChanged: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  deleteInput: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default CommissionStepProduct
