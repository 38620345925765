/* Componente Padre che forma il blocco productId, LotNumber, Quantity,Uom */
import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  TextField
} from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function UomComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    componentIndex,
    component,
    isModifyEnabled,
    componentChanged,
    type
  } = props

  return (
    <Grid item xs={12} md={6}>
      <TextField required
        InputLabelProps={{ shrink: true }}
        id={`${type}-${trackIndex}-unita-di-misura-${componentIndex}`}
        name={'uom'}
        label={t('tabs.traceability.inputs.materialInputs.measurementUnit')}
        fullWidth
        value={component.uom}
        onChange={(e) => componentChanged(e, trackIndex, componentIndex)}
        disabled={!isModifyEnabled}
      />
    </Grid>
  )
}

UomComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  component: PropTypes.object.isRequired, // input or output
  isModifyEnabled: PropTypes.bool.isRequired,
  componentChanged: PropTypes.func.isRequired, // inputChanged or outputChanged
  type: PropTypes.string.isRequired
}
export default UomComponent
