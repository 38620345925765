import React from 'react'
import { withTranslation } from 'react-i18next'
import { returnAllSupplyChainFacilities } from '../../utils/facilities'
import { getLocalStorageSupplychainFiltered, isThisAccountHasLightLotFunctionality } from '../../utils/acl-organization'
import { returnAllSupplychains } from '../../utils/supplychains'
import { returnAllSupplyChainCompanies, returnAllSupplyChainCompaniesProjection } from '../../utils/companies'
import { returnAllSupplyChainProductsProjection } from '../../utils/products'
import { returnAllSupplychainLotProjection, getLastInputOrOutputInformationSupplychainField } from '../../utils/lots'
import { returnAllSupplychainLightLotProjection } from '../../utils/lightLots'

import configuration from '../../configuration'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import { domainToLogos } from '../../domainToLogos'

import { isLocalhost } from '../../serviceWorker'

import {

  Grid,

  InputLabel,
  MenuItem,

  Select,
  withStyles,
  FormControl,

  withWidth
} from '@material-ui/core'

const styles = theme => ({
  '@global': {
    '.MuiInputBase-input': {
      padding: '6px 0 4px'
    },
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  progressStyle: {
    color: theme.primary
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: '#FFFFFF',
    width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  formControl: {
    minWidth: 200
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  selectContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 12
    }
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

class Supplychains extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      allSupplychainsId: getLocalStorageSupplychainFiltered(),
      hasLightLotFunctionality: isThisAccountHasLightLotFunctionality(),
      companies: [],
      facilities: [],
      products: [],
      lots: [],
      supplychainsId: '',
      elementType: '', // 0 : facility; 1 : company;  2 : product; 3 : lots
      supplychainColumns: [],
      supplychainMobileColumns: [],
      supplychainColumnsLastProducts: [],
      supplychainMobileColumnsLastProducts: [],
      tableData: [],
      allSupplychainIdNameMap: {},
      frontEndUrl: isLocalhost ? configuration.frontendBaseUrl : (this.props.domain.domain === 'trusty' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}`)
    }
    this.handleChangeSelectSupplychain = this.handleChangeSelectSupplychain.bind(this)
    this.handleChangeSelectElement = this.handleChangeSelectElement.bind(this)
    this.supplychainElementLoads = this.supplychainElementLoads.bind(this)
    this.openUrl = this.openUrl.bind(this)
  }

  async componentDidMount () {
    const allSupplychainsDocsResource = await returnAllSupplychains(this.state.allSupplychainsId)
    const allSupplychainIdNameMap = allSupplychainsDocsResource
      .data.data
      .reduce((acc, supply) => {
        acc[supply.uuid] = supply.name
        return acc
      }, {})
    this.setState({ allSupplychainIdNameMap })
  }

  openUrl (url) {
    window.open(url, '_blank')
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.elementType !== this.state.elementType) {
      this.supplychainElementLoads(this.state.elementType)
    }
  }

  async supplychainElementLoads (type) {
    switch (type) {
      case 0: {
        const companiesSupplychainParams = {

          fields: 'name,uuid'
        }
        const facilitiesResponse = await returnAllSupplyChainFacilities(this.state.supplychainsId)
        const facilities = facilitiesResponse.data.data.facilities
          .filter(fac => fac.status === 'active')
        /* richiedo anche le company per poter mettere la colonna con il company name */
        const companiesNameResponse = await returnAllSupplyChainCompaniesProjection(this.state.supplychainsId, companiesSupplychainParams)
        const companyName = companiesNameResponse.data.data.companies
        facilities.forEach(facility => {
          const company = companyName.find(c => c.uuid === facility.companyId)
          facility.companyData = company
        })
        const supplychainColumns = [
          { title: this.props.t('table.facilities.name'), field: 'name' },
          { title: this.props.t('table.facilities.company'), field: 'companyData.name' },
          { title: this.props.t('table.facilities.city'), field: 'address.city' }
        ]
        return this.setState({
          facilities,
          supplychainColumns,
          tableData: facilities
        })
      }
      case 1: {
        const companiesResponse = await returnAllSupplyChainCompanies(this.state.supplychainsId)
        const companies = companiesResponse.data.data.companies
          .filter(comp => comp.status === 'active')
        const supplychainColumns = [
          { title: this.props.t('table.companies.name'), field: 'name' },
          { title: this.props.t('table.companies.piva'), field: 'vatId' },
          { title: this.props.t('table.companies.city'), field: 'address.city' }
        ]
        return this.setState({
          companies,
          supplychainColumns,
          tableData: companies
        })
      }
      case 2:
      {
        const companiesSupplychainParams = {
          fields: 'name,uuid'
        }
        const productSupplychainParams = {
          fields: 'name,status,uuid,organizationId,companyId,gtin'
        }
        const productsResponse = await returnAllSupplyChainProductsProjection(this.state.supplychainsId, productSupplychainParams)
        /* prendo solo i prodotti active -> bisogna far si che il il filter dei params funzioni */
        const products = productsResponse.data.data.products
          .filter(prod => prod.status === 'active')
        /* richiedo anche le company per poter mettere la colonna con il company name */
        const companiesNameResponse = await returnAllSupplyChainCompaniesProjection(this.state.supplychainsId, companiesSupplychainParams)
        const companyName = companiesNameResponse.data.data.companies
        products.forEach(product => {
          const company = companyName.find(c => c.uuid === product.companyId)
          product.companyData = company
        })
        const supplychainColumns = [
          { title: this.props.t('table.products.name'), field: 'name' },
          { title: this.props.t('table.products.company'), field: 'companyData.name' },
          { title: this.props.t('table.products.gtin'), field: 'gtin' }
        ]
        return this.setState({
          products,
          supplychainColumns,
          tableData: products
        })
      }
      case 3:
      {
        const productSupplychainParams = {
          fields: 'name,gtin,uuid,companyId'
        }
        /* devo ritornarmi anche i campi sulla tracciabilità per poter prendere la quantità e visualizzarla sulla tabella */
        const lotParams = {
          fields: 'lotNumber,status,uuid,productId,traceability'
        }
        const companiesParams = {
          filter: JSON.stringify({ status: { $ne: 'deleted' } }),
          limit: Number.MAX_SAFE_INTEGER,
          fields: 'name,uuid'
        }
        const lotResponse = await returnAllSupplychainLotProjection(this.state.supplychainsId, lotParams)
        const lots = lotResponse.data.data.lots
          .filter(lot => lot.status === 'active')
        /* richiedo anche i product per poter mettere la colonna con il product name name */
        const productNameResponse = await returnAllSupplyChainProductsProjection(this.state.supplychainsId, productSupplychainParams)
        const productName = productNameResponse.data.data.products
        /* richiedo anche le company per poter mettere la colonna con il company name */
        const companiesNameResponse = await returnAllSupplyChainCompaniesProjection(this.state.supplychainsId, companiesParams)
        const companyName = companiesNameResponse.data.data.companies
        lots.forEach(lot => {
          const product = productName.find(c => c.uuid === lot.productId)
          if (product) {
            product.companyName = companyName.find(c => c.uuid === product.companyId).name
            lot.productData = product
            lot.quantity = getLastInputOrOutputInformationSupplychainField(lot)
          } else {
            lot.productData = {
              name: this.props.t('table.lots.undefined'),
              companyName: this.props.t('table.lots.undefined'),
              gtin: this.props.t('table.lots.undefined')
            }
            lot.quantity = getLastInputOrOutputInformationSupplychainField(lot)
          }
        })
        const supplychainColumns = [
          { title: this.props.t('table.lots.lotNumber'), field: 'lotNumber' },
          { title: this.props.t('table.lots.quantity'), field: 'quantity' },
          { title: this.props.t('table.lots.product'), field: 'productData.name' },
          { title: this.props.t('table.lots.company'), field: 'productData.companyName' },
          { title: this.props.t('table.lots.gtin'), field: 'productData.gtin' }
        ]
        return this.setState({
          lots,
          supplychainColumns,
          tableData: lots
        })
      }
      case 4:
      {
        const productSupplychainParams = {
          fields: 'name,gtin,uuid,companyId'
        }
        /* devo ritornarmi anche i campi sulla tracciabilità per poter prendere la quantità e visualizzarla sulla tabella */
        const lotParams = {
          fields: 'lotNumber,status,uuid,productId,traceability'
        }
        const companiesParams = {
          filter: JSON.stringify({ status: { $ne: 'deleted' } }),
          limit: Number.MAX_SAFE_INTEGER,
          fields: 'name,uuid'
        }
        const lotResponse = await returnAllSupplychainLightLotProjection(this.state.supplychainsId, lotParams)
        const lots = lotResponse.data.data.lots
          .filter(lot => lot.status === 'active')
        /* richiedo anche i product per poter mettere la colonna con il product name name */
        const productNameResponse = await returnAllSupplyChainProductsProjection(this.state.supplychainsId, productSupplychainParams)
        const productName = productNameResponse.data.data.products
        /* richiedo anche le company per poter mettere la colonna con il company name */
        const companiesNameResponse = await returnAllSupplyChainCompaniesProjection(this.state.supplychainsId, companiesParams)
        const companyName = companiesNameResponse.data.data.companies
        lots.forEach(lot => {
          const product = productName.find(c => c.uuid === lot.productId)
          if (product) {
            product.companyName = companyName.find(c => c.uuid === product.companyId).name
            lot.productData = product
          } else {
            lot.productData = {
              name: this.props.t('table.lots.undefined'),
              companyName: this.props.t('table.lots.undefined'),
              gtin: this.props.t('table.lots.undefined')
            }
          }
        })
        const supplychainColumns = [
          { title: this.props.t('table.lots.lotNumber'), field: 'lotNumber' },
          { title: this.props.t('table.lots.product'), field: 'productData.name' },
          { title: this.props.t('table.lots.company'), field: 'productData.companyName' },
          { title: this.props.t('table.lots.gtin'), field: 'productData.gtin' }
        ]
        return this.setState({
          lots,
          supplychainColumns,
          tableData: lots
        })
      }
      default:
        break
    }
  }

  handleChangeSelectSupplychain (event) {
    this.setState({
      supplychainsId: event.target.value,
      elementType: '',
      tableData: [],
      supplychainColumns: []
    })
  }

  handleChangeSelectElement (event) {
    this.setState({
      elementType: event.target.value
    })
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <>
        <Grid container direction="row" alignItems="center" className={`${classes.root} filterRoot`}>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">{this.props.t('inputs.supplychain')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.supplychainsId}
                onChange={this.handleChangeSelectSupplychain}
              >
                <MenuItem value={''}>{this.props.t('inputs.none')}</MenuItem>
                {this.state.allSupplychainsId.map((id) => (
                  <MenuItem key={id} value={id}>
                    {this.state.allSupplychainIdNameMap[id]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">{this.props.t('inputs.type')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.elementType}
                onChange={this.handleChangeSelectElement}
              >
                <MenuItem value={''}>{this.props.t('inputs.none')}</MenuItem>
                <MenuItem value={1}>{this.props.t('inputs.companies')}</MenuItem>
                <MenuItem value={0}>{this.props.t('inputs.facilities')}</MenuItem>
                <MenuItem value={2}>{this.props.t('inputs.products')}</MenuItem>

                {this.state.hasLightLotFunctionality
                  ? <MenuItem value={4}>{this.props.t('inputs.lots')}</MenuItem>
                  : <MenuItem value={3}>{this.props.t('inputs.lots')}</MenuItem>
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" justify="space-between" className={`${classes.root} filterRoot`}>
          <Grid item xs={12}>
            <MaterialTable
              title={this.props.t('table.title')}
              localization={{
                toolbar: this.props.t('table.localization.toolbar', { returnObjects: true }),
                body: {
                  emptyDataSourceMessage: this.state.selectedProductId !== '' ? this.props.t('table.localization.body.emptyDataSourceMessage') : this.props.t('table.localization.body.emptyDataSourceMessage2')
                },
                pagination: this.props.t('table.localization.pagination', { returnObjects: true }),
                header: this.props.t('table.localization.header', { returnObjects: true })
              }}
              columns={isSmall ? this.state.lotsMobileColumnsLastProducts : this.state.supplychainColumns}
              data={this.state.tableData}
              options={{
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
                pageSize: 20,
                pageSizeOptions: [20, 40, 60, 80, 100],
                showEmptyDataSourceMessage: true,
                rowStyle: rowData => ({
                  backgroundColor: rowData.status === 'draft' ? '#F6DA8D' : (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                })
              }}
              actions={[(rowData) => {
                if (this.state.elementType === 2) {
                  return {
                    icon: 'open_in_new',
                    tooltip: this.props.t('table.products.goToPage'),
                    onClick: (event, rowData) => {
                      // console.log('rowData => ', rowData)
                      this.openUrl(`${this.state.frontEndUrl}/gtin/${rowData.gtin}`)
                    }
                  }
                } else if (this.state.elementType === 3) {
                  return {
                    icon: 'open_in_new',
                    tooltip: this.props.t('table.lots.goToPage'),
                    onClick: (event, rowData) => {
                      // console.log('rowData => ', rowData)
                      if (rowData.productData.gtin !== this.props.t('table.lots.undefined')) {
                        this.openUrl(`${this.state.frontEndUrl}/gtin/${rowData.productData.gtin}/lot/${encodeURIComponent(rowData.lotNumber)}`)
                      }
                    }
                  }
                } else {
                  return {
                    icon: 'open_in_new',
                    hidden: true,
                    onClick: (event, rowData) => {
                      console.log('DISABLED You Clicked => ', rowData)
                    }
                  }
                }
              }
              ]}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('supplychain')(Supplychains))))
