import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import CardModalProductClassic from './CardModalProductClassic.jsx'
import NewCardModalProductSupplychain from './NewCardModalProductSupplychain'
import {
  withStyles,
  withWidth
} from '@material-ui/core'

import { withTranslation } from 'react-i18next'
import { getLocalStorageSupplychain } from '../../utils/acl-organization'
// import axios from 'axios'
import ls from 'local-storage'

// import configuration from '../../configuration.js'

import { amber } from '@material-ui/core/colors'
// import { relativeTimeRounding } from 'moment'

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  title: {
    flexGrow: 1
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  paperDialog: {
    maxHeight: '70vh'
  },
  actionButtonContainer: {
    width: '10%',
    backgroundColor: '#fafafa'
  },
  arrowContainer: {
    width: '5%',
    backgroundColor: '#fafafa'
  },
  chipContainer: {
    width: '85%',
    backgroundColor: '#fafafa',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  navBar: {
    color: 'white'
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tableContainer: {
    marginTop: 16
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  icon: {
    fontSize: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  primary: {
    color: theme.primary
  },
  warningText: {
    color: amber[700]
  },
  primaryDark: {
    color: theme.primaryDark
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  deletedStyle: {
    backgroundColor: '#fff18c',
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModalProduct extends React.Component {
  constructor (props) {
    super(props)
    console.log('SIAMO NEL COSTRUTTORE!!!')
    this.user = ls.get('user') || {}

    this.returnDefaultState = this.returnDefaultState.bind(this)

    this.state = this.returnDefaultState()
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti

  // funzione che torno lo stato di default per il reset
  returnDefaultState () {
    return {
      supplychainsId: getLocalStorageSupplychain()
    }
  }

  /* funzione che mi prepara tutti gli input per le company, le facilities e i prodotti */

  render () {
    // creo variabile che sarà true se siamo da mobile
    if (this.state.supplychainsId.length > 0) {
      return (
        <NewCardModalProductSupplychain
          setSnackbar={this.props.setSnackbar}
          product={this.props.product}
          open={this.props.open}
          onCloseModal={this.props.onCloseModal}
          frontEndUrl={this.props.frontEndUrl}
          supplychainsId = {this.state.supplychainsId}
        />
      )
    } else {
      return (
        <CardModalProductClassic
          isFels={this.props.isFels}
          setSnackbar={this.props.setSnackbar}
          product={this.props.product}
          open={this.props.open}
          onCloseModal={this.props.onCloseModal}
          frontEndUrl={this.props.frontEndUrl}
        />
      )
    }
  }
}

CardModalProduct.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func,
  open: PropTypes.bool,
  product: PropTypes.object
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('productDetails')(CardModalProduct))))
