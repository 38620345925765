import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'

import DeleteComponent from './DeleteComponent'
import VerifyAndDiscover from './VerifyAndDiscover'

const useStyles = makeStyles((theme) => ({
  blockLine: {
    padding: '10px 0',
    marginTop: '25px',
    borderTop: '1px solid #eaeaea'
  }
}))

function ActionsInBlock (props) {
  const classes = useStyles()

  const {
    trackIndex,
    componentIndex,
    isModifyEnabled,
    deleteComponent,
    verifyInput,
    component,
    frontEndUrl,
    openUrl
  } = props

  return (
    <Grid
      container
      direction='row'
      justify='center'
      alignItems='center'
      spacing={2}
      className={classes.blockLine}
    >
      <Grid item xs={6} md={6}>
        <VerifyAndDiscover
          trackIndex={trackIndex}
          openUrl={openUrl}
          verifyInput={verifyInput}
          component={component}
          frontEndUrl={frontEndUrl}
          componentIndex={componentIndex}
        ></VerifyAndDiscover>
      </Grid>
      <Grid item xs={6} md={6}>
        <DeleteComponent
          trackIndex={trackIndex}
          componentIndex={componentIndex}
          deleteComponent={deleteComponent}
          isModifyEnabled={isModifyEnabled}
        ></DeleteComponent>
      </Grid>
    </Grid>
  )
}

ActionsInBlock.propTypes = {
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  deleteComponent: PropTypes.func.isRequired, // deleteInput or deleteOutput
  openUrl: PropTypes.func.isRequired,
  verifyInput: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  frontEndUrl: PropTypes.string.isRequired
}
export default ActionsInBlock
