/*
        {
    "data": {
        "status": false,
        "error": {
            "statusCode": 409,
            "type": "Conflict",
            "message": "Company already subscribed",
            "name": "Conflict",
            "code": "Conflict"
        }
    },
    "status": 409,
    "statusText": "Conflict",
    "headers": {
        "content-length": "178",
        "content-type": "application/json; charset=utf-8"
    },
    "config": {...}
*/

import {
  Grid,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
  withWidth,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import FileSaver from 'file-saver'

import { createLabelFromLot } from '../../utils/lightLots'

import React from 'react'
import PropTypes from 'prop-types'

const styles = theme => ({
  '@global': {
    '.MuiDialog-root': {
      minWidth: '80%',
      minHeight: 300
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    overflow: 'scroll'
  },
  modalSize: {
    width: 300,
    height: 300,
    backgroundColor: 'red'
  },
  progressStyle: {
    color: theme.primary
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 'auto',
    outline: 0
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  navBar: {
    color: 'white'
  },
  tableContainer: {
    marginTop: 16
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  paperDialog: {
    maxHeight: '70vh'
  },
  imgInput: {
    display: 'none'
  },
  imgCover: {
    // width: 'auto',
    // height: '250px',
    margin: '10px',
    width: 400,
    height: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  modifyImgButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  textfieldPrimary: {
    color: theme.primary
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  dialogPaper: {
    minHeight: 200,
    maxHeight: 500
  },
  paperRootMiddleSize: {
    maxHeight: '700px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})
class WeightModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open,
      weight: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.createLabel = this.createLabel.bind(this)
  }

     handleClose = () => {
       this.props.onClose()
     }

     createLabel = async () => {
       const data = await createLabelFromLot(this.props.lotUuid, { productName: this.props.productName, lotNumber: this.props.lotNumber, productGtin: this.props.productGtin, weight: this.state.weight || '0' })
       const fileName = data.headers['content-disposition'].slice(data.headers['content-disposition'].indexOf('=') + 1)

       const file = new Blob([data.data], { type: data.headers['content-type'] })
       FileSaver.saveAs(file, fileName)
     }

     handleChange (event) {
       this.setState({
         [event.target.name]: event.target.value
       })
     }

     render () {
       const classes = this.props.classes
       return (
         <>
           <Dialog
             className={'mailModal'}
             classes={{ paper: classes.dialogPaper }}
             onClose={this.handleClose}
             aria-labelledby="simple-dialog-title"
             open={this.state.open}
             fullWidth={true}
             maxWidth={'sm'}
           >
             <DialogTitle id="simple-dialog-title">{this.props.t('lotLabelModal.header')}</DialogTitle>
             <DialogContent>
               <DialogContentText>
                 {this.props.t('lotLabelModal.body')}
               </DialogContentText>
               <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                 <Grid item xs={12} style={{ width: '100%' }}>
                   <TextField
                     autoFocus
                     margin="dense"
                     name="weight"
                     id="weight"
                     value={this.state.weight}
                     label={this.props.t('lotLabelModal.weight')}
                     type="number"
                     fullWidth
                     onChange={this.handleChange}
                   />
                 </Grid>
               </Grid>
             </DialogContent>
             <DialogActions>
               <Button onClick={this.handleClose} color="primary">
                 {this.props.t('lotLabelModal.close')}
               </Button>
               <Button onClick={this.createLabel} color="primary">
                 {this.props.t('lotLabelModal.createLabel')}
               </Button>
             </DialogActions>
           </Dialog>
         </>
       )
     }
}

WeightModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  supplychainId: PropTypes.string,
  domain: PropTypes.object
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('lots')(WeightModal))))
