import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  withStyles,
  TextField,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  Tab,
  Tabs,
  Fab,
  Chip,
  withWidth
  // Switch
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import QRCode from 'qrcode.react'
import { withTranslation } from 'react-i18next'

import { replaceLightLot, deleteLightLot } from '../../utils/lightLots'
import { returnAllFacilitiesProjection, returnAllSupplyChainFacilitiesProjection } from '../../utils/facilities'
import { returnAllSupplyChainProductsProjection } from '../../utils/products'
import { deepCloneObj, checkFileSize, generateUuid, loadFile, ItLocalizedUtils, locale, urlTobase64, imageToBlobAndUrl, isIsoDate } from '../../utils/utils'
import { returnAllCompaniesProjection, returnAllSupplyChainCompaniesProjection } from '../../utils/companies.js'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  Lock,
  LockOpen,
  Add as AddIcon,
  ArrowBack,
  ArrowForward,
  GetApp as GetAppIcon,
  ExitToApp as ExitToAppIcon
} from '@material-ui/icons'
// import ErrorIcon from '@material-ui/icons/Error'

import NameComponent from '../StepUIProductComponent/NameComponent.jsx'
import DescriptionComponent from '../StepUIProductComponent/DescriptionComponent.jsx'
import SelectEventTypeComponent from '../StepUIProductComponent/SelectEventType/SelectEventTypeComponent.jsx'
import CommissionStepProduct from '../StepProduct/CommissionStepProduct.jsx'
import TransformationStepProduct from '../StepProduct/TransformationStepProduct.jsx'
import AddTrackComponent from '../StepUIProductComponent/AddTrackComponent/AddTrackComponent.jsx'

import DeliverStepProduct from '../StepProduct/DeliverStepProduct.jsx'
import AddImageTrackComponent from '../StepUIProductComponent/AddImageTrack/AddImageTrackComponent.jsx'
import AddDocumentsComponent from '../StepUIProductComponent/AddDocuments/AddDocumentsComponent.jsx'

const getLastLotnumber = lot => {
  const { traceability } = lot

  if (!traceability || !traceability.length) {
    return
  }

  if (Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length && traceability[traceability.length - 1].outputs.length > 0) {
    const outputs = traceability[traceability.length - 1].outputs
    return outputs[outputs.length - 1].lotNumber
  }

  if (Array.isArray(traceability[traceability.length - 1].inputs) && traceability[traceability.length - 1].inputs.length && traceability[traceability.length - 1].inputs.length > 0) {
    const inputs = traceability[traceability.length - 1].inputs
    return inputs[inputs.length - 1].lotNumber
  }
}

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiFormLabel-root': {
      fontSize: '16px'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    margin: 'auto',
    width: '80%',
    height: '70%',
    outline: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primary: {
    color: theme.primary
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: '#c8e0e8',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#c8e0e8'
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  // chipPrimary: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primary,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  // chipPrimaryDark: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primaryDark,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class NewCardModalLightLotSupplychain extends React.Component {
  constructor (props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    // this.onDragEnd = this.onDragEnd.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.changePhaseTab = this.changePhaseTab.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.deleteLot = this.deleteLot.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.deadlineTimestampChanged = this.deadlineTimestampChanged.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.toogleAlreadyCompletedDialog = this.toogleAlreadyCompletedDialog.bind(this)
    this.locationChanged = this.locationChanged.bind(this)
    this.addTraceabilityImage = this.addTraceabilityImage.bind(this)
    this.addTrack = this.addTrack.bind(this)
    this.addTraceabilityDocument = this.addTraceabilityDocument.bind(this)
    this.deleteTraceabilityDocument = this.deleteTraceabilityDocument.bind(this)
    this.trackDescriptionChanged = this.trackDescriptionChanged.bind(this)
    this.lotNumberChange = this.lotNumberChange.bind(this)
    this.trackNameChanged = this.trackNameChanged.bind(this)
    this.eventTypeChanged = this.eventTypeChanged.bind(this)
    this.deleteOutput = this.deleteOutput.bind(this)
    this.locationFromChanged = this.locationFromChanged.bind(this)
    this.locationToChanged = this.locationToChanged.bind(this)
    this.addFacilityTo = this.addFacilityTo.bind(this)
    this.addFacilityFrom = this.addFacilityFrom.bind(this)
    this.deleteFacilityFrom = this.deleteFacilityFrom.bind(this)
    this.deleteFacilityTo = this.deleteFacilityTo.bind(this)
    this.deleteInput = this.deleteInput.bind(this)
    this.addInput = this.addInput.bind(this)
    this.addOutput = this.addOutput.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.attributeNameChanged = this.attributeNameChanged.bind(this)
    this.attributeValueChanged = this.attributeValueChanged.bind(this)
    this.attributeValueDateChanged = this.attributeValueDateChanged.bind(this)
    this.addField = this.addField.bind(this)
    this.removeField = this.removeField.bind(this)
    this.supplyChainPreparation = this.supplyChainPreparation.bind(this)
    this.prepareSelectChoice = this.prepareSelectChoice.bind(this)
    /* funzione che governa la select dello stato per il lotto */
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward () {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward () {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  returnDefaultState () {
    return {
      filesToLoad: [],
      language: ls.get('i18nextLng'),
      isConfirmDialogOpened: false,
      canIAddAnotherAttribute: true,
      isShowPhase: (this.props.lot.traceability && this.props.lot.traceability.length > 0) ? this.props.lot.traceability[0].showPhase : true,
      isSaveDialogOpen: false,
      isAlreadyCompletedDialogOpen: false,
      someInfoAreMissing: false,
      tabNumber: 0,
      phaseTabNumber: 0,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      modified: false,
      open: this.props.open,
      isCompleted: false,
      facilities: [],
      products: this.props.products,
      lot: {
        ...this.props.lot,
        supplychains: [] /* array di oggetti id e name. Il tutto potrebbe essere gestito meglio, in quanto in caso di non draft il lotto si ritrova anche la proprietà lot.supplychainsId.
        ATTUALMENTE, PER NON ROMPERE NULLA CON IL PASSATO (LA SCELTA PER IL DRAFT è STATA PRESA IN SEGUITO) SI MANTIENE QUESTA POSSIBILIT */
      },
      initialLot: deepCloneObj(this.props.lot),
      /* la variabile di stato che tiene il controllo dello stato del lotto. Lo stato del lotto può essere:
      - aggiornato automaticamente man mano che si compilano le fasi
      - può essere aggiornato forzatamente (NB: questo tipo di aggiornamento si può fare solo quando il lotto può effettivamente passare da bozza ad attivo
      (ossia tutte le fasi complete) e, una volta che il lotto è stato salvato come attivo,
      non si può più far tornare a bozza
      */
      lotStatus: this.props.lot.status,
      product: this.props.product,
      verifyLot: {}, // oggetto formato {'numero-fase':[{input:inputIndex, verified:true}]}
      productsIdNameMap: this.props.productsIdNameMap,
      supplychainsId: this.props.lot.supplychainsId, // se il lotto è draft prendo le supplychain dal prodotto, se il lotto è complete prendo le supplychain del lotto
      allLotsAreSameCheckboxInput: false,
      allLotsAreSameCheckboxOutput: false
    }
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  changePhaseTab (phaseTabNumber) {
    this.setState({ phaseTabNumber })
  }

  addField (index) {
    const lot = this.state.lot
    const attributes = lot.attributes
    if (attributes[index] && (!attributes[index].name || !attributes[index].value)) {
      return this.setState({ canIAddAnotherAttribute: false })
    }

    attributes.push({
      name: '',
      value: ''
    })
    this.setState({ lot, canIAddAnotherAttribute: true })
  }

  removeField (index) {
    const attributes = deepCloneObj(this.state.lot.attributes)
    if (index === 0) {
      if (attributes.length === 1) {
        attributes[0].name = ''
        attributes[0].value = ''
      } else {
        attributes.splice(index, 1)
      }
    } else {
      attributes.splice(index, 1)
    }

    this.setState({
      lot: {
        ...this.state.lot,
        attributes
      },
      canIAddAnotherAttribute: true
    })
  }

  closeSaveDialog () {
    if (!this.state.isModifing) {
      this.setState({ isSaveDialogOpen: false })
    }
  }

  async componentDidMount () {
    const lot = this.supplyChainPreparation()
    const language = this.state.language === 'en-US' ? 'en' : 'it'

    // controllo se è un draft e abilito modifiche
    /* isCompleted, dopo che la modal è stata montata, viene aggiornato con il valore corretto in base a se ogni fase ha il valore completed */
    /* dopo che la modal è stata montata, lotStatus viene aggiornato a active o draft in base a se le fasi sono tutte complete.
    NB: se il lotto, che viene passato come props, ha lo stato ad active la select non si vede (in quanto, una volta che il lotto è stato reso attivo, non posso tornare a bozza)
    */

    await this.prepareSelectChoice()

    if (!Array.isArray(lot.attributes) || lot.attributes.length === 0) {
      lot.attributes = [{ name: '', value: '' }]
    }

    this.setState({
      lot,
      language
    })
  }

  async componentDidUpdate (prevPros, prevState) {
    if (prevState.lot.supplychains !== this.state.lot.supplychains) {
      await this.prepareSelectChoice()
    }
    if (this.state.isCompleted && (prevState.isCompleted !== this.state.isCompleted) && this.props.lot.status !== 'active') { this.props.setSnackbar(this.props.t('notifications.mantainDraft'), 'success') }
  }

  supplyChainPreparation () {
    const lot = deepCloneObj(this.state.lot)
    if (this.state.supplychainsId.length > 0) {
      lot.supplychains = this.state.supplychainsId.map(value => ({
        name: this.props.allSupplychainIdNameMap[value],
        id: value
      }))
    } else {
      lot.supplychains = []
    }
    console.log('lot::::', lot)
    return lot
  }

  /* funzione che mi prepara tutti gli input per le company, le facilities e i prodotti */
  async prepareSelectChoice () {
    // const instance = this.state.lot.status === 'draft' ? deepCloneObj(this.state.product) : deepCloneObj(this.state.lot) // quando voglio dare un comportamento diversificato in base a se si è draft (tipo poter definire tra le supplychain TUTTE quelle del prodotto se non definite nel lotto di partenza)
    const instance = deepCloneObj(this.state.lot)
    /* ci metto anche la company come parametro così che nelle select posso metterci il nome della facility e l'azienda legata */

    // FACILITY
    const facilityParam = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER,
      fields: 'name,uuid,status,companyId'
    }
    const facilitySupplychainParam = {
      fields: 'name,uuid,status,companyId'
    }
    const facilitiesResponse = await returnAllFacilitiesProjection(facilityParam)

    /* ha senso fare la doppia richiesta poiché non tutte le mie facilities devono perforza appartenere alla filiera. */

    let allOrgAndSupplyFacilities = []

    for (const supplychain of instance.supplychains) {
      /* ritorno tutti i prodotti della supplychain solo per nome e uuid */
      const facilitiesSupplychainsResponse = await returnAllSupplyChainFacilitiesProjection(supplychain.id, facilitySupplychainParam)
      for (const facility of facilitiesSupplychainsResponse.data.data.facilities) {
        allOrgAndSupplyFacilities.push(facility)
      }
    }
    // all facilities from API
    facilitiesResponse.data.data.forEach(fac => {
      allOrgAndSupplyFacilities.push(fac)
    })

    // filtro sull'array in order to remove duplicates
    allOrgAndSupplyFacilities = allOrgAndSupplyFacilities.filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i)

    // filtro per avere solo le facilities attive e elimino la ND
    const facilities = allOrgAndSupplyFacilities.filter(fac => fac.status === 'active')

    // COMPANY
    const companiesParams = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER,
      fields: 'name,uuid,status'
    }
    const companiesSupplychainParams = {
      fields: 'name,uuid,status'
    }

    /* faccio anche la richiesta per le company per riportarmi i nomi vicino ai nomi dei prodotti e stabilimenti sulle select */
    const companiesNameResponse = await returnAllCompaniesProjection(companiesParams)

    let allOrgAndSupplyCompanies = []

    for (const supplychain of instance.supplychains) {
      /* ritorno tutti i prodotti della supplychain solo per nome e uuid */
      const companiesSupplychainsResponse = await returnAllSupplyChainCompaniesProjection(supplychain.id, companiesSupplychainParams)
      for (const company of companiesSupplychainsResponse.data.data.companies) {
        allOrgAndSupplyCompanies.push(company)
      }
    }
    // all facilities from API
    companiesNameResponse.data.data
      .forEach(com => {
        allOrgAndSupplyCompanies.push(com)
      })

    // filtro sull'array in order to remove duplicates
    allOrgAndSupplyCompanies = allOrgAndSupplyCompanies.filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i)

    facilities.forEach(facility => {
      const company = allOrgAndSupplyCompanies
        .find(c => c.uuid === facility.companyId)
      facility.companyData = company
    })

    const productsParamsSupplychain = {
      fields: 'name,uuid,status,companyId'
    }
    /* per la select mi ritorno tutti i prodotti ma solo il nome,l'uuid,lo status e il companyId (mi serve cosi da visualizzare al fianco del nome del prodotto, il nome della company) sia per l'azienda, sia per la supplychain */
    const productsProject = this.state.products.map(prod => ({
      name: prod.name,
      uuid: prod.uuid,
      status: prod.status,
      companyId: prod.companyId
    }))

    let allOrgAndSupplyProducts = []
    for (const supplychain of instance.supplychains) {
      /* ritorno tutti i prodotti della supplychain solo per nome e uuid */
      const productsSupplychainsResponse = await returnAllSupplyChainProductsProjection(supplychain.id, productsParamsSupplychain)

      for (const productSupply of productsSupplychainsResponse.data.data.products) {
        allOrgAndSupplyProducts.push(productSupply)
      }
    }

    // all products from API
    productsProject.forEach(prod => {
      allOrgAndSupplyProducts.push(prod)
    })
    // filtro sull'array in order to remove duplicates
    allOrgAndSupplyProducts = allOrgAndSupplyProducts.filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i)

    // filtro per avere solo i prodotti attivi e elimino la ND
    const products = allOrgAndSupplyProducts.filter(prod => prod.status === 'active')
    products.forEach(product => {
      const company = allOrgAndSupplyCompanies
        .find(c => c.uuid === product.companyId)
      product.companyData = company
    })
    this.setState({ facilities, products, companies: allOrgAndSupplyCompanies })
  }

  phaseCompleted (track) {
    console.log('SONO nel phase completed della NEWCARDmodal:::::::')
    if (
      (
        track.completedAt &&
        track.startedAt &&
        Array.isArray(track.inputs) &&
        track.inputs.length > 0 &&
        track.inputs.every(input => (input.lotNumber && (input.productId && input.productId.indexOf('supplychain') === -1) && (input.uom && input.uom.indexOf('supplychain') === -1)))
      ) &&
      (
        ((track.eventType !== 'observation_shipping') && (track.eventType !== 'observation_receiving') && (track.eventType !== 'transformation') && (track.location.facilityId && track.location.facilityId.indexOf('supplychain') === -1)) ||
        ((track.eventType === 'transformation') && track.outputs.length > 0 && track.outputs.every(output => (output.lotNumber && (output.productId && output.productId.indexOf('supplychain') === -1) && (output.uom && output.uom.indexOf('supplychain') === -1))) && (track.location.facilityId && track.location.facilityId.indexOf('supplychain') === -1)) ||
        (((track.eventType === 'observation_shipping') || (track.eventType === 'observation_receiving')) && (Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationFrom.every(fac => fac.facilityId && fac.facilityId.indexOf('supplychain') === -1)) && (Array.isArray(track.locationTo) && track.locationTo.length > 0 && track.locationTo.every(fac => fac.facilityId && fac.facilityId.indexOf('supplychain') === -1)))
      )
    ) {
      return true
    }
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false })
  }

  // funzione che viene lanciata alla chiusara della dialog
  closeModal (forceClose, shouldIForceRefresh) {
    if (this.state.isModifyEnabled || this.state.modified) {
      // console.log('dentro primo if')
      if (forceClose === true) {
        // console.log('dentro primo force clse, forceClose => ', forceClose)
        this.setState(this.returnDefaultState())
        this.props.onCloseModal(shouldIForceRefresh)
      } else {
        this.setState({ isSaveDialogOpen: true, saveFromClose: true, modified: false })
      }
    } else {
      console.log('dentro secondo if')
      this.setState(this.returnDefaultState())
      // this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.onCloseModal(shouldIForceRefresh)
    }
  }

  trackDescriptionChanged ({ target }, index) {
    const lot = this.state.lot
    lot.traceability[index].description = target.value
    this.setState({ lot })
  }

  lotNumberChange ({ target }) {
    const lot = this.state.lot
    lot.lotNumber = target.value
    this.setState({ lot })
  }

  trackNameChanged ({ target }, index) {
    const lot = this.state.lot
    lot.traceability[index].name = target.value
    this.setState({ lot })
  }

  async deleteLot () {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    const lot = this.state.lot
    if (Array.isArray(lot.attributes)) {
      /* rimuovo il mock se non è stato compilato così da poter salvare la risorsa */
      if (lot.attributes.length === 1) {
        if (lot.attributes[0].name === '' && lot.attributes[0].value === '') {
          lot.attributes = []
        }
      }
      /* ritorno errore se non stati compilati o il tipo o l'url -> l'utente deve cancellarli e poi salvare */
      if (lot.attributes && lot.attributes.length > 1) {
        if (lot.attributes.some(attr => attr.name === '' || attr.value === '')) {
          this.setState({ isDeleting: false })
          return this.props.setSnackbar(this.props.t('notifications.errorAdditionalAttributes'), 'error')
        }
      }
    }
    try {
      lot.status = 'deleted'
      await deleteLightLot(lot)
      // await axios.put(`${configuration.apiBaseUrl}/lots/${this.state.lot.uuid}`, { status: 'deleted' }, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      this.setState({ isDeleting: false })
      this.closeModal(true, true)
    } catch (e) {
      this.setState({ isDeleting: false })
      console.error(`Unable to delete lot with uuid ${lot.uuid}: `, e)
      this.props.setSnackbar(this.props.t('notifications.deleteLotError'), 'error')
      // alert('Impossibile eliminare il lotto')
    }
  }

  enableOrDisableModify () {
    if (this.state.isModifyEnabled === true) {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, modified: true })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
  }

  // funzione che controlla la completezza di una fase
  isThisPhaseCompleted (track) {
    if (track.completedAt !== '' && track.startedAt !== '' && !track.inputs.some(input => input.lotNumber === '')) {
      if (track.outputs) {
        if (!track.outputs.some(output => output.lotNumber === '')) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  // funzione che cambia i valori dei campi di input
  inputChanged ({ target }, trackIndex, inputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].inputs[inputIndex][target.name] = target.value
    this.setState({ lot })
  }

  eventTypeChanged ({ target }, index) {
    const lot = this.state.lot
    const traceability = lot.traceability
    const eventType = target.value
    traceability[index].eventType = eventType
    if (eventType === 'commission' || eventType === 'observation') {
      delete traceability[index].location_from
      delete traceability[index].location_to
      delete traceability[index].outputs
    } else if (eventType === 'transformation') {
      console.log('dentro evento trasformazione')
      // traceability[index].bussiness_event = 'transforming'
      if (!Object.prototype.hasOwnProperty.call(traceability[index], 'outputs') || !traceability[index].outputs.length) {
        console.log('dentro if manca output')
        traceability[index].outputs = [{
          productId: '',
          uom: ''
        }]
      }
      delete traceability[index].location_from
      delete traceability[index].location_to
    } else if (eventType === 'observation_shipping' || eventType === 'observation_receiving') {
      delete traceability[index].location
      delete traceability[index].outputs
    }
    console.log('lot => ', lot)

    this.setState({ lot })
  }

  outputChangedNewSelect ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    this.setState({ lot, isCompleted })
  }

  addFacilityTo (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)

    const locationTo = traceability[trackIndex].locationTo

    locationTo.push('')

    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  addFacilityFrom (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const locationFrom = traceability[trackIndex].locationFrom

    locationFrom.push('')

    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  deleteFacilityFrom (trackIndex, locationIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const locationFrom = traceability[trackIndex].locationFrom

    locationFrom.splice(locationIndex, 1)

    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  deleteFacilityTo (trackIndex, locationIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)

    const locationTo = traceability[trackIndex].locationTo
    locationTo.splice(locationIndex, 1)

    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  addInput (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const inputs = traceability[trackIndex].inputs

    inputs.push({
      productId: '',
      uom: ''
    })
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  addOutput (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const outputs = traceability[trackIndex].outputs

    outputs.push({
      productId: '',
      uom: ''
    })
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  deleteInput (trackIndex, inputIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const inputs = traceability[trackIndex].inputs

    inputs.splice(inputIndex, 1)
    if (!inputs.length) {
      inputs.push({
        productId: '',
        uom: ''
      })
    }

    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  deleteOutput (trackIndex, outputIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const outputs = traceability[trackIndex].outputs

    outputs.splice(outputIndex, 1)
    if (!outputs.length) {
      outputs.push({
        productId: '',
        uom: ''
      })
    }
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      }
    })
  }

  locationChanged ({ target }, index) {
    const lot = this.state.lot
    lot.traceability[index].location = target.value
    this.setState({ lot })
  }

  locationFromChanged ({ target }, index, locationIndex) {
    const lot = this.state.lot
    lot.traceability[index].locationFrom[locationIndex] = target.value
    this.setState({ lot })
  }

  locationToChanged ({ target }, index, locationIndex) {
    const lot = this.state.lot
    lot.traceability[index].locationTo[locationIndex] = target.value
    this.setState({ lot })
  }

  // funzione che cambia i valori dei campi dgli output
  outputChanged ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    // controllo se tutti le fasi sono complete
    this.setState({ lot })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  attributeNameChanged ({ target }, index) {
    const lot = this.state.lot
    lot.attributes[index].name = target.value
    this.setState({ lot })
  }

  attributeValueChanged ({ target }, index) {
    const lot = this.state.lot
    lot.attributes[index].value = target.value
    this.setState({ lot })
  }

  attributeValueDateChanged (date, index) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      date.setHours(0, 0, 0, 0)
      lot.attributes[index].value = date.toISOString()
    } else {
      lot.attributes[index].value = null
    }
    this.setState({ lot })
  }

  async openMedia (url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
        <head>
          <title>Trusty | Documenti</title>
        </head>
        <body style="margin: 0px;">
          <iframe width="100%" height="100%" src="${file}"></iframe>
        </body>
      </html>
      `)
    } catch (e) {
      this.props.setSnackbar('Impossibile visualizzare il documento!', 'error')
    }
  }

  // funzione che salva le modifiche
  async saveModification () {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    const updateObject = deepCloneObj(this.state.lot)
    // const updateObject = Object.keys(this.state.lot).reduce((acc, k) => {
    //   acc[k] = this.state.lot[k]
    //   return acc
    // }, {})

    for (let i = 0; i < updateObject.attributes.length; i++) {
      if (updateObject.attributes[i].name === '' && updateObject.attributes[i].value === '') {
        updateObject.attributes.splice(i--, 1)
      }
    }
    delete updateObject._id
    updateObject.updatedAt = Date.now()

    try {
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedTraceability = Object.assign([], this.state.lot.traceability)
      // console.log('filesToLoad => ', filesToLoad)
      // console.log('updateObject => ', updateObject)
      const newTraceability = deepCloneObj(this.state.lot.traceability)
      for (const file of filesToLoad) {
        const { blob, name, index, order, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `light-lots/${this.state.lot.uuid}/${fileUuid}.${extention}`
        if (section === 'traceability' && type === 'media') {
          const url = await loadFile(blob, filename)
          console.log('traceability url: ', url)
          updatedTraceability[index].media[0].original = url
          updatedTraceability[index].media[0].cropped = url
          newTraceability[index].media[0].original = url
          newTraceability[index].media[0].cropped = url
          file.loaded = true
        } else if (section === 'traceability' && type === 'document') {
          const url = await loadFile(blob, filename)
          updatedTraceability[index].documents[order].fileUrl = url
          newTraceability[index].documents[order].fileUrl = url
          file.loaded = true
        }
      }
      updateObject.traceability = updatedTraceability

      // controllo l'expirationDate
      if (!updateObject.expirationDate || updateObject.expirationDate === '') {
        delete updateObject.expirationDate
      }
      const responseObject = await replaceLightLot(updateObject)

      // const lot = responseObject.data && responseObject.data.data ? responseObject.data.data : this.state.lot
      const lot = responseObject.data.data
      lot.updatedAt = updateObject.updatedAt
      this.setState({
        isModifing: false,
        lot: {
          ...this.state.lot,
          traceability: newTraceability
        },
        filesToLoad: []
      })
      await this.props.onCloseModal(true)
      if (responseObject.error) {
        this.props.setSnackbar(this.props.t('notifications.errorSupplychain'), 'error')
      } else {
        this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success')
      }
      // alert('Modifica avvenuta con successo')
    } catch (e) {
      this.setState({ isModifing: false })
      console.error('Unable to modify lot, error => ', e)
      this.props.setSnackbar(this.props.t('notifications.editsError'), 'error')
      // alert('Impossibile modificare il lotto')
    }
  }

  // funzione che viene lanciata alla chiusura della dialog salvando le modifiche
  async confirmSave () {
    await this.saveModification()
    this.setState({
      isModifyEnabled: false,
      open: false,
      isDeleting: false,
      isModifing: false,
      isConfirmDialogOpened: false
    })
    this.closeSaveDialog()
    // this.props.onCloseModal(true)
  }

  confirmClose () {
    if (!this.state.isModifing) {
      this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
      this.closeSaveDialog()
      this.props.onCloseModal(false)
    }
  }

  timestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].completedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].completedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    this.setState({ lot, isCompleted })
  }

  deadlineTimestampChanged (date) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      date.setHours(0, 0, 0, 0)
      lot.expirationDate = date.toISOString()
    } else {
      lot.expirationDate = null
    }

    this.setState({ lot })
  }

  preventDefault = (event) => event.preventDefault();

  addTrack (index) {
    const lot = this.state.lot
    const candidateMaxTraceId = Math.max(...lot.traceability.map(m => m.traceId))
    const maxTraceId = candidateMaxTraceId !== -Infinity ? candidateMaxTraceId : -1
    console.log('This state lot:::::', this.state.lot.traceability)
    if (
      lot.traceability.length > 0 && (
        lot.traceability[index].name &&
        lot.traceability[index].description &&
        lot.traceability[index].eventType &&
        Array.isArray(lot.traceability[index].inputs) &&
        lot.traceability[index].inputs.length > 0 &&
        lot.traceability[index].inputs.every(input => (input.productId && input.uom)) &&
        // !product.traceability[index].media.length ||
        (
          ((lot.traceability[index].eventType !== 'observation_shipping') && (lot.traceability[index].eventType !== 'observation_receiving') && (lot.traceability[index].eventType !== 'transformation') && (lot.traceability[index].location)) ||
          ((lot.traceability[index].eventType === 'transformation') && lot.traceability[index].outputs.length > 0 && lot.traceability[index].outputs.every(output => (output.productId && output.uom)) && (lot.traceability[index].location)) ||
          (((lot.traceability[index].eventType === 'observation_shipping') || (lot.traceability[index].eventType === 'observation_receiving')) && (Array.isArray(lot.traceability[index].locationFrom) && lot.traceability[index].locationFrom.length > 0 && lot.traceability[index].locationFrom.every(fac => fac)) && (Array.isArray(lot.traceability[index].locationTo) && lot.traceability[index].locationTo.length > 0 && lot.traceability[index].locationTo.every(fac => fac)))
        )
      )
    ) {
      //
    } else {
      this.props.setSnackbar(this.props.t('notifications.completeTrack'), 'warning')
      this.setState({ canIAddAnotherTrack: false })
      return
    }

    lot.traceability.push({
      name: '',
      location: [],
      locationFrom: [],
      locationTo: [],
      description: '',
      documents: [],
      eventType: '',
      inputs: [
        {
          productId: '',
          uom: ''
        }
      ],
      outputs: [
        {
          productId: '',
          uom: ''
        }
      ],
      media: [],
      traceId: maxTraceId + 1
    })

    this.setState({
      lot,
      canIAddAnotherTrack: true,
      phaseTabNumber: index + 1
    })
    const scroll = document.getElementById('chipContainer')
    scroll.scrollLeft = scroll.scrollWidth - scroll.clientWidth
  }

  async addTraceabilityImage ({ target }, index) {
    const lot = this.state.lot

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'traceability',
        type: 'media',
        index,
        loaded: false,
        name
      }

      filesToLoad.push(singleFile)

      lot.traceability[index].media = [{
        original: url,
        cropped: url,
        croppingRect: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        }
      }]
      this.setState({ lot, filesToLoad })
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgTooBig'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }
    }
  }

  async addTraceabilityDocument ({ target }, index) {
    const lot = this.state.lot
    const documents = lot.traceability[index].documents
    console.log('documents::', documents)
    const candidateMaxOrder = documents.length - 1
    const maxOrder = candidateMaxOrder
    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)

        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject
          reader.readAsDataURL(target.files[0])
        })

        documents.push({
          order: maxOrder + 1,
          fileUrl: fileContent,
          type: 'other',
          description: '',
          name: fileName
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index,
          order: maxOrder + 1,
          loaded: false,
          name: fileName
        }

        filesToLoad.push(singleFile)

        this.setState({ lot, filesToLoad }, () => {
          console.log('this.state.filesToLoad', this.state.filesToLoad)
        })
        target.value = ''
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooBig'), 'error')
    }
  }

  deleteTraceabilityDocument (trackIndex, documentIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].documents.splice(documentIndex, 1)
    lot.traceability[trackIndex].documents
      .filter((doc, index) => documentIndex <= index)
      .forEach(doc => {
        console.log('doc', doc)
        doc.order = doc.order - 1
      })
    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const filesToLoadIndex = filesToLoad
      .findIndex((file) => file.order === documentIndex && file.type === 'document' && file.index === trackIndex)
    console.log('filesToLoad index', filesToLoadIndex)
    if (filesToLoadIndex > -1) {
      filesToLoad
        .splice(filesToLoadIndex, 1)
    }
    console.log('filesToLoad interno al delete', filesToLoad)
    filesToLoad
      .filter((file, index) => (file.type === 'document' && file.index === trackIndex && documentIndex <= file.order))
      .forEach(file => {
        console.log('doc in filest to load', file)
        file.order = file.order - 1
      })
    this.setState({ lot, filesToLoad: filesToLoad })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  downloadDocument (name, url) {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode () {
    // creo link fittizio
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre la modal dell'impossibilità di lasciare campi vuoti
  toogleAlreadyCompletedDialog () {
    const isAlreadyCompletedDialogOpen = this.state.isAlreadyCompletedDialogOpen
    this.setState(
      { isAlreadyCompletedDialogOpen: !isAlreadyCompletedDialogOpen })
    // console.log('isAlreadyCompletedDialogOpen => ', isAlreadyCompletedDialogOpen)
    // console.log('this.state.isAlreadyCompletedDialogOpen => ', this.state.isAlreadyCompletedDialogOpen)
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <React.Fragment>

        {/* dialog conferma eliminazione */}
        <Dialog
          className='lotModal'
          open={this.state.isConfirmDialogOpened}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle
            id="responsive-dialog-title">
            {this.props.t('dialog.confirmDelete.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('dialog.confirmDelete.description')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.closeConfirmDialog} color="secondary" autoFocus>
              {this.props.t('dialog.confirmDelete.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteLot} color="primary">
              {this.state.isDeleting
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader> : this.props.t('dialog.confirmDelete.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog confirm saved changes */}
        <Dialog className='lotModal'
          open={this.state.isSaveDialogOpen}
          aria-labelledby="Comfirm save">
          <DialogTitle id="responsive-dialog-title">
            {this.props.t('dialog.confirmClose.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color="primary" autoFocus>
              {this.props.t('dialog.confirmClose.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color="primary">
              {this.props.t('dialog.confirmClose.buttons.closeWithoutSave')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={this.confirmSave}
              color="secondary"
              disabled={!!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader> : this.props.t('dialog.confirmClose.buttons.closeAndSave')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog warning can't save a complete lot as draft */}
        <Dialog className='lotModal'
          open={this.state.isAlreadyCompletedDialogOpen}
          aria-labelledby="Impossibile procedere"
          disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="light-lot-dialog-title">
            {this.props.t('dialog.cannotProceed.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.cannotProceed.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }}
              onClick={this.toogleAlreadyCompletedDialog}
              color="primary">
              {this.props.t('dialog.cannotProceed.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='lotModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          PaperProps={{ square: true }}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <DialogTitle
              classes={{ root: classes.titleMobile }}
              disableTypography={true}
              className={classes.navBar}>
              <Typography style={{ width: '95%' }}
                noWrap={true}
                variant="h6"
                component="h2">
                {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="close modal"
                onClick={this.closeModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            : <DialogTitle
              disableTypography={true}
              className={classes.navBar}>
              <Typography
                noWrap={true}
                variant="h6"
                component="h2">
                {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              </Typography>
              <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.closeButton}>
                {this.state.isModifyEnabled &&
                <Tooltip title="Disabilita modifiche">
                  <IconButton color="inherit" aria-label="allow-modify"
                    onClick={() => this.enableOrDisableModify()}>
                    <LockOpen />
                  </IconButton>
                </Tooltip>}
                {!this.state.isModifyEnabled &&
                <Tooltip title="Modifica dati">
                  <IconButton color="inherit" aria-label="disallow-modify" onClick={() => this.enableOrDisableModify()}>
                    <Lock />
                  </IconButton>
                </Tooltip>
                }
                <Tooltip title="Elimina lotto">
                  <IconButton onClick={() => this.openConfirmDialog()} aria-label="delete product">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Chiudi / Salva modifiche">
                  <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal} style={{ marginRight: 0 }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </DialogTitle>
          }
          <Paper elevation={4} square>
            <Tabs classes={{ indicator: classes.tabIndicator }} variant="scrollable" value={this.state.tabNumber} onChange={this.changeTab} indicatorColor="primary">
              <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
              <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.features.title')} />
              <Tab classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.traceability.title')} (${this.state.lot && this.state.lot.traceability ? this.state.lot.traceability.length : 0})`} />
            </Tabs>
            {this.state.tabNumber === 2
              ? <Paper
                elevation={0}
                position="static"
                square>
                <Box style={{ width: '100%' }} display="flex">
                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.actionButtonContainer}>
                    <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                  </Box>
                  <Box id="chipContainer" className={classes.chipContainer}>
                    {this.state.lot.traceability.map((track, trackIndex) => <Chip
                      classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }}
                      key={`subtab-${trackIndex}`}
                      color="primary"
                      label={track.name ? `#${trackIndex + 1} ${track.name}` : this.props.t('generalPhase')}
                      onClick={() => this.changePhaseTab(trackIndex)} />)}
                  </Box>
                  <Box display="flex" justifyContent="center" alignItems="center" className={classes.actionButtonContainer}>
                    <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                  </Box>
                </Box>
              </Paper> : ''}
          </Paper>
          <DialogContent classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }}>
            {/* GENERALE */}
            <div component="div"
              hidden={this.state.tabNumber !== 0}
              id="simple-tabpanel-0"
              aria-labelledby="simple-tabpanel-0"
              className={`${classes.tabContainer} tabContent`}>
              <Grid style={{ height: '100%' }}
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={2}>
                <Grid item md={6} sm={10} xs={10}>
                  <Typography variant="body1" style={{ marginBottom: 20, marginTop: !isSmall ? '-16px' : '' }}>
                    {this.props.t('tabs.general.description')}.
                  </Typography>
                  {/* <Typography variant="overline"><b>{this.props.t('tabs.general.inputs.expirationDate')}</b></Typography> */}
                  <TextField
                    style={{ marginTop: '2px !important', marginLeft: 16 }}
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                    label={this.props.t('lotNumber')}
                    type="text"
                    value={this.state.lot.lotNumber}
                    onChange={(e) => this.lotNumberChange(e)}
                    disabled={!this.state.isModifyEnabled} />
                  <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
                    <KeyboardDatePicker
                      style={{ marginTop: '2px !important', marginLeft: 16 }}
                      className={classes.w100}
                      margin="normal"
                      id="deadlineTimestamp"
                      label={this.props.t('tabs.general.inputs.expirationDate')}
                      format="dd/MM/yyyy"
                      cancelLabel={this.props.t('tabs.general.inputs.dateCancel')}
                      value={this.state.lot.expirationDate ? this.state.lot.expirationDate : null}
                      onChange={(date) => this.deadlineTimestampChanged(date)}
                      KeyboardButtonProps={{
                        'aria-label': this.props.t('tabs.general.inputs.expirationDate')
                      }}
                      disabled={!this.state.isModifyEnabled}
                    />
                  </MuiPickersUtilsProvider>
                  <Typography variant="body2" style={{ paddingLeft: 16 }}>{this.props.t('tabs.general.inputs.expirationDateInfo')}</Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}>
                    <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                      <QRCode size={180} value={`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(this.state.lot.lotNumber)}`} />
                      <QRCode value={`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(getLastLotnumber(this.state.lot))}`} size={512} style={{ display: 'none' }} id="hiddenCanvas" />
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                      <Grid container direction="row" alignItems="center" justify="flex-start">
                        <Grid item xs={12}>
                          <Tooltip title="Apri pagina pubblica">
                            <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.openQrcodeUrl(`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(this.state.lot.lotNumber)}`)} color="primary" size="small" style={{ margin: '10px 5px' }}>
                              <ExitToAppIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Scarica QR code">
                            <Fab
                              classes={{ primary: classes.fabPrimary }}
                              onClick={() => this.downloadQrcode()}
                              color="primary"
                              size="small"
                              style={{ margin: '10px 5px' }}>
                              <GetAppIcon />
                            </Fab>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/* CARATTERISTICHE */}
            <div component="div"
              hidden={this.state.tabNumber !== 1}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tabpanel-1"
              className={`${classes.tabContainer} tabContent`}>
              <Typography component="p" variant="body1" style={{ paddingBottom: 0 }}>
                {this.props.t('tabs.features.title')}
              </Typography>
              {this.state.lot.attributes.map((attr, index, ref) => (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                  spacing={2}
                  key={`product-${index}`}>
                  <Grid item xs>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-end"
                      justify="center"
                      spacing={1}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          value={attr.name}
                          label={`${this.props.t('tabs.features.inputs.name')}*`}
                          onChange={(e) => this.attributeNameChanged(e, index)}
                          type="text"
                          fullWidth
                          disabled={!this.state.isModifyEnabled} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {attr.value && isIsoDate(attr.value)
                          ? <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
                            <KeyboardDatePicker
                              style={{ marginBottom: '0.8%' }}
                              className={classes.w100}
                              id="deadlineTimestamp"
                              label={this.props.t('tabs.general.inputs.expirationDate')}
                              format="dd/MM/yyyy"
                              cancelLabel={this.props.t('tabs.general.inputs.dateCancel')}
                              value={this.state.lot.expirationDate ? this.state.lot.expirationDate : null}
                              onChange={(date) => this.attributeValueDateChanged(date)}
                              KeyboardButtonProps={{
                                'aria-label': this.props.t('tabs.general.inputs.expirationDate')
                              }}
                              disabled={!this.state.isModifyEnabled}
                            />
                          </MuiPickersUtilsProvider>
                          : <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            value={attr.value}
                            label={`${this.props.t('tabs.features.inputs.value')}*`}
                            onChange={(e) => this.attributeValueChanged(e, index)}
                            type="text"
                            fullWidth
                            disabled={!this.state.isModifyEnabled}
                            multiline
                            rowsMax="8"
                            rows="1" />
                        }
                      </Grid>
                      <Grid item xs={12}
                        hidden={(index !== ref.length - 1) || this.state.canIAddAnotherAttribute}>
                        <Typography content="p" variant="body2" fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                          {this.props.t('tabs.features.inputs.errMessage')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addField(index)} hidden={index !== ref.length - 1} size="small" color="primary" aria-label={this.props.t('tabs.features.tooltip.addAttribute')}
                      disabled={!this.state.isModifyEnabled}>
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    {/* <Fab onClick={() => this.removeField(index)} disabled={this.state.product.attributes.length < 2 || !this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 8 }} aria-label={this.props.t('tabs.features.tooltip.deleteAttribute')}> */}
                    <Fab onClick={() => this.removeField(index)} disabled={!this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 8 }} aria-label={this.props.t('tabs.features.tooltip.deleteAttribute')}>
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>
              ))}
            </div>
            {/* FASI */}
            {/* Step da fare:
                1. Ciclo sulle fasi del lotto
                2. In base all'event type della fase, chiamo la particolare FASE
                3. Alla fase passo:
                    - le variabili di stato che gestisce prepare supplychain
                    - la fase incriminata
                    -
                4. Do la possibilità di creare una nuova fase sul lotto;
                5. Mock fase vuota
            */}
            <div
              component="div"
              hidden={this.state.tabNumber !== 2}
              id="simple-tabpanel-4"
              aria-labelledby="simple-tabpanel-4"
              className={`${classes.tabContainer} tabContent`}>
              {this.state.lot.traceability.length === 0 ? (
                <AddTrackComponent
                  lot={this.state.lot}
                  isModifyEnabled={this.state.isModifyEnabled}
                  enableOrDisableModify={this.state.enableOrDisableModify}
                  addTrack={this.addTrack}
                  translation={'lotDetails'}
                >
                </AddTrackComponent>
              )
                : this.state.lot.traceability.map((track, trackIndex) =>
                  <div
                    key={`tab-traceability-${trackIndex}`}
                    component="div"
                    hidden={this.state.phaseTabNumber !== trackIndex}
                    id={`simple-phasetabpanel-${trackIndex}`}
                    aria-labelledby={`simple-phasetabpanel-${trackIndex}`}
                    className={classes.tabContainer}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} md={5}>
                        <AddImageTrackComponent
                          track={track}
                          trackIndex={trackIndex}
                          isModifyEnabled={this.state.isModifyEnabled}
                          translation={'lotDetails'}
                          addTraceabilityImage={this.addTraceabilityImage}
                        ></AddImageTrackComponent>
                        <AddDocumentsComponent
                          track={track}
                          trackIndex={trackIndex}
                          isModifyEnabled={this.state.isModifyEnabled}
                          openMedia={this.openMedia}
                          addTraceabilityDocument={this.addTraceabilityDocument}
                          deleteTraceabilityDocument={this.deleteTraceabilityDocument}
                          translation={'lotDetails'}
                        ></AddDocumentsComponent>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              {/* nome + descrizione */}
                              <Grid item xs={12} md={12}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                  <NameComponent
                                    track={track}
                                    trackIndex={trackIndex}
                                    isModifyEnabled={this.state.isModifyEnabled}
                                    trackNameChanged={this.trackNameChanged}
                                    translation={'lotDetails'}
                                  ></NameComponent>
                                  <DescriptionComponent
                                    track={track}
                                    trackIndex={trackIndex}
                                    isModifyEnabled={this.state.isModifyEnabled}
                                    trackDescriptionChanged={this.trackDescriptionChanged}
                                    translation={'lotDetails'}
                                  ></DescriptionComponent>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%', marginTop: '20px' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              {/* tipo di evento */}
                              <SelectEventTypeComponent
                                track={track}
                                trackIndex={trackIndex}
                                isModifyEnabled={this.state.isModifyEnabled}
                                eventTypeChanged={this.eventTypeChanged}
                                translation={'lotDetails'}
                              ></SelectEventTypeComponent>
                              {/* non trasferimenti */}
                              {(track.eventType === 'commission' || track.eventType === 'observation') &&
                                <CommissionStepProduct
                                  track={track}
                                  addInput={this.addInput}
                                  trackIndex={trackIndex}
                                  locationChanged={this.locationChanged}
                                  inputChanged={this.inputChanged}
                                  isModifyEnabled={this.state.isModifyEnabled}
                                  facilities={this.state.facilities}
                                  product={this.state.product}
                                  products={this.state.products}
                                  deleteInput={this.deleteInput}
                                  translation={'lotDetails'}
                                ></CommissionStepProduct>
                              }
                              {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') &&
                                    <DeliverStepProduct
                                      track={track}
                                      trackIndex={trackIndex}
                                      locationFromChanged={this.locationFromChanged}
                                      locationToChanged={this.locationToChanged}
                                      inputChanged={this.inputChanged}
                                      isModifyEnabled={this.state.isModifyEnabled}
                                      facilities={this.state.facilities}
                                      product={this.state.product}
                                      products={this.state.products}
                                      addInput={this.addInput}
                                      deleteInput={this.deleteInput}
                                      addFacilityFrom={this.addFacilityFrom}
                                      addFacilityTo={this.addFacilityTo}
                                      deleteFacilityFrom={this.deleteFacilityFrom}
                                      deleteFacilityTo = {this.deleteFacilityTo}
                                      translation={'lotDetails'}
                                    ></DeliverStepProduct>
                              }
                              {(track.eventType === 'transformation') &&
                                <TransformationStepProduct
                                  track={track}
                                  trackIndex={trackIndex}
                                  locationChanged={this.locationChanged}
                                  inputChanged={this.inputChanged}
                                  outputChanged={this.outputChanged}
                                  isModifyEnabled={this.state.isModifyEnabled}
                                  facilities={this.state.facilities}
                                  product={this.state.product}
                                  products={this.state.products}
                                  addInput={this.addInput}
                                  deleteInput={this.deleteInput}
                                  addOutput={this.addOutput}
                                  deleteOutput={this.deleteOutput}
                                  translation={'lotDetails'}
                                ></TransformationStepProduct>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
            </div>
          </DialogContent>
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <DialogActions classes={{ root: classes.mobileActions }}>
              <Button startIcon={<DeleteIcon />} onClick={() => this.openConfirmDialog()} color="white" aria-label="delete lot">
                {this.props.t('buttons.delete')}
              </Button>
              {this.state.isModifyEnabled && <Button startIcon={<LockOpen />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="allow-modify" title={this.props.t('buttons.lockEdits')} onClick={() => this.enableOrDisableModify()}>
                {this.props.t('buttons.close')}
              </Button>}
              {!this.state.isModifyEnabled && <Button startIcon={<Lock />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="disallow-modify" title={this.props.t('buttons.unlockEdits')} onClick={() => this.enableOrDisableModify()}>
                {this.props.t('buttons.edit')}
              </Button>}
            </DialogActions>
            : ''}
        </Dialog>
      </React.Fragment>
    )
  }
}

NewCardModalLightLotSupplychain.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  lotNumber: PropTypes.string,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func,
  productsIdNameMap: PropTypes.object,
  product: PropTypes.object,
  allSupplychainIdNameMap: PropTypes.object
}
export default withRouter(withStyles(styles)(withWidth()(withTranslation('lotDetails')(NewCardModalLightLotSupplychain))))
