import React from 'react'
import PropTypes from 'prop-types'
import ls from 'local-storage'
import axios from 'axios'

import configuration from '../../configuration'
import { withTranslation } from 'react-i18next'

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  // Button,
  withWidth,
  Typography,
  Paper,
  IconButton,
  Tabs,
  Tab,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
  // Grid,
  // TextField,
  // Select,
  // MenuItem,
  // InputLabel
} from '@material-ui/core'

import { Close as CloseIcon, ExpandMore } from '@material-ui/icons'

const styles = theme => ({
  navBar: {
    background: '#3C4858',
    color: '#FFFFFF!important'
  },
  primary: {
    color: theme.primary
  },
  secondary: {
    color: theme.secondary
  },
  buttonPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primaryDark
    }
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  paperDialog: {
    padding: 0,
    height: '45vh',
    backgroundColor: 'rgba(250, 250, 250, 0.75)'
  },
  paperDialogMobile: {
    padding: 0,
    backgroundColor: 'rgba(250, 250, 250, 0.75)'
  },
  heading: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0
  },
  headingMobile: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.00%',
    flexShrink: 0
  },
  errorNotification: {
    opacity: 1,
    color: 'white',
    backgroundColor: 'rgba(195, 36, 39, 0.6)',
    // transition: 'opacity 0.3s, background-color 0.3s'
    transition: 'all 0.3s'
  },
  errorNotificationTransparent: {
    opacity: 0,
    color: 'transparent',
    backgroundColor: 'rgba(195, 36, 39, 0.0)',
    // transition: 'opacity 0.3s, background-color 0.3s'
    transition: 'all 0.3s'
  },
  paperBorder: {
    borderBottom: '1px solid #eee'
  },
  categoryInfo: {
    fontWeight: 500
  }
})
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
/* In localhost non viene più visualizzato il nome TRUSTY */
class HelpDialog extends React.Component {
  constructor (props) {
    super(props)
    const userData = ls.get('user')
    this.state = {
      emailData: {
        email: userData.email,
        subject: '',
        typology: 'azienda',
        message: '',
        domain: props.domain.domain
      },
      tabNumber: 0,
      expanded: ''
    }

    this.changeTab = this.changeTab.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  resetState () {
    const userData = ls.get('user')
    this.setState({
      emailData: {
        email: userData.email,
        subject: '',
        typology: 'azienda',
        message: ''
      },
      tabNumber: 1,
      expanded: ''
    })
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  changeExpanded (event, expanded) {
    const isExpanded = (this.state.expanded === expanded)
    this.setState({ expanded: isExpanded ? false : expanded })
  }

  handleChange = ({ target }) => {
    const emailData = this.state.emailData
    this.setState({ emailData: { ...emailData, [target.name]: target.value } })
  }

  async sendMessage () {
    try {
      await axios.post(`${configuration.apiBaseUrl}/email/send`, this.state.emailData,
        {
          headers: {
            authorization: `Bearer ${ls.get('token')}`
          }
        })

      this.resetState()
      this.props.setSnackbar(this.props.t('tabs.contactUs.notifications.success'), 'success')
      // this.props.onClose()
    } catch (err) {
      this.resetState()
      this.props.setSnackbar(this.props.t('tabs.contactUs.notifications.error'), 'error')
      // this.props.onClose()
    }
  }

  render () {
    const { width, onClose, open, classes } = this.props
    let faq = []
    if (this.props.domain.domain !== 'trusty') {
      faq = [
        {
          question: this.props.t('tabs.faq.questionsOtherDomain.0.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questionsOtherDomain.0.description', { whitelabel: capitalize(this.props.domain.domain), domain: `${window.location.origin}` })
        },
        {
          question: this.props.t('tabs.faq.questionsOtherDomain.1.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questionsOtherDomain.1.description')
        },
        {
          question: this.props.t('tabs.faq.questionsOtherDomain.2.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questionsOtherDomain.2.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questionsOtherDomain.3.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questionsOtherDomain.3.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questionsOtherDomain.4.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questionsOtherDomain.4.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questionsOtherDomain.5.title'),
          answer: this.props.t('tabs.faq.questionsOtherDomain.5.description')
        },
        {
          question: this.props.t('tabs.faq.questions.8.title'),
          answer: this.props.t('tabs.faq.questions.8.description')
        }
      ]
    } else {
      faq = [
        {
          question: this.props.t('tabs.faq.questions.0.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questions.0.description', { whitelabel: capitalize(this.props.domain.domain), domain: `${window.location.origin}` })
        },
        {
          question: this.props.t('tabs.faq.questions.1.title'),
          answer: this.props.t('tabs.faq.questions.1.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questions.2.title'),
          answer: this.props.t('tabs.faq.questions.2.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questions.3.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questions.3.description')
        },
        {
          question: this.props.t('tabs.faq.questions.4.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questions.4.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questions.5.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questions.5.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questions.6.title', { whitelabel: capitalize(this.props.domain.domain) }),
          answer: this.props.t('tabs.faq.questions.6.description', { whitelabel: capitalize(this.props.domain.domain) })
        },
        {
          question: this.props.t('tabs.faq.questions.7.title'),
          answer: this.props.t('tabs.faq.questions.7.description')
        },
        {
          question: this.props.t('tabs.faq.questions.8.title'),
          answer: this.props.t('tabs.faq.questions.8.description')
        }
      ]
    }
    return (
      <Dialog
        fullWidth={true}
        fullScreen={width === 'xs' || width === 'sm'}
        maxWidth={'lg'}
        onClose={onClose}
        open={open}
        className='helpModal'
      >
        <DialogTitle disableTypography={true} className={classes.navBar}>
          <Box width="100%" display="flex" alignItems="center">
            <Typography style={{ width: '98%' }} noWrap={true} variant="h6" component="h2">{this.props.t('title')}</Typography>
            <IconButton style={{ justifySelf: 'flex-end', padding: '8px' }} color="inherit" aria-label="close dialog" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Paper className={classes.paperBorder} elevation={4} position="static" square>
          <Tabs
            classes={{
              indicator: classes.tabIndicator
            }}
            value={this.state.tabNumber}
            onChange={this.changeTab}
            indicatorColor="primary"
          >

            <Tab classes={{ selected: classes.primary }} label={this.props.t('tabs.faq.title')} />
            {/* <Tab classes={{ selected: classes.primary }} label={this.props.t('tabs.contactUs.title')} /> */}
          </Tabs>
        </Paper>
        <DialogContent classes={width === 'sm' || width === 'xs' ? { root: classes.paperDialogMobile } : { root: classes.paperDialog }}>
          {/* FAQ */}
          <Box component="div" pt={2} pl={width === 'sm' || width === 'xs' ? 2 : 6} pr={width === 'sm' || width === 'xs' ? 2 : 6} pb={1} hidden={!(this.state.tabNumber === 0)}>
            <Box mb={1}>
              <Typography variant="h6">
                {this.props.t('tabs.faq.title')}
              </Typography>
            </Box>
            <Typography variant="body2">
              {this.props.t('tabs.faq.description', { whitelabel: capitalize(this.props.domain.domain) })}
            </Typography>
            <Box width="100%" mt={4} pb={4}>
              <Box mb={2}>
                <Typography component="h2" variant="body1" className={classes.categoryInfo}>{this.props.t('tabs.faq.subtitle', { whitelabel: capitalize(this.props.domain.domain) })}</Typography>
              </Box>
              {faq.map((singleFaq, index) => <ExpansionPanel key={`faq-${index}`} style={{ padding: 0 }} expanded={this.state.expanded === index} onChange={(e) => this.changeExpanded(e, index)}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore className={classes.primary} />}
                >

                  <Typography className={width === 'xs' || width === 'sm' ? classes.headingMobile : classes.heading}>{`${index + 1}. ${singleFaq.question}`}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2" component="p">
                    {singleFaq.answer}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>)}
            </Box>
          </Box>
          {/* CONTATTACI */}
          {/*
          <Box component="div" hidden={!(this.state.tabNumber === 1)}>
            {/* <Box p={2} className={this.state.emailData.email === '' || this.state.emailData.typology === '' || this.state.emailData.subject === '' || this.state.emailData.message === '' ? classes.errorNotification : classes.errorNotificationTransparent}>
              <Typography variant="body2" component="p">Completa tutti i campi per inviare il messaggio</Typography>
            </Box>
            <Box mt={2} pl={2} pr={2} pb={2}>
              <Paper>
                <Box pt={2} pb={2} pl={3} pr={3}>
                  <Box mb={4}>
                    <Typography variant="h6">
                      {this.props.t('tabs.contactUs.title')}
                    </Typography>
                  </Box>
                  <Grid container direction="column">
                    <Grid item style={{ width: '100%' }}>
                      <Box mb={4}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id="email"
                              name="email"
                              label={this.props.t('tabs.contactUs.inputs.email')}
                              type="text"
                              fullWidth
                              value={this.state.emailData.email}
                              onChange={this.handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <InputLabel shrink htmlFor={'typology'}>{this.props.t('tabs.contactUs.inputs.problemCategory')}</InputLabel>
                            <Select inputProps={{
                              name: 'typology',
                              id: 'typology'
                            }} value={this.state.emailData.typology} onChange={this.handleChange}
                            displayEmpty fullWidth>
                              <MenuItem value={'azienda'}>
                                {this.props.t('tabs.contactUs.inputs.resources.0')}
                              </MenuItem>
                              <MenuItem value={'stabilimento'}>
                                {this.props.t('tabs.contactUs.inputs.resources.1')}
                              </MenuItem>
                              <MenuItem value={'prodotto'}>
                                {this.props.t('tabs.contactUs.inputs.resources.2')}
                              </MenuItem>
                              <MenuItem value={'lotto'}>
                                {this.props.t('tabs.contactUs.inputs.resources.3')}
                              </MenuItem>
                              <MenuItem value={'altro'}>
                                {this.props.t('tabs.contactUs.inputs.resources.4')}
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              id="subject"
                              name="subject"
                              label={this.props.t('tabs.contactUs.inputs.subject')}
                              type="text"
                              fullWidth
                              value={this.state.emailData.subject}
                              onChange={this.handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                      <TextField InputLabelProps={{ shrink: true }} margin="dense" id="message" name="message" value={this.state.emailData.message} label={this.props.t('tabs.contactUs.inputs.description')} onChange={this.handleChange}
                        type="text" fullWidth rowsMax="6" rows="6" multiline />
                    </Grid>
                    <Grid item style={{ width: '100%' }}>
                      <Box mt={4} width="100%" display="flex" alignItems="center" justifyContent="space-between">
                        <Typography style={{ fontStyle: 'italic' }} variant="subtitle2" component="div">* {this.props.t('tabs.contactUs.legend')}</Typography>
                        <Button onClick={this.sendMessage} disabled={this.state.emailData.email === '' || this.state.emailData.typology === '' || this.state.emailData.subject === '' || this.state.emailData.message === ''} classes={{ containedPrimary: classes.buttonPrimary }} variant="contained" color="primary"> {this.props.t('tabs.contactUs.buttons.send')}</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Box>
        */}
        </DialogContent>
      </Dialog>
    )
  }
}

HelpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(withTranslation('guide')(HelpDialog)))
