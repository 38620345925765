import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'
import { createSupplychainMembership, modifyResource } from './supplychains.js'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnLightLotsCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}
// esport query per i lotti
export async function returnAllLightLots () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// esport query per avere alcuni lotti
export async function returnSomeLightLots (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllLightLots()
  }
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}

// funzione che crea una nuovo lotto
export async function returnNewLightLot (newLot) {
  /*
    questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
    Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */

  let supplychainIds = []
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è il prodotto
  if (!newLot) {
    return
  }

  if (newLot.supplychains) {
    supplychainIds = newLot.supplychains.map(supplychain => supplychain.id)
    delete newLot.supplychains
  }

  console.log('BEFORE QUERY newLot => ', newLot)
  // mando la query
  const response = await axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/`, newLot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(newLot.organizationId, 'lot', response.data.data.uuid, supplychainIds)
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }

  return response
}
// funzione che crea una nuovo lotto
export async function createDefaultLightLot (productId) {
  if (!productId) {
    return
  }

  const response = await axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/create`, { productId }, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  return response
}
// funzione che modifica un lotto
export async function editLightLot (lot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  /* TODO: adattare parte supplychain anche per light lot */

  let supplychainIds = []
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }

  if (lot.supplychains) {
    supplychainIds = lot.supplychains.map(supplychain => supplychain.id)
    delete lot.supplychains
  }

  console.log('BEFORE QUERY edit lot => ', lot)
  // mando la query
  const response = await axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/${lot.uuid}`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(lot.organizationId, 'lot', response.data.data.uuid, supplychainIds)
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }

  return response
}

// funzione che rimpiazza un lotto
export async function replaceLightLot (lot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  /* TODO: adattare parte supplychain anche per light lot */

  let supplychainIds = []

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }

  if (lot.supplychains) {
    supplychainIds = lot.supplychains.map(supplychain => supplychain.id)
    delete lot.supplychains
  }

  /* cancello verified così da non mandarlo alla replace del Lot ma poi lo ricopio perché in chiusura della modal lo utilizzo
     per la snackbar
  */
  console.log('BEFORE QUERY replace lot => ', lot)

  const response = await axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/${lot.uuid}/replace/`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(lot.organizationId, 'light-lot', response.data.data.uuid, supplychainIds)
      for (const supplychainId of supplychainIds) {
        await modifyResource(response.data.data.uuid, supplychainId)
      }
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }

  return response
}

// funzione che elimina un light lot
export async function deleteLightLot (lot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  console.log('BEFORE QUERY delete lot => ', lot)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/${lot.uuid}`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// export funzione che mi tira fuori ultimi valori del lotto
export const getLastInputOrOutputInformation = (lot, product, productsIdNameMap) => {
  const traceability = lot.traceability
  if (!traceability || !traceability.length) {
    return
  }
  if (!Object.keys(productsIdNameMap).length) {
    return
  }

  const inputs = traceability[traceability.length - 1].inputs
  // const productLastTrack = product.traceability[product.traceability.length - 1]

  const lastLot = {
    trackName: traceability[traceability.length - 1].name,
    productName: inputs.length > 0 ? productsIdNameMap[inputs[inputs.length - 1].productId] : '',
    quantity: inputs.length > 0 ? inputs[inputs.length - 1].quantity : '',
    lotNumber: inputs.length > 0 ? lot.lotNumber || inputs[inputs.length - 1].lotNumber : '',
    gtin: product.gtin || null
  }
  // console.log('lastLot => ', lastLot)
  // console.log('productLastTrack.outputs => ', productLastTrack.outputs)
  if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length && traceability[traceability.length - 1].outputs.length > 0) {
    const outputs = traceability[traceability.length - 1].outputs
    lastLot.productName = productsIdNameMap[outputs[outputs.length - 1].productId]
    lastLot.quantity = outputs[outputs.length - 1].quantity
    lastLot.lotNumber = lot.lotNumber || outputs[outputs.length - 1].lotNumber
  }

  return lastLot
}

/* TODO: Trattare caso supplychain */

// export const getLastInputOrOutputLightLotInformationSupplychainField = (lot) => {
//   const traceability = lot.traceability
//   if (!traceability || !traceability.length) {
//     return
//   }
//   const inputs = traceability[traceability.length - 1].inputs
//   let quantity = inputs[inputs.length - 1].quantity
//   if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
//     const outputs = traceability[traceability.length - 1].outputs
//     quantity = outputs[outputs.length - 1].quantity
//   }
//   return quantity
// }

/* TODO: Trattare caso supplychain */

export async function returnAllSupplychainLightLotProjection (supplychainId, params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/light-lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
  return response
}

export async function createLabelFromLot (uuid, data) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/light-lots/${uuid}/label/create`, data, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    responseType: 'arraybuffer'
  })
  return response
}

/* TODO: Trattare caso supplychain */
/*
export async function returnSomeSupplyChainLots (supplychainId, params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
  return response
}
*/
