import axios from 'axios'
import ls from 'local-storage'
import configuration from '../configuration'
const apiBaseUrl = configuration.apiBaseUrl
const organizationsService = {
  find: (query = {}) => {
    return axios({
      headers: {
        authorization: `bearer ${ls.get('token')}`
      },
      method: 'GET',
      url: `${apiBaseUrl}/organizations`,
      query: query
    })
  },
  create: (data) => {
    return axios({
      headers: {
        authorization: `bearer ${ls.get('token')}`
      },
      method: 'POST',
      url: `${apiBaseUrl}/organizations`,
      data: data
    })
  },
  getCurrent: () => {
    return ls.get('organization')
  },
  setCurrent: (data) => {
    ls.set('organization', data)
  },
  getCurrOrganization: () => {
    return ls.get('currOrganization')
  },
  setCurrOrganization: (data) => {
    // return ls.get('currOrganization')
    ls.set('currOrganization', data)
  }

}

export default organizationsService
