export const wasteSchema = {
  data: [
    {
      id: 1,
      name: 'Acciaio',
      created_at: '2020-05-25T14:36:19.000000Z',
      updated_at: '2020-06-01T09:54:13.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 1,
          name: 'Acciaio',
          family_id: 1,
          created_at: '2020-05-25T14:43:00.000000Z',
          updated_at: '2020-12-04T15:13:40.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'FE 40',
          question_id: null,
          composed: '0',
          note: null
        }
      ]
    },
    {
      id: 4,
      name: 'Alluminio',
      created_at: '2020-06-01T09:56:38.000000Z',
      updated_at: '2020-06-01T09:56:38.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 15,
          name: 'Alluminio',
          family_id: 4,
          created_at: '2020-06-22T10:20:01.000000Z',
          updated_at: '2020-12-04T14:36:41.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'ALU 41',
          question_id: null,
          composed: '0',
          note: null
        }
      ]
    },
    {
      id: 3,
      name: 'Carta',
      created_at: '2020-06-01T09:54:37.000000Z',
      updated_at: '2020-06-01T09:54:37.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 2,
          name: 'Carta',
          family_id: 3,
          created_at: '2020-06-02T08:19:23.000000Z',
          updated_at: '2020-12-30T11:55:10.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PAP 22',
          question_id: null,
          composed: '0',
          note: null
        },
        {
          id: 3,
          name: 'Cartoncino',
          family_id: 3,
          created_at: '2020-06-02T08:20:36.000000Z',
          updated_at: '2020-12-30T11:55:02.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PAP 21',
          question_id: null,
          composed: '0',
          note: null
        },
        {
          id: 23,
          name: 'Cartone ondulato',
          family_id: 3,
          created_at: '2020-06-22T16:40:27.000000Z',
          updated_at: '2020-12-30T11:54:55.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PAP 20',
          question_id: null,
          composed: '0',
          note: null
        }
      ]
    },
    {
      id: 5,
      name: 'Legno',
      created_at: '2020-06-01T09:56:50.000000Z',
      updated_at: '2020-12-04T15:24:15.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 20,
          name: 'Legno',
          family_id: 5,
          created_at: '2020-06-22T15:56:22.000000Z',
          updated_at: '2020-06-22T15:56:22.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'FOR 50',
          question_id: null,
          composed: '0',
          note: null
        }
        // {
        //   id: 80,
        //   name: 'Legno',
        //   family_id: 5,
        //   created_at: '2020-08-11T12:10:50.000000Z',
        //   updated_at: '2020-10-16T09:29:11.000000Z',
        //   deleted_at: null,
        //   icon: null,
        //   waste_disposal_id: 24,
        //   material_parent_id: null,
        //   code: 'FOR 50',
        //   question_id: null,
        //   composed: '0',
        //   note: null
        // },
        // {
        //   id: 81,
        //   name: 'Legno',
        //   family_id: 5,
        //   created_at: '2020-08-11T12:15:50.000000Z',
        //   updated_at: '2020-09-30T08:21:07.000000Z',
        //   deleted_at: null,
        //   icon: null,
        //   waste_disposal_id: 17,
        //   material_parent_id: null,
        //   code: 'FOR 50',
        //   question_id: null,
        //   composed: '0',
        //   note: null
        // }
      ]
    },
    {
      id: 7,
      name: 'Materiale prevalente - Acciaio',
      created_at: '2020-06-29T11:25:40.000000Z',
      updated_at: '2020-08-06T16:06:58.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 34,
          name: 'Materiale secondario - Alluminio',
          family_id: 7,
          created_at: '2020-07-30T10:09:30.000000Z',
          updated_at: '2021-06-01T08:49:01.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'FE 40',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 35,
          name: 'Materiale secondario - Carta',
          family_id: 7,
          created_at: '2020-07-30T10:10:53.000000Z',
          updated_at: '2020-11-29T21:13:13.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/FE 83',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 117,
          name: 'Materiale secondario - Carta/Plastica/Alluminio',
          family_id: 7,
          created_at: '2020-12-23T09:15:40.000000Z',
          updated_at: '2020-12-23T09:17:44.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/FE 85',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 36,
          name: 'Materiale secondario - Legno',
          family_id: 7,
          created_at: '2020-07-30T10:18:15.000000Z',
          updated_at: '2021-06-01T08:42:56.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'FE 40',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 37,
          name: 'Materiale secondario - Plastica',
          family_id: 7,
          created_at: '2020-07-30T10:19:12.000000Z',
          updated_at: '2020-11-29T21:28:12.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/FE 91',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 38,
          name: 'Materiale secondario - Vetro',
          family_id: 7,
          created_at: '2020-07-30T10:19:34.000000Z',
          updated_at: '2020-11-29T21:28:52.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/FE 97',
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 10,
      name: 'Materiale prevalente - Alluminio',
      created_at: '2020-07-28T14:01:49.000000Z',
      updated_at: '2020-08-06T16:07:05.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 29,
          name: 'Materiale secondario - Acciaio',
          family_id: 10,
          created_at: '2020-07-28T14:03:09.000000Z',
          updated_at: '2021-06-01T08:47:11.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'ALU 41',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 30,
          name: 'Materiale secondario - Carta',
          family_id: 10,
          created_at: '2020-07-28T14:03:43.000000Z',
          updated_at: '2020-11-29T21:41:58.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/ALU 82',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 118,
          name: 'Materiale secondario - Carta/Plastica',
          family_id: 10,
          created_at: '2020-12-23T09:26:18.000000Z',
          updated_at: '2020-12-23T09:26:18.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/ALU 84',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 119,
          name: 'Materiale secondario - Carta/Plastica/Acciaio',
          family_id: 10,
          created_at: '2020-12-23T09:27:24.000000Z',
          updated_at: '2020-12-23T09:27:24.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/ALU 85',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 31,
          name: 'Materiale secondario - Legno',
          family_id: 10,
          created_at: '2020-07-28T14:04:33.000000Z',
          updated_at: '2021-06-01T08:43:25.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'ALU 41',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 32,
          name: 'Materiale secondario - Plastica',
          family_id: 10,
          created_at: '2020-07-28T14:05:33.000000Z',
          updated_at: '2020-11-29T21:42:24.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/ALU 90',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 33,
          name: 'Materiale secondario - Vetro',
          family_id: 10,
          created_at: '2020-07-28T14:06:29.000000Z',
          updated_at: '2020-11-29T21:42:47.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/ALU 96',
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 12,
      name: 'Materiale prevalente - Carta',
      created_at: '2020-07-30T10:22:08.000000Z',
      updated_at: '2020-08-06T16:07:11.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 39,
          name: 'Materiale secondario - Acciaio',
          family_id: 12,
          created_at: '2020-07-30T10:22:51.000000Z',
          updated_at: '2020-07-30T10:22:51.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'C/PAP 83',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 40,
          name: 'Materiale secondario - Alluminio',
          family_id: 12,
          created_at: '2020-07-30T10:23:15.000000Z',
          updated_at: '2020-07-30T10:23:15.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'C/PAP 82',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 44,
          name: 'Materiale secondario - Metalli vari',
          family_id: 12,
          created_at: '2020-07-30T10:25:59.000000Z',
          updated_at: '2020-07-30T10:25:59.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'C/PAP 80',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 79,
          name: 'Materiale secondario - Plastica',
          family_id: 12,
          created_at: '2020-08-10T08:58:09.000000Z',
          updated_at: '2020-08-10T08:58:09.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'C/PAP 81',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 42,
          name: 'Materiale secondario - Plastica/Alluminio',
          family_id: 12,
          created_at: '2020-07-30T10:24:54.000000Z',
          updated_at: '2020-07-30T10:24:54.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'C/PAP 84',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 43,
          name: 'Materiale secondario - Plastica/Alluminio/Acciaio',
          family_id: 12,
          created_at: '2020-07-30T10:25:33.000000Z',
          updated_at: '2020-07-30T10:25:33.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: 'C/PAP 85',
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 11,
      name: 'Materiale prevalente - Legno',
      created_at: '2020-07-30T10:21:55.000000Z',
      updated_at: '2020-08-06T16:07:18.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 45,
          name: 'Materiale secondario - Acciaio',
          family_id: 11,
          created_at: '2020-07-30T10:30:52.000000Z',
          updated_at: '2021-06-01T08:46:23.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 17,
          material_parent_id: null,
          code: 'FOR 50',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 46,
          name: 'Materiale secondario - Alluminio',
          family_id: 11,
          created_at: '2020-07-30T10:31:27.000000Z',
          updated_at: '2021-06-01T08:48:16.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 17,
          material_parent_id: null,
          code: 'FOR 50',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 47,
          name: 'Materiale secondario - Carta',
          family_id: 11,
          created_at: '2020-07-30T10:31:48.000000Z',
          updated_at: '2021-06-01T08:50:32.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 17,
          material_parent_id: null,
          code: 'FOR 50',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 48,
          name: 'Materiale secondario - Plastica',
          family_id: 11,
          created_at: '2020-07-30T10:32:06.000000Z',
          updated_at: '2021-06-01T08:51:30.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 17,
          material_parent_id: null,
          code: 'FOR 50',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 49,
          name: 'Materiale secondario - Vetro',
          family_id: 11,
          created_at: '2020-07-30T10:32:25.000000Z',
          updated_at: '2021-06-01T08:51:10.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 17,
          material_parent_id: null,
          code: 'FOR 50',
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 18,
      name: 'Materiale prevalente - Plastica - Altro',
      created_at: '2020-08-06T16:09:48.000000Z',
      updated_at: '2021-01-18T16:49:58.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 112,
          name: 'Materiale secondario - Acciaio',
          family_id: 18,
          created_at: '2020-10-14T07:18:31.000000Z',
          updated_at: '2021-01-15T14:20:38.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta. <br /><br /></p>"
        },
        {
          id: 111,
          name: 'Materiale secondario - Alluminio',
          family_id: 18,
          created_at: '2020-10-14T07:18:04.000000Z',
          updated_at: '2021-01-15T14:20:45.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 153,
          name: 'Materiale secondario - Carta',
          family_id: 18,
          created_at: '2021-01-15T14:47:58.000000Z',
          updated_at: '2021-01-15T14:47:58.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 166,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 18,
          created_at: '2021-01-15T15:03:06.000000Z',
          updated_at: '2021-01-15T15:03:06.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 173,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 18,
          created_at: '2021-01-15T15:09:47.000000Z',
          updated_at: '2021-01-15T15:09:47.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 113,
          name: 'Materiale secondario - Metalli vari',
          family_id: 18,
          created_at: '2020-10-14T07:20:11.000000Z',
          updated_at: '2021-01-15T14:20:31.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta. <br /><br /></p>"
        },
        {
          id: 114,
          name: 'Materiale secondario - Plastica',
          family_id: 18,
          created_at: '2020-10-14T07:20:31.000000Z',
          updated_at: '2021-01-14T18:34:15.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta. <br /><br /></p>"
        },
        {
          id: 159,
          name: 'Materiale secondario - Vetro',
          family_id: 18,
          created_at: '2021-01-15T14:54:19.000000Z',
          updated_at: '2021-01-15T14:54:19.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/abbreviazione del polimero 95',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        }
      ]
    },
    {
      id: 13,
      name: 'Materiale prevalente - Plastica - HDPE',
      created_at: '2020-08-06T16:08:02.000000Z',
      updated_at: '2021-01-18T16:50:14.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 94,
          name: 'Materiale secondario - Acciaio',
          family_id: 13,
          created_at: '2020-10-14T06:45:53.000000Z',
          updated_at: '2021-01-15T14:09:29.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 93,
          name: 'Materiale secondario - Alluminio',
          family_id: 13,
          created_at: '2020-10-14T06:44:24.000000Z',
          updated_at: '2021-01-15T14:08:42.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 148,
          name: 'Materiale secondario - Carta',
          family_id: 13,
          created_at: '2021-01-15T14:43:15.000000Z',
          updated_at: '2021-01-15T14:43:15.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 161,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 13,
          created_at: '2021-01-15T14:58:08.000000Z',
          updated_at: '2021-01-15T14:58:08.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 168,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 13,
          created_at: '2021-01-15T15:05:15.000000Z',
          updated_at: '2021-01-15T15:05:15.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 95,
          name: 'Materiale secondario - Metalli vari',
          family_id: 13,
          created_at: '2020-10-14T06:46:39.000000Z',
          updated_at: '2021-01-15T14:12:08.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 96,
          name: 'Materiale secondario - Plastica',
          family_id: 13,
          created_at: '2020-10-14T06:52:17.000000Z',
          updated_at: '2021-01-15T14:33:14.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 155,
          name: 'Materiale secondario - Vetro',
          family_id: 13,
          created_at: '2021-01-15T14:50:23.000000Z',
          updated_at: '2021-01-15T14:50:23.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/HDPE 95',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        }
      ]
    },
    {
      id: 15,
      name: 'Materiale prevalente - Plastica - LDPE',
      created_at: '2020-08-06T16:08:33.000000Z',
      updated_at: '2021-01-18T16:50:39.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 102,
          name: 'Materiale secondario - Acciaio',
          family_id: 15,
          created_at: '2020-10-14T07:10:15.000000Z',
          updated_at: '2021-01-15T14:12:35.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 101,
          name: 'Materiale secondario - Alluminio',
          family_id: 15,
          created_at: '2020-10-14T07:07:56.000000Z',
          updated_at: '2021-01-15T14:12:28.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 150,
          name: 'Materiale secondario - Carta',
          family_id: 15,
          created_at: '2021-01-15T14:44:41.000000Z',
          updated_at: '2021-01-15T14:44:41.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 163,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 15,
          created_at: '2021-01-15T14:59:25.000000Z',
          updated_at: '2021-01-15T14:59:25.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 170,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 15,
          created_at: '2021-01-15T15:06:59.000000Z',
          updated_at: '2021-01-15T15:06:59.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 103,
          name: 'Materiale secondario - Metalli vari',
          family_id: 15,
          created_at: '2020-10-14T07:10:49.000000Z',
          updated_at: '2021-01-15T14:11:27.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 104,
          name: 'Materiale secondario - Plastica',
          family_id: 15,
          created_at: '2020-10-14T07:11:55.000000Z',
          updated_at: '2021-01-15T14:32:50.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 156,
          name: 'Materiale secondario - Vetro',
          family_id: 15,
          created_at: '2021-01-15T14:51:28.000000Z',
          updated_at: '2021-01-15T14:51:28.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/LDPE 95',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        }
      ]
    },
    {
      id: 8,
      name: 'Materiale prevalente - Plastica - PET',
      created_at: '2020-06-29T16:04:05.000000Z',
      updated_at: '2021-01-18T16:50:53.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 90,
          name: 'Materiale secondario - Acciaio',
          family_id: 8,
          created_at: '2020-10-14T06:41:22.000000Z',
          updated_at: '2021-01-15T14:09:52.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 89,
          name: 'Materiale secondario - Alluminio',
          family_id: 8,
          created_at: '2020-10-12T13:10:08.000000Z',
          updated_at: '2021-01-15T14:10:00.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 147,
          name: 'Materiale secondario - Carta',
          family_id: 8,
          created_at: '2021-01-15T14:42:31.000000Z',
          updated_at: '2021-01-15T14:42:31.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 160,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 8,
          created_at: '2021-01-15T14:57:24.000000Z',
          updated_at: '2021-01-15T14:57:24.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 167,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 8,
          created_at: '2021-01-15T15:04:10.000000Z',
          updated_at: '2021-01-15T15:04:10.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 91,
          name: 'Materiale secondario - Metalli vari',
          family_id: 8,
          created_at: '2020-10-14T06:42:43.000000Z',
          updated_at: '2021-01-15T14:09:44.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 92,
          name: 'Materiale secondario - Plastica',
          family_id: 8,
          created_at: '2020-10-14T06:43:33.000000Z',
          updated_at: '2021-01-15T14:33:30.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 154,
          name: 'Materiale secondario - Vetro',
          family_id: 8,
          created_at: '2021-01-15T14:49:39.000000Z',
          updated_at: '2021-01-15T14:49:39.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PET 95',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 24,
          name: 'PET',
          family_id: 8,
          created_at: '2020-06-29T16:05:55.000000Z',
          updated_at: '2020-06-29T16:05:55.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: null,
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 16,
      name: 'Materiale prevalente - Plastica - PP',
      created_at: '2020-08-06T16:08:47.000000Z',
      updated_at: '2021-01-18T16:51:11.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 106,
          name: 'Materiale secondario - Acciaio',
          family_id: 16,
          created_at: '2020-10-14T07:13:31.000000Z',
          updated_at: '2021-01-15T14:13:03.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 105,
          name: 'Materiale secondario - Alluminio',
          family_id: 16,
          created_at: '2020-10-14T07:12:32.000000Z',
          updated_at: '2021-01-15T14:12:56.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 151,
          name: 'Materiale secondario - Carta',
          family_id: 16,
          created_at: '2021-01-15T14:45:30.000000Z',
          updated_at: '2021-01-15T14:45:30.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 164,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 16,
          created_at: '2021-01-15T15:00:51.000000Z',
          updated_at: '2021-01-15T15:00:51.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 171,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 16,
          created_at: '2021-01-15T15:07:36.000000Z',
          updated_at: '2021-01-15T15:07:50.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 107,
          name: 'Materiale secondario - Metalli vari',
          family_id: 16,
          created_at: '2020-10-14T07:14:35.000000Z',
          updated_at: '2021-01-15T14:13:09.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 108,
          name: 'Materiale secondario - Plastica',
          family_id: 16,
          created_at: '2020-10-14T07:15:15.000000Z',
          updated_at: '2021-01-15T14:32:35.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 157,
          name: 'Materiale secondario - Vetro',
          family_id: 16,
          created_at: '2021-01-15T14:51:53.000000Z',
          updated_at: '2021-01-15T14:51:53.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PP 95',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        }
      ]
    },
    {
      id: 17,
      name: 'Materiale prevalente - Plastica - PS',
      created_at: '2020-08-06T16:09:20.000000Z',
      updated_at: '2021-01-18T16:52:11.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 115,
          name: 'Materiale secondario - Acciaio',
          family_id: 17,
          created_at: '2020-10-20T08:30:03.000000Z',
          updated_at: '2020-11-29T21:00:11.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta. <br /><br /></p>"
        },
        {
          id: 109,
          name: 'Materiale secondario - Alluminio',
          family_id: 17,
          created_at: '2020-10-14T07:16:06.000000Z',
          updated_at: '2021-01-15T14:13:23.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 152,
          name: 'Materiale secondario - Carta',
          family_id: 17,
          created_at: '2021-01-15T14:46:05.000000Z',
          updated_at: '2021-01-15T14:46:05.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 165,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 17,
          created_at: '2021-01-15T15:01:58.000000Z',
          updated_at: '2021-01-15T15:01:58.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 172,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 17,
          created_at: '2021-01-15T15:08:24.000000Z',
          updated_at: '2021-01-15T15:08:24.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 116,
          name: 'Materiale secondario - Metalli vari',
          family_id: 17,
          created_at: '2020-10-20T08:31:03.000000Z',
          updated_at: '2021-01-15T14:07:08.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 110,
          name: 'Materiale secondario - Plastica',
          family_id: 17,
          created_at: '2020-10-14T07:17:06.000000Z',
          updated_at: '2021-01-15T14:32:18.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta. <br /><br /></p>"
        },
        {
          id: 158,
          name: 'Materiale secondario - Vetro',
          family_id: 17,
          created_at: '2021-01-15T14:52:37.000000Z',
          updated_at: '2021-01-15T14:52:37.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PS 95',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        }
      ]
    },
    {
      id: 14,
      name: 'Materiale prevalente - Plastica - PVC',
      created_at: '2020-08-06T16:08:15.000000Z',
      updated_at: '2021-01-18T16:52:20.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 98,
          name: 'Materiale secondario - Acciaio',
          family_id: 14,
          created_at: '2020-10-14T06:59:42.000000Z',
          updated_at: '2021-01-15T14:12:42.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 91',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 97,
          name: 'Materiale secondario - Alluminio',
          family_id: 14,
          created_at: '2020-10-14T06:59:11.000000Z',
          updated_at: '2021-01-15T14:13:30.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 90',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 149,
          name: 'Materiale secondario - Carta',
          family_id: 14,
          created_at: '2021-01-15T14:43:54.000000Z',
          updated_at: '2021-01-15T14:43:54.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 81',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 162,
          name: 'Materiale secondario - Carta/Alluminio',
          family_id: 14,
          created_at: '2021-01-15T14:58:44.000000Z',
          updated_at: '2021-01-15T14:58:44.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 84',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 169,
          name: 'Materiale secondario - Carta/Alluminio/Acciaio',
          family_id: 14,
          created_at: '2021-01-15T15:06:02.000000Z',
          updated_at: '2021-01-15T15:06:02.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 85',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 99,
          name: 'Materiale secondario - Metalli vari',
          family_id: 14,
          created_at: '2020-10-14T07:06:05.000000Z',
          updated_at: '2021-01-15T14:12:14.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 92',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 100,
          name: 'Materiale secondario - Plastica',
          family_id: 14,
          created_at: '2020-10-14T07:06:37.000000Z',
          updated_at: '2021-01-15T14:33:00.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: '7  >abbreviazione polimero1 + abbreviazione polimero 2 +u2026+ abbreviazione polimero n<',
          question_id: null,
          composed: '1',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 122,
          name: 'Materiale secondario - Vetro',
          family_id: 14,
          created_at: '2021-01-05T09:52:41.000000Z',
          updated_at: '2021-01-05T09:52:41.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/PVC 95',
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 9,
      name: 'Materiale prevalente - Vetro',
      created_at: '2020-07-07T20:46:49.000000Z',
      updated_at: '2020-08-06T16:07:28.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 25,
          name: 'Materiale secondario - Acciaio',
          family_id: 9,
          created_at: '2020-07-07T20:47:29.000000Z',
          updated_at: '2020-08-10T12:52:40.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/GL 97',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 26,
          name: 'Materiale secondario - Alluminio',
          family_id: 9,
          created_at: '2020-07-24T12:00:14.000000Z',
          updated_at: '2020-08-10T12:52:27.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/GL 96',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 28,
          name: 'Materiale secondario - Metalli vari',
          family_id: 9,
          created_at: '2020-07-24T12:01:46.000000Z',
          updated_at: '2020-08-10T12:52:05.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/GL 98',
          question_id: null,
          composed: '1',
          note: null
        },
        {
          id: 27,
          name: 'Materiale secondario - Plastica',
          family_id: 9,
          created_at: '2020-07-24T12:01:13.000000Z',
          updated_at: '2020-08-10T12:52:13.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'C/GL 95',
          question_id: null,
          composed: '1',
          note: null
        }
      ]
    },
    {
      id: 2,
      name: 'Plastica',
      created_at: '2020-06-01T09:54:23.000000Z',
      updated_at: '2021-01-18T16:49:45.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: '<p>Puoi consultare le abbreviazioni dei principali polimeri plastici <a href="http://e-tichettamanager.conai.org/storage/LE_ABBREVIAZIONI_DEI_PRINCIPALI_POLIMERI_PLASTICI.pdf" target="_blank" rel="noopener">qui</a></p>',
      use_displayname: 0,
      show_richname: 1,
      display_inpdf: 0,
      materials: [
        {
          id: 13,
          name: 'Altro',
          family_id: 2,
          created_at: '2020-06-22T10:12:21.000000Z',
          updated_at: '2021-01-13T09:29:15.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: null,
          material_parent_id: null,
          code: '7 abbreviazione del polimero',
          question_id: null,
          composed: '0',
          note: null
        },
        {
          id: 7,
          name: 'HDPE',
          family_id: 2,
          created_at: '2020-06-22T10:04:28.000000Z',
          updated_at: '2020-12-04T15:13:14.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'HDPE 2',
          question_id: null,
          composed: '0',
          note: null
        },
        {
          id: 85,
          name: 'LDPE',
          family_id: 2,
          created_at: '2020-10-12T08:36:14.000000Z',
          updated_at: '2021-01-15T14:10:52.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'LDPE 4',
          question_id: null,
          composed: '0',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 83,
          name: 'PET',
          family_id: 2,
          created_at: '2020-10-12T08:34:06.000000Z',
          updated_at: '2021-01-15T14:10:59.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PET 1',
          question_id: null,
          composed: '0',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 86,
          name: 'PP',
          family_id: 2,
          created_at: '2020-10-12T08:37:17.000000Z',
          updated_at: '2021-01-15T14:10:25.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PP 5',
          question_id: null,
          composed: '0',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 87,
          name: 'PS',
          family_id: 2,
          created_at: '2020-10-12T08:37:55.000000Z',
          updated_at: '2021-01-15T14:10:18.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PS 6',
          question_id: null,
          composed: '0',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        },
        {
          id: 84,
          name: 'PVC',
          family_id: 2,
          created_at: '2020-10-12T08:35:16.000000Z',
          updated_at: '2020-12-04T14:33:00.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'PVC 3',
          question_id: null,
          composed: '0',
          note: "<p>Non separare il tappo dalla bottiglia. <br />Schiaccia la bottiglia. <br />Svuota l'imballaggio prima di conferirlo in raccolta.</p>"
        }
      ]
    },
    {
      id: 19,
      name: 'Sughero',
      created_at: '2020-12-04T15:23:33.000000Z',
      updated_at: '2020-12-04T15:23:33.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 21,
          name: 'Sughero',
          family_id: 19,
          created_at: '2020-06-22T15:57:02.000000Z',
          updated_at: '2021-01-11T13:06:48.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 26,
          material_parent_id: null,
          code: 'FOR 51',
          question_id: null,
          composed: '0',
          note: null
        }
        // {
        //   id: 50,
        //   name: 'Sughero',
        //   family_id: 19,
        //   created_at: '2020-08-03T07:43:31.000000Z',
        //   updated_at: '2020-12-30T14:08:51.000000Z',
        //   deleted_at: null,
        //   icon: null,
        //   waste_disposal_id: 26,
        //   material_parent_id: null,
        //   code: 'FOR 51',
        //   question_id: null,
        //   composed: '0',
        //   note: null
        // }
      ]
    },
    {
      id: 6,
      name: 'Vetro',
      created_at: '2020-06-19T13:08:40.000000Z',
      updated_at: '2020-06-19T13:08:40.000000Z',
      deleted_at: null,
      note: null,
      display_name: null,
      rich_name: null,
      use_displayname: 0,
      show_richname: 0,
      display_inpdf: 1,
      materials: [
        {
          id: 17,
          name: 'Vetro incolore',
          family_id: 6,
          created_at: '2020-06-22T11:28:36.000000Z',
          updated_at: '2020-08-10T12:30:34.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'GL 70',
          question_id: null,
          composed: '0',
          note: null
        },
        {
          id: 19,
          name: 'Vetro marrone',
          family_id: 6,
          created_at: '2020-06-22T11:29:22.000000Z',
          updated_at: '2020-08-10T12:29:52.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'GL 72',
          question_id: null,
          composed: '0',
          note: null
        },
        {
          id: 18,
          name: 'Vetro verde',
          family_id: 6,
          created_at: '2020-06-22T11:29:01.000000Z',
          updated_at: '2020-08-10T12:30:11.000000Z',
          deleted_at: null,
          icon: null,
          waste_disposal_id: 3,
          material_parent_id: null,
          code: 'GL 71',
          question_id: null,
          composed: '0',
          note: null
        }
      ]
    }
  ]
}
