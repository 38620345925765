import React, { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function CheckboxTypeComponent (props) {
  const { valueFromChild, waste, isModifyEnabled } = props
  const [checkedMono, setCheckedMono] = useState(waste.componentType === 'monocomponent')
  const [checkedMulti, setCheckedMulti] = useState(waste.componentType === 'multicomponent')

  const { t } = useTranslation('productDetails')

  const handleChange = (event) => {
    if (event.target.name === 'multi') {
      setCheckedMulti(event.target.checked)
      if (event.target.checked === true) {
        setCheckedMono(false)
      }
      valueFromChild(event, 'multicomponent')
    } else if (event.target.name === 'mono') {
      setCheckedMono(event.target.checked)
      if (event.target.checked === true) {
        setCheckedMulti(false)
      }
      valueFromChild(event, 'monocomponent')
    }
  }

  useEffect(() => {
    if (waste.component === '' &&
    waste.material === '' &&
    waste.wasteCollection === '') {
      setCheckedMono(false)
      setCheckedMulti(false)
    }
  }, [waste.component]) // Only re-run the effect if count changes

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-end"
        spacing={1}>
        <Grid item xs={12} md={6}>
          <Typography content="p" variant="body2" fontSize={10} style={{ color: 'black', marginTop: 8 }}>
            {t('tabs.waste.inputs.monocomponent.monoComponent')}
          </Typography>
          <Checkbox
            disabled={!isModifyEnabled}
            name='mono'
            checked={checkedMono}
            color="primary"
            onChange={handleChange}
            di
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography content="p" variant="body2" fontSize={10} style={{ color: 'black', marginTop: 8 }}>
            {t('tabs.waste.inputs.multicomponent.multiComponent')}
          </Typography>
          <Checkbox
            disabled={!isModifyEnabled}
            name='multi'
            checked={checkedMulti}
            color="primary"
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Grid>
      </Grid>
    </div>
  )
}
CheckboxTypeComponent.propTypes = {
  valueFromChild: PropTypes.func.isRequired,
  waste: PropTypes.object.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired
}

export default CheckboxTypeComponent
