import React from 'react'
import { AreaChart, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Area, ResponsiveContainer } from 'recharts'
import moment from 'moment'
import { makeStyles, Typography, useTheme, CardHeader, Card, CardContent } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  eventsText: {
    color: theme.palette.primary.main,
    fontWeight: 900
  },
  responsiveContainer: {
    boxSizing: 'border-box',
    padding: '10%',
    width: '100%',
    height: '100%'
  }
  // responsive: {
  //   width: '100%',
  //   height: '75%'
  // }
}))

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles()
  const { t } = useTranslation('home')
  if (active) {
    return (
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: 'overline' }}
          title={moment(label).format('DD/MM/YYYY')}
        />
        <CardContent>
          <Typography component='p' variant='body1'>
            {`${t('graph.title')}:`} <span className={classes.eventsText}>{payload && payload[0].payload.count}</span>
          </Typography>
        </CardContent>
      </Card>
    )
  }
  return null
}

function EventsChart ({ data }) {
  const matchesXs = useMediaQuery('(min-width:0px')
  const matchesSm = useMediaQuery('(min-width:600px)')
  const matchesMd = useMediaQuery('(min-width:960px)')
  const theme = useTheme()
  const { t } = useTranslation('home')
  return (<>
    {matchesMd
      ? <ResponsiveContainer width='100%' height={500}>
        <AreaChart
          data={data}
          height = {500}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='eventTime' tickFormatter={eventTime => moment(eventTime).format('DD/MM/YYYY')} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign='top' height={36} />
          <Area
            name={t('graph.title')}
            type='monotone'
            dataKey='count'
            stackId='1'
            stroke={theme.palette.primary.azzurro}
            fill={theme.palette.primary.azzurro}
          />
        </AreaChart>
      </ResponsiveContainer>
      : (matchesSm
        ? <ResponsiveContainer width='100%' height={300}>
          <AreaChart
            data={data}
            height = {300}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='eventTime' tickFormatter={eventTime => moment(eventTime).format('DD/MM/YYYY')} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign='top' height={36} />
            <Area
              name={t('graph.title')}
              type='monotone'
              dataKey='count'
              stackId='1'
              stroke={theme.palette.primary.azzurro}
              fill={theme.palette.primary.azzurro}
            />
          </AreaChart>
        </ResponsiveContainer>
        : (matchesXs
          ? <ResponsiveContainer width='100%' height={300}>
            <AreaChart
              data={data}
              height = {300}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='eventTime' tickFormatter={eventTime => moment(eventTime).format('DD/MM/YYYY')} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign='top' height={36} />
              <Area
                name={t('graph.title')}
                type='monotone'
                dataKey='count'
                stackId='1'
                stroke={theme.palette.primary.azzurro}
                fill={theme.palette.primary.azzurro}
              />
            </AreaChart>
          </ResponsiveContainer>
          : null
        ))}
  </>
  )
}

export default EventsChart
