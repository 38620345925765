import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'
import { createSupplychainMembership } from './supplychains.js'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnFacilitiesCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}

// esport query per i prori ACL
export async function returnAllFacilities () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      limit: Number.MAX_SAFE_INTEGER
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// esport query per avere alcuni stabilimenti
export async function returnSomeFacilities (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllFacilities()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}

// funzione che crea una nuova azienda
export async function createDefaultFacility (facility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  console.log('BEFORE QUERY newFacility => ', facility)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/create`, facility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
// funzione che crea una nuova azienda
export async function returnNewFacility (newFacility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!newFacility) {
    return
  }
  console.log('BEFORE QUERY newFacility => ', newFacility)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/`, newFacility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che modifica un'azienda
export async function editFacility (facility) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  let supplychainIds = []

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!facility) {
    return
  }

  if (facility.supplychains) {
    supplychainIds = facility.supplychains.map(supplychain => supplychain.id)
    delete facility.supplychains
  }
  // mando la query
  const response = await axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/${facility.uuid}`, facility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  if (Array.isArray(supplychainIds)) {
    try {
      await createSupplychainMembership(facility.organizationId, 'facility', response.data.data.uuid, supplychainIds)
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }

  return response
}

// funzione che modifica un'azienda
export async function deleteFacility (facility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!facility) {
    return
  }
  console.log('BEFORE QUERY delete facility => ', facility)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/${facility.uuid}`, facility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export async function returnAllFacilitiesProjection (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllFacilities()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}
export async function returnAllSupplyChainFacilities (supplychainId) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/facilities`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
  return response
}

export async function returnAllSupplyChainFacilitiesProjection (supplychainId, params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/facilities`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
  return response
}
