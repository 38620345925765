import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, Button } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'

function DeleteButtonComponent (props) {
  const {
    deleteComponent,
    index,
    isModifyEnabled,
    toolTipTitle
  } = props

  return (
    <div>
      {/* <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addOutput(trackIndex, outputIndex)} hidden={outputIndex !== ref.length - 1} size="small" color="primary" aria-label="aggiungi input" disabled={!this.state.isModifyEnabled}> */}
      <Tooltip title={toolTipTitle} placement='top-end'>
        <Button className="inputCardButton" onClick={() => deleteComponent(index)} disabled={!isModifyEnabled} size="small" aria-label="elimina input">
          <ClearIcon />
        </Button>
      </Tooltip>
    </div>
  )
}
DeleteButtonComponent.propTypes = {
  deleteComponent: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  toolTipTitle: PropTypes.string.isRequired
}

export default DeleteButtonComponent
