import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SwitchLabels from '../SwitchLabels/SwitchLabels'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'

export default function SimpleMenu (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [switchProp, setSwitchProp] = React.useState({
    checkedA: true, // not used property
    checkedB: props.showAllergenes
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const setShowAttributes = (state) => {
    setSwitchProp(state)
    console.log('switchProp::::', switchProp)
  }

  const closeMenu = (state) => {
    setAnchorEl(null)
    props.handleChangeOption(switchProp)
  }

  return (
    <div>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem>
          <SwitchLabels
            showAllergenes={props.showAllergenes}
            handleChangeOption={props.handleChangeOption}
            setShowAttributes={setShowAttributes}
          ></SwitchLabels>
        </MenuItem>
        {/* <MenuItem>My account</MenuItem> */}
      </Menu>
    </div>
  )
}
