import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  withStyles,
  MenuItem,
  Typography,
  InputLabel,
  Select,
  IconButton,
  Paper,
  Tooltip,
  Tab,
  Tabs,
  Fab,
  Link,
  Chip,
  withWidth,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
  // Switch
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import QRCode from 'qrcode.react'
import { withTranslation } from 'react-i18next'

import { replaceLot, deleteLot, returnSomeSupplyChainLots } from '../../utils/lots'
import { returnAllFacilitiesProjection, returnAllSupplyChainFacilitiesProjection } from '../../utils/facilities'
import { returnAllProductsProjection, returnAllSupplyChainProductsProjection, returnSomeProducts } from '../../utils/products'
import { deepCloneObj, checkFileSize, generateUuid, loadFile, ItLocalizedUtils, locale, urlTobase64 } from '../../utils/utils'
import { returnAllCompaniesProjection, returnAllSupplyChainCompaniesProjection } from '../../utils/companies.js'
import configuration from '../../configuration.js'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DownLoadIcon from '@material-ui/icons/CloudDownload'
import TimeIcon from '@material-ui/icons/AccessTime'
import GetAppIcon from '@material-ui/icons/GetApp'

// import ErrorIcon from '@material-ui/icons/Error'

import {
  amber,
  green
  // red
} from '@material-ui/core/colors'

import { ArrowBack, ArrowForward, Check as CheckIcon } from '@material-ui/icons'
import moment from 'moment'
import CommissionStep from '../Steps/CommissionStep.jsx'
import TransformationStep from '../Steps/TransformationStep.jsx'
import DeliverStep from '../Steps/DeliverStep.jsx'

const getLastLotnumber = lot => {
  const { traceability } = lot

  if (!traceability || !traceability.length) {
    return
  }

  if (Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length && traceability[traceability.length - 1].outputs.length > 0) {
    const outputs = traceability[traceability.length - 1].outputs
    return outputs[outputs.length - 1].lotNumber
  }

  if (Array.isArray(traceability[traceability.length - 1].inputs) && traceability[traceability.length - 1].inputs.length && traceability[traceability.length - 1].inputs.length > 0) {
    const inputs = traceability[traceability.length - 1].inputs
    return inputs[inputs.length - 1].lotNumber
  }
}

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiFormLabel-root': {
      fontSize: '16px'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    margin: 'auto',
    width: '80%',
    height: '70%',
    outline: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primary: {
    color: theme.primary
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: '#c8e0e8',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#c8e0e8'
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  // chipPrimary: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primary,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  // chipPrimaryDark: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primaryDark,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class NewCardModalLotSupplychain extends React.Component {
  constructor (props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    // this.onDragEnd = this.onDragEnd.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.locationChanged = this.locationChanged.bind(this)
    this.locationFromChanged = this.locationFromChanged.bind(this)
    this.locationToChanged = this.locationToChanged.bind(this)
    this.changePhaseTab = this.changePhaseTab.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteLot = this.deleteLot.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.timestampChanged = this.timestampChanged.bind(this)
    this.deadlineTimestampChanged = this.deadlineTimestampChanged.bind(this)
    /* funzione che governa lo stato del lotto */
    this.lotStatusChange = this.lotStatusChange.bind(this)
    this.changeShowDeadline = this.changeShowDeadline.bind(this)
    this.startTimestampChanged = this.startTimestampChanged.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.toogleAlreadyCompletedDialog = this.toogleAlreadyCompletedDialog.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.handleChangeSelectFromChild = this.handleChangeSelectFromChild.bind(this)
    this.getKeyByValue = this.getKeyByValue.bind(this)
    this.supplyChainPreparation = this.supplyChainPreparation.bind(this)
    this.prepareSelectChoice = this.prepareSelectChoice.bind(this)
    this.addFacilityFrom = this.addFacilityFrom.bind(this)
    this.addFacilityTo = this.addFacilityTo.bind(this)
    this.deleteFacilityFrom = this.deleteFacilityFrom.bind(this)
    this.deleteFacilityTo = this.deleteFacilityTo.bind(this)
    this.addInput = this.addInput.bind(this)
    this.deleteInput = this.deleteInput.bind(this)
    this.addOutput = this.addOutput.bind(this)
    this.deleteOutput = this.deleteOutput.bind(this)
    this.verifyInput = this.verifyInput.bind(this)
    this.getQuantity = this.getQuantity.bind(this)
    this.isThisVerified = this.isThisVerified.bind(this)
    this.openUrl = this.openUrl.bind(this)
    this.setAllLotsAreSameCheckbox = this.setAllLotsAreSameCheckbox.bind(this)
    this.completeAllInputs = this.completeAllInputs.bind(this)
    /* funzione che governa la select dello stato per il lotto */
    this.changeLotStatusValue = this.changeLotStatusValue.bind(this)
    this.handleVisibility = this.handleVisibility.bind(this)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward () {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward () {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  returnDefaultState () {
    return {
      filesToLoad: [],
      language: ls.get('i18nextLng'),
      isConfirmDialogOpened: false,
      isShowPhase: (this.props.lot.traceability && this.props.lot.traceability.length > 0) ? this.props.lot.traceability[0].showPhase : true,
      isSaveDialogOpen: false,
      isAlreadyCompletedDialogOpen: false,
      someInfoAreMissing: false,
      tabNumber: 0,
      phaseTabNumber: 0,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      modified: false,
      open: this.props.open,
      isCompleted: false,
      facilities: [],
      products: [],
      lot: {
        ...this.props.lot,
        supplychains: [] /* array di oggetti id e name. Il tutto potrebbe essere gestito meglio, in quanto in caso di non draft il lotto si ritrova anche la proprietà lot.supplychainsId.
        ATTUALMENTE, PER NON ROMPERE NULLA CON IL PASSATO (LA SCELTA PER IL DRAFT è STATA PRESA IN SEGUITO) SI MANTIENE QUESTA POSSIBILIT */
      },
      initialLot: deepCloneObj(this.props.lot),
      /* la variabile di stato che tiene il controllo dello stato del lotto. Lo stato del lotto può essere:
      - aggiornato automaticamente man mano che si compilano le fasi
      - può essere aggiornato forzatamente (NB: questo tipo di aggiornamento si può fare solo quando il lotto può effettivamente passare da bozza ad attivo
      (ossia tutte le fasi complete) e, una volta che il lotto è stato salvato come attivo,
      non si può più far tornare a bozza
      */
      lotStatus: this.props.lot.status,
      product: this.props.product,
      verifyLot: {}, // oggetto formato {'numero-fase':[{input:inputIndex, verified:true}]}
      productsIdNameMap: this.props.productsIdNameMap,
      supplychainsId: this.props.lot.supplychainsId, // se il lotto è draft prendo le supplychain dal prodotto, se il lotto è complete prendo le supplychain del lotto
      allLotsAreSameCheckboxInput: false,
      allLotsAreSameCheckboxOutput: false
    }
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  changeLotStatusValue (isCompleted) {
    /* decommentare se si vuole riattivare la vecchia logica, ossia che il lotto passava automaticamente ad attivo. */
    // if (isCompleted) {
    //   return 'active'
    // } else {
    //   return 'draft'
    // }
    return 'draft'
  }

  changePhaseTab (phaseTabNumber) {
    const lot = this.state.lot
    this.setState({ phaseTabNumber, isShowPhase: lot.traceability[phaseTabNumber].showPhase })
  }

  closeSaveDialog () {
    if (!this.state.isModifing) {
      this.setState({ isSaveDialogOpen: false })
    }
  }

  setAllLotsAreSameCheckbox (event, inputIndex, type) {
    if (type === 'input') {
      this.setState({
        allLotsAreSameCheckboxInput: event.target.checked
      })
    }
    if (type === 'output') {
      this.setState({
        allLotsAreSameCheckboxOutput: event.target.checked
      })
    }
    if (event.target.checked) {
      this.props.setSnackbar(this.props.t('notifications.automaticCompile'), 'warning')
      this.completeAllInputs(inputIndex, type)
    }
  }

  completeAllInputs (inputIndex, type) {
    const lot = deepCloneObj(this.state.lot)
    lot.traceability.forEach((step, stepIndex) => {
      if (stepIndex !== 0) {
        if (Array.isArray(step.inputs) && step.inputs.length > 0) {
          step.inputs.forEach(input => {
            input.lotNumber = type === 'input' ? lot.traceability[0].inputs[inputIndex].lotNumber : (type === 'output' ? lot.traceability[0].outputs[inputIndex].lotNumber : null)
            input.quantity = type === 'input' ? lot.traceability[0].inputs[inputIndex].quantity : (type === 'output' ? lot.traceability[0].outputs[inputIndex].quantity : null)
          })
        }
        const isComplete = this.phaseCompleted(step)
        if (isComplete) {
          step.status = 'completed'
        } else {
          step.status = 'draft'
        }
      }
    })

    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({
      lot: {
        ...this.state.lot,
        traceability: lot.traceability
      },
      isCompleted,
      lotStatus
    })
  }

  async componentDidMount () {
    const lot = this.supplyChainPreparation()
    const verifyLot = {}
    const language = this.state.language === 'en-US' ? 'en' : 'it'
    lot.traceability.forEach((track, index) => {
      // controllo se ha lo status
      if (!Object.prototype.hasOwnProperty.call(track, 'status')) {
        track.status = this.phaseCompleted(track) ? 'completed' : 'draft'
      }

      console.log('inputs nel lotto', track.inputs)
      verifyLot[index] = []
    })
    // controllo se è un draft e abilito modifiche
    const isModifyEnabled = lot.status === 'draft'
    /* isCompleted, dopo che la modal è stata montata, viene aggiornato con il valore corretto in base a se ogni fase ha il valore completed */
    const isCompleted = lot.traceability.every(track => track.status === 'completed')
    /* dopo che la modal è stata montata, lotStatus viene aggiornato a active o draft in base a se le fasi sono tutte complete.
    NB: se il lotto, che viene passato come props, ha lo stato ad active la select non si vede (in quanto, una volta che il lotto è stato reso attivo, non posso tornare a bozza)
    */
    const lotStatus = this.changeLotStatusValue(isCompleted)

    console.log('isCompleted', isCompleted)
    const tabNumber = lot.status === 'draft' ? 2 : 0
    await this.prepareSelectChoice()

    this.setState({
      lot,
      language,
      lotStatus,
      isModifyEnabled,
      tabNumber,
      isCompleted,
      verifyLot
    })
  }

  async componentDidUpdate (prevPros, prevState) {
    if (prevState.lot.supplychains !== this.state.lot.supplychains) {
      await this.prepareSelectChoice()
    }
    if (this.state.isCompleted && (prevState.isCompleted !== this.state.isCompleted) && this.props.lot.status !== 'active') { this.props.setSnackbar(this.props.t('notifications.mantainDraft'), 'success') }
  }

  supplyChainPreparation () {
    const lot = deepCloneObj(this.state.lot)
    if (this.state.supplychainsId.length > 0) {
      lot.supplychains = this.state.supplychainsId.map(value => ({
        name: this.props.allSupplychainIdNameMap[value],
        id: value
      }))
    } else {
      lot.supplychains = []
    }
    console.log('lot::::', lot)
    return lot
  }

  /* funzione che mi prepara tutti gli input per le company, le facilities e i prodotti */
  async prepareSelectChoice () {
    // const instance = this.state.lot.status === 'draft' ? deepCloneObj(this.state.product) : deepCloneObj(this.state.lot) // quando voglio dare un comportamento diversificato in base a se si è draft (tipo poter definire tra le supplychain TUTTE quelle del prodotto se non definite nel lotto di partenza)
    const instance = deepCloneObj(this.state.lot)
    /* ci metto anche la company come parametro così che nelle select posso metterci il nome della facility e l'azienda legata */
    const facilityParam = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER,
      fields: 'name,uuid,status,companyId'
    }

    const facilitySupplychainParam = {
      fields: 'name,uuid,status,companyId'
    }

    /* ha senso fare la doppia richiesta poiché non tutte le mie facilities devono perforza appartenere alla filiera. */
    const facilitiesResponse = await returnAllFacilitiesProjection(facilityParam)

    let allFacilities = []

    for (const supplychain of instance.supplychains) {
      /* ritorno tutti i prodotti della supplychain solo per nome e uuid */
      const facilitiesSupplychainsResponse = await returnAllSupplyChainFacilitiesProjection(supplychain.id, facilitySupplychainParam)
      for (const facility of facilitiesSupplychainsResponse.data.data.facilities) {
        allFacilities.push(facility)
      }
    }
    // all facilities from API
    facilitiesResponse.data.data.forEach(fac => {
      allFacilities.push(fac)
    })
    // filtro sull'array in order to remove duplicates
    allFacilities = allFacilities.filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i)

    // filtro per avere solo le facilities attive e elimino la ND
    const facilities = allFacilities.filter(fac => fac.status === 'active')

    const companiesParams = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER,
      fields: 'name,uuid,status'
    }
    const companiesSupplychainParams = {
      fields: 'name,uuid,status'
    }

    /* faccio anche la richiesta per le company per riportarmi i nomi vicino ai nomi dei prodotti e stabilimenti sulle select */
    const companiesNameResponse = await returnAllCompaniesProjection(companiesParams)

    let allCompanies = []

    for (const supplychain of instance.supplychains) {
      /* ritorno tutti i prodotti della supplychain solo per nome e uuid */
      const companiesSupplychainsResponse = await returnAllSupplyChainCompaniesProjection(supplychain.id, companiesSupplychainParams)
      for (const company of companiesSupplychainsResponse.data.data.companies) {
        allCompanies.push(company)
      }
    }
    // all facilities from API
    companiesNameResponse.data.data
      .forEach(com => {
        allCompanies.push(com)
      })

    // filtro sull'array in order to remove duplicates
    allCompanies = allCompanies.filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i)

    facilities.forEach(facility => {
      const company = allCompanies
        .find(c => c.uuid === facility.companyId)
      facility.companyData = company
    })

    /* per la select mi ritorno tutti i prodotti ma solo il nome,l'uuid,lo status e il companyId (mi serve cosi da visualizzare al fianco del nome del prodotto, il nome della company) sia per l'azienda, sia per la supplychain */
    const productsParams = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER,
      fields: 'name,uuid,status,companyId'
    }

    const productsParamsSupplychain = {
      fields: 'name,uuid,status,companyId'
    }
    const productsResponse = await returnAllProductsProjection(productsParams)
    let allProducts = []
    console.log('product.supplychainsId:::::', instance.supplychainsId)
    for (const supplychain of instance.supplychains) {
      /* ritorno tutti i prodotti della supplychain solo per nome e uuid */
      const productsSupplychainsResponse = await returnAllSupplyChainProductsProjection(supplychain.id, productsParamsSupplychain)

      for (const productSupply of productsSupplychainsResponse.data.data.products) {
        allProducts.push(productSupply)
      }
    }

    // all products from API
    productsResponse.data.data.forEach(prod => {
      allProducts.push(prod)
    })
    // filtro sull'array in order to remove duplicates
    allProducts = allProducts.filter((v, i, a) => a.findIndex(t => (t.uuid === v.uuid)) === i)

    // filtro per avere solo i prodotti attivi e elimino la ND
    const products = allProducts.filter(prod => prod.status === 'active')
    products.forEach(product => {
      const company = allCompanies
        .find(c => c.uuid === product.companyId)
      product.companyData = company
    })
    this.setState({ facilities, products, companies: allCompanies })
  }

  phaseCompleted (track) {
    console.log('SONO nel phase completed della NEWCARDmodal:::::::')
    if (
      (
        track.completedAt &&
        track.startedAt &&
        Array.isArray(track.inputs) &&
        track.inputs.length > 0 &&
        track.inputs.every(input => (input.lotNumber && (input.productId && input.productId.indexOf('supplychain') === -1) && (input.uom && input.uom.indexOf('supplychain') === -1)))
      ) &&
      (
        ((track.eventType !== 'observation_shipping') && (track.eventType !== 'observation_receiving') && (track.eventType !== 'transformation') && (track.location.facilityId && track.location.facilityId.indexOf('supplychain') === -1)) ||
        ((track.eventType === 'transformation') && track.outputs.length > 0 && track.outputs.every(output => (output.lotNumber && (output.productId && output.productId.indexOf('supplychain') === -1) && (output.uom && output.uom.indexOf('supplychain') === -1))) && (track.location.facilityId && track.location.facilityId.indexOf('supplychain') === -1)) ||
        (((track.eventType === 'observation_shipping') || (track.eventType === 'observation_receiving')) && (Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationFrom.every(fac => fac.facilityId && fac.facilityId.indexOf('supplychain') === -1)) && (Array.isArray(track.locationTo) && track.locationTo.length > 0 && track.locationTo.every(fac => fac.facilityId && fac.facilityId.indexOf('supplychain') === -1)))
      )
    ) {
      return true
    }
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false })
  }

  // funzione che viene lanciata alla chiusara della dialog
  closeModal (forceClose, shouldIForceRefresh) {
    if (this.state.isModifyEnabled || this.state.modified) {
      // console.log('dentro primo if')
      if (forceClose === true) {
        // console.log('dentro primo force clse, forceClose => ', forceClose)
        this.setState(this.returnDefaultState())
        this.props.onCloseModal(shouldIForceRefresh)
      } else {
        this.setState({ isSaveDialogOpen: true, saveFromClose: true, modified: false })
      }
    } else {
      console.log('dentro secondo if')
      this.setState(this.returnDefaultState())
      // this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.onCloseModal(shouldIForceRefresh)
    }
  }

  deleteDocument (trackIndex, documentIndex) {
    const lot = this.state.lot
    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const filesToLoadIndex = filesToLoad
      .findIndex((file) => file.order === documentIndex && file.type === 'document' && file.index === trackIndex)

    if (filesToLoadIndex > -1) {
      filesToLoad.splice(filesToLoadIndex, 1)
    }
    filesToLoad
      .filter((file, index) => (file.type === 'document' && file.index === trackIndex && documentIndex <= file.order))
      .forEach(file => {
        // console.log('doc in filest to load', file)
        file.order = file.order - 1
      })
    lot.traceability[trackIndex].documents.splice(documentIndex, 1)
    lot.traceability[trackIndex].documents
      .filter((doc, index) => documentIndex <= index)
      .forEach(doc => {
        // console.log('doc', doc)
        doc.order = doc.order - 1
      })
    // console.log('this.state.files', this.state.filesToLoad)
    this.setState({ lot, filesToLoad: filesToLoad }
      // () => {
      //   console.log('this.state.files', this.state.filesToLoad)
      // }
    )
  }

  // DEPRECATED
  deleteImage (trackIndex, mediaIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].media.splice(mediaIndex, 1)
    this.setState({ lot })
  }

  async deleteLot () {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    const lot = this.state.lot
    try {
      lot.status = 'deleted'
      await deleteLot(lot)
      // await axios.put(`${configuration.apiBaseUrl}/lots/${this.state.lot.uuid}`, { status: 'deleted' }, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      this.setState({ isDeleting: false })
      this.closeModal(true, true)
    } catch (e) {
      this.setState({ isDeleting: false })
      console.error(`Unable to delete lot with uuid ${lot.uuid}: `, e)
      this.props.setSnackbar(this.props.t('notifications.deleteLotError'), 'error')
      // alert('Impossibile eliminare il lotto')
    }
  }

  enableOrDisableModify () {
    if (this.state.isModifyEnabled === true) {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, modified: true })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
  }

  // funzione che controlla la completezza di una fase
  isThisPhaseCompleted (track) {
    if (track.completedAt !== '' && track.startedAt !== '' && !track.inputs.some(input => input.lotNumber === '')) {
      if (track.outputs) {
        if (!track.outputs.some(output => output.lotNumber === '')) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  // funzione che controlla se tutte le fasi sono complete
  isThisLotComplete (tracks) {
    return tracks.every(track => { return this.phaseCompleted(track) })
    // let count = 0
    // const max = tracks.length
    // tracks.forEach(track => {
    //   if (this.isThisPhaseCompleted(track)) {
    //     count += 1
    //   }
    // })
    // if (count === max) {
    //   return true
    // } else {
    //   return false
    // }
  }

  // funzione che cambia i valori dei campi di input
  inputChanged ({ target }, trackIndex, inputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].inputs[inputIndex][target.name] = target.value
    // controllo se la fase è completa
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let allLotsAreSameCheckboxInput = this.state.allLotsAreSameCheckboxInput
    if (trackIndex === 0) {
      if (allLotsAreSameCheckboxInput) {
        allLotsAreSameCheckboxInput = false
        this.props.setSnackbar(this.props.t('notifications.deleteAutomaticCompile'), 'warning')
      }
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus, allLotsAreSameCheckboxInput })
  }

  // inputChangedNewSelect ({ target }, trackIndex, inputIndex) {
  //   const lot = this.state.lot
  //   lot.traceability[trackIndex].inputs[inputIndex][target.name] = target.value
  //   const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
  //   if (isComplete) {
  //     lot.traceability[trackIndex].status = 'completed'
  //   } else {
  //     lot.traceability[trackIndex].status = 'draft'
  //   }
  //   let isCompleted = this.state.isCompleted
  //   if (this.isThisLotComplete(lot.traceability)) {
  //     isCompleted = true
  //   } else {
  //     isCompleted = false
  //   }
  //   const lotStatus = this.changeLotStatusValue(isCompleted)
  //   this.setState({ lot, isCompleted, lotStatus })
  // }

  outputChangedNewSelect ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  /* utilizzata per decidere se mostrare o meno il bottone add input */
  // isNotLastInput (trackIndex, inputIndex, length) {
  //   const initialLot = deepCloneObj(this.state.initialLot)
  //   if (inputIndex + 1 !== length && (initialLot.traceability[trackIndex].inputs[inputIndex - 1] === undefined || initialLot.traceability[trackIndex].inputs[inputIndex - 1].productId.indexOf('supplychain') > -1)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  /* utilizzata per decidere se mostrare o meno il bottone add facility */
  // isNotLastFacility (trackIndex, locationIndex, length, type) {
  //   const initialLot = deepCloneObj(this.state.initialLot)
  //   if (type === 'locationFrom') {
  //     if (locationIndex + 1 !== length && (initialLot.traceability[trackIndex].locationFrom[locationIndex - 1] === undefined || initialLot.traceability[trackIndex].locationFrom[locationIndex - 1].facilityId.indexOf('supplychain') > -1)) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  //   if (type === 'locationTo') {
  //     if (locationIndex + 1 !== length && (initialLot.traceability[trackIndex].locationTo[locationIndex - 1] === undefined || initialLot.traceability[trackIndex].locationTo[locationIndex - 1].facilityId.indexOf('supplychain') > -1)) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   }
  // }

  /* utilizzata per decidere se mostrare o meno il bottone delete Input
  il Bottone di delete non viene mostrato nel caso in cui gli input sono tutti ND
  e sono il primo Input
  */

  /* NOTE: se mai riserverp decommentare */
  // ifThereAreAlsoOtherInput (trackIndex, inputIndex, length) {
  //   const initialLot = deepCloneObj(this.state.initialLot)
  //   // gli inputs sono solo ND
  //   if (initialLot.traceability[trackIndex].inputs.length === length) {
  //     if (length === 1) { return true }
  //   }
  // }

  // ifThereAreAlsoOtherFacility (trackIndex, locationIndex, length, type) {
  //   const initialLot = deepCloneObj(this.state.initialLot)
  //   // gli inputs sono solo ND
  //   if (type === 'locationFrom') {
  //     if (initialLot.traceability[trackIndex].locationFrom.length === length) {
  //       if (length === 1) { return true }
  //     }
  //   }
  //   if (type === 'locationTo') {
  //     if (initialLot.traceability[trackIndex].locationTo.length === length) {
  //       if (length === 1) { return true }
  //     }
  //   }
  // }

  addFacilityTo (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)

    const locationTo = traceability[trackIndex].locationTo

    locationTo.push({
      facilityId: '',
      name: '',
      lat: '',
      lng: ''
    })

    traceability[trackIndex].status = 'draft'
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted: false, // lo faccio passare a false per far capire che la fase non è pronta
      lotStatus: 'draft' // essendo isCompleted passato a false, faccio passare lotStatus a draft
    })
  }

  addFacilityFrom (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const locationFrom = traceability[trackIndex].locationFrom

    locationFrom.push({
      facilityId: '',
      name: '',
      lat: '',
      lng: ''
    })

    traceability[trackIndex].status = 'draft'
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted: false, // lo faccio passare a false per far capire che la fase non è pronta
      lotStatus: 'draft' // essendo isCompleted passato a false, faccio passare lotStatus a draft
    })
  }

  deleteFacilityFrom (trackIndex, locationIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const locationFrom = traceability[trackIndex].locationFrom

    locationFrom.splice(locationIndex, 1)

    const isComplete = this.phaseCompleted(traceability[trackIndex])
    if (isComplete) {
      traceability[trackIndex].status = 'completed'
    } else {
      traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted,
      lotStatus
    })
  }

  deleteFacilityTo (trackIndex, locationIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)

    const locationTo = traceability[trackIndex].locationTo
    locationTo.splice(locationIndex, 1)

    const isComplete = this.phaseCompleted(traceability[trackIndex])
    if (isComplete) {
      traceability[trackIndex].status = 'completed'
    } else {
      traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted,
      lotStatus
    })
  }

  addInput (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const inputs = traceability[trackIndex].inputs

    inputs.push({
      productId: '',
      uom: '',
      quantity: '',
      lotNumber: ''
    })
    traceability[trackIndex].status = 'draft'
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted: false, // lo faccio passare a false per far capire che la fase non è pronta
      lotStatus: 'draft' // essendo isCompleted passato a false, faccio passare lotStatus a draft
    })
  }

  addOutput (trackIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const outputs = traceability[trackIndex].outputs

    outputs.push({
      productId: '',
      uom: '',
      quantity: '',
      lotNumber: ''
    })
    traceability[trackIndex].status = 'draft'
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted: false, // lo faccio passare a false per far capire che la fase non è pronta
      lotStatus: 'draft' // essendo isCompleted passato a false, faccio passare lotStatus a draft
    })
  }

  openUrl (url) {
    // console.log('url =>', url)
    window.open(url, '_blank')
  }

  deleteInput (trackIndex, inputIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const inputs = traceability[trackIndex].inputs

    inputs.splice(inputIndex, 1)

    const isComplete = this.phaseCompleted(traceability[trackIndex])
    if (isComplete) {
      traceability[trackIndex].status = 'completed'
    } else {
      traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted,
      lotStatus
    })
  }

  deleteOutput (trackIndex, outputIndex) {
    const traceability = deepCloneObj(this.state.lot.traceability)
    const outputs = traceability[trackIndex].outputs

    outputs.splice(outputIndex, 1)

    const isComplete = this.phaseCompleted(traceability[trackIndex])
    if (isComplete) {
      traceability[trackIndex].status = 'completed'
    } else {
      traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({
      lot: {
        ...this.state.lot,
        traceability
      },
      isCompleted,
      lotStatus
    })
  }

  getQuantity (lot) {
    const { traceability } = lot

    if (!traceability || !traceability.length) {
      return
    }

    if (Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
      const outputs = traceability[traceability.length - 1].outputs
      return outputs[outputs.length - 1].quantity
    }

    const inputs = traceability[traceability.length - 1].inputs
    return inputs[inputs.length - 1].quantity
  }

  async verifyInput (input, trackIndex, inputOutputIndex, type = 'single') {
    const verifyLot = deepCloneObj(this.state.verifyLot)
    let verified = false
    const isMyProductResponse = await returnSomeProducts({ filter: JSON.stringify({ uuid: input.productId, status: { $ne: 'deleted' } }), fields: 'uuid' })
    const isMyProduct = isMyProductResponse.data.data
    if (Array.isArray(isMyProduct) && isMyProduct.length > 0) {
      verified = true
      if (type !== 'all') { this.props.setSnackbar(this.props.t('notifications.lotOwn'), 'info') }
    } else {
      const lotParam = {
        productId: input.productId,
        lotNumber: input.lotNumber,
        limit: Number.MAX_SAFE_INTEGER
      }
      for (const [index, supply] of Object.entries(this.state.lot.supplychains)) {
        try {
          const lotsResponse = await returnSomeSupplyChainLots(supply.id, lotParam)
          const lot = lotsResponse.data.data.lots
          if (lot.length > 0) {
            const quantity = this.getQuantity(lot[0])
            if (parseInt(quantity) >= parseInt(input.quantity)) {
              verified = true
              if (type !== 'all') { this.props.setSnackbar(this.props.t('notifications.lotLoaded'), 'info') }
              break
            } else {
              verified = false
              if ((parseInt(index) === this.state.lot.supplychains.length - 1)) {
                if (type !== 'all') { this.props.setSnackbar(this.props.t('notifications.quantityNotVerified'), 'warning') }
              }
            }
          } else {
            if ((parseInt(index) === this.state.lot.supplychains.length - 1)) {
              console.log('sono ULTIMO nel false')
              if (type !== 'all') { this.props.setSnackbar(this.props.t('notifications.lotNotVerified'), 'warning') }
            }
          }
        } catch (e) {
          this.props.setSnackbar(this.props.t('notifications.errorSupplychain'), 'error')
        }
      }
    }
    const inputYetVerified = verifyLot[trackIndex].find((inp) => parseInt(inp.input) === parseInt(inputOutputIndex))
    if (inputYetVerified) {
      inputYetVerified.verified = verified
    } else {
      verifyLot[trackIndex].push({ input: parseInt(inputOutputIndex), verified })
    }
    this.setState({
      verifyLot
    }, () => {
      console.log('verify LOt::', this.state.verifyLot)
    })
  }

  handleVisibility (trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].showPhase = !lot.traceability[trackIndex].showPhase
    this.setState({ lot, isShowPhase: lot.traceability[trackIndex].showPhase }, () => {
      console.log('LOT post handle click:', this.state.lot)
    })
  }

  getKeyByValue (object, value) {
    return Object.keys(object).find(key => object[key] === value)
    // return values.map(value => Object.keys(object).find(key => object[key] === value))
  }

  handleChangeSelectFromChild (supplychainsName) {
    const supplychains = []
    for (const supplychainName of supplychainsName) {
      supplychains.push({
        name: supplychainName,
        id: this.getKeyByValue(this.props.allSupplychainIdNameMap, supplychainName)
      })
    }
    this.setState({
      lot: {
        ...this.state.lot,
        supplychains
      }
    })
  }

  locationChanged ({ target }, index) {
    const thisLocation = this.state.facilities.find(facility => facility.uuid === target.value)
    const lot = this.state.lot
    lot.traceability[index].location = {
      name: thisLocation.name || 'N.F.',
      lat: thisLocation.geolocation ? thisLocation.geolocation.lat : '41.896187',
      lng: thisLocation.geolocation ? thisLocation.geolocation.lng : '12.492046',
      facilityId: target.value
    }
    const isComplete = this.phaseCompleted(lot.traceability[index])
    if (isComplete) {
      lot.traceability[index].status = 'completed'
    } else {
      lot.traceability[index].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    console.log('TRACEABILITY - LOCATION ', lot.traceability[index])
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  locationFromChanged ({ target }, index, locationIndex) {
    // console.log('target.value => ', target.value)

    const thisFrom = this.state.facilities.find(facility => facility.uuid === target.value)
    const lot = this.state.lot
    lot.traceability[index].locationFrom[locationIndex] = {
      name: thisFrom.name || 'N.F.',
      lat: thisFrom.geolocation ? thisFrom.geolocation.lat : '41.896187',
      lng: thisFrom.geolocation ? thisFrom.geolocation.lng : '12.492046',
      facilityId: target.value
    }
    const isComplete = this.phaseCompleted(lot.traceability[index])
    if (isComplete) {
      lot.traceability[index].status = 'completed'
    } else {
      lot.traceability[index].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    console.log('TRACEABILITY - LOCATION ', lot.traceability[index])
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  locationToChanged ({ target }, index, locationIndex) {
    // console.log('target.value => ', target.value)
    const thisTo = this.state.facilities.find(facility => facility.uuid === target.value)
    const lot = this.state.lot
    lot.traceability[index].locationTo[locationIndex] = {
      name: thisTo.name || 'N.F.',
      lat: thisTo.geolocation ? thisTo.geolocation.lat : '41.896187',
      lng: thisTo.geolocation ? thisTo.geolocation.lng : '12.492046',
      facilityId: target.value
    }
    const isComplete = this.phaseCompleted(lot.traceability[index])
    if (isComplete) {
      lot.traceability[index].status = 'completed'
    } else {
      lot.traceability[index].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    const lotStatus = this.changeLotStatusValue(isCompleted)
    console.log('TRACEABILITY - LOCATION ', lot.traceability[index])
    this.setState({ lot, isCompleted, lotStatus })
  }

  // funzione che cambia i valori dei campi dgli output
  outputChanged ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    // controllo se la fase è completa
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let allLotsAreSameCheckboxOutput = this.state.allLotsAreSameCheckboxOutput
    if (trackIndex === 0) {
      if (allLotsAreSameCheckboxOutput) {
        allLotsAreSameCheckboxOutput = false
        this.props.setSnackbar(this.props.t('notifications.deleteAutomaticCompile'), 'warning')
      }
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus, allLotsAreSameCheckboxOutput })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  async openMedia (url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
        <head>
          <title>Trusty | Documenti</title>
        </head>
        <body style="margin: 0px;">
          <iframe width="100%" height="100%" src="${file}"></iframe>
        </body>
      </html>
      `)
    } catch (e) {
      this.props.setSnackbar('Impossibile visualizzare il documento!', 'error')
    }
  }

  // funzione che salva le modifiche
  async saveModification () {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    const updateObject = deepCloneObj(this.state.lot)
    // const updateObject = Object.keys(this.state.lot).reduce((acc, k) => {
    //   acc[k] = this.state.lot[k]
    //   return acc
    // }, {})

    delete updateObject._id
    updateObject.updatedAt = Date.now()
    // controllo che tutto sia settato bene
    /* caso in cui il lotto è attivo e provo a cancellare qualche informazione. TODO: da verificare se è voluto che si possa cambiare
    qualche info dopo che il lotto è stato reso attivo */
    if (!this.state.isCompleted && updateObject.status === 'active') {
      this.setState({ isModifing: false })
      return this.toogleAlreadyCompletedDialog()
    } else if (this.state.isCompleted && this.state.lotStatus === 'active' && updateObject.status === 'draft') {
      /* in questo ci entro solo se nella select il valore è active
      isCompleted è true e il lotto ha stato draft -> queste tre condizioni mi indicano che voglio rendere il lotto attivo
      */
      /* viene preso il lot number inserito nell'ultima fase, se contiene solo la fase di input viene preso da lì altrimenti se contiene quella di output da li */
      updateObject.lotNumber = updateObject.traceability[updateObject.traceability.length - 1].inputs[updateObject.traceability[updateObject.traceability.length - 1].inputs.length - 1].lotNumber
      // se ci sono gli output nell'ultima fase segno quello come lot number
      if (updateObject.traceability[updateObject.traceability.length - 1].outputs && updateObject.traceability[updateObject.traceability.length - 1].outputs.length) {
        updateObject.lotNumber = updateObject.traceability[updateObject.traceability.length - 1].outputs[updateObject.traceability[updateObject.traceability.length - 1].outputs.length - 1].lotNumber
      }
      updateObject.status = 'active'
    }

    try {
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedTraceability = Object.assign([], this.state.lot.traceability)
      // console.log('filesToLoad => ', filesToLoad)
      // console.log('updateObject => ', updateObject)
      for (const file of filesToLoad) {
        const { blob, name, index, order, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `lots/${this.state.lot.uuid}/${fileUuid}.${extention}`
        if (section === 'traceability' && type === 'document') {
          const url = await loadFile(blob, filename)
          if (updatedTraceability && updatedTraceability[index] && updatedTraceability[index].documents && updatedTraceability[index].documents[order] && updatedTraceability[index].documents[order].fileUrl) {
            updatedTraceability[index].documents[order].fileUrl = url
          }
          file.loaded = true
        }
      }
      updateObject.traceability = updatedTraceability

      // elimino l'oggetto opentimestamps per evitare di sovrascrivere l'url aggiornato dall'opentimestamps worker
      delete updateObject.opentimestamps
      // controllo l'expirationDate
      if (!updateObject.expirationDate || updateObject.expirationDate === '') {
        delete updateObject.expirationDate
      }
      await this.verifyInputComplete(updateObject)
      const verified = this.isThisVerified(this.state.verifyLot)
      console.log('verified:::::', verified)
      updateObject.verified = verified
      console.log('updateObject => ', updateObject)
      const responseObject = await replaceLot(updateObject)
      // const responseObject = await axios.put(`${configuration.apiBaseUrl}/lots/${updateObject.uuid}`, updateObject, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      // const lot = responseObject.data && responseObject.data.data ? responseObject.data.data : this.state.lot
      const lot = responseObject.data.data
      lot.updatedAt = updateObject.updatedAt
      this.setState({
        isModifing: false,
        lot: {
          ...this.state.lot,
          lot
        },
        filesToLoad: []
      })
      await this.props.onCloseModal(true)
      if (responseObject.error) {
        this.props.setSnackbar(this.props.t('notifications.errorSupplychain'), 'error')
      } else {
        if (updateObject.verified) { this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success') } else { this.props.setSnackbar(this.props.t('notifications.editsOkNotVerified'), 'success') }
      }
      // alert('Modifica avvenuta con successo')
    } catch (e) {
      this.setState({ isModifing: false })
      console.error('Unable to modify lot, error => ', e)
      this.props.setSnackbar(this.props.t('notifications.editsError'), 'error')
      // alert('Impossibile modificare il lotto')
    }
  }

  // funzione che viene lanciata alla chiusura della dialog salvando le modifiche
  async confirmSave () {
    if (!this.state.isCompleted && this.state.lot.status === 'active') {
      this.toogleAlreadyCompletedDialog()
      return this.setState({ isSaveDialogOpen: false })
    } else {
      await this.saveModification()
      this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
      this.closeSaveDialog()
      // this.props.onCloseModal(true)
    }
  }

  confirmClose () {
    if (!this.state.isModifing) {
      this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
      this.closeSaveDialog()
      this.props.onCloseModal(false)
    }
  }

  startTimestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].startedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].startedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  timestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].completedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].completedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.phaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    const lotStatus = this.changeLotStatusValue(isCompleted)
    this.setState({ lot, isCompleted, lotStatus })
  }

  lotStatusChange (event) {
    this.setState({
      lotStatus: event.target.value
      // lot: {
      //   ...this.state.lot,
      //   status: event.target.value
      // }
    }, () => {
      console.log('ciao ciao ', this.state.lotStatus)
    })
  }

  deadlineTimestampChanged (date) {
    console.log('date = ', date)
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      date.setHours(0, 0, 0, 0)
      lot.expirationDate = date.toISOString()
    } else {
      lot.expirationDate = null
    }

    this.setState({ lot })
  }

  // DEPRECATED
  // funzione che cambia il valore di mostra / nascondi data
  changeShowDeadline () {
    const lot = this.state.lot
    console.log('lot.lot_information.showDeadline => ', lot.lot_information.showDeadline)
    lot.lot_information.showDeadline = !lot.lot_information.showDeadline
    this.setState({ lot })
    console.log('new this.state.lot.lot_information =>', this.state.lot.lot_information)
  }

  preventDefault = (event) => event.preventDefault();

  async uploadDocument ({ target }, trackIndex) {
    const lot = this.state.lot
    const documents = lot.traceability[trackIndex].documents
    const candidateMaxOrder = documents.length - 1
    const maxOrder = candidateMaxOrder
    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)
        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(blob)
        })

        documents.push({
          order: maxOrder + 1,
          fileUrl: fileContent,
          type: 'other',
          description: '',
          name: fileName
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index: trackIndex,
          order: maxOrder + 1,
          loaded: false,
          name: fileName
        }

        filesToLoad.push(singleFile)

        this.setState({ lot, filesToLoad: filesToLoad })
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooLarge'), 'error')
    }
  }

  async uploadImage ({ target }, trackIndex) {
    const lot = this.state.lot
    const traceability = lot.traceability
    try {
      for (const file of target.files) {
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(file)
        })

        traceability[trackIndex].media.push({
          // url: fileContent,
          original: fileContent,
          cropped: fileContent,
          croppingRect: {
            x: 1,
            y: 1,
            width: 1,
            height: 1
          }
          // order: traceability[trackIndex].media.length
        })
      }

      this.setState({ lot })
    } catch (e) {
      console.error('Error while reading file: ', e)
      this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      // alert('Errore durante la lettura del file')
    }
  }

  valueChanged ({ target }, trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex][target.name] = target.value
    this.setState({ lot })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  downloadDocument (name, url) {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  async verifyInputComplete (lot) {
    const traceability = lot.traceability
    for (const [trackIndex, track] of Object.entries(traceability)) {
      for (const [index, input] of Object.entries(track.inputs)) {
        await this.verifyInput(input, trackIndex, index, 'all')
      }
      /* sugli output non dovrebbe servire */
      // if (Array.isArray(track.outputs) && track.outputs.length > 0) {
      //   for (const [index, output] of Object.entries(track.outputs)) {
      //     await this.verifyInput(output, trackIndex, index, 'output')
      //   }
      // }
    }
    console.log('this.state.verify lot::::', this.state.verifyLot)
  }

  // isThisVerified (verifyInput) {
  //   const phases = verifyInput.keys
  //   phases.forEach((index) => {
  //     const inputs = verifyInput[index].input.keys
  //     console.log('inputs:::', inputs)
  //   })
  // }

  isThisVerified (verifyInput) {
    const phases = Object.keys(verifyInput)
    for (const phase of phases) {
      const verified = verifyInput[phase].every(inputx =>
        inputx.verified === true)
      if (!verified) { return false }
    }
    return true
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode () {
    // creo link fittizio
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre la modal dell'impossibilità di lasciare campi vuoti
  toogleAlreadyCompletedDialog () {
    const isAlreadyCompletedDialogOpen = this.state.isAlreadyCompletedDialogOpen
    this.setState({ isAlreadyCompletedDialogOpen: !isAlreadyCompletedDialogOpen })
    // console.log('isAlreadyCompletedDialogOpen => ', isAlreadyCompletedDialogOpen)
    // console.log('this.state.isAlreadyCompletedDialogOpen => ', this.state.isAlreadyCompletedDialogOpen)
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <React.Fragment>
        {/* <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.closeModal}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.state.open}>
          <div className={classes.content}> */}

        {/* dialog conferma eliminazione */}
        <Dialog className='lotModal' open={this.state.isConfirmDialogOpened} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.confirmDelete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('dialog.confirmDelete.description')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.closeConfirmDialog} color="secondary" autoFocus>
              {this.props.t('dialog.confirmDelete.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteLot} color="primary">
              {this.state.isDeleting
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader> : this.props.t('dialog.confirmDelete.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog confirm saved changes */}
        <Dialog className='lotModal' open={this.state.isSaveDialogOpen} aria-labelledby="Comfirm save">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.confirmClose.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color="primary" autoFocus>
              {this.props.t('dialog.confirmClose.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color="primary">
              {this.props.t('dialog.confirmClose.buttons.closeWithoutSave')}
            </Button>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.confirmSave} color="secondary" disabled={!!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader> : this.props.t('dialog.confirmClose.buttons.closeAndSave')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog warning can't save a complete lot as draft */}
        <Dialog className='lotModal' open={this.state.isAlreadyCompletedDialogOpen} aria-labelledby="Impossibile procedere" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.cannotProceed.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.cannotProceed.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.toogleAlreadyCompletedDialog} color="primary">
              {this.props.t('dialog.cannotProceed.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='lotModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          PaperProps={{ square: true }}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <DialogTitle classes={{ root: classes.titleMobile }} disableTypography={true} className={classes.navBar}>
              <Typography style={{ width: '95%' }} noWrap={true} variant="h6" component="h2">
                {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              </Typography>
              <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            : <DialogTitle disableTypography={true} className={classes.navBar}>
              <Typography noWrap={true} variant="h6" component="h2">
                {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              </Typography>
              <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.closeButton}>
                {this.state.isModifyEnabled && <Tooltip title="Disabilita modifiche"><IconButton color="inherit" aria-label="allow-modify" onClick={() => this.enableOrDisableModify()}>
                  <LockOpen />
                </IconButton></Tooltip>}
                {!this.state.isModifyEnabled && <Tooltip title="Modifica dati"><IconButton color="inherit" aria-label="disallow-modify" onClick={() => this.enableOrDisableModify()}>
                  <Lock />
                </IconButton></Tooltip>}
                <Tooltip title="Elimina lotto">
                  <IconButton onClick={() => this.openConfirmDialog()} aria-label="delete product">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Chiudi / Salva modifiche">
                  <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal} style={{ marginRight: 0 }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </DialogTitle>
          }
          <Paper elevation={4} square>
            <Tabs classes={{ indicator: classes.tabIndicator }} variant="scrollable" value={this.state.tabNumber} onChange={this.changeTab} indicatorColor="primary">
              <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
              <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.timestamps.title')} />
              <Tab classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.traceability.title')} (${this.state.lot && this.state.lot.traceability ? this.state.lot.traceability.length : 0})`} />
            </Tabs>
            {this.state.tabNumber === 2 ? <Paper elevation={0} position="static" square>
              <Box style={{ width: '100%' }} display="flex">
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.actionButtonContainer}>
                  <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                </Box>
                <Box id="chipContainer" className={classes.chipContainer}>
                  {this.state.lot.traceability.map((track, trackIndex) => <Chip
                    classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }}
                    key={`subtab-${trackIndex}`}
                    color="primary"
                    label={track.name ? `#${trackIndex + 1} ${track.name}` : this.props.t('generalPhase')}
                    onClick={() => this.changePhaseTab(trackIndex)} />)}
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.actionButtonContainer}>
                  <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                </Box>
              </Box>
            </Paper> : ''}
          </Paper>
          <DialogContent classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }}>
            {/* GENERALE */}
            <div component="div" hidden={this.state.tabNumber !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tabpanel-0" className={`${classes.tabContainer} tabContent`}>
              <Grid style={{ height: '100%' }} container direction="row" alignItems="center" justify="center" spacing={2}>
                <Grid item md={6} sm={10} xs={10}>
                  <Typography variant="body1" style={{ marginBottom: 20, marginTop: !isSmall ? '-16px' : '' }}>
                    {this.props.t('tabs.general.description')}.
                  </Typography>
                  {/* <Typography variant="overline"><b>{this.props.t('tabs.general.inputs.expirationDate')}</b></Typography> */}
                  <Box display="flex" alignItems="center">
                    {/* <Tooltip title={this.state.lot.lot_information.showDeadline ? 'Mostra scadenza' : 'Scadenza nascosta' }>
                      <Switch
                        checked={this.state.lot.lot_information.showDeadline}
                        onChange={this.changeShowDeadline}
                        value={this.state.lot.lot_information.showDeadline}
                        color="primary"
                        inputProps={{ 'aria-label': 'Mostra/Nascondi data scadenza' }}
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Tooltip> */}
                    <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
                      <KeyboardDatePicker
                        style={{ marginTop: '2px !important', marginLeft: 16 }}
                        className={classes.w100}
                        margin="normal"
                        id="deadlineTimestamp"
                        label={this.props.t('tabs.general.inputs.expirationDate')}
                        format="dd/MM/yyyy"
                        cancelLabel={this.props.t('tabs.general.inputs.dateCancel')}
                        value={this.state.lot.expirationDate ? this.state.lot.expirationDate : null}
                        onChange={(date) => this.deadlineTimestampChanged(date)}
                        KeyboardButtonProps={{
                          'aria-label': this.props.t('tabs.general.inputs.expirationDate')
                        }}
                        disabled={!this.state.isModifyEnabled}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                  <Typography variant="body2" style={{ paddingLeft: 16 }}>{this.props.t('tabs.general.inputs.expirationDateInfo')}</Typography>
                </Grid>
                {/* viene mostrato solo quando il lotto è draft */}
                {this.props.lot.status !== 'active' &&
                <Grid item md={2} sm={2} xs={2}>
                  <InputLabel id="demo-simple-select-label">Stato</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.lotStatus}
                    onChange={this.lotStatusChange}
                    disabled={!this.state.isCompleted || this.props.lot.status === 'active'}
                  >
                    <MenuItem value={'draft'}>{this.props.t('tabs.general.inputs.draft')}</MenuItem>
                    <MenuItem value={'active'}>{this.props.t('tabs.general.inputs.active')}</MenuItem>
                  </Select>
                </Grid>
                }
                <Grid item md={4} sm={12} xs={12}>
                  <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                      <QRCode size={180} value={`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(this.state.lot.lotNumber)}`} />
                      <QRCode value={`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(getLastLotnumber(this.state.lot))}`} size={512} style={{ display: 'none' }} id="hiddenCanvas" />
                    </Grid>
                    <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                      <Grid container direction="row" alignItems="center" justify="flex-start">
                        <Grid item xs={12}>
                          <Tooltip title="Apri pagina pubblica">
                            <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.openQrcodeUrl(`${this.props.frontEndUrl}/01/${this.state.product.gtin}/10/${encodeURIComponent(this.state.lot.lotNumber)}`)} color="primary" size="small" style={{ margin: '10px 5px' }}>
                              <ExitToAppIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Scarica QR code">
                            <Fab
                              classes={{ primary: classes.fabPrimary }}
                              onClick={() => this.downloadQrcode()}
                              color="primary"
                              size="small"
                              style={{ margin: '10px 5px' }}>
                              <GetAppIcon />
                            </Fab>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/* OPENTIMESTAMPS */}
            <div className={`${classes.tabContainer} tabContent`} component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tabpanel-1">
              <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ marginBottom: 2 }}>
                    {this.props.t('tabs.timestamps.description')}.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{this.props.t('tabs.timestamps.table.status')}</TableCell>
                          <TableCell align="center">{this.props.t('tabs.timestamps.table.txHash')}</TableCell>
                          <TableCell align="center">{this.props.t('tabs.timestamps.table.date')}</TableCell>
                          <TableCell align="right">{this.props.t('tabs.timestamps.table.actions')}</TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0
                        ? <TableBody>
                          {this.state.lot.opentimestamps.map((timestamp, index) => (
                            <TableRow key={index}>
                              <TableCell scope="row">
                                {timestamp.verified
                                  ? <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <CheckIcon style={{ marginRight: '8px', color: green[700] }} />
                                    <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.verified')}</Typography>
                                  </Box>
                                  : timestamp.filename === '' ? <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                                    <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.waitingCreation')}</Typography>
                                  </Box> : <Box component="div" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                                    <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.waitingValidation')}</Typography>
                                  </Box>
                                }
                              </TableCell>
                              <TableCell align="center">
                                {timestamp.transactionId && timestamp.transactionId !== ''
                                  ? <>
                                    <Link href ={`${configuration.blockchainExplorer}/${this.state.language}/btc/tx/${timestamp.transactionId}`} target="_blank" color="inherit">
                                      {timestamp.transactionId
                                        .slice(0, 3)}...{timestamp.transactionId
                                        .slice(61, 64)}`
                                    </Link>
                                  </>
                                  : `${this.props.t('tabs.timestamps.table.txHashNotPresent')}`
                                }
                              </TableCell>
                              <TableCell align="center">
                                {timestamp.timestamp && timestamp.timestamp !== ''
                                  ? moment(timestamp.timestamp).format('DD/MM/YYYY')
                                  : moment(this.state.lot.updatedAt).format('DD/MM/YYYY')
                                }
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title={this.props.t('tabs.timestamps.table.pdfTooltip')}>
                                  <span>
                                    <Fab classes={{ primary: classes.fabPrimary }} disabled={!(this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].filename)} onClick={() => this.openMedia(this.state.lot.opentimestamps[index].filename) } color="primary" size="small" style={{ margin: '10px 5px' }}>
                                      <DownLoadIcon />
                                    </Fab>
                                  </span>
                                </Tooltip>
                                <Tooltip title={this.props.t('tabs.timestamps.table.otsTooltip')}>
                                  <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].ots ? this.downloadDocument('ots_file', this.state.lot.opentimestamps[index].ots) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                    <GetAppIcon />
                                  </Fab>
                                </Tooltip>
                                <Tooltip title={this.props.t('tabs.timestamps.table.infoTooltip')}>
                                  <Fab
                                    classes={{ primary: classes.fabPrimary }}
                                    onClick={() => this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].ots && this.state.lot.lotNumber ? this.openQrcodeUrl(`/opentimestamps/?filename=${this.state.lot.opentimestamps[index].ots}&lotNum=${this.state.lot.lotNumber}`) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                    <ExitToAppIcon />
                                  </Fab>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        : <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" colSpan={3}>
                              {this.props.t('tabs.timestamps.table.noData')}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>

            {/* FASI */}
            {/* Step da fare:
                1. Ciclo sulle fasi del lotto
                2. In base all'event type della fase, chiamo la particolare FASE
                3. Alla fase passo:
                    - le variabili di stato che gestisce prepare supplychain
                    - la fase incriminata
                    -
                4. Do la possibilità di creare una nuova fase sul lotto;
                5. Mock fase vuota
            */}
            <div component="div"
              hidden={this.state.tabNumber !== 2}
              id="simple-tabpanel-2"
              aria-labelledby="simple-tabpanel-2"
              className={`${classes.tabContainer} tabContent`}
            >
              {this.state.lot.traceability.map((track, trackIndex) => {
                if (track.eventType === 'commission' || track.eventType === 'observation') {
                  return <CommissionStep
                    track={track}
                    classes={classes}
                    phaseTabNumber={this.state.phaseTabNumber}
                    trackIndex={trackIndex}
                    handleVisibility={this.handleVisibility}
                    uploadDocument={this.uploadDocument}
                    openMedia={this.openMedia}
                    deleteDocument={this.deleteDocument}
                    startTimestampChanged={this.startTimestampChanged}
                    timestampChanged={this.timestampChanged}
                    valueChanged={this.valueChanged}
                    locationChanged={this.locationChanged}
                    inputChanged={this.inputChanged}
                    isModifyEnabled={this.state.isModifyEnabled}
                    isShowPhase={this.state.isShowPhase}
                    facilities={this.state.facilities}
                    product={this.state.product}
                    products={this.state.products}
                    addInput={this.addInput}
                    deleteInput={this.deleteInput}
                    openUrl={this.openUrl}
                    frontEndUrl={this.props.frontEndUrl}
                    verifyInput={this.verifyInput}
                  ></CommissionStep>
                } else if (track.eventType === 'transformation') {
                  return <TransformationStep
                    track={track}
                    classes={classes}
                    phaseTabNumber={this.state.phaseTabNumber}
                    trackIndex={trackIndex}
                    handleVisibility={this.handleVisibility}
                    uploadDocument={this.uploadDocument}
                    openMedia={this.openMedia}
                    deleteDocument={this.deleteDocument}
                    startTimestampChanged={this.startTimestampChanged}
                    timestampChanged={this.timestampChanged}
                    valueChanged={this.valueChanged}
                    locationChanged={this.locationChanged}
                    inputChanged={this.inputChanged}
                    outputChanged={this.outputChanged}
                    isModifyEnabled={this.state.isModifyEnabled}
                    isShowPhase={this.state.isShowPhase}
                    facilities={this.state.facilities}
                    product={this.state.product}
                    products={this.state.products}
                    addInput={this.addInput}
                    deleteInput={this.deleteInput}
                    addOutput={this.addOutput}
                    deleteOutput={this.deleteOutput}
                    openUrl={this.openUrl}
                    frontEndUrl={this.props.frontEndUrl}
                    verifyInput={this.verifyInput}
                  ></TransformationStep>
                } else if (track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') {
                  return <DeliverStep
                    track={track}
                    classes={classes}
                    phaseTabNumber={this.state.phaseTabNumber}
                    trackIndex={trackIndex}
                    handleVisibility={this.handleVisibility}
                    uploadDocument={this.uploadDocument}
                    openMedia={this.openMedia}
                    deleteDocument={this.deleteDocument}
                    startTimestampChanged={this.startTimestampChanged}
                    timestampChanged={this.timestampChanged}
                    valueChanged={this.valueChanged}
                    locationFromChanged={this.locationFromChanged}
                    locationToChanged={this.locationToChanged}
                    inputChanged={this.inputChanged}
                    isModifyEnabled={this.state.isModifyEnabled}
                    isShowPhase={this.state.isShowPhase}
                    facilities={this.state.facilities}
                    product={this.state.product}
                    products={this.state.products}
                    addInput={this.addInput}
                    deleteInput={this.deleteInput}
                    openUrl={this.openUrl}
                    frontEndUrl={this.props.frontEndUrl}
                    verifyInput={this.verifyInput}
                    addFacilityFrom={this.addFacilityFrom}
                    addFacilityTo={this.addFacilityTo}
                    deleteFacilityFrom={this.deleteFacilityFrom}
                    deleteFacilityTo = {this.deleteFacilityTo}
                  ></DeliverStep>
                }
              }
              )}
            </div>
          </DialogContent>
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <DialogActions classes={{ root: classes.mobileActions }}>
              <Button startIcon={<DeleteIcon />} onClick={() => this.openConfirmDialog()} color="white" aria-label="delete lot">
                {this.props.t('buttons.delete')}
              </Button>
              {this.state.isModifyEnabled && <Button startIcon={<LockOpen />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="allow-modify" title={this.props.t('buttons.lockEdits')} onClick={() => this.enableOrDisableModify()}>
                {this.props.t('buttons.close')}
              </Button>}
              {!this.state.isModifyEnabled && <Button startIcon={<Lock />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="disallow-modify" title={this.props.t('buttons.unlockEdits')} onClick={() => this.enableOrDisableModify()}>
                {this.props.t('buttons.edit')}
              </Button>}
            </DialogActions>
            : ''}
        </Dialog>
      </React.Fragment>
    )
  }
}

NewCardModalLotSupplychain.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  lotNumber: PropTypes.string,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func,
  productsIdNameMap: PropTypes.object,
  product: PropTypes.object,
  allSupplychainIdNameMap: PropTypes.object
}
export default withRouter(withStyles(styles)(withWidth()(withTranslation('lotDetails')(NewCardModalLotSupplychain))))
