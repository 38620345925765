import { DialogContentText, DialogActions, Button, withStyles, withWidth, TextField, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { createSupplychain } from '../../utils/supplychains'

import React from 'react'
import PropTypes from 'prop-types'

const styles = theme => ({
  '@global': {
    '.MuiDialog-root': {
      minWidth: '80%',
      minHeight: 300
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    overflow: 'scroll'
  },
  modalSize: {
    width: 300,
    height: 300,
    backgroundColor: 'red'
  },
  progressStyle: {
    color: theme.primary
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 'auto',
    outline: 0
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  navBar: {
    color: 'white'
  },
  tableContainer: {
    marginTop: 16
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  paperDialog: {
    maxHeight: '70vh'
  },
  imgInput: {
    display: 'none'
  },
  imgCover: {
    // width: 'auto',
    // height: '250px',
    margin: '10px',
    width: 400,
    height: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  modifyImgButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  textfieldPrimary: {
    color: theme.primary
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  dialogPaper: {
    minHeight: 200,
    maxHeight: 500
  },
  paperRootMiddleSize: {
    maxHeight: '700px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})
class FormDialogSupplychain extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open,
      supplychain: {}
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.saveSupplychain = this.saveSupplychain.bind(this)
    this.handleCloseWithoutSave = this.handleCloseWithoutSave.bind(this)
  }

  handleClose (supplychain) {
    this.props.setSnackbar(this.props.t('manage.createModal.createStatusCode.success'), 'success')
    this.props.onClose(supplychain)
  }

   handleCloseWithoutSave = () => {
     this.props.onCloseWithoutSave()
   }

   handleChange (event) {
     this.setState({
       supplychain: {
         ...this.state.supplychain,
         name: event.target.value
       }
     }, () => {
       console.log('this.state.supplychain__', this.state.supplychain)
     }
     )
   }

   saveSupplychain = async () => {
     const supplychain = JSON.parse(JSON.stringify(this.state.supplychain))
     console.log('this.state.supplychain__arrivo puro', supplychain)
     const response = await createSupplychain(this.state.supplychain)
     if (response.status === 400) {
       this.props.setSnackbar(this.props.t('manage.createModal.createStatusCode.badRequest'), 'error')
       return
     }
     supplychain.uuid = response.data.data.uuid
     this.setState({
       supplychain
     }, () => {
       console.log('this.state.supplychain__', this.state.supplychain)
       this.handleClose(this.state.supplychain)
     })
   }

   render () {
     const classes = this.props.classes
     return (
       <>
         <Dialog
           className={'createModalSupplychain'}
           classes={{ paper: classes.dialogPaper }}
           aria-labelledby="simple-dialog-title"
           onClose={this.handleCloseWithoutSave}
           open={this.state.open}
           fullWidth={true}
           maxWidth={'sm'}
         >
           <DialogTitle id="simple-dialog-title">{this.props.t('manage.createModal.title')}</DialogTitle>
           <DialogContent>
             <DialogContentText>
               {this.props.t('manage.createModal.description')}
             </DialogContentText>
             <TextField
               autoFocus
               margin="dense"
               id="name"
               label={this.props.t('manage.createModal.inputName')}
               type="email"
               fullWidth
               onChange={this.handleChange}
             />
           </DialogContent>
           <DialogActions>
             <Button onClick={this.handleCloseWithoutSave} color="primary">
               {this.props.t('manage.createModal.cancel')}
             </Button>
             <Button onClick={this.saveSupplychain} color="primary">
               {this.props.t('manage.createModal.send')}
             </Button>
           </DialogActions>
         </Dialog>
       </>
     )
   }
}

FormDialogSupplychain.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('supplychain')(FormDialogSupplychain))))
