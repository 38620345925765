import React from 'react'
import PropTypes from 'prop-types'
import logoImg from '../../assets/images/loghi/trusty-logo-black.svg'

function Loader (props) {
  const { show, text } = props
  return (
    <div className='loaderContainer' style={{ display: show ? 'flex' : 'none' }}>
      <img src={logoImg} alt='' />
      <h3>{ text }</h3>
    </div>
  )
}

Loader.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string
}

export default Loader
