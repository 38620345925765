import React from 'react'
// import Loader from '../Loader'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  withStyles,
  Box,
  Snackbar,
  SnackbarContent,
  withWidth,
  Tooltip,
  Fab
} from '@material-ui/core'

import Pagination from 'material-ui-flat-pagination'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import { returnAllFacilities } from '../../utils/facilities'
import { returnAllCompanies } from '../../utils/companies'
import FormDialogFacility from './FormDialogFacility'
import CardModalFacilities from '../Modal/CardModalFacilities'

import {
  Search as SearchIcon,
  AddCircle as AddCircleIcon,
  Cancel
} from '@material-ui/icons'

import CreateIcon from '@material-ui/icons/Create'
import ErrorIcon from '@material-ui/icons/Error'
import SuccessIcon from '@material-ui/icons/CheckCircle'

import { green, amber } from '@material-ui/core/colors'

const doesFacilityMatchCriteria = (facility, searchCriteria) => Object.keys(facility).some(key => {
  if (typeof facility[key] === 'object') {
    return doesFacilityMatchCriteria(facility[key], searchCriteria)
  }

  const value = String(facility[key])
  return value.toLowerCase().includes(searchCriteria.toLowerCase())
})

const styles = theme => ({
  '@global': {
    '.MuiInputBase-input': {
      padding: '6px 0 4px'
    },
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  progressStyle: {
    color: theme.primary
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: '#FFFFFF',
    width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  selectContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 12
    }
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

class Facilities extends React.Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.user = ls.get('user') || {}
    // if (!props.guidedLoaded) { window.userGuiding.hideChecklist() }
    this.state = {
      // loadedGuide: false,
      areFacilitiesFetching: true,
      companies: [],
      companyUuid: '',
      facilities: [],
      facility: {},
      isFacilityModalOpened: false,
      isConfirmDialogOpened: false,
      isDeleting: false,
      isFormDialogOpened: false,
      lastSearchCriteria: '',
      limit: 10,
      offset: 0,
      originalFacilities: [],
      owner: this.user.uuid,
      page: 0,
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: ''
    }

    this.changePage = this.changePage.bind(this)
    this.closeFacilityModal = this.closeFacilityModal.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeFormDialog = this.closeFormDialog.bind(this)
    this.filterFacilitiesViaCompanyUuid = this.filterFacilitiesViaCompanyUuid.bind(this)
    this.filterFacilitiesViaText = this.filterFacilitiesViaText.bind(this)
    this.openFacilityModal = this.openFacilityModal.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.openFormDialog = this.openFormDialog.bind(this)
    this.refreshFacilities = this.refreshFacilities.bind(this)
    this.searchCriteriaChanged = this.searchCriteriaChanged.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.closeDialogWithoutRefresh = this.closeDialogWithoutRefresh.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    this.refreshFacilities()

    // const history = useHistory()
    // const userData = ls.get('user')

    /* per bloccare le cose relative alla guida se siamo in WL */
    // if (this.props.guidedLoaded) {
    //   const response = await axios({
    //     method: 'GET',
    //     url: `${configuration.apiBaseUrl}/accounts/self/onboardings/last`,
    //     headers: {
    //       authorization: `Bearer ${ls.get('token')}`
    //     }
    //   })

    //   const lastOnboarding = response.data.data
    //   console.log('Last on boarding::::', lastOnboarding)
    //   // Quando la guida non esiste e gli step non sono stati completati
    //   if (!lastOnboarding || (!lastOnboarding.completed && !lastOnboarding.guides[onboardingConfig.facilities.id])) {
    //     let dataToSend = lastOnboarding
    //     if (!dataToSend) {
    //       dataToSend = {
    //         accountId: userData.uuid,
    //         guides: {
    //           [onboardingConfig.facilities.id]: {
    //             steps: []
    //           }
    //         },
    //         completed: false
    //       }
    //     }

    //     // window.userGuidingLayer.push({
    //     //   event: 'onload',
    //     //   fn: () => { this.setState({ loadedGuide: true }) }
    //     // })

    //     // window.userGuidingLayer.push({
    //     //   event: 'onPreviewStart',
    //     //   fn: () => { this.setState({ loadedGuide: true }) }
    //     // })
    //     window.userGuiding.previewGuide(onboardingConfig.facilities.id)

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewStep',
    //       fn: async data => {
    //         const d = new Date()
    //         if (!Object.prototype.hasOwnProperty.call(dataToSend.guides, onboardingConfig.facilities.id)) {
    //           dataToSend.guides[onboardingConfig.facilities.id] = { steps: [] }
    //         }

    //         if (typeof dataToSend.guides[onboardingConfig.facilities.id].steps[data.stepIndex] !== 'object') {
    //           dataToSend.guides[onboardingConfig.facilities.id].steps[data.stepIndex] = {}
    //         }

    //         dataToSend.guides[onboardingConfig.facilities.id].steps[data.stepIndex].startedAt = d
    //         if (data.stepIndex > 0) {
    //           dataToSend.guides[onboardingConfig.facilities.id].steps[data.stepIndex - 1].completedAt = d
    //         }

    //         if (lastOnboarding) {
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${lastOnboarding.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         } else {
    //           const response = await axios({
    //             method: 'POST',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })

    //           dataToSend = response.data.data
    //         }
    //       }
    //     })

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewEnd',
    //       fn: async data => {
    //         if (data.completed) {
    //           const { steps } = dataToSend.guides[onboardingConfig.facilities.id]
    //           steps[steps.length - 1].completedAt = new Date()
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${dataToSend.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         }
    //       }
    //     })
    //   } else {
    //   // this.setState({ loadedGuide: true })
    //   }
    //   // }

    // // // Quando la guida è completa
    // // if (lastOnboarding.guides[onboardingConfig.facilities.id] && lastOnboarding.guides[onboardingConfig.facilities.id].steps.length === onboardingConfig.facilities.steps) {
    // //   history.push('/products')
    // // }
    // }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  changePage (offset, page) {
    this.setState({ offset, page: page - 1 })
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  async closeFacilityModal () {
    this.setState({ isFacilityModalOpened: false })
    await this.refreshFacilities()
    this.filterFacilitiesViaText(this.state.lastSearchCriteria)
    this.filterFacilitiesViaCompanyUuid(this.state.companyUuid)
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false, isDeleting: false })
  }

  async closeFormDialog () {
    this.setState({ isFormDialogOpened: false })
    await this.refreshFacilities()
    this.filterFacilitiesViaText(this.state.lastSearchCriteria)
    this.filterFacilitiesViaCompanyUuid(this.state.companyUuid)
  }

  filterFacilitiesViaCompanyUuid (filterCriteria) {
    let facilities = this.state.originalFacilities
    if (filterCriteria !== '') {
      facilities = facilities.filter(facility => facility.companyData.uuid === filterCriteria)
    }

    if (this.state.lastSearchCriteria !== '') {
      facilities = facilities.filter(facility => doesFacilityMatchCriteria(facility, this.state.lastSearchCriteria))
    }

    this.setState({ companyUuid: filterCriteria, facilities, offset: 0, page: 0 })
  }

  filterFacilitiesViaText (searchCriteria) {
    let facilities = this.state.originalFacilities
    if (searchCriteria !== '') {
      facilities = facilities.filter(facility => doesFacilityMatchCriteria(facility, searchCriteria))
    }

    if (this.state.companyUuid !== '') {
      facilities = facilities.filter(facility => facility.companyData.uuid === this.state.companyUuid)
    }

    this.setState({ facilities, offset: 0, page: 0 })
  }

  async refreshFacilities () {
    try {
      const root = document.getElementById('root')
      root.className = ''
      root.classList.add('facilities')

      const companiesResponse = await returnAllCompanies()
      const companies = companiesResponse.data.data
      // const companiesIds = companies.map(company => company.companyId)

      const facilitiesResponse = await returnAllFacilities()
      const facilities = facilitiesResponse.data.data
      facilities.forEach(facility => {
        const company = companies.find(c => c.uuid === facility.companyId)
        facility.companyData = company
      })

      // console.log('facilities: ', facilities)

      if (this._isMounted) {
        console.log('refresh facilities => ', facilities)
        console.log('refresh companies => ', companies)
        this.setState({ areFacilitiesFetching: false, companies, facilities, originalFacilities: facilities })
      }
    } catch (e) {
      console.error('Error while refreshing facilities, e => ', e)
      this.setSnackbar(this.props.t('notifications.facilityError'), 'error')
    }
  }

  searchCriteriaChanged ({ target }) {
    this.setState({ lastSearchCriteria: target.value })
    this.filterFacilitiesViaText(target.value)
  }

  openFacilityModal (facility) {
    this.setState({ facility, isFacilityModalOpened: true })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  openFormDialog () {
    this.setState({ isFormDialogOpened: true })
  }

  closeDialogWithoutRefresh () {
    this.setState({ isFormDialogOpened: false })
  }

  valueChanged ({ target }) {
    this.filterFacilitiesViaCompanyUuid(target.value)
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <div>
        {/* <Loader
          show={!this.state.loadedGuide}
          // text={this.props.t('guide.loading')}
        >
        </Loader> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={8000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
            aria-describedby={this.state.snackbarType === 'success' ? 'success-snackbar' : 'error-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'success' ? <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} /> : <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> }
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }
          />
        </Snackbar>
        {this.state.isFormDialogOpened && <FormDialogFacility
          setSnackbar={this.setSnackbar}
          open={this.state.isFormDialogOpened}
          onCloseDialog={this.closeFormDialog}
          onCloseDialogWithoutRefresh={this.closeDialogWithoutRefresh} />
        }
        {this.state.isFacilityModalOpened && <CardModalFacilities
          setSnackbar={this.setSnackbar}
          facility={this.state.facility}
          open={this.state.isFacilityModalOpened}
          onCloseModal={this.closeFacilityModal} />
        }

        {/* search bar + buttons */}
        <Grid container direction="row" justify="space-between" alignItems="center" className={`${classes.root} filterRoot`}>
          {/*  tablet + mobile */}
          <Grid item xs={10} sm={10} md={11} lg={11}>
            <Box className={classes.paperContainer}>
              <Paper className={classes.paper}>
                <TextField
                  className={`${classes.input} searchField`}
                  placeholder={this.props.t('inputs.search')}
                  inputProps={{ 'aria-label': this.props.t('inputs.search') }}
                  onChange={this.searchCriteriaChanged}
                />
                <SearchIcon className={classes.searchIcon} />
              </Paper>
            </Box>
          </Grid>

          <Grid item xs={2} sm={2} md={1} lg={1} style={{ textAlign: 'right' }}>
            <Tooltip title={this.props.t('tooltip.buttons.addCompany')}>
              <Fab
                size="medium"
                aria-label={this.props.t('tooltip.buttons.addCompany')}
                variant="round"
                className={classes.addButton}
                onClick={this.openFormDialog}
              >
                <AddCircleIcon />
              </Fab>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <Box className={classes.selectContainer}>
              <InputLabel shrink htmlFor="companyId">
                { this.props.t('inputs.filterFacilities') }
              </InputLabel>
              <Select
                id="companyId"
                name="companyId"
                value={this.state.companyUuid}
                onChange={this.valueChanged}
                displayEmpty
                fullWidth
              >
                <MenuItem key="all" value="">
                  <span style={{ color: '#AEAEAE' }}>{ this.props.t('inputs.allFacilities') }</span>
                </MenuItem>
                {this.state.companies
                  .map(company => (
                    <MenuItem key={company.uuid} value={company.uuid}>
                      {company.name}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </Grid>
          {/* {!isSmall &&
            <Grid item xs={1} md={1} lg={1} style={{ textAlign: 'right' }}>
              <Tooltip title={this.props.t('tooltip.buttons.addFacilities')}>
                <Fab
                  size="medium"
                  aria-label={this.props.t('tooltip.buttons.addFacilities')}
                  variant="round"
                  className={classes.addButton}
                  onClick={this.openFormDialog}
                >
                  <AddCircleIcon />
                </Fab>
              </Tooltip>
            </Grid>
          } */}
        </Grid>

        {/* Lista facilities */}
        <Grid container direction="column">
          <Grid item xs>
            {this.state.facilities.length > 10 && <Pagination
              limit={this.state.limit}
              offset={this.state.offset}
              total={this.state.facilities.length}
              onClick={(e, offset, page) => this.changePage(offset, page)}
              style={{ textAlign: 'center', paddingBottom: 2 }}
            />}
          </Grid>
          <Grid item xs className={classes.cardListContainer}>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.grid} spacing={3}>
              {this.state.areFacilitiesFetching
                ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={ this.props.t('facilitiesList.downloading') }
                    >
                    </LogoLoader>
                  </Grid>
                </Grid>
                : this.state.facilities
                  .slice(this.state.page * this.state.limit, this.state.page * this.state.limit + this.state.limit)
                  .map(facility => (
                    facility.uuid.indexOf('supplychain') !== 0 &&
                    <Grid key={facility.uuid} item xs={12} sm={6} md={3} lg={3} xl={3} className={classes.cardContainer}>
                      <Card className={classes.card} onClick={() => this.openFacilityModal(facility)}>
                        <div className='innerCard' style={isSmall ? {} : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Tooltip title={this.props.t('facilitiesList.tooltip.buttons.edit')}>
                            <CreateIcon className='editIcon' onClick={() => this.openFacilityModal(facility)}></CreateIcon>
                          </Tooltip>
                          {/* <div className='borderFrame'>
                            <CardActionArea>
                              <CardHeader
                                className={classes.cardHeaderContainer}
                                avatar={
                                  <Avatar className={classes.cardAvatar} aria-label="product">F</Avatar>
                                }
                              />
                            </CardActionArea>
                          </div> */}
                          <div className='bottomCard'>
                            <CardActionArea>
                              <CardHeader
                                title={facility.name}
                                titleTypographyProps={{
                                  component: 'h5',
                                  variant: 'h5',
                                  noWrap: true
                                }}
                                subheader={this.props.t('facilitiesList.company') + ': ' + facility.companyData.name}
                                subheaderTypographyProps={{ noWrap: true }}
                              />
                            </CardActionArea>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item xs>
            {this.state.areFacilitiesFetching === false && <Pagination
              limit={this.state.limit}
              offset={this.state.offset}
              total={this.state.facilities.length}
              onClick={(e, offset, page) => this.changePage(offset, page)}
              style={{ textAlign: 'center', paddingTop: 8 }}
            />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Facilities.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('facilities')(Facilities))))
