/* Componente Padre che forma il blocco productId, LotNumber, Quantity,Uom */
import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import ProductComponent from '../IOComponents/ProductComponent'
import UomComponent from '../IOComponents/UomComponent'
import LotNumberComponent from '../IOComponents/LotNumberComponent'
import QuantityComponent from '../IOComponents/QuantityComponent'
import DeleteComponent from '../AdditionalBlocks/DeleteComponent'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function OutputComponent (props) {
  // const classes = useStyles()
  const {
    outputIndex,
    trackIndex,
    output,
    isModifyEnabled,
    product,
    products,
    outputChanged,
    deleteOutput
  } = props

  return (
    <div key={`output-${outputIndex}`} className="inputCard">
      <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
        <ProductComponent
          trackIndex={trackIndex}
          componentIndex={outputIndex}
          component={output}
          isModifyEnabled={isModifyEnabled}
          componentChanged={outputChanged}
          product={product}
          products={products}
          type={'output'}
        ></ProductComponent>
        <UomComponent
          trackIndex={trackIndex}
          componentIndex={outputIndex}
          component={output}
          isModifyEnabled={isModifyEnabled}
          componentChanged={outputChanged}
          type={'output'}
        >
        </UomComponent>
        <LotNumberComponent
          trackIndex={trackIndex}
          componentIndex={outputIndex}
          component={output}
          isModifyEnabled={isModifyEnabled}
          componentChanged={outputChanged}
          type={'output'}
        >
        </LotNumberComponent>
        <QuantityComponent
          trackIndex={trackIndex}
          componentIndex={outputIndex}
          component={output}
          isModifyEnabled={isModifyEnabled}
          componentChanged={outputChanged}
          type={'output'}
        >
        </QuantityComponent>
        <DeleteComponent
          trackIndex={trackIndex}
          componentIndex={outputIndex}
          deleteComponent={deleteOutput}
          isModifyEnabled={isModifyEnabled}
        ></DeleteComponent>
      </Grid>
    </div>
  )
}

OutputComponent.propTypes = {
  track: PropTypes.object.isRequired,
  outputIndex: PropTypes.number.isRequired,
  deleteOutput: PropTypes.func.isRequired,
  trackIndex: PropTypes.number.isRequired,
  output: PropTypes.object.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  outputChanged: PropTypes.func.isRequired
}
export default OutputComponent
