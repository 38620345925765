import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core'
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function ShowStepComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')
  const { isShowPhase, isModifyEnabled, handleVisibility, trackIndex } = props

  return (

    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleVisibility(trackIndex)} disabled={!isModifyEnabled}>
        {isShowPhase
          ? (<><Tooltip title={t('tabs.traceability.inputs.tooltipVisibility.hide')}><Visibility></Visibility></Tooltip></>)
          : (<><Tooltip title={t('tabs.traceability.inputs.tooltipVisibility.show')}><VisibilityOff></VisibilityOff></Tooltip></>)}
      </IconButton>
    </Grid>
  )
}

ShowStepComponent.propTypes = {
  isModifyEnabled: PropTypes.bool.isRequired,
  isShowPhase: PropTypes.bool.isRequired,
  trackIndex: PropTypes.number.isRequired,
  handleVisibility: PropTypes.func.isRequired
}
export default ShowStepComponent
