import React, { useEffect } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import WeightModal from './WeightModal'
import { isThisAccountHasPrintLabelFunctionality } from './../../utils/acl-organization'
function LightLotsTable ({
  selectedProductId,
  lightLotsMobileColumns,
  lightLotsColumns,
  lightLots,
  pageSize,
  pageSizeOptions,
  frontEndUrl,
  isSmall,
  openLotModal,
  openUrl,
  createLabel
}) {
  const { t } = useTranslation('lots')
  // title
  const [title, setTitle] = React.useState('')
  const [isWeightLotOpen, setIsWeightLotOpen] = React.useState(false)
  const [lotUuid, setLotUuid] = React.useState('')
  const [lotNumber, setLotNumber] = React.useState('')
  const [productName, setProductName] = React.useState('')
  const [productGtin, setProductGtin] = React.useState('')
  const hasPrintLabelFunctionality = isThisAccountHasPrintLabelFunctionality()

  useEffect(() => {
    if (lightLots.lenght === 0) {
      setTitle(t('lotTable.titleNoProduct'))
    } else {
      if (!selectedProductId) {
        setTitle(t('lotTable.titleLastAddedLots'))
      } else {
        setTitle(t('lotTable.title'))
      }
    }
  }, [])

  const openWeightModal = (lotUuid, data) => {
    setLotUuid(lotUuid)
    setLotNumber(data.lotNumber)
    setProductName(data.productName)
    setProductGtin(data.productGtin)
    setIsWeightLotOpen(true)
  }

  const closeWeightModal = () => {
    setIsWeightLotOpen(false)
  }
  return (
    <>

      {isWeightLotOpen ? (<WeightModal
        open={isWeightLotOpen}
        onClose= {closeWeightModal}
        createLabel={createLabel}
        lotUuid = {lotUuid}
        lotNumber = {lotNumber}
        productName = {productName}
        productGtin = {productGtin}
      />) : null
      }
      <MaterialTable
        title={title}
        localization={{
          toolbar: t('lotTable.localization.toolbar', { returnObjects: true }),
          body: {
            emptyDataSourceMessage: selectedProductId !== '' ? t('lotTable.localization.body.emptyDataSourceMessage') : t('lotTable.localization.body.emptyDataSourceMessage2')
          },
          pagination: t('lotTable.localization.pagination', { returnObjects: true }),
          header: t('lotTable.localization.header', { returnObjects: true })
        }}
        columns={isSmall ? lightLotsMobileColumns : lightLotsColumns}
        data={lightLots}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          pageSize: pageSize,
          pageSizeOptions: pageSizeOptions,
          showEmptyDataSourceMessage: true,
          rowStyle: rowData => ({
            backgroundColor: (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
          })
        }}

        onRowClick={(event, rowData) => {
          console.log('rowData =>', rowData)
          const currentLot = lightLots.find(lightLot => { return lightLot.uuid === rowData.uuid })
          openLotModal(currentLot, currentLot.lotNumber)
        }}
        actions={[
          hasPrintLabelFunctionality && {
            icon: 'print',
            tooltip: t('lotTable.createLabel'),
            onClick: (event, rowData) => {
              openWeightModal(rowData.uuid, { lotNumber: rowData.lotNumber, productName: rowData.productName, productGtin: rowData.lastInputOrOutputInformation.gtin })
            }
          },
          rowData => {
            return rowData.status === 'active'
              ? {
                icon: 'open_in_new',
                tooltip: t('lotTable.goToPage'),
                onClick: (event, rowData) => {
                // console.log('rowData => ', rowData)
                  openUrl(`${frontEndUrl}/gtin/${rowData.lastInputOrOutputInformation.gtin}/lot/${encodeURIComponent(rowData.lotNumber)}`)
                }
              }
              : {
                icon: 'open_in_new',
                hidden: true,
                onClick: (event, rowData) => {
                  console.log('DISABLED You Clicked => ', rowData)
                }
              }
          }]}
      />
    </>
  )
}

export default LightLotsTable
