import axios from 'axios'
import ls from 'local-storage'
import configuration from '../configuration'
import { currLang } from './translations.js'

export let currOrganization = ls.get('currOrganization') || null

/* funzione per tutte le risorse (facility, prodotto, lotto, company) tranne che per organization */
export async function createSupplychainMembership (organizationId, resourceType, resourceId, supplychainIds, verified = true) {
  /* nel caso in cui la risorsa non viene legata a nessuna filiera viene mandato l'array vuoto, così che l'api capisce che deve cancellare tutti i legami */
  const supplychainMembershipInstances = []
  for (const supplychainId of supplychainIds) {
    const supplychainMembershipInstance = {
      supplychainId: supplychainId,
      organizationId: organizationId,
      resourceType: resourceType,
      resourceId: resourceId,
      verified
    }
    supplychainMembershipInstances.push(supplychainMembershipInstance)
  }
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/smlinstances/resourceType/${resourceType}/resourceId/${resourceId}`, supplychainMembershipInstances, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export async function returnAllSupplychainForResource (resourceId) {
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      resourceId
    }
  })
  return response
}
export async function returnAllSupplychains (supplychainsId) {
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/supplychains`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ uuid: { $in: supplychainsId } })
    }
  })
  return response
}
export async function createSupplychain (supplychain) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  const data = {
    name: supplychain.name,
    organizationId: currOrganization.uuid
  }
  // console.log('dentro returnSelfACL')
  let response = {}
  try {
    response = await axios.post(`${configuration.apiBaseUrl}/supplychains`, data, {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    })
  } catch (e) {
    response = e.response
  }

  /* questa richiesta è stata commentata perché la membership viene creata direttamnete da API */
  // await createSupplychainMembershipOrganization(currOrganization.uuid, 'organization', currOrganization.uuid, response.data.data.uuid)

  return response
}
/* funzione per creare la membership per le organization */
export async function createSupplychainMembershipOrganization (organizationId, resourceType, resourceId, supplychainId) {
  const supplychainMembershipInstance = {
    supplychainId: supplychainId,
    organizationId: organizationId,
    resourceType: resourceType,
    resourceId: resourceId
  }

  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/smlinstances`, supplychainMembershipInstance, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export async function inviteSupplychain (email, supplychainId, vatId, domain) {
  const data = { email, supplychainId, vatId, locale: currLang, domain }

  let response = {}
  try {
    response = await axios.post(`${configuration.apiBaseUrl}/supplychains/invite`, data, {
      headers: {
        authorization: `Bearer ${ls.get('token')}`
      }
    })
  } catch (e) {
    response = e.response
  }
  return response
  // // mando la query
  // return axios.post(`${configuration.apiBaseUrl}/supplychains/invite`, data, {
  //   headers: {
  //     authorization: `Bearer ${ls.get('token')}`
  //   }
  // })
}

export async function acceptInvite (email, token, supplychainId, companyId, domain) {
  const data = { email, token, supplychainId, companyId, domain }

  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/supplychains/acceptInvite`, data, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

/* Funzione per la modifica dei metadati. NB: viene sostituito tutto l'oggetto metadata quindi riportarte qualsiasi cosa si vuole persistere */
/* il metadato con la proprietà hidden lo contiene solo l'istanza per l'organization */
export async function modifyMetadata (resourceId, supplychainId, metadata) {
  const data = {
    metadata
  }
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/smlinstances/resourceId/${resourceId}/supplychainId/${supplychainId}`, data, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export async function modifyResource (resourceId, supplychainId, update) {
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/smlinstances/resourceId/${resourceId}/supplychainId/${supplychainId}`, update, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export async function getMetadataForResource (resourceId, supplychainId) {
  // mando la query
  return axios.get(`${configuration.apiBaseUrl}/smlinstances/resourceId/${resourceId}/supplychainId/${supplychainId}`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
