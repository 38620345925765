// import countryCodes from '../components/Overview/CountryCodes.json'
// import configuration from '../../configuration'
import axios from 'axios'
import moment from 'moment'
// import { ItLocalizedUtils, locale } from '../utils/utils'

// VARIABLES
export const analyticsUrl = 'https://analytics.trusty.id/index.php'

// FUNCTIONS
// funzione che ritona i dati dell'analytics
export async function returnLastVisitsDetails (startDate, endDate) {
  // this.setState({ areDataFetching: true })
  // const { startDate, endDate } = this.state
  // formatto le date
  const formattedStartDate = moment(startDate.getTime()).format('YYYY-MM-DD')
  const formattedEndDate = moment(endDate.getTime()).format('YYYY-MM-DD')
  // prendo i dati live
  const response = await axios.get(analyticsUrl, {
    params: {
      date: `${formattedStartDate},${formattedEndDate}`,
      expanded: 1,
      filter_limit: -1,
      format: 'JSON',
      idSite: 2,
      method: 'Live.getLastVisitsDetails',
      module: 'API',
      period: 'day',
      token_auth: '744fab58915876d9d09adc8e6f411d9a'
    }
  })
  console.log('returnLastVisitsDetails response.data => ', response.data)
  return response.data
}
