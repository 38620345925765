import React from 'react'
// import Loader from '../Loader'
// import { withRouter, useHistory } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  TextField,
  Paper,
  Typography,
  withStyles,
  Box,
  Snackbar,
  SnackbarContent,
  Fab,
  Tooltip
} from '@material-ui/core'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import withWidth from '@material-ui/core/withWidth'

import Pagination from 'material-ui-flat-pagination'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'

// import configuration from '../../configuration'
import FormDialog from './FormDialog.jsx'
import CardModal from '../Modal/CardModal'

import { green, amber } from '@material-ui/core/colors'
import { returnAllCompanies } from '../../utils/companies'

import CreateIcon from '@material-ui/icons/Create'
import {
  Search as SearchIcon,
  AddCircle as AddCircleIcon,
  DraftsTwoTone as DraftsTwoToneIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  PhoneTwoTone as PhoneTwoToneIcon,
  LanguageTwoTone as LanguageTwoToneIcon,
  Cancel
} from '@material-ui/icons'

// variabile con gli stili css
const styles = theme => ({
  '@global': {
    '.MuiInputBase-input': {
      padding: '6px 0 4px'
    },
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  progressStyle: {
    color: theme.primary
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
    // width: '100%'
  },
  // Blue gradient
  /* addButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  }, */
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: 'white'
    // width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
    // width: 400
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  companyLogo: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

class Companies extends React.Component {
  constructor (props) {
    super(props)
    // console.log('props => ', props)
    // if (!props.guidedLoaded) { window.userGuiding.hideChecklist() }
    this._isMounted = false
    this.user = ls.get('user') || {}

    this.state = {
      // loadedGuide: false,
      areCompaniesFetching: true,
      companies: [],
      company: {},
      isFormDialogOpened: false,
      isCompanyModalOpened: false,
      isConfirmDialogOpened: false,
      isDeleting: false,
      lastSearchCriteria: '',
      limit: 20,
      offset: 0,
      owner: this.user.uuid,
      page: 0,
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: ''
    }

    // bindo il this alle funzioni
    this.changePage = this.changePage.bind(this)
    this.closeCompanyModal = this.closeCompanyModal.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeFormDialog = this.closeFormDialog.bind(this)
    // this.deleteAllCompanies = this.deleteAllCompanies.bind(this)
    this.filterCompaniesViaText = this.filterCompaniesViaText.bind(this)
    this.openCompanyModal = this.openCompanyModal.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.openFormDialog = this.openFormDialog.bind(this)
    this.refreshCompanies = this.refreshCompanies.bind(this)
    this.searchCriteriaChanged = this.searchCriteriaChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.closeFormDialogWithoutRefresh = this.closeFormDialogWithoutRefresh.bind(this)

    // this.isSmall = this.isSmall.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    await this.refreshCompanies()

    // const history = useHistory()
    // const userData = ls.get('user')
    // if (this.props.guidedLoaded) {
    //   const response = await axios({
    //     method: 'GET',
    //     url: `${configuration.apiBaseUrl}/accounts/self/onboardings/last`,
    //     headers: {
    //       authorization: `Bearer ${ls.get('token')}`
    //     }
    //   })

    //   const lastOnboarding = response.data.data

    //   // Quando la guida non esiste e gli step non sono stati completati
    //   if (!lastOnboarding || (!lastOnboarding.completed && !lastOnboarding.guides[onboardingConfig.companies.id])) {
    //     let dataToSend = lastOnboarding

    //     if (!dataToSend) {
    //       dataToSend = {
    //         accountId: userData.uuid,
    //         guides: {
    //           [onboardingConfig.companies.id]: {
    //             steps: []
    //           }
    //         },
    //         completed: false
    //       }
    //     }

    //     // window.userGuidingLayer.push({
    //     //   event: 'onload',
    //     //   fn: () => { this.setState({ loadedGuide: true }) }
    //     // })

    //     // window.userGuidingLayer.push({
    //     //   event: 'onPreviewStart',
    //     //   fn: () => { this.setState({ loadedGuide: true }) }
    //     // })

    //     window.userGuiding.previewGuide(onboardingConfig.companies.id)

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewStep',
    //       fn: async data => {
    //         const d = new Date()
    //         if (!Object.prototype.hasOwnProperty.call(dataToSend.guides, onboardingConfig.companies.id)) {
    //           dataToSend.guides[onboardingConfig.companies.id] = { steps: [] }
    //         }

    //         if (typeof dataToSend.guides[onboardingConfig.companies.id].steps[data.stepIndex] !== 'object') {
    //           dataToSend.guides[onboardingConfig.companies.id].steps[data.stepIndex] = {}
    //         }

    //         dataToSend.guides[onboardingConfig.companies.id].steps[data.stepIndex].startedAt = d
    //         if (data.stepIndex > 0) {
    //           dataToSend.guides[onboardingConfig.companies.id].steps[data.stepIndex - 1].completedAt = d
    //         }

    //         if (lastOnboarding) {
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${lastOnboarding.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         } else {
    //           const response = await axios({
    //             method: 'POST',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })

    //           dataToSend = response.data.data
    //         }
    //       }
    //     })

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewEnd',
    //       fn: async data => {
    //         if (data.completed) {
    //           const { steps } = dataToSend.guides[onboardingConfig.companies.id]
    //           steps[steps.length - 1].completedAt = new Date()
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${dataToSend.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         }
    //       }
    //     })
    //   } else {
    //     this.setState({ loadedGuide: true })
    //   }

    //   // Quando la guida è completa
    //   // if (lastOnboarding.guides[onboardingConfig.companies.id] && lastOnboarding.guides[onboardingConfig.companies.id].steps.length === onboardingConfig.companies.steps) {
    //   //   window.location.replace('/facilities')
    //   // }
    // }
  }

  closeFormDialogWithoutRefresh () {
    this.setState({ isFormDialogOpened: false })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  changePage (offset, page) {
    this.setState({ offset, page: page - 1 })
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  async closeCompanyModal () {
    // console.log('closed edited company => ', company)
    this.setState({ isCompanyModalOpened: false })
    await this.refreshCompanies()
    this.filterCompaniesViaText(this.state.lastSearchCriteria)
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false, isDeleting: false })
  }

  async closeFormDialog () {
    await this.refreshCompanies()
    this.setState({ isFormDialogOpened: false })
  }

  filterCompaniesViaText (searchCriteria) {
    const doesCompanyMatchCriteria = company => Object.keys(company).some(key => {
      if (typeof company[key] === 'object') {
        return doesCompanyMatchCriteria(company[key])
      }

      const value = String(company[key])
      return value.toLowerCase().includes(searchCriteria.toLowerCase())
    })

    if (searchCriteria.length) {
      const filtered = this.state.originalCompanies.filter(doesCompanyMatchCriteria)
      this.setState({ companies: filtered, offset: 0, page: 0 })
    } else {
      this.setState({ companies: this.state.originalCompanies, offset: 0, page: 0 })
    }
  }

  // funzione che prende le aziende
  async refreshCompanies () {
    try {
      const response = await returnAllCompanies()

      const root = document.getElementById('root')
      root.className = ''
      root.classList.add('companies')

      if (this._isMounted) {
        console.log('refreshed companies => ', response.data.data)
        this.setState({ areCompaniesFetching: false, companies: response.data.data, originalCompanies: response.data.data })
      }
    } catch (e) {
      console.error('Error while refreshing companies: ', e)
      this.setSnackbar(this.props.t('notifications.companiesError'), 'error')
      // alert('Impossibile ricaricare le aziende.')
    }
  }

  // funzione che cerca/filtra le aziende
  searchCriteriaChanged ({ target }) {
    this.setState({ lastSearchCriteria: target.value })
    this.filterCompaniesViaText(target.value)
  }

  openCompanyModal (company) {
    this.setState({ company, isCompanyModalOpened: true })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  openFormDialog () {
    this.setState({ isFormDialogOpened: true })
  }

  // renderizzo il componente
  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <div>
        {/* <Loader
          show={!this.state.loadedGuide}
          // text= {this.props.t('guide.loading')}
        >
        </Loader> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
            aria-describedby={this.state.snackbarType === 'success' ? 'success-snackbar' : 'error-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'success' ? <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} /> : <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> }
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }
          />
        </Snackbar>
        {/* form per aggiungere azienda */}
        {this.state.isFormDialogOpened ? (
          <FormDialog
            setSnackbar={this.setSnackbar}
            open={this.state.isFormDialogOpened}
            onCloseDialog={this.closeFormDialog}
            onCloseDialogWithoutRefresh={this.closeFormDialogWithoutRefresh}
          />
        ) : null}
        {this.state.isCompanyModalOpened ? (
          <CardModal
            setSnackbar={this.setSnackbar}
            company={this.state.company}
            open={this.state.isCompanyModalOpened}
            onCloseModal={this.closeCompanyModal} />
        ) : null }
        {/* search bar + buttons */}
        <Grid container direction="row" alignItems="center" justify="space-between" className={`${classes.root} filterRoot`}>
          <Grid item xs={10} sm={11} md={11} lg={11}>
            <Box>
              <Paper className={classes.paper}>
                <TextField
                  className={`${classes.input} searchField`}
                  placeholder={ this.props.t('inputs.search') }
                  inputProps={{ 'aria-label': this.props.t('inputs.search') }}
                  onChange={this.searchCriteriaChanged}
                />
                <SearchIcon className={classes.searchIcon} />
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={2} sm={1} md={1} lg={1} style={{ textAlign: 'right' }}>
            <Tooltip title={this.props.t('tooltip.buttons.addCompany')}>
              <Fab
                size="medium"
                aria-label={this.props.t('tooltip.buttons.addCompany')}
                variant="round"
                className={classes.addButton}
                onClick={this.openFormDialog}
              >
                <AddCircleIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
        {/* {isSmall
          ? <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Paper className={classes.paper}>
                  <InputBase
                    className={classes.input}
                    placeholder="Cerca azienda"
                    inputProps={{ 'aria-label': 'cerca azienda' }}
                    onChange={this.searchCriteriaChanged}
                  />
                  <SearchIcon className={classes.searchIcon} />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pb={0} className={classes.addButtonContainer}>
                <Button
                  variant="contained"
                  className={classes.addButton}
                  onClick={this.openFormDialog}
                  style={{ width: '100%' }}
                >
                  Aggiungi azienda
                  <AddCircleIcon className={classes.rightIcon} />
                </Button>
              </Box>
            </Grid>
          </Grid>
          : <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
            <Grid item xs>
              <Box>
                <Paper className={classes.paper}>
                  <InputBase
                    className={classes.input}
                    placeholder="Cerca azienda"
                    inputProps={{ 'aria-label': 'cerca azienda' }}
                    onChange={this.searchCriteriaChanged}
                  />
                  <SearchIcon className={classes.searchIcon} />
                </Paper>
              </Box>
            </Grid>
            <Grid item>
              <Tooltip title="Aggiungi Azienda">
                <Fab
                  size="medium"
                  aria-label="Aggiungi Azienda"
                  variant="round"
                  className={classes.addButton}
                  onClick={this.openFormDialog}
                >
                  <AddCircleIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </Grid>
        } */}
        {/* companies list */}
        <Grid container direction="column">
          {this.state.companies.length > 10 &&
            <Grid item xs>
              {this.state.areCompaniesFetching === false && <Pagination
                limit={this.state.limit}
                offset={this.state.offset}
                total={this.state.companies.length}
                onClick={(e, offset, page) => this.changePage(offset, page)}
                style={{ textAlign: 'center', paddingBottom: 8 }}
              />}
            </Grid>
          }
          <Grid item xs className={classes.cardListContainer}>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.grid} spacing={4}>
              {this.state.areCompaniesFetching
                ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={ this.props.t('notifications.downloadingCompanies') }
                    >
                    </LogoLoader>
                  </Grid>
                </Grid>
                : this.state.companies
                  .slice(this.state.page * this.state.limit, this.state.page * this.state.limit + this.state.limit)
                  .map((company, index) => (
                    company.uuid.indexOf('supplychain') !== 0 &&
                    <Grid key={company.uuid} item xs={12} sm={6} md={6} lg={4} xl={3} className={`${classes.cardContainer} snippetCard`}>
                      <Card onClick={() => this.openCompanyModal(company)} style={{ cursor: 'pointer' }}>
                        <div className='innerCard' style={isSmall ? {} : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className='borderFrame'>
                            <Tooltip title={this.props.t('companiesList.tooltip.buttons.edit')}>
                              <CreateIcon className='editIcon' onClick={() => this.openCompanyModal(company)}></CreateIcon>
                            </Tooltip>
                            <CardMedia
                              className={`${classes.companyLogo} sourceLogo`}
                              style={isSmall ? { height: 120, width: 120 } : { width: 180, height: 180 }}
                              image={(company.logo && company.logo.cropped) || '/images/img-placeholder.png'}
                              title='Logo Azienda'
                            />
                          </div>
                          <div className='bottomCard'>
                            <CardContent style={{ flex: '1 0 auto' }}>
                              <Typography component="h5" variant="h5" style={isSmall ? {} : { marginTop: 10 }} noWrap={true}>
                                { company.name }
                              </Typography>
                              <Typography component="h6" variant="subtitle2" color="textSecondary" noWrap={true} gutterBottom>
                                { company.vatId ? `${this.props.t('companiesList.vatId')} ${company.vatId}` : company.description } - { company.taxId ? `${this.props.t('companiesList.taxId')} ${company.taxId}` : '' }
                              </Typography>
                              <Grid container direction="column" justify="flex-start" alignItems="center" style={{ marginTop: 10 }}>
                                {company.email &&
                                  <Grid item xs>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginBottom: 8 }}>
                                      <DraftsTwoToneIcon size="small" style={{ marginRight: 10 }} />
                                      <Typography variant="body2" noWrap={true}>
                                        {company.email}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                                {company.mobileNumber &&
                                  <Grid item xs>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginBottom: 8 }}>
                                      <PhoneTwoToneIcon size="small" style={{ marginRight: 10 }} />
                                      <Typography variant="body2" noWrap={true}>
                                        {company.mobileNumber}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                                {(!company.email || !company.mobileNumber) && company.website &&
                                  <Grid item xs>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginBottom: 8 }}>
                                      <LanguageTwoToneIcon size="small" style={{ marginRight: 10 }} />
                                      <Typography variant="body2" noWrap={true}>
                                        {company.website}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                              </Grid>
                            </CardContent>
                          </div>
                        </div>
                      </Card>
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item xs>
            {this.state.areCompaniesFetching === false && <Pagination
              limit={this.state.limit}
              offset={this.state.offset}
              total={this.state.companies.length}
              onClick={(e, offset, page) => this.changePage(offset, page)}
              style={{ textAlign: 'center', paddingTop: 8 }}
            />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Companies.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('companies')(Companies))))
