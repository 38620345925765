import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  Box,
  Button

} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

function AddTrackComponent (props) {
  const classes = useStyles()
  const {
    product,
    isModifyEnabled,
    enableOrDisableModify,
    translation,
    addTrack
  } = props
  const { t } = useTranslation(translation)

  return (
    <div className={classes.tabContainer} style={{ minHeight: '50vh' }}>
      <Box width='100%' height='50vh' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <img style={{ width: '240px', marginBottom: '16px' }} src={'/images/no_phase.svg'} alt={t('tabs.traceability.emptyTraceability')} />
        <Typography variant='body2'>{t('tabs.traceability.emptyTraceability')}</Typography>
        <Box mt={4}>
          <Button onClick={() => {
            !isModifyEnabled && enableOrDisableModify()
            addTrack(product.traceability.length - 1)
          }} color='primary' classes={{ contained: classes.mainButton }} variant='contained'>{t('tabs.traceability.buttons.newPhase')}</Button>
        </Box>
      </Box>
    </div>
  )
}

AddTrackComponent.propTypes = {
  product: PropTypes.object.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  enableOrDisableModify: PropTypes.func.isRequired,
  addTrack: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired

}
export default AddTrackComponent
