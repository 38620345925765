export const domainToLogos = {
  filierapuntozero: {
    frontendBaseUrl: 'https://discover.filierapuntozero.it',
    favicon: '/images/filierapuntozero_favicon.ico',
    centralLogos: [
      {
        /* immagine che coincide con il nome del dominio */
        img: '/images/logo-filierapuntozero-transparent.png',
        title: 'Logo Filierapuntozero',
        author: 'Filierapuntozero',
        cols: 1,
        url: 'https://www.filierapuntozero.it/'
      },
      {
        /* immagine della azienda proprietaria del dominio */
        img: '/images/Molino-Bongermino-Logo.png',
        title: 'Logo Molino',
        author: 'Molino',
        cols: 1,
        url: 'https://molinobongermino.it/'
      },
      /* immagine delle aziende partner */
      {
        img: '/images/logo_apio.png',
        title: 'Logo Apio',
        author: 'Apio',
        cols: 1,
        url: 'https://www.apio.cc/'
      }, {
        img: '/images/ibm-logo.png',
        title: 'Logo IBM',
        author: 'IBM',
        cols: 1,
        url: 'https://www.ibm.com/blockchain/solutions/food-trust'
      }, {
        img: '/images/logo_var_group.png',
        title: 'Logo Var Group',
        author: 'Var Group',
        cols: 1,
        url: 'https://www.vargroup.it/'
      }
    ],
    sidebarLogos: [
      {
        /* immagine che coincide con il nome del dominio */
        img: '/images/logo-filierapuntozero-minimal.png',
        title: 'Logo Filierapuntozero',
        author: 'Filierapuntozero',
        cols: 1
      }
    ]
  },
  trusty: {
    favicon: '/images/favicon.ico',
    centralLogos: [{
      img: '/images/logo-trusty-transparent.png',
      title: 'Logo Trusty',
      author: 'Trusty',
      cols: 1
    }, {
      img: '/images/logo_apio.png',
      title: 'Logo Apio',
      author: 'Apio',
      cols: 1,
      url: 'https://www.apio.cc/'
    }
    ],
    sidebarLogos: [
      {
        /* immagine che coincide con il nome del dominio */
        img: '/images/logo-trusty-minimal.png',
        title: 'Logo Filierapuntozero',
        author: 'Filierapuntozero',
        cols: 1
      }
    ]
  },
  fairtrade: {
    favicon: '/images/favicon.ico',
    centralLogos: [{
      img: '/images/logo-trusty-transparent.png',
      title: 'Logo Trusty',
      author: 'Trusty',
      cols: 1
    }, {
      img: '/images/logo_apio.png',
      title: 'Logo Apio',
      author: 'Apio',
      cols: 1,
      url: 'https://www.apio.cc/'
    }
    ],
    sidebarLogos: [
      {
        /* immagine che coincide con il nome del dominio */
        img: '/images/logo-trusty-minimal.png',
        title: 'Logo Filierapuntozero',
        author: 'Filierapuntozero',
        cols: 1
      }
    ]
  },
  chocofair: {
    favicon: '/images/favicon.ico',
    centralLogos: [{
      img: '/images/logo-trusty-transparent.png',
      title: 'Logo Trusty',
      author: 'Trusty',
      cols: 1
    }, {
      img: '/images/logo_apio.png',
      title: 'Logo Apio',
      author: 'Apio',
      cols: 1,
      url: 'https://www.apio.cc/'
    }
    ],
    sidebarLogos: [
      {
        /* immagine che coincide con il nome del dominio */
        img: '/images/logo-trusty-minimal.png',
        title: 'Logo Filierapuntozero',
        author: 'Filierapuntozero',
        cols: 1
      }
    ]
  },
  localhost: {
    favicon: '/images/logo_apio.png',
    centralLogos: [{
      img: '/images/logo-trusty-transparent.png',
      title: 'Logo Trusty',
      author: 'Trusty',
      cols: 1,
      url: 'https://www.filierapuntozero.it/'
    }, {
      img: '/images/logo_apio.png',
      title: 'Logo Apio',
      author: 'Apio',
      cols: 1,
      url: 'https://www.apio.cc/'
    }, {
      /* immagine della azienda proprietaria del dominio */
      img: '/images/Molino-Bongermino-Logo.png',
      title: 'Logo Molino',
      author: 'Molino',
      cols: 1,
      url: 'https://molinobongermino.it/'
    }, {
      img: '/images/ibm-logo.png',
      title: 'Logo IBM',
      author: 'IBM',
      cols: 1,
      url: 'https://www.ibm.com/blockchain/solutions/food-trust'
    }, {
      img: '/images/logo_var_group.png',
      title: 'Logo Var Group',
      author: 'Var Group',
      cols: 1,
      url: 'https://www.vargroup.it/'
    }
    ],
    sidebarLogos: [
      {
        /* immagine che coincide con il nome del dominio */
        img: '/images/logo-trusty-minimal.png',
        title: 'Logo Filierapuntozero',
        author: 'Filierapuntozero',
        cols: 1
      }
    ]
  }
}
