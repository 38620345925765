import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import NameComponent from '../StepUIComponent/NameComponent'
import ShowStepComponent from '../StepUIComponent/ShowStepComponent'
import MediaComponent from '../StepUIComponent/MediaComponent'
import InsertDocumentComponent from '../StepUIComponent/DocumentComponents/InsertDocumentComponent'
import DocumentComponent from '../StepUIComponent/DocumentComponents/DocumentComponent'
import StartDate from '../StepUIComponent/StartDate'
import EndDate from '../StepUIComponent/EndDate'
import DescriptionComponent from '../StepUIComponent/DescriptionComponent'
import InputComponent from '../StepUIComponent/InputComponents/InputComponent'
import TitleWithAddButton from '../StepUIComponent/AdditionalBlocks/TitleWithAddButton'
import FacilityFromComponent from '../StepUIComponent/FacilityFromComponent/FacilityFromComponent'
import FacilityToComponent from '../StepUIComponent/FacilityToComponent/FacilityToComponent'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

function DeliverStep (props) {
  const {
    track,
    phaseTabNumber,
    trackIndex,
    handleVisibility,
    uploadDocument,
    isModifyEnabled,
    isShowPhase,
    openMedia,
    deleteDocument,
    timestampChanged,
    startTimestampChanged,
    addInput,
    deleteInput,
    addFacilityFrom,
    addFacilityTo,
    inputChangedNewSelect,
    inputChanged,
    valueChanged,
    facilities,
    product,
    products,
    frontEndUrl,
    openUrl,
    verifyInput,
    locationToChanged,
    locationFromChanged,
    deleteFacilityFrom,
    deleteFacilityTo
  } = props
  const classes = useStyles()

  return (

    <div key={`tab-traceability-${trackIndex}`}
      component="div"
      hidden={phaseTabNumber !== trackIndex}
      id={`simple-phasetabpanel-${trackIndex}`}
      aria-labelledby={`simple-phasetabpanel-${trackIndex}`}
      className={classes.tabContainer}
    >
      <Grid container direction="column" justify="flex-start" alignItems="flex-start">
        <Grid container direction='row' justify='flex-start' alignItems='center'>
          <NameComponent
            track={track}
          ></NameComponent>
          <ShowStepComponent
            handleVisibility={handleVisibility}
            trackIndex={trackIndex}
            isModifyEnabled={isModifyEnabled}
            isShowPhase={isShowPhase}
          ></ShowStepComponent>
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={4}>
            <Grid item xs={12} md={5}>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <MediaComponent
                  track={track}
                ></MediaComponent>
                <InsertDocumentComponent
                  trackIndex={trackIndex}
                  uploadDocument = {uploadDocument}
                  isModifyEnabled={isModifyEnabled}
                ></InsertDocumentComponent>
                <DocumentComponent
                  track={track}
                  trackIndex={trackIndex}
                  openMedia={openMedia}
                  isModifyEnabled={isModifyEnabled}
                  deleteDocument={deleteDocument}
                ></DocumentComponent>

              </Grid>
            </Grid>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" justify="flex-start" alignItems="center">
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <StartDate
                      track={track}
                      startTimestampChanged={startTimestampChanged}
                      trackIndex={trackIndex}
                      isModifyEnabled={isModifyEnabled}
                    ></StartDate>
                    <EndDate
                      track={track}
                      timestampChanged={timestampChanged}
                      trackIndex={trackIndex}
                      isModifyEnabled={isModifyEnabled}
                    ></EndDate>

                  </Grid>
                </Grid>
                <DescriptionComponent
                  track={track}
                  valueChanged={valueChanged}
                  trackIndex={trackIndex}
                  isModifyEnabled={isModifyEnabled}
                ></DescriptionComponent>
                <Grid item xs={12} style={{ width: '100%' }} className="inputContainer">
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <TitleWithAddButton
                              trackIndex={trackIndex}
                              isModifyEnabled={isModifyEnabled}
                              addComponent={addFacilityFrom}
                              title={'tabs.traceability.inputs.locationFromTitle'}
                              toolTipTitle={'tabs.traceability.inputs.addFacilityFrom'}
                            ></TitleWithAddButton>
                            {track.locationFrom.map((locationFrom, locationIndex) => {
                              return (
                                <FacilityFromComponent
                                  key={`${locationFrom.facilityId}-${locationIndex}-${trackIndex}`}
                                  track={track}
                                  trackIndex={trackIndex}
                                  locationFrom={locationFrom}
                                  locationIndex={locationIndex}
                                  locationFromChanged={locationFromChanged}
                                  isModifyEnabled={isModifyEnabled}
                                  facilities={facilities}
                                  deleteFacilityFrom={deleteFacilityFrom}
                                ></FacilityFromComponent>
                              )
                            })}
                            <TitleWithAddButton
                              trackIndex={trackIndex}
                              isModifyEnabled={isModifyEnabled}
                              addComponent={addFacilityTo}
                              title={'tabs.traceability.inputs.locationToTitle'}
                              toolTipTitle={'tabs.traceability.inputs.addFacilityTo'}
                            ></TitleWithAddButton>
                            {track.locationTo.map((locationTo, locationIndex) => {
                              return (
                                <FacilityToComponent
                                  key={`${locationTo.facilityId}-${locationIndex}-${trackIndex}`}
                                  track={track}
                                  trackIndex={trackIndex}
                                  locationTo={locationTo}
                                  locationIndex={locationIndex}
                                  locationToChanged={locationToChanged}
                                  isModifyEnabled={isModifyEnabled}
                                  facilities={facilities}
                                  deleteFacilityTo={deleteFacilityTo}
                                ></FacilityToComponent>
                              )
                            })}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <TitleWithAddButton
                            trackIndex={trackIndex}
                            isModifyEnabled={isModifyEnabled}
                            addComponent={addInput}
                            title={'tabs.traceability.inputs.inputMaterials'}
                            toolTipTitle={'tabs.traceability.inputs.addInput'}
                          ></TitleWithAddButton>
                          {track.inputs.map((input, inputIndex) => {
                            return (
                              <InputComponent
                                key={`${input.productId}-${inputIndex}-${trackIndex}`}
                                track={track}
                                trackIndex={trackIndex}
                                inputIndex={inputIndex}
                                input={input}
                                deleteInput={deleteInput}
                                isModifyEnabled={isModifyEnabled}
                                inputChangedNewSelect={inputChangedNewSelect}
                                inputChanged={inputChanged}
                                product={product}
                                products={products}
                                verifyInput={verifyInput}
                                openUrl={openUrl}
                                frontEndUrl={frontEndUrl}
                              ></InputComponent>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

DeliverStep.propTypes = {
  track: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  phaseTabNumber: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  handleVisibility: PropTypes.func,
  uploadDocument: PropTypes.func,
  openMedia: PropTypes.func,
  deleteDocument: PropTypes.func,
  startTimestampChanged: PropTypes.func,
  timestampChanged: PropTypes.func,
  locationChanged: PropTypes.func.isRequired,
  inputChanged: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  isShowPhase: PropTypes.bool,
  valueChanged: PropTypes.func.isRequired,
  addInput: PropTypes.func.isRequired,
  addFacilityFrom: PropTypes.func.isRequired,
  addFacilityTo: PropTypes.func.isRequired,
  deleteFacilityFrom: PropTypes.func.isRequired,
  deleteFacilityTo: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  verifyInput: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  openUrl: PropTypes.func.isRequired,
  frontEndUrl: PropTypes.string.isRequired,
  componentIndex: PropTypes.number.isRequired
}
export default DeliverStep
