import React from 'react'
import { withTranslation } from 'react-i18next'
import configuration from '../../configuration'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import MailModal from './MailModal'
import FormDialogSupplychain from './FormDialogSupplychain'
import { green, amber } from '@material-ui/core/colors'

import { returnAllSupplychains } from '../../utils/supplychains'
import { setLocalStorageSupplychain, getLocalStorageSupplychain } from '../../utils/acl-organization'
import classNames from 'classnames'

import { domainToLogos } from '../../domainToLogos'
import {
  AddCircle as AddCircleIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  Cancel
} from '@material-ui/icons'
import {

  Grid,
  InputLabel,
  MenuItem,
  Select,
  withStyles,
  FormControl,
  Snackbar,
  SnackbarContent,
  withWidth,
  Tooltip,
  Fab
} from '@material-ui/core'
import PrivilegeModal from './PrivilegeModal'
import { returnAllSupplyChainCompaniesProjection } from '../../utils/companies'

const styles = theme => ({
  '@global': {
    '.MuiInputBase-input': {
      padding: '6px 0 4px'
    },
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  progressStyle: {
    color: theme.primary
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: '#FFFFFF',
    width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  formControl: {
    minWidth: '100%'
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  selectContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 12
    }
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

class Manage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      allSupplychainsId: getLocalStorageSupplychain(),
      allSupplychainsName: [],
      allSupplychainIdNameMap: {},
      companies: [],
      supplychainsId: '',
      supplychainColumns: [],
      supplychainMobileColumns: [],
      supplychainColumnsLastProducts: [],
      supplychainMobileColumnsLastProducts: [],
      tableData: [],
      open: false,
      openDialog: false,
      openPrivilegeDialog: false,
      isModalEmailOpen: false,
      isModalPrivilegeOpen: false,
      isFormDialogOpened: false,
      frontEndUrl: this.props.domain.type === 'INVALID' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}` /* fare attenzione che in caso di più topLevelsDomain (eg:co.uk) bisogna gestire */,
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: ''
    }
    this.handleChangeSelectSupplychain = this.handleChangeSelectSupplychain.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClosePrivilege = this.handleClosePrivilege.bind(this)
    this.openFormDialog = this.openFormDialog.bind(this)
    this.closeFormDialog = this.closeFormDialog.bind(this)
    this.closeFormDialogWithoutSave = this.closeFormDialogWithoutSave.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
  }

  async componentDidMount () {
    try {
      const allSupplychainsResponse = await returnAllSupplychains(this.state.allSupplychainsId)
      let allSupplychains = allSupplychainsResponse.data.data
      const allSupplychainIdNameMap = allSupplychains.reduce((acc, supply) => {
        acc[supply.name] = supply.uuid
        return acc
      }, {})
      allSupplychains = allSupplychains.map(x => x.name)
      this.setState({ allSupplychainsName: allSupplychains, allSupplychainIdNameMap })
    } catch (e) {
      console.log(e)
    }
  }

  async handleChangeSelectSupplychain (event) {
    switch (event.target.value) {
      case '': {
        return this.setState({
          supplychainsId: event.target.value,
          supplychainColumns: [],
          tableData: []
        })
      }
      // case 'all': {
      //   return this.setState({
      //     supplychainsId: event.target.value,
      //     supplychainColumns: [],
      //     tableData: []
      //   })
      // }
      default:
      {
        const supplychainsResponse = await returnAllSupplychains(this.state.allSupplychainIdNameMap[event.target.value])
        const supplychain = supplychainsResponse.data.data

        const companiesSupplychainParams = {
          fields: 'name,uuid,organizationId'
        }
        const companiesResponse = await returnAllSupplyChainCompaniesProjection(this.state.allSupplychainIdNameMap[event.target.value], companiesSupplychainParams)
        const companies = companiesResponse.data.data.companies
        companies.forEach((company) => {
          company.metadata = {}
          company.metadata.hidden = false
        })
        supplychain.forEach(supply => {
          supply.companies = companies
          supply.members = supply.companies.length
        })
        const supplychainColumns = [
          { title: this.props.t('manage.table.name'), field: 'name' },
          { title: this.props.t('manage.table.members'), field: 'members' }
        ]
        return this.setState({
          supplychainsId: event.target.value,
          supplychainColumns,
          tableData: supplychain,
          companies
        })
      }
    }
  }

  handleClose () {
    this.setState({
      isModalEmailOpen: false,
      open: false
    })
  }

  handleClosePrivilege () {
    this.setState({
      isModalPrivilegeOpen: false,
      openPrivilegeDialog: false
    })
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  openFormDialog () {
    this.setState({ isFormDialogOpened: true, openDialog: true })
  }

  closeFormDialog (supply) {
    console.log('Supply', supply)
    const supplychain = JSON.parse(JSON.stringify(supply))
    supplychain.target = {
      value: supply.name
    }
    setLocalStorageSupplychain(supply)
    this.setState({
      isFormDialogOpened: false,
      openDialog: false,
      allSupplychainIdNameMap: {
        ...this.state.allSupplychainIdNameMap,
        [supply.name]: supply.uuid
      },
      allSupplychainsId: [...this.state.allSupplychainsId, supply.uuid],
      allSupplychainsName: [...this.state.allSupplychainsName, supply.name]
    }, () => {
      this.handleChangeSelectSupplychain(supplychain)
    })
  }

  closeFormDialogWithoutSave () {
    this.setState({ isFormDialogOpened: false, openDialog: false })
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
            aria-describedby={this.state.snackbarType === 'success' ? 'success-snackbar' : 'error-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'success' ? <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} /> : <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> }
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }
          />
        </Snackbar>
        {this.state.isModalEmailOpen &&
      <MailModal
        setSnackbar={this.setSnackbar}
        open={this.state.open}
        onClose={this.handleClose}
        supplychainId={this.state.allSupplychainIdNameMap[this.state.supplychainsId]}
        domain={this.props.domain}
      />}
        {this.state.isModalPrivilegeOpen &&
      <PrivilegeModal
        open={this.state.openPrivilegeDialog}
        onClose={this.handleClosePrivilege}
        supplychainId={this.state.allSupplychainIdNameMap[this.state.supplychainsId]}
        companies = {this.state.companies}
        domain={this.props.domain}
      />}
        {this.state.isFormDialogOpened &&
      <FormDialogSupplychain
        setSnackbar={this.setSnackbar}
        open={this.state.openDialog}
        onClose={this.closeFormDialog}
        onCloseWithoutSave={this.closeFormDialogWithoutSave}
      />}
        <Grid container direction="row" alignItems="center" justify="space-between" className={`${classes.root} filterRoot`}>
          <Grid item xs={4} sm={4} md={3} lg={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">{this.props.t('inputs.supplychain')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.supplychainsId}
                onChange={this.handleChangeSelectSupplychain}
              >
                <MenuItem value={''}>{this.props.t('manage.inputs.none')}</MenuItem>
                {/* <MenuItem value={'all'}>{this.props.t('manage.inputs.allSupplychains')}</MenuItem> */}
                {this.state.allSupplychainsName.map((id) => (
                  <MenuItem key={id} value={id}>
                    {id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={4} md={7} lg={7}></Grid>
          <Grid item xs={2} sm={1} md={1} lg={1} style={{ textAlign: 'right' }}>
            <Tooltip title={this.props.t('manage.tooltip.buttons.addSupplychain')}>
              <Fab
                size="medium"
                aria-label={this.props.t('manage.tooltip.buttons.addSupplychain')}
                variant="round"
                className={classes.addButton}
                onClick={this.openFormDialog}
              >
                <AddCircleIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center" justify="space-between" className={`${classes.root} filterRoot`}>
          <Grid item xs={12}>
            <MaterialTable
              title={this.props.t('table.title')}
              localization={{
                toolbar: this.props.t('table.localization.toolbar', { returnObjects: true }),
                body: {
                  emptyDataSourceMessage: this.state.selectedProductId !== '' ? this.props.t('table.localization.body.emptyDataSourceMessage') : this.props.t('table.localization.body.emptyDataSourceMessage2')
                },
                pagination: this.props.t('table.localization.pagination', { returnObjects: true }),
                header: this.props.t('table.localization.header', { returnObjects: true })
              }}
              columns={isSmall ? this.state.lotsMobileColumnsLastProducts : this.state.supplychainColumns}
              data={this.state.tableData}
              options={{
                actionsColumnIndex: -1,
                emptyRowsWhenPaging: false,
                pageSize: 20,
                pageSizeOptions: [20, 40, 60, 80, 100],
                showEmptyDataSourceMessage: true,
                rowStyle: rowData => ({
                  backgroundColor: rowData.status === 'draft' ? '#F6DA8D' : (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                })
              }}
              actions={
                [{
                  icon: 'settings',
                  onClick: () => {
                    this.setState({
                      isModalPrivilegeOpen: true,
                      openPrivilegeDialog: true
                    })
                  }
                }, {
                  icon: 'email',
                  onClick: () => {
                    this.setState({
                      isModalEmailOpen: true,
                      open: true
                    })
                  }
                }
                ]}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}
export default withRouter(withStyles(styles)(withWidth()(withTranslation('supplychain')(Manage))))
