import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { green, amber } from '@material-ui/core/colors'
import { createDefaultProduct } from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'
import {
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles,
  Box,
  withWidth,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
  // Link,
} from '@material-ui/core'
const styles = theme => ({
  '@global': {
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  progressStyle: {
    color: theme.primary
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: 'white',
    width: '100%'
  },
  archivedCard: {
    opacity: 0.6
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  // Blue gradient
  /* cardAvatar: {
      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
    }, */
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
    // width: 400
  },
  selectContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 12
    }
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  success: {
    backgroundColor: green[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  mh5: {
    margin: '0 5px'
  },
  draftProduct: {
    backgroundColor: '#eaeaea',
    opacity: '0.8'
  },
  addButton: {
    background: '#C0EDCB',
    color: '#3C4858'
  }
})
class FormDialogProduct extends React.Component {
  constructor (props) {
    super(props)
    this._isMounted = false

    /* STATO */
    this.state = {
      open: this.props.open,
      product: {
        // type, companyId,gtin,name
      },
      confirmCloseOpen: false,
      companies: [],
      facilities: []
    }

    /* FUNZIONI */
    this.valueChanged = this.valueChanged.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.setProductConfirmDialog = this.setProductConfirmDialog.bind(this)
    this.closeDialogWithoutRefresh = this.closeDialogWithoutRefresh.bind(this)
  }

  async componentDidMount () {
    // mi prendo le aziende
    const companiesResponse = await returnAllCompanies()
    const companies = companiesResponse.data.data

    this.setState(prevState => ({
      ...prevState,
      companies
    }))
  }

  closeConfirmDialog () {
    this.setState({ confirmCloseOpen: false })
  }

  // funzione che setta il prodotto selezionato
  async setProductConfirmDialog () {
    try {
      await createDefaultProduct(this.state.product)
      this.closeDialog()
    } catch (e) {
      console.log('sono nel catch!!!')
      // response.errorSupplychain="Resource cannot subscribe to supplychain"
      switch (e.response.status) {
        case 409: {
          this.props.setSnackbar(this.props.t('notifications.errBeforeFacility'), 'error')
          this.props.onCloseDialogWithoutRefresh()
          break
        }
        case 400: {
          this.props.setSnackbar(this.props.t('notifications.errRequiredField'), 'error')
          this.props.onCloseDialogWithoutRefresh()
          break
        }
        default: {
          this.props.setSnackbar(this.props.t('notifications.errNewProduct'), 'error')
          break
        }
      }
    }
  }

  async closeDialog () {
    await this.props.onCloseDialog()
    this.props.setSnackbar(this.props.t('notifications.newProductOk'), 'success')
  }

  async closeDialogWithoutRefresh () {
    this.props.onCloseDialogWithoutRefresh()
  }

  openConfirmDialog () {
    this.setState({ confirmCloseOpen: true })
  }

  async valueChanged ({ target }) {
    const product = this.state.product
    product[target.name] = target.value
    this.setState({ product })
    /* else if commentato in quanto le facilities e i prodotti devono rimanere quelli caricati nel didMount, ossia tutti i prodotti e le facilities dell'organizzazion e non filtrati per company
    else if (target.name === 'companyId') {
      // const facilitiesParams = {
      //   companyId: target.value
      // }
      // const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
      // // const facilitiesResponse = await axios.get(`${configuration.apiBaseUrl}/facilities/`, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   },
      //   params: {
      //     companyId: target.value
      //   }
      // })

      // const facilities = facilitiesResponse.data.data || []
      // this.setState({ facilities })

      const productsParams = {
        filter: JSON.stringify({ companyId: target.value, status: { $ne: 'deleted' } })
      }
      const productsResponse = await returnSomeProducts(productsParams)
      // const productsResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   },
      //   params: {
      //     filter: JSON.stringify({ companyId: target.value, status: { $ne: 'deleted' } })
      //   }
      // })

      // all products from API
      const allProducts = productsResponse.data.data || []
      // filtering only active products
      const products = allProducts.filter(prod => prod.status === 'active')
      this.setState({ products })
    }
    */
  }

  render () {
    const { classes } = this.props
    return (
      <>
        {this.state.confirmCloseOpen ? (
          <Dialog open={this.state.confirmCloseOpen} className="productModal" id='confirmDialog'
            aria-labelledby='responsive-dialog-title'>
            <DialogTitle id='responsive-dialog-title'>{this.props.t('dialog.confirmClose.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.props.t('dialog.confirmClose.description')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button classes={{ textPrimary: classes.primary }} onClick={this.closeConfirmDialog} color='secondary' autoFocus>
                {this.props.t('dialog.confirmClose.buttons.cancel')}
              </Button>
              <Button
                classes={{ textPrimary: classes.primary }}
                onClick={async () => {
                  await this.closeDialogWithoutRefresh()
                  this.closeConfirmDialog()
                }}
                color='primary'
              >
                {this.props.t('dialog.confirmClose.buttons.close')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null
        }
        <Dialog className="productModal"
          open={this.state.open}
          // onClose={this.openFormDialog}
          onClose={this.openConfirmDialog}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id='form-dialog-title' className={classes.navBar}>
            {this.props.t('title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{ this.props.t('step.generalInfo.description') }</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor='type'>
                  {this.props.t('step.generalInfo.inputs.type.label')}
                </InputLabel>
                <Select id='type' name='type' value={this.state.product.type} onChange={this.valueChanged} displayEmpty fullWidth>
                  <MenuItem value='rawMaterial'>
                    {this.props.t('step.generalInfo.inputs.type.values.rawMaterial')}
                  </MenuItem>
                  <MenuItem value='intermediateGood'>
                    {this.props.t('step.generalInfo.inputs.type.values.intermediateGood')}
                  </MenuItem>
                  <MenuItem value='finishedGood'>
                    {this.props.t('step.generalInfo.inputs.type.values.finishedGood')}
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink htmlFor='companyId'>
                  {this.props.t('step.generalInfo.inputs.company')}
                </InputLabel>
                <Select id='companyId' name='companyId' value={this.state.product.companyId} onChange={this.valueChanged} displayEmpty fullWidth>
                  {this.state.companies
                    .filter(com => com.uuid.indexOf('supplychain') === -1)
                    .map(company => (
                      <MenuItem key={company.uuid} value={company.uuid}>
                        {company.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ shrink: true }} autoFocus margin='dense' id='name' name='name' value={this.state.product.name} label={`${this.props.t('step.generalInfo.inputs.name')}*`} onChange={this.valueChanged}
                  type='text' fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    id='gtin'
                    name='gtin'
                    value={this.state.product.gtin}
                    label={`${this.props.t('step.generalInfo.inputs.gtin')}`}
                    onChange={this.valueChanged}
                    type='text' fullWidth
                  />
                  <Typography style={{ color: '#737373' }} variant='body2'>
                    {this.props.t('step.generalInfo.inputs.gtinDescription')}. <i><b>{this.props.t('step.generalInfo.inputs.gtinExample')}</b></i>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.openConfirmDialog} color="secondary" autoFocus>
              {this.props.t('dialog.confirmClose.buttons.close')}
            </Button>
            <Button onClick={this.setProductConfirmDialog} color="primary" disabled={!(this.state.product.type && this.state.product.companyId)}>
              { this.props.t('buttons.add')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

FormDialogProduct.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func,
  onCloseDialogWithoutRefresh: PropTypes.func,
  open: PropTypes.bool,
  setSnackbar: PropTypes.func
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('newProduct')(FormDialogProduct))))
