import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AddComponent from './AddComponent'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function TitleWithAddButton (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    addComponent,
    isModifyEnabled,
    title,
    toolTipTitle
  } = props

  return (
    <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h6" style={{ marginRight: '10px' }}>
          {t(`${title}`)}
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <AddComponent
          trackIndex={trackIndex}
          addComponent={addComponent}
          isModifyEnabled={isModifyEnabled}
          toolTipTitle={toolTipTitle}
        ></AddComponent>
      </Grid>
    </Grid>

  )
}

TitleWithAddButton.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  addComponent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toolTipTitle: PropTypes.string.isRequired
}
export default TitleWithAddButton
