import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function SelectMaterialComponent (props) {
  const {
    valueFromChild,
    valueFromChildInnerMaterial,
    waste,
    isModifyEnabled,
    materials,
    index,
    componentType
  } = props
  const [firstMaterial, setFirstMaterial] = useState(materials.find(elem => elem.id === waste.material.id) || '')
  const [innerMaterial, setInnerMaterial] = useState('')
  const { t } = useTranslation('productDetails')

  console.log('MATERIALS:::::', materials)
  const selectMaterialValueChanged = (e, index) => {
    setFirstMaterial(e.target.value)
    setInnerMaterial('')
    valueFromChild(e, index)
  }

  const changeInnerMaterial = (e, index) => {
    setInnerMaterial(e.target.value)
    valueFromChildInnerMaterial(e, index)
  }

  useEffect(() => {
    if (waste.component === '' &&
    waste.material === '' &&
    waste.wasteCollection === '') {
      setInnerMaterial('')
      setFirstMaterial('')
    }
    console.log('innerMAteria:::::::::::::::::::', innerMaterial)
  }, [waste.material]) // Only re-run the effect if count changes
  return (
    <>
      <InputLabel style={{ fontSize: '12px', marginBottom: '15px' }} id="demo-simple-select-label">{`${t(`${componentType}.firstMaterial`)}`}</InputLabel>
      <Select
        label={`${t(`${componentType}.firstMaterial`)}`}
        labelId="waste-mono-select-label"
        id="waste-mono-select-select"
        name="material"
        onChange={ (e) => {
          selectMaterialValueChanged(e, index)
        }}
        // value={firstMaterial}
        value={materials.find(elem => elem.id === waste.material.id) || ''}
        disabled={!isModifyEnabled} fullWidth>

        {materials
          .map(element => {
            return <MenuItem key={`firstMaterial# ${element.name}`} value={element}>
              {t(`${componentType}.${element.name}`)}
            </MenuItem>
          }
          )}
      </Select>
      {firstMaterial && Object.keys(firstMaterial).length > 0 && Array.isArray(firstMaterial.materials) && firstMaterial.materials.length > 1 &&
      <>
        <InputLabel style={{ fontSize: '12px', marginBottom: '15px' }} id="demo-simple-select-label">{`${t(`${componentType}.innerMaterial`)}`}</InputLabel>
        <Select
          label={`${t(`${componentType}.innerMaterial`)}`}
          labelId="waste-mono-select-label"
          id="waste-mono-select-select"
          name="materials"
          onChange={ (e) => {
            // valueFromChildInnerMaterial(e, index)
            changeInnerMaterial(e, index)
          }}
          // value={innerMaterial || ''} // per non far passare la select da uno stato uncontrolled a controlled
          value={materials.find(elem => elem.id === waste.material.id)?.materials.filter(x => x.id === waste.material?.materials[0].id)[0] || ''}
          disabled={!isModifyEnabled} fullWidth>

          {materials
            .find(elem => elem.id === firstMaterial.id)
            .materials
            .map(element => {
              return <MenuItem key={`secondMaterial# ${element.name}`} value={element}>
                {t(`${componentType}.${element.name}`)}
              </MenuItem>
            }
            )}
        </Select>
      </>
      }
    </>
  )
}
SelectMaterialComponent.propTypes = {
  valueFromChild: PropTypes.func.isRequired,
  valueFromChildInnerMaterial: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  componentType: PropTypes.string.isRequired
}

export default SelectMaterialComponent
