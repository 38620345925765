import React from 'react'
import PropTypes from 'prop-types'
import {
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

function SelectWasteCollection (props) {
  const {
    valueFromChild,
    isModifyEnabled,
    materials,
    index,
    componentType,
    waste
  } = props

  const { t } = useTranslation('productDetails')
  const selectMaterialValueChanged = (e, index) => {
    valueFromChild(e, index)
  }
  return (
    <>
      <InputLabel style={{ fontSize: '12px', marginBottom: '15px' }} id="demo-simple-select-label"> {t(`${componentType}.name`)}</InputLabel>
      <Select
        label="Scegli il mono component"
        labelId="waste-mono-select-label"
        id="waste-mono-select-select"
        name="wasteCollection"
        onChange={ (e) => {
          selectMaterialValueChanged(e, index)
        }}
        value={materials.find(elem => elem === waste.wasteCollection) || ''}
        disabled={!isModifyEnabled}
        fullWidth>

        {materials
          .map(element => {
            return <MenuItem key={element} value={element}>
              {t(`${componentType}.${element}`)}
            </MenuItem>
          }
          )}
      </Select>
    </>
  )
}
SelectWasteCollection.propTypes = {
  valueFromChild: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  materials: PropTypes.any.isRequired,
  componentType: PropTypes.string.isRequired,
  waste: PropTypes.object.isRequired
}

export default SelectWasteCollection
