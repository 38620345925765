import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Grid,
  withStyles,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Snackbar,
  SnackbarContent,
  Box
} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MaterialTable from 'material-table'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveChoroplethCanvas } from '@nivo/geo'
import featureCollection from '../Overview/world_countries.json'
import countryCodes from '../Overview/CountryCodes.json'
// import configuration from '../../configuration'
// import axios from 'axios'
import moment from 'moment'
import ls from 'local-storage'
import withWidth from '@material-ui/core/withWidth'

import ErrorIcon from '@material-ui/icons/Error'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import Cancel from '@material-ui/icons/Cancel'

import { green, amber } from '@material-ui/core/colors'

import { ItLocalizedUtils, locale } from '../../utils/utils'
import { withTranslation } from 'react-i18next'
// import { currLang } from '../../utils/translations'
import { returnLastVisitsDetails } from '../../utils/analytics'
// import { currOrganization, organizations } from '../../utils/acl-organization'
import { returnAllProducts } from '../../utils/products'
// import { currOrganization, organizations, updateOrganizationACL } from '../../utils/acl-organization'
// const colors = ['#48b3d8', '#ffa64d', '#36b336', '#ff8080']

const styles = theme => ({
  '@global': {
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  maxExtend: {
    width: '100%'
  },
  mb2: {
    marginBottom: theme.spacing(2)
  },
  mb4: {
    marginBottom: theme.spacing(4)
  },
  mt4: {
    marginTop: theme.spacing(4)
  },
  mt2: {
    marginTop: theme.spacing(2)
  },
  mt3: {
    marginTop: theme.spacing(3)
  },
  mt6: {
    marginTop: theme.spacing(6)
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  columnStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  contentVerticalSpacing: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

// const analyticsUrl = 'https://analytics.trusty.id/index.php'
// variaible con le colonne della tabella
// const producDataColumns = [
//   { title: 'Prodotto', field: 'product' },
//   { title: 'Visite', field: 'visits' }
// ]

class Analytics extends React.Component {
  constructor (props) {
    super(props)
    const temp = new Date()
    this.user = ls.get('user') || {}
    this.state = {
      owner: this.user.uuid,
      selectedProduct: '',
      analyticsLoaded: false,
      hiddenGraphDailyVisit: false,
      productData: [],
      periodData: [],
      countryData: [],
      countryDataTable: [],
      maxCountryData: 1000,
      areDataFetching: false,
      originalDate: new Date(),
      startDate: new Date(temp.setDate(temp.getDate() - (temp.getDay() || 7) + 1)),
      endDate: new Date(),
      period: 'this-week',
      showPickers: false,
      companies: [],
      products: [],
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: '',
      producDataColumns: [
        { title: props.t('totalViews.columns.product'), field: 'product' },
        { title: props.t('totalViews.columns.views'), field: 'visits', defaultSort: 'desc' }
      ]
    }

    this.onPeriodChange = this.onPeriodChange.bind(this)
    this.onProductChange = this.onProductChange.bind(this)
    this.onStartDateChange = this.onStartDateChange.bind(this)
    this.onEndDateChange = this.onEndDateChange.bind(this)
    this.getAnalytics = this.getAnalytics.bind(this)
    this.getCountriesByProduct = this.getCountriesByProduct.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
  }

  async componentDidMount () {
    try {
      // console.log('OVERVIEW currOrganization => ', currOrganization)
      // console.log('OVERVIEW LS currOrganization => ', ls.get('currOrganization'))
      // console.log('OVERVIEW organizations => ', organizations)

      const root = document.getElementById('root')

      root.className = ''
      root.classList.add('analytics')

      const productsResponse = await returnAllProducts()
      const products = productsResponse.data.data
      console.log('refresh products => ', products)
      const selectedProduct = products.length > 0 ? products[0].gtin : ''
      this.setState({ products, selectedProduct })
      // preparo i dati di analytics
      await this.getAnalytics()
      this.setState({
        analyticsLoaded: true
      })
    } catch (e) {
      console.error('Error while fetching data for analytics, e => ', e)
      this.setSnackbar('Impossibile visualizzare le statistiche', 'error')
      // alert('Impossibile visualizzare le statistiche.')
    }
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  getCountriesByProduct (data, gtin) {
    // console.log('getCountriesByProduct, data => ', data)
    // console.log('getCountriesByProduct, gtin => ', gtin)
    if (data != null && data[gtin]) {
      // const countryData = data[gtin].reverse()
      // mi preparo l'oggetto per il grafico e per la tabella
      const countryData = []
      const countryDataTable = []
      Object.keys(data).forEach(currentGtin => {
        // controllo se il gtin è del prodotto corrente o meno
        data[currentGtin].reverse().forEach(countryVisit => {
          // se è il gtin corrente aggiungo al grafico
          if (currentGtin === gtin && countryCodes[countryVisit.countryCode]) {
            // preparo i dati per il grafico
            const obj = {
              id: countryCodes[countryVisit.countryCode]['alpha-3'],
              value: Number(countryVisit.value.toFixed())
            }
            countryData.push(obj)
          }
          // preparo i dati per la tabella
          if (countryCodes[countryVisit.countryCode]) {
            const thisObjIndex = countryDataTable.findIndex(elem => elem.name === countryCodes[countryVisit.countryCode].name)
            if (thisObjIndex > -1) {
              countryDataTable[thisObjIndex].value = countryDataTable[thisObjIndex].value + Number(countryVisit.value.toFixed())
            } else {
              const tableObj = {
                id: countryCodes[countryVisit.countryCode]['alpha-3'],
                name: countryCodes[countryVisit.countryCode].name,
                value: Number(countryVisit.value.toFixed())
                // percentage: Number(countryVisit.percentage.toFixed())
              }
              countryDataTable.push(tableObj)
            }
          }
        })
      })
      console.log('countryData => ', countryData)
      // console.log('countryDataTable => ', countryDataTable)
      // estrapolo il max value e lo moltiplico per 5
      const max = Math.max.apply(Math, countryData.map(o => { return o.value }))
      const lenght = String(max).length
      const maxCountryData = Math.pow(10, lenght)
      this.setState({ countryData, maxCountryData, countryDataTable })
    }
  }

  getDiff (a, b) {
    const array = []
    const diff = []

    for (let i = 0; i < a.length; i++) {
      array[a[i]] = true
    }
    for (let i = 0; i < b.length; i++) {
      if (array[b[i]]) {
        delete array[b[i]]
      } else {
        array[b[i]] = true
      }
    }
    for (const date in array) {
      diff.push(date)
    }

    return diff
  }

  async getAnalytics () {
    this.setState({ areDataFetching: true, analyticsLoaded: false })
    const { startDate, endDate } = this.state
    // formatto le date
    // const formattedStartDate = moment(startDate.getTime()).format('YYYY-MM-DD')
    // const formattedEndDate = moment(endDate.getTime()).format('YYYY-MM-DD')
    // prendo i dati live
    // const response = await axios.get(analyticsUrl, {
    //   params: {
    //     date: `${formattedStartDate},${formattedEndDate}`,
    //     expanded: 1,
    //     filter_limit: -1,
    //     format: 'JSON',
    //     idSite: 2,
    //     method: 'Live.getLastVisitsDetails',
    //     module: 'API',
    //     period: 'day',
    //     token_auth: '744fab58915876d9d09adc8e6f411d9a'
    //   }
    // })
    const analyticsObj = await returnLastVisitsDetails(startDate, endDate)
    console.log('analyticsObj => ', analyticsObj)
    // console.log('this.state.products => ', deepCloneObj(this.state.products))
    // filtro i prodotti
    const products = this.state.products.filter(prod => prod.type && prod.type === 'finishedGood').reduce((acc, singleProd) => {
      acc.push(singleProd.gtin)

      return acc
    }, [])
    // mi prendo i dettagli delle azioni
    const actionDetails = analyticsObj.map(x => x.actionDetails).reduce((acc, x) => acc.concat(x), [])
    console.log('actionDetails => ', actionDetails)
    // visits for products in selected period
    const productData = products.map(p => ({ product: this.state.products.find(product => product.gtin === p).name, visits: actionDetails.filter(x => x.url.includes(`gtin/${p}`)).length, color: '#ff4d4d' })).sort((prod1, prod2) => (prod1.visits > prod2.visits) ? 1 : -1)
    console.log('productData => ', productData)
    // countries visits per product
    this.productsCountriesMap = products.reduce((acc, p) => {
      const productAnalytics = analyticsObj.filter(x => x.actionDetails.findIndex(a => a.url.includes(`gtin/${p}`)) > -1)
      // console.log('productAnalytics => ', productAnalytics)
      const productCountries = productAnalytics.map(x => x.country).filter((x, index, arr) => index === arr.indexOf(x))
      // console.log('productCountries => ', productCountries)
      acc[p] = productCountries.map(c => {
        const totalActionsByProduct = productAnalytics.reduce((accu, x) => accu.concat(x.actionDetails.filter(a => a.url.includes(`gtin/${p}`))), []).length
        const totalActionsByContry = productAnalytics.filter(p => p.country === c).reduce((accu, x) => accu.concat(x.actionDetails.filter(a => a.url.includes(`gtin/${p}`))), []).length
        return {
          id: c,
          label: c,
          countryCode: productAnalytics.find((elem) => elem.country === c).countryCode.toUpperCase(),
          value: totalActionsByContry,
          percentage: totalActionsByContry / totalActionsByProduct * 100,
          color: '#48b3d8'
        }
      })

      return acc
    }, {})
    // elaborate and save country data
    this.getCountriesByProduct(this.productsCountriesMap, this.state.selectedProduct !== '' ? this.state.selectedProduct : products[0])

    // visits for products in selected period indexed by date
    const periodData = products.map(p => {
      // const productsActionDetails = JSON.parse(JSON.stringify(actionDetails.filter(a => a.url.includes(`gtin/${p}`))))
      const productsActionDetails = JSON.parse(JSON.stringify(actionDetails.filter(a => a.url.includes(`gtin/${p}`) && !a.url.includes('testdiscover') && !a.url.includes('localhost'))))
      productsActionDetails.forEach(a => {
        const d = moment.unix(a.timestamp).format('DD-MM-YYYY')
        a.timestamp = d
      })
      const productName = this.state.products.find(sp => sp.gtin === p).name
      const timestamps = productsActionDetails.map(p => p.timestamp).filter((x, index, arr) => index === arr.indexOf(x))
      return {
        id: productName,
        data: timestamps.map(t => {
          return {
            x: t,
            y: productsActionDetails.filter(a => a.timestamp === t).length
          }
        }).reverse()
      }
    })
    console.log('periodData => ', periodData)

    // prende tutte le date presenti per le statistiche e le raggruppa in un unico array eliminando i duplicati
    const dates = periodData.map(period => period.data.reduce((acc, date) => {
      acc.push(date.x)
      return acc
    }, [])).flat().filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj).indexOf(obj) === pos
    })
    // normalizzo i dati riempiendo i valori nulli con 0
    const normalizedPeriodData = periodData.map((period) => {
      let data = period.data
      if (data.length <= 0) {
        data = dates.map(day => ({ x: day, y: 0 }))
      } else {
        const periodDates = data.reduce((acc, date) => {
          acc.push(date.x)
          return acc
        }, [])
        const missingDays = this.getDiff(dates, periodDates)
        data = period.data.concat(missingDays.map(day => ({ x: day, y: 0 }))).filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.x).indexOf(obj.x) === pos
        })
      }
      data.sort((a, b) => (moment(`${a.x.split('-').reverse().join('-')}T00:00:00`).unix() < moment(`${b.x.split('-').reverse().join('-')}T00:00:00`).unix()) ? -1 : 1)

      return { id: period.id, data }
    })

    console.log('normalizedPeriodData => ', normalizedPeriodData)
    this.setState({ productData, periodData: normalizedPeriodData, areDataFetching: false, analyticsLoaded: true })
  }

  onEndDateChange (date) {
    date.setHours(0, 0, 0, 0)
    this.setState({ endDate: date })
  }

  onStartDateChange (date) {
    date.setHours(0, 0, 0, 0)
    this.setState({ startDate: date })
  }

  onProductChange ({ target }) {
    const selectedProduct = target.value
    this.getCountriesByProduct(this.productsCountriesMap, selectedProduct)
    this.setState({ selectedProduct })
  }

  onPeriodChange ({ target }) {
    const { originalDate } = this.state

    const endDate = new Date(originalDate.getTime())
    const period = target.value
    const startDate = new Date(originalDate.getTime())

    let showPickers

    if (period === 'this-week') {
      startDate.setDate(startDate.getDate() - (startDate.getDay() || 7) + 1)
      showPickers = false
    } else if (period === 'this-month') {
      startDate.setDate(1)
      showPickers = false
    } else if (period === 'this-year') {
      startDate.setMonth(0, 1)
      showPickers = false
    } else if (period === 'custom') {
      showPickers = true
    }

    this.setState({ endDate, period, showPickers, startDate })
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <React.Fragment>
        {/* contenitore notifiche */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
            aria-describedby={this.state.snackbarType === 'success' ? 'success-snackbar' : 'error-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'success' ? <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} /> : <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> }
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }/>
        </Snackbar>
        {/* contenuto */}
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item xs={12} className={classNames(classes.maxExtend, classes.mb2)}>
            <Typography variant="h5">{ this.props.t('title') }</Typography>
          </Grid>
          {/* sotto menu iniziale */}
          <Grid item xs={12} className={classNames(classes.maxExtend, classes.mb4)}>
            <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={8} md={this.state.showPickers ? 4 : 10} lg={10}>
                <FormControl className={classes.maxExtend}>
                  <InputLabel id="period-select-label">{ this.props.t('inputs.period.label') }</InputLabel>
                  <Select
                    labelid="period-select-label"
                    id="period-select"
                    value={this.state.period}
                    onChange={this.onPeriodChange}
                    disabled={this.state.areDataFetching}
                  >
                    <MenuItem value="today">{ this.props.t('inputs.period.values.today') }</MenuItem>
                    <MenuItem value="this-week">{ this.props.t('inputs.period.values.this-week') }</MenuItem>
                    <MenuItem value="this-month">{ this.props.t('inputs.period.values.this-month') }</MenuItem>
                    <MenuItem value="this-year">{ this.props.t('inputs.period.values.this-year') }</MenuItem>
                    <MenuItem value="custom">{ this.props.t('inputs.period.values.custom') }</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {this.state.showPickers && <Grid item xs={12} md={6}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl className={classes.maxExtend}>
                      <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid style={{ paddingTop: 0 }} item xs={12} md={6}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="start-date-picker"
                              label="Da:"
                              value={this.state.startDate}
                              onChange={this.onStartDateChange}
                              disabled={this.state.areDataFetching}
                              KeyboardButtonProps={{
                                'aria-label': 'change start date'
                              }}
                            />
                          </Grid>
                          <Grid style={{ paddingTop: 0 }} item xs={12} md={6}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="end-date-picker"
                              label="A:"
                              value={this.state.endDate}
                              onChange={this.onEndDateChange}
                              disabled={this.state.areDataFetching}
                              KeyboardButtonProps={{
                                'aria-label': 'change end date'
                              }}
                            />
                          </Grid>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>}
              <Grid style={{ padding: '0 auto', textAlign: 'right' }} item xs={12} sm={4} md={2} lg={2}>
                <Button className='buttonUpload' style={{ width: '100%', marginTop: '5px' }} variant="contained" onClick={this.getAnalytics} disabled={this.state.areDataFetching}>
                  { this.props.t('buttons.updateData') }
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* GRAFICO VISITE GIORNALIERE */}
          {this.state.hiddenGraphDailyVisit && <Grid item xs={12} className={classNames(classes.mb4, classes.maxExtend)}>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <Card style={{ height: '500px' }} className={classNames(this.state.periodData.length !== 0 && this.state.periodData[0].data.length !== 0 ? classes.column : classes.columnStart, classes.contentVerticalSpacing)}>
                  <Typography style={{ margin: '30px 0 0 30px' }} variant="h5">
                    { this.props.t('dailyViews.title') }
                  </Typography>
                  {this.state.periodData.length !== 0 && this.state.periodData[0].data.length !== 0
                    ? <Box component="div" display={ 'block' } style={{ width: '100%', height: '100%' }}>
                      { console.log('this.state.periodData => ', this.state.periodData) }
                      <ResponsiveLine
                        data={this.state.periodData.length !== 0 ? this.state.periodData : [{ id: '', data: [{ x: '', y: '' }] }]}
                        margin={{ top: 50, right: 60, bottom: 70, left: 70 }}
                        colors={{ scheme: 'spectral' }}
                        // xScale={{ type: 'point' }}
                        xScale={{
                          type: 'time',
                          format: '%d-%m-%Y',
                          precision: 'day'
                        }}
                        xFormat="time:%d-%m-%Y"
                        axisBottom={{
                          // format: '%b %d',
                          format: '%d/%m/%y',
                          tickValues: this.state.periodData[0].data.length > 91 ? 'every month' : (this.state.periodData[0].data.length > 30 ? 'every week' : this.state.periodData[0].data.length > 9 ? 'every 2 days' : this.state.periodData[0].data.length),
                          // tickValues: this.state.periodData[0].data.length > 31 ? 12 : this.state.periodData[0].data.length > 9 ? 2 : 1,
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: this.props.t('dailyViews.xAxis'),
                          legendOffset: 40,
                          legendPosition: 'middle'
                        }}
                        yScale={{ type: 'linear', stacked: false, min: 0, max: 'auto' }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: this.props.t('dailyViews.yAxis'),
                          legendOffset: -40,
                          legendPosition: 'middle'
                        }}
                        pointSize={8}
                        pointColor={'white'}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel="y"
                        pointLabelYOffset={-12}
                        // useMesh={true}
                        enableSlices="x"
                        // legends={
                        //   isSmall
                        //     ? []
                        //     : [{
                        //       anchor: 'top',
                        //       direction: 'row',
                        //       justify: false,
                        //       translateX: 0,
                        //       translateY: -40,
                        //       itemsSpacing: 0,
                        //       itemDirection: 'left-to-right',
                        //       itemWidth: 230,
                        //       itemHeight: 24,
                        //       itemOpacity: 0.90,
                        //       symbolSize: 12,
                        //       symbolShape: 'circle',
                        //       symbolBorderColor: 'rgba(0, 0, 0, .7)'
                        //     }]
                        // }
                        sliceTooltip={({ slice }) => {
                          // console.log('slice => ', slice)
                          return (
                            <div
                              style={{
                                background: 'white',
                                padding: '9px 12px',
                                boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                                borderRadius: 2
                              }}
                            >
                              <div style={{ marginBottom: 3 }}><b>{slice.points[0].data.xFormatted}</b></div>
                              {slice.points.map(point => (
                                <div key={point.id + point.serieId} style={{ padding: '4px 0', display: 'flex', flexDirection: 'row', alignItems: 'center', justify: 'space-between' }}>
                                  <div style={{ background: point.serieColor, width: 12, height: 12, marginRight: 12, borderRadius: 2 }}></div>
                                  <span style={{ marginRight: 10 }}>{point.serieId}:</span>
                                  <b>{point.data.yFormatted}</b>
                                </div>
                              ))}
                              <div style={{ marginTop: 3 }}><b>{ this.props.t('dailyViews.tooltip.sum') }: {slice.points.reduce((acc, point) => acc + point.data.yFormatted, 0)}</b></div>
                            </div>
                          )
                        }}
                      />
                    </Box>
                    : <div style={{ width: '100%' }} className={classes.column}>
                      <img className={classes.mt6} style={{ width: '240px', alignSelf: 'center' }} src={'/images/empty.svg'} alt="Non ci sono dati per il periodo selezionato" />
                      <Typography className={classes.mt4} style={{ alignSelf: 'center', color: '#333' }} variant="body1">
                        { this.props.t('dailyViews.noData') }
                      </Typography>
                    </div>
                  }
                </Card>
              </Grid>
            </Grid>
          </Grid>}
          {/* Tabella riassuntiva visie per prodotto */}
          {this.state.analyticsLoaded
            ? <>
              <Grid item xs={12} className={classes.maxExtend}>
                <Box className={`${classNames(classes.contentVerticalSpacing, classes.mt3)} analyticsTable`}>
                  <MaterialTable
                    title={isSmall ? this.props.t('totalViews.smallTitle') : this.props.t('totalViews.title') }
                    // localization={{
                    //   toolbar: {
                    //     searchPlaceholder: 'Cerca',
                    //     searchTooltip: 'Cerca'
                    //   },
                    //   body: {
                    //     emptyDataSourceMessage: 'Non ci sono dati da mostrare.'
                    //   },
                    //   pagination: {
                    //     labelRowsSelect: 'Righe',
                    //     labelDisplayedRows: ' {from}-{to} di {count}',
                    //     firstTooltip: 'Prima pagina',
                    //     previousTooltip: 'Pagina precedente',
                    //     nextTooltip: 'Prossima pagina',
                    //     lastTooltip: 'Ultima pagina'
                    //   }
                    // }}
                    localization={this.props.t('totalViews.localization', { returnObjects: true })}
                    columns={this.state.producDataColumns}
                    data={this.state.productData}
                    options={{
                      actionsColumnIndex: -1,
                      emptyRowsWhenPaging: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 15, 30, 45],
                      showEmptyDataSourceMessage: true,
                      rowStyle: rowData => ({
                        backgroundColor: rowData.status === 'draft' ? '#F6DA8D' : (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#F9F9F9' : '')
                      })
                    }}
                  />
                  {/* <Table aria-label="products label">
                        <TableHead>
                          <TableRow>
                            <TableCell>Prodotto</TableCell>
                            <TableCell>Visite</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.productData.map((row, index) => <TableRow key={index}>
                            <TableCell align="left">{row.product}</TableCell>
                            <TableCell align="left">{row.visits}</TableCell>
                          </TableRow>)}
                        </TableBody>
                      </Table> */}
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.maxExtend}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                  {/* GRAFICO VISITE PER STATO */}
                  <Grid item xs={12}>
                    <Card style={{ height: '500px' }} className={classNames(this.state.countryData.length !== 0 ? classes.column : classes.columnStart, classes.contentVerticalSpacing)}>
                      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} style={{ width: '100%', marginBottom: 10 }}>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                          <Typography style={{ margin: '22px 0 0 30px' }} variant="h6">
                            { this.props.t('stateViews.title') }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5} lg={4}>
                          <FormControl className={classNames(classes.maxExtend, classes.mt2)}>
                            <InputLabel id="products">{ this.props.t('inputs.product') }</InputLabel>
                            <Select
                              labelid="products"
                              id="products-select"
                              value={this.state.selectedProduct}
                              onChange={this.onProductChange}
                            >
                              {this.state.products
                                .filter(prod => prod.type && prod.type === 'finishedGood')
                                .sort((a, b) => {
                                  const nameA = a.name.toUpperCase()
                                  const nameB = b.name.toUpperCase()
                                  if (nameA < nameB) {
                                    return -1
                                  }
                                  if (nameA > nameB) {
                                    return 1
                                  }
                                  // names must be equal
                                  return 0
                                })
                                .map(product => <MenuItem key={product.gtin + product.name} value={product.gtin}>{product.name}</MenuItem>)}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {this.state.countryData.length !== 0
                        ? <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ height: '88%' }}>
                          <Grid item xs={12} sm={7} lg={8} style={{ height: '100%' }}>
                            <ResponsiveChoroplethCanvas
                              data={this.state.countryData}
                              features={featureCollection.features}
                              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                              colors="nivo"
                              domain={[0, this.state.maxCountryData]}
                              unknownColor="#d4d4d4"
                              label="properties.name"
                              valueFormat=".2s"
                              projectionTranslation={[0.5, 0.5]}
                              projectionRotation={[0, 0, 0]}
                              enableGraticule={true}
                              graticuleLineColor="#dddddd"
                              borderWidth={0.5}
                              borderColor="#152538"
                              legends={[
                                {
                                  anchor: 'bottom-left',
                                  direction: 'column',
                                  justify: true,
                                  translateX: 20,
                                  translateY: -70,
                                  itemsSpacing: 0,
                                  itemWidth: 94,
                                  itemHeight: 18,
                                  itemDirection: 'left-to-right',
                                  itemTextColor: '#444444',
                                  itemOpacity: 0.85,
                                  symbolSize: 18,
                                  effects: [
                                    {
                                      on: 'hover',
                                      style: {
                                        itemTextColor: '#000000',
                                        itemOpacity: 1
                                      }
                                    }
                                  ]
                                }
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={5} lg={4} style={{ paddingRight: 16 }}>
                            <Typography style={{ marginTop: 16 }} variant="body2">
                              { this.props.t('stateViews.table.title') }
                            </Typography>
                            <Table aria-label="products label">
                              <TableHead>
                                <TableRow>
                                  <TableCell>{ this.props.t('stateViews.table.stateColumn') }</TableCell>
                                  <TableCell align="center">{ this.props.t('stateViews.table.viewsColumn') }</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.countryDataTable.map((state, index) => <TableRow key={index + state.name}>
                                  <TableCell align="left">{state.name}</TableCell>
                                  <TableCell align="center">{state.value}</TableCell>
                                </TableRow>)}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                        : <div style={{ width: '100%' }} className={classes.column}>
                          <img className={classes.mt6} style={{ width: '160px', alignSelf: 'center' }} src={'/images/empty.svg'} alt="Non ci sono dati per il periodo selezionato" />
                          <Typography className={classes.mt4} style={{ alignSelf: 'center', color: '#333' }} variant="body1">
                            { this.props.t('stateViews.noData') }
                          </Typography>
                        </div>
                      }
                    </Card>
                  </Grid>
                  {/* GRAFICO VISITE PER PRODOTTO */}
                  {/* <Grid item xs={12} lg={5}>
                        <Card style={{ height: '500px' }} className={classNames(this.state.productData.length !== 0 && this.state.productData.some(prod => prod.visits > 0) ? classes.column : classes.columnStart, classes.contentVerticalSpacing)}>
                          <Typography style={{ margin: '46px 0 0 50px' }} variant="h6">Visite per prodotto</Typography>
                          {this.state.productData.length !== 0 && this.state.productData.some(prod => prod.visits > 0) ? <ResponsiveBar
                            data={this.state.productData}
                            keys={['visits']}
                            indexBy="product"
                            margin={{ top: 10, right: 40, bottom: 40, left: 120 }}
                            padding={0.3}
                            layout="horizontal"
                            colors={colors[3]}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={{
                              tickSize: 0,
                              tickPadding: 5,
                              tickRotation: 0
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelSize={9}
                            animate={true}
                            enableGridX={false}
                            enableGridY={false}
                            motionStiffness={90}
                            motionDamping={15}
                          /> : <div style={{ width: '100%' }} className={classes.column}>
                            <img className={classes.mt6} style={{ width: '160px', alignSelf: 'center' }} src={'/images/empty.svg'} alt="Non ci sono dati per il periodo selezionato" />
                            <Typography className={classes.mt4} style={{ alignSelf: 'center', color: '#333' }} variant="body1">Non ci sono dati per il periodo selezionato</Typography>
                          </div>
                          }
                        </Card>
                      </Grid> */}
                </Grid>
              </Grid>
            </> : <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
              <Grid item>
                <LogoLoader
                  size='large'
                  text={this.props.t('notifications.downloadingAnalytics')}
                >
                </LogoLoader>
              </Grid>
            </Grid>}

        </Grid>
      </React.Fragment>
    )
  }
}

Analytics.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('dashboard')(Analytics))))
