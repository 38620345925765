import {
  Grid,
  DialogContentText,
  DialogActions,
  Button,
  withStyles,
  withWidth,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { getMetadataForResource, modifyMetadata } from '../../utils/supplychains'
import { deepCloneObj } from '../../utils/utils'

import React from 'react'
import PropTypes from 'prop-types'

const styles = theme => ({
  '@global': {
    '.MuiDialog-root': {
      minWidth: '80%',
      minHeight: 300
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    overflow: 'scroll'
  },
  modalSize: {
    width: 300,
    height: 300,
    backgroundColor: 'red'
  },
  progressStyle: {
    color: theme.primary
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 'auto',
    outline: 0
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  navBar: {
    color: 'white'
  },
  tableContainer: {
    marginTop: 16
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  paperDialog: {
    maxHeight: '70vh'
  },
  imgInput: {
    display: 'none'
  },
  imgCover: {
    // width: 'auto',
    // height: '250px',
    margin: '10px',
    width: 400,
    height: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  modifyImgButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  textfieldPrimary: {
    color: theme.primary
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  dialogPaper: {
    minHeight: 200,
    maxHeight: 500
  },
  paperRootMiddleSize: {
    maxHeight: '700px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})
class PrivilegeModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: this.props.open,
      companies: this.props.companies
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount () {
    const companies = deepCloneObj(this.state.companies)
    for (const company of companies) {
      const resourceResponse = await getMetadataForResource(company.organizationId, this.props.supplychainId)
      const resource = resourceResponse.data.data
      company.metadata = resource.metadata
      company.metadata.hidden = resource.metadata.hidden ? resource.metadata.hidden : false // per ovviare al fatto che se hidden non è definito in metadata da problemi con lo SWITCH per uncontrolled change value
    }
    this.setState({
      companies
    }, () => {
      console.log('comp this state::', this.state.companies)
    })
  }

     handleClose = () => {
       this.props.onClose()
     }

     async handleChange (event, uuid) {
       const companies = deepCloneObj(this.state.companies)
       const company = companies.find(comp => comp.uuid === uuid)
       company.metadata[event.target.name] = event.target.checked
       this.setState({
         companies
       }, async () => {
         await modifyMetadata(company.organizationId, this.props.supplychainId, company.metadata)
       })
     }

     render () {
       const classes = this.props.classes
       return (
         <>
           <Dialog
             className={'mailModal'}
             classes={{ paper: classes.dialogPaper }}
             onClose={this.handleClose}
             aria-labelledby="simple-dialog-title"
             open={this.state.open}
             fullWidth={true}
             maxWidth={'sm'}
           >
             <DialogTitle id="simple-dialog-title">{this.props.t('manage.privilegeModal.title')}</DialogTitle>
             <DialogContent>
               <DialogContentText>
                 {this.state.companies && this.state.companies.length === 0
                   ? `${this.props.t('manage.privilegeModal.descriptionNoCompanies')}`
                   : `${this.props.t('manage.privilegeModal.description')}`
                 }
               </DialogContentText>
               <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                 <Grid item xs={12} style={{ width: '100%' }}>
                   <List>
                     {this.state.companies.map(comp => {
                       return (
                         <ListItem
                           key={comp.uuid}>
                           <ListItemText
                             primary={comp.name}
                           />
                           <ListItemSecondaryAction>
                             <Switch
                               checked={comp.metadata.hidden}
                               onChange={(event) => this.handleChange(event, comp.uuid)}
                               name="hidden"
                               inputProps={{ 'aria-label': 'secondary checkbox' }}
                             />
                           </ListItemSecondaryAction>
                         </ListItem>)
                     })}

                   </List>
                 </Grid>
               </Grid>
             </DialogContent>
             <DialogActions>
               <Button onClick={this.handleClose} color="primary">
                 {this.props.t('manage.privilegeModal.cancel')}
               </Button>
             </DialogActions>
           </Dialog>
         </>
       )
     }
}

PrivilegeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  supplychainId: PropTypes.string,
  companies: PropTypes.array,
  domain: PropTypes.object
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('supplychain')(PrivilegeModal))))
