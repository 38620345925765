import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Button,
  Card,
  CardActionArea,
  CardHeader,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  Box,
  Snackbar,
  SnackbarContent,
  withWidth,
  Tooltip,
  IconButton,
  CardContent
} from '@material-ui/core'

import classNames from 'classnames'
import CreateIcon from '@material-ui/icons/Create'
import Pagination from 'material-ui-flat-pagination'
import MaterialTable from 'material-table'
import { isLocalhost } from '../../serviceWorker'
import organizationsService from '../Organizations.service'
import moment from 'moment'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import { returnSomeLots, getLastInputOrOutputInformation, duplicateLot, createDefaultLot } from '../../utils/lots'
import { returnAllCompanies } from '../../utils/companies'
import { returnAllProductsProjection } from '../../utils/products'
// import { deepCloneObj } from '../../utils/utils'

import configuration from '../../configuration'
import CardModalLot from '../Modal/CardModalLot'
// import FormDialogLot from '../../components/FormDialogLot/FormDialogLot.component'
import FabWithLoader from '../../components/FabWithLoader/FabWithLoader'
import {
  Search as SearchIcon,
  // DeleteForever as DeleteForeverIcon,
  ExitToApp as ExitToAppIcon,
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  Close as CloseIcon,
  Cancel,
  Info as InfoIcon,
  CloudDownload as DownloadIcon
} from '@material-ui/icons'
import { domainToLogos } from '../../domainToLogos'
import { green, amber, grey, blue } from '@material-ui/core/colors'
import QRCode from 'qrcode.react'

const styles = theme => ({
  '@global': {
    '.MuiInputBase-input': {
      padding: '6px 0 4px'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    },
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  addButton: {
    background: '#C0EDCB',
    color: 'white'
  },
  progressStyle: {
    color: theme.primary
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: 'white',
    width: '100%'
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#3C4858!important'
  },
  buttonGrid: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  card: {
    cursor: 'pointer',
    minWidth: 275
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 14
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[700]
  },
  info: {
    backgroundColor: blue[500]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  currentQrdescription: {
    color: grey[600]
  },
  lastLotcard: {
    padding: '24px 16px'
  },
  actionButton: {
    marginRight: theme.spacing(2)
  },
  latestQrButton: {
    color: theme.primary
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }

})

class Lots extends React.Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.user = ls.get('user') || {}

    // if (!props.guidedLoaded) { window.userGuiding.hideChecklist() }

    this.state = {
      // loadedGuide: false,
      areLotsFetching: false,
      areSearchingForLots: false,
      noSearchLotsFound: false,
      areProductsFetching: true,
      isProductLotOpened: false,
      isConfirmDialogOpened: false,
      isDeleting: false,
      isLoadingInfoProducts: false,
      isFels: this.props.isFels,
      companiesIds: [],
      // isFormDialogOpened: false,
      lastSearchCriteria: '',
      limit: 20,
      lot: {},
      lots: [],
      searchedLots: [],
      offset: 0,
      originalLots: [],
      owner: this.user.uuid,
      page: 0,
      productOfLot: '',
      products: [],
      productsIdNameMap: {},
      isAllProductSelected: true,
      selectedProductId: '',
      lotNumber: null,
      howSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: '',
      lotsColumns: [
        { title: this.props.t('lotsColumns.lotNumber'), render: rowData => rowData.lotNumber && rowData.lotNumber !== '' ? rowData.lotNumber : rowData.lastInputOrOutputInformation.lotNumber },
        { title: this.props.t('lotsColumns.status'), field: 'status', lookup: { active: 'Attivo', draft: 'Bozza' } },
        { title: this.props.t('lotsColumns.quantity'), field: 'lastInputOrOutputInformation.quantity' },
        { title: this.props.t('lotsColumns.expirationDate'), field: 'expirationDate', render: rowData => rowData.expirationDate ? moment(rowData.expirationDate).format('DD/MM/YYYY') : '/' }
      ],
      lotsMobileColumns: [
        { title: this.props.t('lotsColumns.lotNumber'), field: 'lotNumber', render: rowData => rowData.lotNumber && rowData.lotNumber !== '' ? rowData.lotNumber : rowData.lastInputOrOutputInformation.lotNumber },
        { title: this.props.t('lotsColumns.status'), field: 'status', render: rowData => rowData.status === 'active' ? 'Attivo' : 'Bozza' },
        { title: this.props.t('lotsColumns.quantity'), field: 'lastInputOrOutputInformation.quantity' }
      ],
      lotsColumnsLastProducts: [
        { title: this.props.t('lotsColumns.lotNumber'), render: rowData => rowData.lotNumber && rowData.lotNumber !== '' ? rowData.lotNumber : rowData.lastInputOrOutputInformation.lotNumber },
        { title: this.props.t('lotsColumns.productName'), field: 'productName' },
        { title: this.props.t('lotsColumns.status'), field: 'status', render: rowData => rowData.status === 'active' ? 'Attivo' : 'Bozza' },
        { title: this.props.t('lotsColumns.quantity'), field: 'lastInputOrOutputInformation.quantity' },
        { title: this.props.t('lotsColumns.expirationDate'), field: 'expirationDate', render: rowData => rowData.expirationDate ? moment(rowData.expirationDate).format('DD/MM/YYYY') : '/' }
      ],
      lotsMobileColumnsLastProducts: [
        { title: this.props.t('lotsColumns.lotNumber'), field: 'lotNumber', render: rowData => rowData.lotNumber && rowData.lotNumber !== '' ? rowData.lotNumber : rowData.lastInputOrOutputInformation.lotNumber },
        { title: this.props.t('lotsColumns.productName'), field: 'productName' },
        { title: this.props.t('lotsColumns.status'), field: 'status', render: rowData => rowData.status === 'active' ? 'Attivo' : 'Bozza' }

      ],
      frontEndUrl: isLocalhost ? configuration.frontendBaseUrl : (this.props.domain.domain === 'trusty' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}`)
      // frontEndUrl: this.props.domain.domain === 'trusty' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}` /* fare attenzione che in caso di più topLevelsDomain (eg:co.uk) bisogna gestire */
      // frontEndUrl: this.props.domain.type === 'INVALID' ? configuration.frontendBaseUrl : `${domainToLogos[this.props.domain.domain].frontendBaseUrl}` /* fare attenzione che in caso di più topLevelsDomain (eg:co.uk) bisogna gestire */
    }

    this.changePage = this.changePage.bind(this)
    this.closeLotModal = this.closeLotModal.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.duplicateLot = this.duplicateLot.bind(this)
    // this.closeFormDialog = this.closeFormDialog.bind(this)
    this.filterLotsViaText = this.filterLotsViaText.bind(this)
    this.openLotModal = this.openLotModal.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    // this.openFormDialog = this.openFormDialog.bind(this)
    this.productHasChanged = this.productHasChanged.bind(this)
    this.productOfLotChanged = this.productOfLotChanged.bind(this)
    this.refreshLots = this.refreshLots.bind(this)
    this.refreshProducts = this.refreshProducts.bind(this)
    this.searchCriteriaChanged = this.searchCriteriaChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.searchLots = this.searchLots.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
    this.openNewLotDialog = this.openNewLotDialog.bind(this)
    this.setProductConfirmDialog = this.setProductConfirmDialog.bind(this)
    this.openUrl = this.openUrl.bind(this)
    this.receivingFromChild = this.receivingFromChild.bind(this)
    this.createNewDefaultLot = this.createNewDefaultLot.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    await this.refreshProducts()

    /* default ultimi lotti */
    await this.refreshLots()
    // const history = useHistory()
    // const userData = ls.get('user')

    // if (this.props.guidedLoaded) {
    //   const response = await axios({
    //     method: 'GET',
    //     url: `${configuration.apiBaseUrl}/accounts/self/onboardings/last`,
    //     headers: {
    //       authorization: `Bearer ${ls.get('token')}`
    //     }
    //   })

    //   const lastOnboarding = response.data.data
    //   // Quando la guida non esiste e gli step non sono stati completati
    //   if (!lastOnboarding || (!lastOnboarding.completed && !lastOnboarding.guides[onboardingConfig.lots.id])) {
    //     let dataToSend = lastOnboarding
    //     if (!dataToSend) {
    //       dataToSend = {
    //         accountId: userData.uuid,
    //         guides: {
    //           [onboardingConfig.lots.id]: {
    //             steps: []
    //           }
    //         },
    //         completed: false
    //       }
    //     }

    //     // window.userGuidingLayer.push({
    //     //   event: 'onload',
    //     //   fn: () => this.setState({ loadedGuide: true })
    //     // })

    //     // window.userGuidingLayer.push({
    //     //   event: 'onPreviewStart',
    //     //   fn: () => this.setState({ loadedGuide: true })
    //     // })

    //     window.userGuiding.previewGuide(onboardingConfig.lots.id)

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewStep',
    //       fn: async data => {
    //         const d = new Date()
    //         if (!Object.prototype.hasOwnProperty.call(dataToSend.guides, onboardingConfig.lots.id)) {
    //           dataToSend.guides[onboardingConfig.lots.id] = { steps: [] }
    //         }

    //         if (typeof dataToSend.guides[onboardingConfig.lots.id].steps[data.stepIndex] !== 'object') {
    //           dataToSend.guides[onboardingConfig.lots.id].steps[data.stepIndex] = {}
    //         }

    //         dataToSend.guides[onboardingConfig.lots.id].steps[data.stepIndex].startedAt = d
    //         if (data.stepIndex > 0) {
    //           dataToSend.guides[onboardingConfig.lots.id].steps[data.stepIndex - 1].completedAt = d
    //         }

    //         if (lastOnboarding) {
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${lastOnboarding.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         } else {
    //           const response = await axios({
    //             method: 'POST',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })

    //           dataToSend = response.data.data
    //         }
    //       }
    //     })

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewEnd',
    //       fn: async data => {
    //         if (data.completed) {
    //           const { steps } = dataToSend.guides[onboardingConfig.lots.id]
    //           steps[steps.length - 1].completedAt = new Date()
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${dataToSend.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         }
    //       }
    //     })
    //   } else {
    //   // this.setState({ loadedGuide: true })
    //   }

    // // // Quando la guida è completa
    // // if (lastOnboarding.guides[onboardingConfig.lots.id] && lastOnboarding.guides[onboardingConfig.lots.id].steps.length === onboardingConfig.lots.steps) {
    // //   history.push('/')
    // // }
    // // }
    // }
  }

  async componentDidUpdate (prevProps, prevState) {
    if (prevState.selectedProductId !== this.state.selectedProductId) {
      await this.refreshLots()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  changePage (offset, page) {
    this.setState({ offset, page: page - 1 })
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  async closeLotModal (shouldIRefresh) {
    this.setState({ isProductLotOpened: false })
    console.log('I should refresh ', shouldIRefresh)
    if (shouldIRefresh === true) {
      await this.refreshLots()
      this.forceUpdate(async () => {
        this.setState({ lastSearchCriteria: '' })
        await this.searchLots(this.state.lastSearchCriteria)
      })
    }
  }

  async duplicateLot (uuid) {
    try {
      await duplicateLot(uuid)
      await this.refreshLots()
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false, isDeleting: false })
  }

  // funzione che setta il prodotto selezionato
  async setProductConfirmDialog () {
    // await new Lot for selected product

    this.setState({
      isConfirmDialogOpened: false
      // isFormDialogOpened: true
    }, // ci assicura che la dialog sia chiusa
    async () => {
      this.createNewDefaultLot()
    })
  }

  // funzione che ritorna il valore del gtin del prodotto selezionato
  getSelectedProductGtin () {
    const prodUuid = this.state.selectedProductId
    if (prodUuid) {
      const product = this.state.products.find(prod => prod.uuid === prodUuid)
      return product.gtin
    }

    return null
  }

  // async closeFormDialog (shouldIRefresh) {
  //   this.setState({ isFormDialogOpened: false })
  //   if (shouldIRefresh === true) {
  //     await this.refreshLots()
  //   }
  // }

  productHasChanged ({ target }) {
    this.setState({ selectedProductId: target.value })
  }

  // funzione che filtra i lotti scaricati in base ad una stringa
  filterLotsViaText (searchCriteria) {
    const doesLotMatchCriteria = lot => Object.keys(lot).some(key => {
      if (typeof lot[key] === 'object') {
        return doesLotMatchCriteria(lot[key])
      }

      const value = String(lot[key])
      return value.toLowerCase().includes(searchCriteria.toLowerCase())
    })

    if (searchCriteria.length) {
      const filtered = this.state.originalLots.filter(doesLotMatchCriteria)
      this.setState({ lots: filtered, offset: 0, page: 0 })
    } else {
      this.setState({ lots: this.state.originalLots, offset: 0, page: 0 })
    }
  }

  // funzione che aggiorna i lotti
  async refreshLots () {
    const root = document.getElementById('root')
    root.className = ''
    root.classList.add('lots')

    console.log('Sto qua in refresh lots')
    if (this.state.selectedProductId === '') {
      // QUI FARE LA CHIAMATA PER GLI ULTIMI LOTTI INSERITI
      const organization = organizationsService.getCurrOrganization()
      try {
        this.setState({ areLotsFetching: true })
        const lotsParam = {
          skip: '0',
          limit: '10',
          filter: JSON.stringify({ organizationId: organization.uuid })
        }
        const lotsResponse = await returnSomeLots(lotsParam)
        console.log('GLi ultimi dieci lotti sono loro', lotsResponse)
        const lots = lotsResponse.data.data

        /* capire questione del lot status */
        lots.forEach(lot => {
          const product = this.state.products.find(product => product.uuid === lot.productId)
          lot.productName = this.state.productsIdNameMap[lot.productId]
          lot.lastInputOrOutputInformation = getLastInputOrOutputInformation(lot, product, this.state.productsIdNameMap)
        })
        // lots.forEach(lot => {
        //   if (!lot.status) {
        //     lot.status = 'active'
        //   }
        //   lot.lastInputOrOutputInformation = getLastInputOrOutputInformation(lot, product, this.state.productsIdNameMap)
        // })
        console.log('refresh lots =>', lots)
        this.setState({ areLotsFetching: false, lots, originalLots: lots })
      } catch (e) {
        console.error('Error while refreshing lots: ', e)
        this.setSnackbar(this.props.t('notifications.lotsError'), 'error')
      }
    } else {
      console.log('selectedProductId: ', this.state.selectedProductId)

      try {
        this.setState({ areLotsFetching: true })
        const product = this.state.products.find(product => product.uuid === this.state.selectedProductId)
        const lotsParam = {
          filter: JSON.stringify({ productId: product.uuid, status: { $ne: 'deleted' }, limit: Number.MAX_SAFE_INTEGER })
        }
        const lotsResponse = await returnSomeLots(lotsParam)
        const lots = lotsResponse.data.data

        lots.forEach(lot => {
          if (!lot.status) {
            lot.status = 'active'
          }
          lot.lastInputOrOutputInformation = getLastInputOrOutputInformation(lot, product, this.state.productsIdNameMap)
        })
        console.log('refresh lots =>', lots)
        this.setState({ areLotsFetching: false, lots, originalLots: lots })
      } catch (e) {
        console.error('Error while refreshing lots: ', e)
        this.setSnackbar(this.props.t('notifications.lotsError'), 'error')
      // alert('Impossibile ricaricare i lotti.')
      }
    }
  }

  // DEPRECATA funzione che salva il valore del prodotto per cui creare nuovo lotto
  productOfLotChanged ({ target }) {
    this.setState({ productOfLot: target.value })
  }

  async refreshProducts () {
    try {
      const companiesResponse = await returnAllCompanies()
      const companies = companiesResponse.data.data
      const companiesIds = companies.map(company => company.uuid)
      console.log('new companies => ', companies)
      console.log('new companiesIds => ', companiesIds)
      const productFields = {
        fields: 'name,status,uuid,gtin,type,companyId',
        limit: Number.MAX_SAFE_INTEGER
      }
      const productsResponse = await returnAllProductsProjection(productFields)
      // const productsResponse = await returnAllProducts()
      const allProducts = productsResponse.data.data
      console.log('new allProducts => ', allProducts)
      const products = allProducts.filter(product => product.type === 'finishedGood')
      products.forEach(product => {
        const company = companies.find(c => c.uuid === product.companyId)
        product.companyData = company
      })

      const productsIdNameMap = allProducts.reduce((acc, product) => {
        acc[product.uuid] = product.name
        return acc
      }, {})

      if (this._isMounted) {
        const currProduct = products.length > 0 ? products[0].gtin : ''
        console.log('new currProduct => ', currProduct)
        console.log('new products => ', products)
        console.log('new productsIdNameMap => ', productsIdNameMap)
        this.setState({ areProductsFetching: false, productOfLotsToDelete: currProduct, products, productsIdNameMap, companiesIds })
      }
    } catch (e) {
      console.log('Error while refreshing products: ', e)
      this.setSnackbar(this.props.t('notifications.productsError'), 'error')
    }
  }

  // funzione che resetta il search
  resetSearch () {
    this.setState({ lastSearchCriteria: '', searchLots: [], noSearchLotsFound: false })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode () {
    // creo link fittizio
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che cerca i lotti in base al campo di ricerca
  async searchLots (lotNumToSearch) {
    console.log('Sto qua')
    // console.log('lotNumToSearch => ', lotNumToSearch)
    if (lotNumToSearch === '') {
      // QUI FARE LA CHIAMATA PER GLI ULTIMI LOTTI INSERITI
      // created at 2020-11-24T14:33:41.880Z
      const lotsParam = {
        skip: '0',
        limit: '10'
      }
      const lotsResponse = await returnSomeLots(lotsParam)
      console.log('GLi ultimi dieci lotti sono loro', lotsResponse)
      return lotsResponse
    }
    if (lotNumToSearch.length > 0) {
      try {
        this.setState({ areSearchingForLots: true })
        // mi prendo le aziende
        const companiesIds = this.state.companiesIds
        const lotsParam = {
          filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' }, limit: Number.MAX_SAFE_INTEGER })
        }
        const lotsResponse = await returnSomeLots(lotsParam)
        // const lotsResponse = await axios.get(`${configuration.apiBaseUrl}/lots/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        //   }
        // })

        // console.log('new lotsResponse.data.data =>', lotsResponse.data.data)
        // console.log('this.state.products =>', this.state.products)
        // console.log('this.state.productsIdNameMap =>', this.state.productsIdNameMap)
        let searchedLots = lotsResponse.data.data
        searchedLots.forEach(lot => {
          lot.lastInputOrOutputInformation = getLastInputOrOutputInformation(lot, this.state.products.find(product => product.uuid === lot.productId), this.state.productsIdNameMap)
        })
        searchedLots = searchedLots.filter(lot => {
          return lot.lotNumber && lot.lotNumber.toLowerCase().includes(lotNumToSearch.toLowerCase())
        })
        console.log('new searchedLots =>', searchedLots)
        let noSearchLotsFound = false
        if (searchedLots.length === 0) {
          noSearchLotsFound = true
        }
        this.setState({ areSearchingForLots: false, searchedLots, noSearchLotsFound })
      } catch (err) {
        console.error('Error while searching lots, err => ', err)
        this.setSnackbar(this.props.t('notifications.searchError'), 'error')
      }
    }
  }

  // funzione lanciata al change dell'input di cerca
  async searchCriteriaChanged ({ target }) {
    console.log('questo è il famoso search', target.value)
    this.setState({ lastSearchCriteria: target.value })
    await this.searchLots(target.value)
    // this.filterLotsViaText(target.value)
  }

  openLotModal (lot, lotNumber) {
    this.setState({ lot, lotNumber, isProductLotOpened: true })
  }

  // apre conferma prodotto per nuovo lotto
  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  // apre modal new lotto
  // openFormDialog () {
  //   this.setState({ isFormDialogOpened: true })
  // }

  receivingFromChild (bool) {
    this.setState({ isLoadingInfoProducts: bool }, () => {
      console.log('ora ho modificato lo stato', this.state.isLoadingInfoProducts)
    })
  }

  // funzione che verifica se un prodotto è archiviato o meno
  isProductArchived (prodId) {
    const selectedProduct = this.state.products.find(prod => prod.uuid === prodId)
    return selectedProduct.status === 'archived'
  }

  async createNewDefaultLot () {
    await createDefaultLot(this.state.selectedProductId)
    await this.refreshLots()
  }

  // funzione che viene lanciata all'aggiunta di un lotto
  async openNewLotDialog () {
    console.log('this.state.selectedProductId => ', this.state.selectedProductId)
    if (this.state.selectedProductId === '') {
      this.openConfirmDialog()
    } else {
      if (!this.isProductArchived(this.state.selectedProductId)) {
        await this.createNewDefaultLot()
      } else {
        this.setSnackbar(this.props.t('notifications.createArchivedProductError'), 'warning')
      }
    }
  }

  // funzione che apre un link
  openUrl (url) {
    // console.log('url =>', url)
    window.open(url, '_blank')
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <div>
        {/* <Loader
          show={!this.state.loadedGuide}
          // text= {this.props.t('guide.loading')}
        >
        </Loader> */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : this.state.snackbarType === 'info' ? classes.info : classes.success }
            aria-describedby={this.state.snackbarType === 'error' ? 'error-snackbar' : this.state.snackbarType === 'warning' ? 'warning-snackbar' : 'success-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'error' || this.state.snackbarType === 'warning' ? <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> : this.state.snackbarType === 'success' ? <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} /> : <InfoIcon className={classNames(classes.icon, classes.iconVariant)} /> }
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }
          />
        </Snackbar>
        {/* dialog scelta prodotto per il nuovo lotto */}
        <Dialog className="lotModal"
          open={this.state.isConfirmDialogOpened}
          // onClose={this.openFormDialog}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{ this.props.t('dialog.chooseProduct.title') }</DialogTitle>
          <DialogContent>
            <DialogContentText>{ this.props.t('dialog.chooseProduct.description') }</DialogContentText>
            <Select
              id="product-of-lot"
              value={this.state.selectedProductId}
              onChange={this.productHasChanged}
              displayEmpty
              name="product-of-lot"
              className={classes.selectEmpty}
              style={{ width: '100%' }}
            >
              {this.state.products.filter(prod => prod.status !== 'archived')
                .sort((a, b) => {
                  const nameA = a.name.toUpperCase()
                  const nameB = b.name.toUpperCase()
                  if (nameA < nameB) {
                    return -1
                  }
                  if (nameA > nameB) {
                    return 1
                  }
                  // names must be equal
                  return 0
                })
                .map(product => (
                  <MenuItem key={product.uuid} value={product.uuid}>
                    {product.name}
                  </MenuItem>
                ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeConfirmDialog} color="secondary" autoFocus>
              { this.props.t('dialog.chooseProduct.buttons.cancel') }
            </Button>
            <Button onClick={this.setProductConfirmDialog} color="primary" disabled={this.state.selectedProductId === ''}>
              { this.props.t('dialog.chooseProduct.buttons.next') }
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.isProductLotOpened ? (<CardModalLot
          setSnackbar={this.setSnackbar}
          isFels={this.state.isFels}
          lot={this.state.lot}
          lotNumber={this.state.lotNumber}
          open={this.state.isProductLotOpened}
          onCloseModal={this.closeLotModal}
          productsIdNameMap = {this.state.productsIdNameMap}
          frontEndUrl={this.state.frontEndUrl} />)
          : null}
        {/* {this.state.isFormDialogOpened ? (<FormDialogLot
          setSnackbar={this.setSnackbar}
          passDataTo = {this.receivingFromChild}
          open={this.state.isFormDialogOpened}
          productId={this.state.selectedProductId}
          products={this.state.products}
          frontEndUrl={this.state.frontEndUrl}
          onCloseDialog={this.closeFormDialog}
          productsIdNameMap = {this.state.productsIdNameMap}/>
        ) : null} */}
        {/* CONTENT */}
        <Grid container direction="row" justify="flex-start" alignItems="center" className={`${classes.root} filterRoot`}>
          <Grid item xs={10} sm={11} md={11} lg={11}>
            <Box>
              <Paper className={classes.paper}>
                <TextField value={this.state.lastSearchCriteria} className={`${classes.input} searchField`} placeholder={this.props.t('inputs.searchLot')} inputProps={{ 'aria-label': this.props.t('inputs.searchLot') }} onChange={this.searchCriteriaChanged} />
                {this.state.lastSearchCriteria === ''
                  ? <SearchIcon className={classes.searchIcon} />
                  : <CloseIcon className={classes.searchIcon} onClick={this.resetSearch} />
                }
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={2} sm={1} md={1} lg={1} style={{ textAlign: 'right' }}>
            {/* <Tooltip title={this.props.t('buttons.addLot')}>
              <Fab
                size="medium"
                aria-label={this.props.t('buttons.addLot')}
                variant="round"
                className={classes.addButton}
                onClick={this.openNewLotDialog}
                // disabled={this.state.selectedProductId === ''}
              >
                <AddCircleIcon />
              </Fab>
            </Tooltip> */}
            <FabWithLoader
              title={this.props.t('buttons.addLot')}
              size="medium"
              className={classes.addButton}
              aria-label={this.props.t('buttons.addLot')}
              variant="round"
              onClick={this.openNewLotDialog}
              loader = {this.state.isLoadingInfoProducts}
            >
            </FabWithLoader>
          </Grid>
        </Grid>
        {/* contenuto normale */}
        <Grid container direction="column">
          {/* primo grid item */}
          {this.state.lastSearchCriteria === ''
            ? <Grid container alignItems="center" justify="flex-start" className={classes.root}>
              {this.state.areProductsFetching
                ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={this.props.t('downloadingLots')}
                    >
                    </LogoLoader>
                  </Grid>
                </Grid>
                : <Grid item xs={12} sm={12} md={6} lg={6} style={{ marginBottom: 20 }}>
                  <FormControl fullWidth>
                    {/* <InputLabel htmlFor="selectedProductId">{this.props.t('inputs.selectProduct')}</InputLabel> */}
                    <Select displayEmpty value={this.state.selectedProductId} onChange={this.productHasChanged} name="selectedProductId"
                      inputProps={{ id: 'selectedProductId' }}>
                      <MenuItem key='allProducts' value=''><span style={{ color: '#AEAEAE' }}>{this.props.t('lotSelect.productSelect')}</span></MenuItem>
                      {this.state.products
                        .filter(prod => prod.status !== 'draft')
                        .sort((a, b) => {
                          const nameA = a.name.toUpperCase()
                          const nameB = b.name.toUpperCase()
                          if (nameA < nameB) {
                            return -1
                          }
                          if (nameA > nameB) {
                            return 1
                          }
                          // names must be equal
                          return 0
                        })
                        .map(product => {
                          return product.status === 'archived' ? (
                            <MenuItem key={product.uuid}
                              value={product.uuid}><i>{product.name} <b>(Prodotto Archiviato)</b></i></MenuItem>
                          ) : (
                            <MenuItem key={product.uuid}
                              value={product.uuid}>{product.name} ({product.companyData.name})</MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              }
            </Grid>
            : ''
          }
          {/* {this.state.selectedProductId === '' && this.state.lastSearchCriteria === '' && (
            <Grid item xs>
              <Typography>Per favore seleziona un prodotto per visualizzare i suoi lotti.</Typography>
            </Grid>
          )} */}
          {/* secondo grid item */}
          <Grid item xs>
            {this.state.lastSearchCriteria !== '' && !this.state.areSearchingForLots && !this.state.noSearchLotsFound && this.state.searchedLots.length > 12 && <Pagination
              limit={this.state.limit}
              offset={this.state.offset}
              total={this.state.lots.length}
              onClick={(e, offset, page) => this.changePage(offset, page)}
              style={{ textAlign: 'center', paddingBottom: 6, paddingTop: 4 }}
            />}
          </Grid>
          {/* terzo grid item */}
          <Grid container className={classes.grid} spacing={3}>
            {this.state.lastSearchCriteria !== ''
              ? (this.state.areSearchingForLots
                ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={this.props.t('searchingLots')}
                    >
                    </LogoLoader>
                  </Grid>
                </Grid>
                : (this.state.noSearchLotsFound
                  ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                    <Grid item style={{ marginTop: 20 }}>
                      <Typography>{this.props.t('noLotsFount')}.</Typography>
                    </Grid>
                  </Grid>
                  : this.state.searchedLots
                    .slice(this.state.page * this.state.limit, this.state.page * this.state.limit + this.state.limit)
                    .map(lot => {
                      return (
                        <Grid key={lot.uuid} item xs={12} md={6} lg={4} xl={3} className={classes.cardContainer}>
                          <Card className={classes.card} style={{ backgroundColor: lot.status === 'draft' ? '#eaeaea' : '' }}>
                            <div className='innerCard' style={isSmall ? {} : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Tooltip title={this.props.t('facilitiesList.tooltip.buttons.edit')}>
                                <CreateIcon className='editIcon' onClick={() => this.openLotModal(lot)}></CreateIcon>
                              </Tooltip>
                              {/* <CardActionArea onClick={() => this.openLotModal(lot, lot.lotNumber)}>
                                <CardHeader
                                  className={classes.cardHeaderContainer}
                                  avatar={<Avatar className={classes.cardAvatar} aria-label="product">L</Avatar>}
                                  title={this.props.t('archivedLot.title') + lot.lotNumber}
                                  titleTypographyProps={{ variant: 'h6', noWrap: true }}
                                  subheader={lot.lastInputOrOutputInformation.productName}
                                  subheaderTypographyProps={{ noWrap: true }}
                                />
                              </CardActionArea> */}
                              <div className='bottomCard'>
                                <CardActionArea onClick={() => this.openLotModal(lot, lot.lotNumber)}>
                                  <CardHeader
                                    className={classes.cardHeaderContainer}
                                    title={this.props.t('archivedLot.title') + lot.lotNumber}
                                    titleTypographyProps={{ variant: 'h6', noWrap: true }}
                                    subheader={lot.lastInputOrOutputInformation.productName}
                                    subheaderTypographyProps={{ noWrap: true }}
                                  />
                                </CardActionArea>
                                <CardActions style={{ paddingTop: 0 }}>
                                  <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                    {lot.status === 'draft'
                                      ? <Typography className="draftLabel" variant="body2">{this.props.t('archivedLot.draft')}</Typography>
                                      : ''
                                    }
                                    <Tooltip title={this.props.t('archivedLot.goToPage')}>
                                      <IconButton aria-label="settings" target="_blank" rel="noopener" href={`${this.state.frontEndUrl}/01/${lot.lastInputOrOutputInformation.gtin}/10/${lot.lotNumber}`} style={{ color: '#319C5A' }}>
                                        <ExitToAppIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </CardActions>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      )
                    }))
              )
              : (this.state.selectedProductId === ''
                ? (this.state.areLotsFetching
                  ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                    <Grid item>
                      {/* <LogoLoader
                        size='large'
                        text={this.props.t('downloadingLots')}
                      >
                      </LogoLoader> */}
                    </Grid>
                  </Grid>
                  : <Grid item xs={12} className={`${classes.cardContainer} lotsTable`}>
                    <MaterialTable
                      title={this.props.t('lotTable.titleLastAddedLots')}
                      localization={{
                        toolbar: this.props.t('lotTable.localization.toolbar', { returnObjects: true }),
                        body: {
                          emptyDataSourceMessage: this.state.selectedProductId !== '' ? this.props.t('lotTable.localization.body.emptyDataSourceMessage') : this.props.t('lotTable.localization.body.emptyDataSourceMessage2')
                        },
                        pagination: this.props.t('lotTable.localization.pagination', { returnObjects: true }),
                        header: this.props.t('lotTable.localization.header', { returnObjects: true })
                      }}
                      columns={isSmall ? this.state.lotsMobileColumnsLastProducts : this.state.lotsColumnsLastProducts}
                      data={this.state.lots}
                      options={{
                        actionsColumnIndex: -1,
                        emptyRowsWhenPaging: false,
                        pageSize: 10,
                        pageSizeOptions: [10],
                        showEmptyDataSourceMessage: true,
                        rowStyle: rowData => ({
                          backgroundColor: rowData.status === 'draft' ? '#F6DA8D' : (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                        })
                      }}

                      onRowClick={(event, rowData) => {
                        console.log('rowData =>', rowData)
                        const currentLot = this.state.lots.find(lot => { return lot.uuid === rowData.uuid })
                        this.openLotModal(currentLot, currentLot.lotNumber)
                      }}
                      actions={[
                        {
                          icon: 'file_copy',
                          tooltip: this.props.t('lotTable.duplicateLot'),
                          onClick: (event, rowData) => {
                            // console.log('rowData => ', rowData)
                            this.duplicateLot(rowData.uuid)
                          }
                        },
                        rowData => ({
                          icon: 'open_in_new',
                          hidden: rowData.status === 'draft' || false,
                          tooltip: this.props.t('lotTable.goToPage'),
                          onClick: (event, rowData) => {
                            // console.log('rowData => ', rowData)
                            this.openUrl(`${this.state.frontEndUrl}/01/${rowData.lastInputOrOutputInformation.gtin}/10/${encodeURIComponent(rowData.lotNumber)}`)
                          }
                        })
                      ]}
                    />
                  </Grid>
                ) : (this.state.areLotsFetching
                  ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                    <Grid item>
                      <LogoLoader
                        size='large'
                        text={this.props.t('downloadingLots')}
                      >
                      </LogoLoader>
                    </Grid>
                  </Grid>
                  : (this.state.lots.length > 0
                    ? <>
                      <Grid item xs={12} className={classes.cardContainer}>
                        <Box marginTop={2} marginBottom={2}>
                          <Card>
                            <CardContent className={classes.lastLotcard}>
                              <Grid container direction='row' alignItems='center' justify='space-between'>
                                <Grid item xs={12} md={10}>
                                  <Typography variant='h6'>QR Code Ultimo lotto</Typography>
                                  <Typography className={classes.currentQrdescription} variant='subtitle2'>Inquadra questo QR Code per visualizzare la pagina dell&apos;ultimo lotto creato</Typography>
                                  <Box mt={3} mb={1}>
                                    <Button onClick={() => this.openQrcodeUrl(`${this.state.frontEndUrl}/01/${this.getSelectedProductGtin()}/10/latest`)} className={classes.actionButton} startIcon={<ExitToAppIcon className={classes.latestQrButton} />}>Vai alla pagina pubblica</Button>
                                    <Button onClick={() => this.downloadQrcode()} startIcon={<DownloadIcon className={classes.latestQrButton} />}>Scarica QR Code</Button>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Box mt={isSmall ? 2 : 0} display='flex' justifyContent='center' alignItems='center'>
                                    <QRCode size={isSmall ? 130 : 100} value={`${this.state.frontEndUrl}/01/${this.getSelectedProductGtin()}/10/latest`} />
                                    <QRCode value={`${this.state.frontEndUrl}/01/${this.getSelectedProductGtin()}/10/latest`} size={512} style={{ display: 'none' }} id="hiddenCanvas" />
                                  </Box>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid item xs={12} className={`${classes.cardContainer} lotsTable`}>
                        <MaterialTable
                          title={this.state.selectedProductId === '' ? this.props.t('lotTable.titleNoProduct') : this.props.t('lotTable.title')}
                          localization={{
                            toolbar: this.props.t('lotTable.localization.toolbar', { returnObjects: true }),
                            body: {
                              emptyDataSourceMessage: this.state.selectedProductId !== '' ? this.props.t('lotTable.localization.body.emptyDataSourceMessage') : this.props.t('lotTable.localization.body.emptyDataSourceMessage2')
                            },
                            pagination: this.props.t('lotTable.localization.pagination', { returnObjects: true }),
                            header: this.props.t('lotTable.localization.header', { returnObjects: true })
                          }}
                          columns={isSmall ? this.state.lotsMobileColumns : this.state.lotsColumns}
                          data={this.state.lots}
                          options={{
                            actionsColumnIndex: -1,
                            emptyRowsWhenPaging: false,
                            pageSize: 20,
                            pageSizeOptions: [20, 40, 60, 80, 100],
                            showEmptyDataSourceMessage: true,
                            rowStyle: rowData => ({
                              backgroundColor: rowData.status === 'draft' ? '#fff8a4' : (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                            })
                          }}

                          onRowClick={(event, rowData) => {
                            console.log('rowData =>', rowData)
                            const currentLot = this.state.lots.find(lot => { return lot.uuid === rowData.uuid })
                            this.openLotModal(currentLot, currentLot.lotNumber)
                          }}
                          actions={[
                            {
                              icon: 'file_copy',
                              tooltip: this.props.t('lotTable.duplicateLot'),
                              onClick: (event, rowData) => {
                                // console.log('rowData => ', rowData)
                                this.duplicateLot(rowData.uuid)
                              }
                            },
                            rowData => ({
                              icon: 'open_in_new',
                              hidden: rowData.status === 'draft' || false,
                              tooltip: this.props.t('lotTable.goToPage'),
                              onClick: (event, rowData) => {
                                // console.log('rowData => ', rowData)
                                this.openUrl(`${this.state.frontEndUrl}/01/${rowData.lastInputOrOutputInformation.gtin}/10/${encodeURIComponent(rowData.lotNumber)}`)
                              }
                            })
                          ]}
                        />
                      </Grid>
                    </>
                    : <Grid item xs={12} className={`${classes.cardContainer} lotsTable`}>
                      <MaterialTable
                        title={this.state.selectedProductId === '' ? this.props.t('lotTable.titleNoProduct') : this.props.t('lotTable.title')}
                        localization={{
                          toolbar: this.props.t('lotTable.localization.toolbar', { returnObjects: true }),
                          body: {
                            emptyDataSourceMessage: this.state.selectedProductId !== '' ? this.props.t('lotTable.localization.body.emptyDataSourceMessage') : this.props.t('lotTable.localization.body.emptyDataSourceMessage2')
                          },
                          pagination: this.props.t('lotTable.localization.pagination', { returnObjects: true }),
                          header: this.props.t('lotTable.localization.header', { returnObjects: true })
                        }}
                        columns={isSmall ? this.state.lotsMobileColumns : this.state.lotsColumns}
                        data={this.state.lots}
                        options={{
                          actionsColumnIndex: -1,
                          emptyRowsWhenPaging: false,
                          pageSize: 20,
                          pageSizeOptions: [20, 40, 60, 80, 100],
                          showEmptyDataSourceMessage: true,
                          rowStyle: rowData => ({
                            backgroundColor: rowData.status === 'draft' ? '#fff8a4' : (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                          })
                        }}

                        onRowClick={(event, rowData) => {
                          console.log('rowData =>', rowData)
                          const currentLot = this.state.lots.find(lot => { return lot.uuid === rowData.uuid })
                          this.openLotModal(currentLot, currentLot.lotNumber)
                        }}
                        actions={[{
                          icon: 'file_copy',
                          tooltip: this.props.t('lotTable.duplicateLot'),
                          onClick: (event, rowData) => {
                            // console.log('rowData => ', rowData)
                            this.duplicateLot(rowData.uuid)
                          }
                        },
                        rowData => ({
                          icon: 'open_in_new',
                          tooltip: this.props.t('lotTable.goToPage'),
                          hidden: rowData.status === 'draft' || false,
                          onClick: (event, rowData) => {
                            // console.log('rowData => ', rowData)
                            this.openUrl(`${this.state.frontEndUrl}/01/${rowData.lastInputOrOutputInformation.gtin}/10/${encodeURIComponent(rowData.lotNumber)}`)
                          }
                        })
                        ]}
                      />
                    </Grid>
                  )
                )
              )}

            {/* this.state.lots
                  .slice(this.state.page * this.state.limit, this.state.page * this.state.limit + this.state.limit)
                  .map(lot => {
                    return (
                      <Grid key={lot.uuid} item xs={12} md={6} lg={4} xl={3} className={classes.cardContainer}>
                        <Card className={classes.card}>
                          <CardActionArea onClick={() => this.openLotModal(lot, lot.lastInputOrOutputInformation.lotnumber)}>
                            <CardHeader
                              className={classes.cardHeaderContainer}
                              avatar={
                                <Avatar className={classes.cardAvatar} aria-label="product">L</Avatar>
                              }
                              title={'Lotto n. ' + lot.lastInputOrOutputInformation.lotnumber}
                              titleTypographyProps={{ variant: 'h6', noWrap: true }}
                              subheader={'Quantità: ' + lot.lastInputOrOutputInformation.quantity}
                              subheaderTypographyProps={{ noWrap: true }}
                            />
                          </CardActionArea>
                          <CardActions style={{ paddingTop: 0 }}>
                            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                              <Tooltip title="Vai alla pagina">
                                <IconButton aria-label="settings" target="_blank" rel="noopener" href={`${configuration.frontendBaseUrl}/gtin/${lot.gtin}/lot/${lot.lastInputOrOutputInformation.lotnumber}`} style={{ color: '#319c5a' }}>
                                  <ExitToAppIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  }) */}
          </Grid>
          <Grid item xs>
            {this.state.lastSearchCriteria !== '' && !this.state.areSearchingForLots && !this.state.noSearchLotsFound && <Pagination
              limit={this.state.limit}
              offset={this.state.offset}
              total={this.state.lots.length}
              onClick={(e, offset, page) => this.changePage(offset, page)}
              style={{ textAlign: 'center', paddingTop: 20 }}
            />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Lots.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('lots')(Lots))))
