import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

export default function SwitchLabels (props) {
  const [state, setState] = React.useState({
    checkedA: true, // not used property
    checkedB: props.showAllergenes
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  React.useEffect(() => {
    props.setShowAttributes(state)
    // eslint-disable-next-line
  }, [state.checkedB])

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
          />
        }
        label="Visualizza"
        labelPlacement="start"
      />
    </FormGroup>
  )
}
