import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import { ItLocalizedUtils, locale } from '../../utils/utils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

function StartDate (props) {
  const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const { track, trackIndex, startTimestampChanged, isModifyEnabled } = props

  return (
    <Grid item xs={12} sm={6}>
      <MuiPickersUtilsProvider
        utils={ItLocalizedUtils}
        locale={locale}
        className={classes.w100}>
        <KeyboardDatePicker
          className={classes.w100}
          required
          margin="normal"
          id={`startTimestamp-${trackIndex}`}
          label={t('tabs.traceability.inputs.startedAt')}
          format="dd/MM/yyyy"
          cancelLabel={t('tabs.traceability.inputs.dateCancel')}
          value={track.startedAt}
          onChange={(date) => startTimestampChanged(date, trackIndex)}
          KeyboardButtonProps={{
            'aria-label': t('tabs.traceability.inputs.startedAt')
          }}
          disabled={!isModifyEnabled}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )
}

StartDate.propTypes = {
  track: PropTypes.object.isRequired,
  startTimestampChanged: PropTypes.func.isRequired,
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired
}
export default StartDate
