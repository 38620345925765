import React, { useState, useEffect } from 'react'
import { makeStyles, Container, TextField, Button } from '@material-ui/core'
import validate from 'validate.js'
import clsx from 'clsx'
import configuration from '../../configuration'
import Axios from 'axios'
import ls from 'local-storage'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: '#3C4858!important'
  },
  submitButton: {
    margin: theme.spacing(2, 0),
    width: '100%'
  }
}))

function ResetPasswordForm ({ className, changeStatusCode, domain, ...rest }) {
  const classes = useStyles()
  const { t } = useTranslation('resetPassword')

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {}
  })

  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('inputs.validations.email') },
      email: {
        message: t('inputs.validations.validEmail')
      }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email } = formState.values
    const currentLanguage = ls.get('i18nextLng')
    console.log(currentLanguage)
    try {
      const response = await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/accounts/requestPasswordReset`,
        data: { email, locale: currentLanguage, domain: domain.domain }
      })
      const successCode = response.status
      changeStatusCode(successCode)
    } catch (e) {
      const errorCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode
      changeStatusCode(errorCode)
    }
  }

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field])

  const handleChange = event => {
    event.persist()
    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })
    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <Container>
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.field}>
          <TextField
            error={hasError('email')}
            fullWidth
            helperText={hasError('email') ? formState.errors.email[0] : null}
            label={t('inputs.email')}
            name='email'
            required
            onChange={handleChange}
            value={formState.values.email || ''}
          />
        </div>
        <Button
          className={!formState.isValid ? classes.submitButton : clsx(classes.submitButton, classes.primaryGradient)}
          color='primary'
          type='submit'
          size='large'
          variant='contained'
          disabled={!formState.isValid}
        >
          {t('buttons.success')}
        </Button>
      </form>
    </Container>
  )
}

export default ResetPasswordForm
