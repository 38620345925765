
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  contentText: {
    marginBottom: theme.spacing(8)
  },
  primaryGradient: {
    background: '#3C4858!important'
  }
}))

// Componente che fornisce una formattazione omogenea per i messaggi da visualizzare a schermo per l'utente
function Message ({ children, action, form, buttonText, ...rest }) {
  const classes = useStyles()

  return (
    <>
      <Typography component='div' className={action && buttonText && classes.contentText} variant='subtitle2'>
        {children}
      </Typography>
      {form && form}
      {!form && action && buttonText && <Button onClick={action} className={classes.primaryGradient} variant='contained' color='primary' >{buttonText}</Button>}
    </>
  )
}

Message.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  form: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  buttonText: PropTypes.string,
  action: PropTypes.func
}

export default Message
