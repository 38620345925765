import React from 'react'
import { withRouter } from 'react-router-dom'
import { domainToLogos } from '../../domainToLogos'

import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  withStyles,
  Snackbar,
  SnackbarContent,
  Box
} from '@material-ui/core'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import Menu from '../Menu/Menu.component'
import ChangePasswordDialog from '../ChangePasswordDialog/ChangePasswordDialog.component'
import { parseDomain } from 'parse-domain'
import {
  AccountCircle,
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  Cancel
} from '@material-ui/icons'

import axios from 'axios'
import ls from 'local-storage'

import { green, amber } from '@material-ui/core/colors'

import configuration from '../../configuration'

// variaible con le classi css
const styles = theme => ({
  root: {},
  appBar: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    borderBottom: '1px solid #ddd'
  },
  menuButton: {
    color: theme.primary
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  success: {
    backgroundColor: green[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

// funzione che ritorna il nome della pagina
const getPageName = location => {
  if (location === '/') {
    return 'Home'
  } else if (location === '/companies') {
    return 'Aziende'
  } else if (location === '/facilities') {
    return 'Stabilimenti'
  } else if (location === '/products') {
    return 'Prodotti'
  } else if (location === '/lots') {
    return 'Lotti'
  }
}

function RenderLogo (props) {
  if (props.domain.type === 'LISTED') {
    if (props.domain.domain === 'trusty') {
      if (props.domain.subDomains.length > 1) {
        return (
          <img src={`${domainToLogos[props.domain.subDomains[1]].sidebarLogos[0].img}`} alt={`${domainToLogos[props.domain.subDomains[1]].sidebarLogos[0].title}`} style={{ width: '24px', height: '24px' }}/>
        )
      }
    }
    return (
      <>
        <img src={`${domainToLogos[props.domain.domain].sidebarLogos[0].img}`} alt={`${domainToLogos[props.domain.domain].sidebarLogos[0].title}`} style={{ width: '24px', height: '24px' }}/>
      </>
    )
  } else {
    return (
      <img src={`${domainToLogos.localhost.sidebarLogos[0].img}`} alt={`${domainToLogos.localhost.sidebarLogos[0].title}`} style={{ width: '24px', height: '24px' }}/>
    )
  }
}

class TopToolbar extends React.Component {
  constructor (props) {
    super(props)
    const currentOrganization = ls.get('organization')
    const userData = ls.get('user')
    // controllo la sezione per segnarmi la pagina da mobile
    const thisLocation = props.location.pathname
    const pageName = getPageName(thisLocation)
    // preparo lo stato
    this.state = {
      anchorEl: null,
      currentOrganization,
      isChangePasswordModalOpened: false,
      pass1: '',
      pass2: '',
      userData,
      title: pageName,
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: '',
      domain: parseDomain(window.location.host)
    }
    // bindo il this principale alle sotto funzioni
    this.changePassword = this.changePassword.bind(this)
    this.closeChangePasswordModal = this.closeChangePasswordModal.bind(this)
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.logout = this.logout.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
  }

  componentDidUpdate (prevProps) {
    // console.log('top toolbar old prevProps =>', prevProps)
    // console.log('top toolbar this.props =>', this.props)
    const thisLocation = this.props.location.pathname
    const pageName = getPageName(thisLocation)
    if (pageName !== this.state.title) {
      const userData = ls.get('user')
      if (!pageName) {
        this.setState({ anchorEl: null })
      }
      this.setState({ title: pageName, userData })
    }
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  // funzione che viene lanciata per cambiare password
  async changePassword (pass1, pass2) {
    if (pass1 !== pass2) {
      return this.setSnackbar('Impossibile procedere: le password non coincidono', 'error')
    }

    try {
      await axios.put(`${configuration.apiBaseUrl}/accounts/self`, {
        password: pass1,
        updatedAt: Date.now()
      }, {
        headers: {
          authorization: `Bearer ${ls.get('token')}`
        }
      })

      this.setSnackbar('Password cambiata con successo, si verrà rindirizzati al login', 'success')
      setTimeout(() => this.logout(), 1000)
      // this.logout()
    } catch (e) {
      console.log('Error while updating password: ', e)
      this.setSnackbar('Impossibile cambiare la password', 'error')
    }
  }

  // funzione che apre la modal
  openChangePasswordModal () {
    this.setState({ isChangePasswordModalOpened: true })
  }

  // funzione che chiude la modal del cambia password
  closeChangePasswordModal () {
    this.setState({ isChangePasswordModalOpened: false })
  }

  handleChange ({ target }) {
    this.setState({ [target.name]: target.value })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  // funzione che fa il logout
  logout () {
    this.setState({
      userData: null
    }, () => {
      ls.clear()
      this.props.history.push('/signin')
    })
  }

  // funzione che apre la sidebar da mobile
  openMobileSidenav = () => {
    console.log('dentro openMobileSidenav')
    if (this.props.onMenuClick) {
      this.props.onMenuClick()
    }
  }

  // renderizzo l'app
  render () {
    const { anchorEl } = this.state
    const { classes } = this.props

    if (this.state.userData && !(this.props.location.pathname.includes('opentimestamps') || this.props.location.pathname.includes('/recover') || this.props.location.pathname.includes('/resetPassword') || this.props.location.pathname.includes('/activate') || this.props.location.pathname.includes('/signup') || this.props.location.pathname.includes('/signin') || this.props.location.pathname.includes('/completeinvitation'))) {
      return (
        <Hidden mdUp>
          <div className={classes.root}>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={this.state.showSnackbar}
              autoHideDuration={4000}
              onClose={this.closeSnackbar}
            >
              <SnackbarContent
                className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
                aria-describedby={this.state.snackbarType === 'error' ? 'error-snackbar' : 'success-snackbar'}
                message={
                  <span id="error-snackbar" className={classes.message}>
                    {this.state.snackbarType === 'error' ? <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> : <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} />}
                    {this.state.snackbarMessage}
                    <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
                  </span>
                }
              />
            </Snackbar>
            {/* Modal cambio password */}
            <ChangePasswordDialog
              open={this.state.isChangePasswordModalOpened}
              onClose={this.closeChangePasswordModal}
              changePassword={this.changePassword}
            />
            {/* toolbar */}
            <AppBar position="static" color="default" className={classes.appBar}>
              <Toolbar>
                <Grid container direction="row" justify="center" alignItems="center">
                  {/* tablet e mobile */}
                  <Grid item style={{ display: 'flex', alignItems: 'left' }}>
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.openMobileSidenav}>
                      <RenderLogo
                        domain = {this.state.domain}
                      ></RenderLogo>
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Box ml={1}>
                      <h3>{this.state.title}</h3>
                    </Box>
                  </Grid>
                  <Grid item>
                    <div style={{ textAlign: 'right' }}>
                      <Button className={classes.menuButton} size="small" color="primary" aria-label="Menu" onClick={this.handleClick}>
                        <AccountCircle />
                      </Button>
                      <Menu
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        logout={this.logout}
                        handleClose={this.handleClose}
                        openChangePasswordModal={this.openChangePasswordModal}
                        anchorEl={anchorEl}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </div>
        </Hidden>
      )
    } else {
      return (<div></div>)
    }
  }
}

TopToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  onMenuClick: PropTypes.func
}

export default withRouter(withStyles(styles)(TopToolbar))
