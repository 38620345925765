/*
NOTA BENE: se si vuol far partire l'applicazione in localhost, commentare
la parte d codice relativa al else if con il parseDomain
*/
import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import AccountsService from './Accounts.service'
import { parseDomain } from 'parse-domain'

class Redirector extends React.Component {
  constructor (props) {
    super(props)
    const userData = AccountsService.getCurrentAccount()
    const activateRegex = /activate\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+/gm
    const pecRegex = /pec\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+/gm
    const completeinvitationRegex = /completeinvitation\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+/gm
    const completeinvitationSupplychainRegex = /completeinvitationSupplychain\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+\/[a-zA-z0-9-]+\/[a-zA-z0-9-]+/gm
    const completeinvitationSupplychainNewUserRegex = /completeinvitationSupplychainNewUser\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+\/[a-zA-z0-9-]+/gm

    console.log('redirector props', props.domain)
    /* commentare else if per far partire l'app in localhost */
    if (!userData) {
      if (props.domain.domain !== 'trusty') {
        // Se l'utente non è logggato può unicamente accedere a signup, signin e le pagine di attivzione
        if (!this.props.location.pathname.includes('/recover') && this.props.location.pathname !== '/resetpassword' && this.props.location.pathname !== '/signup' && this.props.location.pathname !== '/signin' && !this.props.location.pathname.match(activateRegex) && !this.props.location.pathname.match(pecRegex) && !this.props.location.pathname.match(completeinvitationRegex) && !this.props.location.pathname.match(completeinvitationSupplychainRegex) && !this.props.location.pathname.match(completeinvitationSupplychainNewUserRegex)) {
          this.props.location.pathname = '/signin'
          this.props.history.push('/signin')
        }
      } else {
        // Se l'utente non è logggato ed è su dominio trusty può unicamente accedere a signin e le pagine di attivzione (non c'è il controllo per la pagina di /signup poichè su trusty non è utilizzata)
        if (!this.props.location.pathname.includes('/recover') && this.props.location.pathname !== '/resetpassword' && this.props.location.pathname !== '/signin' && !this.props.location.pathname.match(activateRegex) && !this.props.location.pathname.match(pecRegex) && !this.props.location.pathname.match(completeinvitationRegex) && !this.props.location.pathname.match(completeinvitationSupplychainRegex) && !this.props.location.pathname.match(completeinvitationSupplychainNewUserRegex)) {
          this.props.location.pathname = '/signin'
          this.props.history.push('/signin')
        }
      }
    } else if (parseDomain(window.location.host).type === 'INVALID') {
      this.props.location.pathname = '/error'
      this.props.history.push('/error')
    }
  }

  render () {
    return (<div></div>)
  }
}

Redirector.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

// Esportare con withrouter mi consente di usare la history api
// https://stackoverflow.com/a/42716055/150571
export default withRouter(Redirector)
