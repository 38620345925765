import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import DeleteComponentSimple from '../AdditionalBlocks/DeleteComponentSimple'
import LocationToComponent from '../LocationComponent/LocationToComponent'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   },
//   facilityCard: {
//     padding: '30',
//     backgroundColor: '#fafafa',
//     borderRadius: 8,
//     marginBottom: 25,
//     '-webkit-box-shadow': '0px 0px 5px 1px #eaeaea',
//     boxShadow: '0px 0px 5px 1px #eaeaea'
//   }
// }))

function FacilityToComponent (props) {
  // const classes = useStyles()
  const {
    locationIndex,
    trackIndex,
    track,
    isModifyEnabled,
    locationTo,
    locationToChanged,
    facilities,
    deleteFacilityTo,
    translation
  } = props

  return (
    <div key={`locationTo-${locationIndex}`}>
      <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
        <LocationToComponent
          key={`${locationTo.facilityId}-${locationIndex}-${trackIndex}`}
          track={track}
          trackIndex={trackIndex}
          locationTo={locationTo}
          locationIndex={locationIndex}
          locationToChanged={locationToChanged}
          isModifyEnabled={isModifyEnabled}
          facilities={facilities}
          translation={translation}
        ></LocationToComponent>
        <DeleteComponentSimple
          trackIndex={trackIndex}
          componentIndex={locationIndex}
          deleteComponent={deleteFacilityTo}
          isModifyEnabled={isModifyEnabled}
          translation={translation}
        ></DeleteComponentSimple>
      </Grid>
    </div>
  )
}

FacilityToComponent.propTypes = {
  track: PropTypes.object.isRequired,
  locationIndex: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  locationTo: PropTypes.string.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  locationToChanged: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  deleteFacilityTo: PropTypes.func.isRequired,
  translation: PropTypes.string
}
export default FacilityToComponent
