import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'
import { createSupplychainMembership } from './supplychains.js'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnCompaniesCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}

// esport query per i prori ACL
export async function returnAllCompanies () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      limit: Number.MAX_SAFE_INTEGER
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// funzione che crea una nuova azienda
export async function returnNewCompany (newCompany) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!newCompany) {
    return
  }
  console.log('BEFORE QUERY newCompany => ', newCompany)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/`, newCompany, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che crea una nuova azienda
export async function createDefaultCompany (newCompany) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  console.log('BEFORE QUERY newCompany => ', newCompany)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/create`, newCompany, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
// funzione che modifica un'azienda
export async function editCompany (company) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello)
  */
  let supplychainIds = []
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!company) {
    return
  }
  if (company.supplychains) {
    supplychainIds = company.supplychains.map(supplychain => supplychain.id)
    delete company.supplychains
  }

  console.log('BEFORE QUERY edit company => ', company)
  // mando la query
  const response = await axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/${company.uuid}/replace/`, company, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(company.organizationId, 'company', response.data.data.uuid, supplychainIds)
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }
  return response
}

// funzione che elimina un'azienda
export async function deleteCompany (company) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!company) {
    return
  }
  console.log('BEFORE QUERY delete company => ', company)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/${company.uuid}`, company, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export async function returnAllCompaniesProjection (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}

export async function returnAllSupplyChainCompanies (supplychainId) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/companies`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
  return response
}

export async function returnAllSupplyChainCompaniesProjection (supplychainId, params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/companies`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
  return response
}
