import React from 'react'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
  DialogTitle,
  TextField,
  Box,
  Typography,
  IconButton,
  withWidth
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ls from 'local-storage'
import { createDefaultCompany } from '../../utils/companies'

import { Close as CloseIcon } from '@material-ui/icons'
// import configuration from '../../configuration'

const styles = theme => ({
  primary: {
    color: theme.primary
  },
  warn: {
    color: 'red'
  },
  progressStyle: {
    color: theme.primary
  },
  navBar: {
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
})

class FormDialog extends React.Component {
  /* rispetto agli altri FormDialog in questo non mi servono il componentDidMount in quanto non mi serve fare chiamate async per ricevere le info */
  constructor (props) {
    super(props)
    this.user = ls.get('user') || {}

    /* STATO */
    this.state = {
      open: this.props.open,
      company: {
        // vatId,name
      },
      confirmCloseOpen: false,
      errors: {}
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.createCompany = this.createCompany.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.isEmpty = this.isEmpty.bind(this)
  }

  closeConfirmDialog () {
    this.setState({ confirmCloseOpen: false })
  }

  openConfirmDialog () {
    this.setState({ confirmCloseOpen: true })
  }

  async closeDialog () {
    this.setState({ isCreating: false, open: false })
    await this.props.onCloseDialog()
    this.props.setSnackbar(this.props.t('notifications.creationOk'), 'success')
  }

  closeDialogWithoutRefresh () {
    this.props.onCloseDialogWithoutRefresh()
  }

  async createCompany () {
    if (this.state.isCreating) {
      return
    }
    this.setState({ isCreating: true })

    try {
      await createDefaultCompany(this.state.company)
      this.closeDialog()
    } catch (e) {
      console.log('sono nel catch!!!')
      // response.errorSupplychain="Resource cannot subscribe to supplychain"
      switch (e.response.status) {
        case 400: {
          this.props.setSnackbar(this.props.t('notifications.creationPiva'), 'error')
          this.props.onCloseDialogWithoutRefresh()
          break
        }
        default: {
          this.props.setSnackbar(this.props.t('notifications.creationError'), 'error')
          this.props.onCloseDialogWithoutRefresh()
          break
        }
      }
    }
  }

  // funzione che ritorna true se il parametro passato è una stringa vuota, altrimenti ritorna false
  isEmpty (value) {
    return value === ''
  }

  // funzione lanciata al change degli input
  valueChanged ({ target }) {
    const { company } = this.state
    company[target.name] = target.value
    this.setState({ company })
    // this.setState({ [target.name]: target.value })
  }

  // funzione che ritorna true se il parametro passato è una stringa vuota, altrimenti ritorna false

  // funzione che si occupa della validazione inline degli input
  handleBlur ({ target }) {
    this.setState({ errors: { [target.name]: this.isEmpty(target.value) } })
  }

  render () {
    const { classes } = this.props
    // const company = {
    //   name: this.state.name,
    //   description: this.state.description,
    //   address: this.state.address,
    //   houseNumber: this.state.houseNumber,
    //   city: this.state.city,
    //   owner: this.state.owner,
    //   companyId: this.state.companyId,
    //   additionalData: this.state.additionalData
    // }
    return (
      <React.Fragment>
        {/* dialog conferma chiusura */}
        <Dialog className='companyModal' id="confirmDialog" open={this.state.confirmCloseOpen} aria-labelledby="responsive-dialog-title">
          <DialogTitle className='companyModal' id="responsive-dialog-title">{ this.props.t('dialog.confirmClose.title') }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { this.props.t('dialog.confirmClose.description') }?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.closeConfirmDialog} color="primary" autoFocus>
              { this.props.t('dialog.confirmClose.buttons.cancel') }
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={async () => {
                this.closeConfirmDialog()
                this.closeDialogWithoutRefresh()
              }}
              color="secondary">
              { this.props.t('dialog.confirmClose.buttons.confirm') }
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog principale */}
        <Dialog
          className='companyModal'
          open={this.props.open}
          onClose={this.openConfirmDialog}
          aria-labelledby="form-dialog-title"
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
        >
          <DialogTitle className={classes.navBar} id="form-dialog-title">
            { this.props.t('title') }
            <IconButton aria-label="close" className={classes.closeButton} onClick={this.openConfirmDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputLabelProps={{ shrink: true }}
              autoFocus
              margin="dense"
              id="name"
              name="name"
              value={this.state.company.name}
              label={this.props.t('inputs.name')}
              onChange={this.valueChanged}
              type="text"
              fullWidth
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              error={this.state.errors.vatId}
              margin="dense"
              id="vatId"
              name="vatId"
              label={this.props.t('inputs.vatId')}
              onChange={this.valueChanged}
              onBlur={this.handleBlur}
              helperText={this.state.errors.vatId ? this.props.t('notifications.helpText') : '' }
              type="text"
              fullWidth
              required
            />
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <TextField
                error={this.state.errors.companyId}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                id="gs1"
                name="gs1"
                label={this.props.t('inputs.gs1')}
                value={this.state.company.gs1}
                onChange={this.valueChanged}
                type="text"
                fullWidth
              />
              <Typography style={{ color: '#737373' }} variant="body2">{ this.props.t('notifications.gs1Info.title') }. <i><b>{ this.props.t('notifications.gs1Info.example') }</b></i></Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
              <Button classes={{ textPrimary: classes.warn }} onClick={this.openConfirmDialog} color="primary">
                { this.props.t('buttons.cancel') }
              </Button>
              <Button disabled={!this.state.company.vatId} classes={{ textPrimary: classes.primary }} onClick={this.createCompany} color="primary">
                {this.state.isCreating
                  ? <LogoLoader
                    size='small'
                  >
                  </LogoLoader>

                  : this.props.t('buttons.add')}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  onCloseDialogWithoutRefresh: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withStyles(styles)(withWidth()(withTranslation('newCompany')(FormDialog)))
