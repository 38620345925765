import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  CardMedia
} from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function MediaComponent (props) {
  // const classes = useStyles()
  const { track } = props

  return (
    <Grid item xs={12} style={{ width: '100%' }}>
      <CardMedia style={{ height: '280px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}
        title="immagini della fase"
        image={track.media[0] ? track.media[0].original : '/images/img-placeholder.png'}>
      </CardMedia>
    </Grid>
  )
}

MediaComponent.propTypes = {
  track: PropTypes.object.isRequired
}
export default MediaComponent
