import React from 'react'
import PropTypes from 'prop-types'
import {
  Tooltip,
  Button
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

function AddButtonComponent (props) {
  const {
    addComponent,
    isModifyEnabled,
    toolTipTitle
  } = props

  return (
    <div>
      <Tooltip title={toolTipTitle} placement='top-end'>
        <Button className="inputCardButton" onClick={() => addComponent()} disabled={!isModifyEnabled} size="small" aria-label="aggiungi input" >
          <AddIcon />
        </Button>
      </Tooltip>
    </div>
  )
}
AddButtonComponent.propTypes = {
  addComponent: PropTypes.func.isRequired,
  toolTipTitle: PropTypes.string.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired
}

export default AddButtonComponent
