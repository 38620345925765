import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { domainToLogos } from '../../domainToLogos'
import { isThisAccountHasSupplychainFunctionality, isThisAccountHasSupplychain, isThisAccountHasLightLotFunctionality, isThisAccountHasBothLotTypeFunctionality } from '../../utils/acl-organization'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExploreIcon from '@material-ui/icons/Explore'

import ls from 'local-storage'
import {
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Grid,
  Typography,
  Snackbar,
  SnackbarContent,
  Box
} from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  GroupWork as GroupWorkIcon,
  Business as FacilitiesIcon,
  ViewModule as ViewModuleIcon,
  Error as ErrorIcon,
  CheckCircle as SuccessIcon,
  Home as HomeIcon,
  // AccountCircle,
  Settings as SettingsIcon,
  Help,
  NewReleases,
  Cancel
} from '@material-ui/icons'

import configuration from '../../configuration'
import axios from 'axios'

import PersonIcon from '@material-ui/icons/Person'
import ChangePasswordDialog from '../ChangePasswordDialog/ChangePasswordDialog.component'
import HelpDialog from '../HelpDialog/HelpDialog.component'

import Menu from '../Menu/Menu.component'
import TrustyLogoBlack from '../../components/TrustyLogo/TrustyLogoBlack'
import { parseDomain } from 'parse-domain'
import { green, amber } from '@material-ui/core/colors'

import { withTranslation } from 'react-i18next'
// import { changeLanguage } from '../../utils/translations'

const drawerWidth = 230
const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    height: '100vh',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  primary: {
    color: theme.primary
  },
  secondary: {
    color: theme.secondary
  },
  white: {
    color: 'white'
  },
  brandCustom: {
    marginTop: '20px'
  },

  drawerPaperClose: {
    overflowX: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    height: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto'
  },
  helpButton: {
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    transition: 'background-color .3s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      transition: 'background-color .3s'
    }
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  h5: {
    marginBottom: theme.spacing(2)
  },
  toolbarFormControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectedTab: {
    background: '#3C4858!important',
    color: 'white'
  },
  selectedIcon: {
    color: 'white'
  },
  bottomDrawerContainer: {
    width: '100%',
    backgroundColor: '#fcfcfc',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  logImg: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    width: '20%',
    marginRight: 20
  },
  logoContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  logoContainerCustom: {
    backgroundSize: 'contain!important',
    marginTop: '20px'
  },
  helpIcon: {
    color: '#5e5e5e',
    fontSize: '24px',
    marginLeft: '4px'
  },
  menuSection: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderBottom: '1px solid rgba(0,0,0,0.12)',
    backgroundColor: theme.secondary
  },
  helpSectionMobile: {
    borderTop: '1px solid rgba(0,0,0,0.12)'
  },
  accountButton: {
    width: '100%',
    paddingLeft: '16px',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  helpText: {
    color: '#5e5e5e',
    marginLeft: '16px',
    width: '95%'
  },
  fab: {
    position: 'fixed',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    top: theme.spacing(2),
    right: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  success: {
    backgroundColor: green[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  }
})

// eslint-disable-next-line react/display-name
const CustomLink = React.forwardRef((props, ref) => <Link {...props} ref={ref}></Link>)

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
function RenderLogo (props) {
  const classes = props.classes
  if (props.domain.type === 'LISTED') {
    if (props.domain.domain === 'trusty') {
      if (props.domain.subDomains.length > 1) {
        return (
          <div className={classes.logoContainer}>
            <img src={`${domainToLogos[props.domain.subDomains[1]].sidebarLogos[0].img}`} alt={`${domainToLogos[props.domain.subDomains[1]].sidebarLogos[0].title}`} className={classes.logImg} />
            <h4 className='siteName' style={{ display: props.open ? 'block' : 'none', marginRight: 'auto' }}>Trusty</h4>
          </div>)
      }
    }
    return (
      <div className={classes.logoContainer}>
        <img src={`${domainToLogos[props.domain.domain].sidebarLogos[0].img}`} alt={`${domainToLogos[props.domain.domain].sidebarLogos[0].title}`} className={classes.logImg} />
        <h4 className='siteName' style={{ display: props.open ? 'block' : 'none', marginRight: 'auto' }}>{capitalize(props.domain.domain)}</h4>
      </div>
    )
  } else {
    return (
      <div className={classes.logoContainer}>
        <img src={`${domainToLogos.localhost.sidebarLogos[0].img}`} alt={`${domainToLogos.localhost.sidebarLogos[0].title}`} className={classes.logImg} />
        <h4 className='siteName' style={{ display: props.open ? 'block' : 'none', marginRight: 'auto' }}>Trusty</h4>
      </div>
    )
  }
}

class Sidebar extends React.Component {
  constructor (props) {
    super(props)
    const userData = ls.get('user')
    // console.log('props.t(\'sideBar:dashboard\') =>', props.t('sideBar:dashboard'))
    this.state = {
      anchorEl: null,
      open: true,
      mobileOpen: props.open,
      userData,
      isChangePasswordModalOpened: false,
      pass1: '',
      pass2: '',
      selectedTab: '',
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: '',
      isHelpDialogOpen: false,
      domain: parseDomain(window.location.host),
      hasLightLotFunctionality: isThisAccountHasLightLotFunctionality(),
      hasBothLotTypeFunctionality: isThisAccountHasBothLotTypeFunctionality(),
      hasSupplychainFunctionality: isThisAccountHasSupplychainFunctionality(),
      hasSupplychain: isThisAccountHasSupplychain()
    }
    this.logout = this.logout.bind(this)
    this.closeChangePasswordModal = this.closeChangePasswordModal.bind(this)
    this.closeHelpDialog = this.closeHelpDialog.bind(this)
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this)
    this.openHelpDialog = this.openHelpDialog.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.openExternalSite = this.openExternalSite.bind(this)
  }

  // funzione che setta i parametri di visualizzazione della snackbar
  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  openExternalSite (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  // funzione che chiude la snackbar
  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  // funzione che fa il logout
  logout () {
    this.setState({
      userData: null
    }, () => {
      ls.clear()
      this.props.history.push('/signin')
    })
  }

  getDrawerIcon () {
    if (this.state.open) {
      return (<ChevronLeftIcon />)
    } else {
      if (this.state.domain.type === 'LISTED') {
        if (this.state.domain.domain === 'trusty') {
          if (this.state.domain.subDomains.length > 1) {
            return (<img src={`${domainToLogos[this.state.domain.subDomains[1]].sidebarLogos[0].img}`} alt={`${domainToLogos[this.state.domain.subDomains[1]].sidebarLogos[0].title}`} style={{ width: '24px', height: '24px' }} />)
          }
        }
        return (<img src={`${domainToLogos[this.state.domain.domain].sidebarLogos[0].img}`} alt={`${domainToLogos[this.state.domain.domain].sidebarLogos[0].title}`} style={{ width: '24px', height: '24px' }} />)
      } else {
        return (<img src={`${domainToLogos.localhost.sidebarLogos[0].img}`} alt={`${domainToLogos.localhost.sidebarLogos[0].title}`} style={{ width: '24px', height: '24px' }} />)
      }
    }
  }

  // return (<ChevronRightIcon />)

  getMobileDrawerIcon () {
    if (this.state.open) {
      console.log('HO CLICCATO QUA, getMobileDrawerIcon')
      return (<ChevronLeftIcon />)
    } else {
      // return (<img src={'/images/logo-trusty-minimal.png'} alt={'Logo Trusty'} style={{ width: '24px', height: '24px' }} />)
      return (<ChevronRightIcon />)
    }
  }

  componentDidMount () {
    console.log('apro didmount----------------------------------------------------------')
    this.setSelectedTab(this.props.location.pathname)
    this.setState({ anchorEl: null })
  }

  componentDidUpdate (prevProps, prevState) {
    const body = document.body
    if (window.location.href.indexOf('companies') > -1) {
      body.className = ''
      body.classList.add('companies')
    } else if (window.location.href.indexOf('facilities') > -1) {
      body.className = ''
      body.classList.add('facilities')
    } else if (window.location.href.indexOf('products') > -1) {
      body.className = ''
      body.classList.add('products')
    } else if (window.location.href.indexOf('lots') > -1) {
      body.className = ''
      body.classList.add('lots')
    } else if (window.location.href.indexOf('light-lots') > -1) {
      body.className = ''
      body.classList.add('light-lots')
    } else if (window.location.href.indexOf('analytics') > -1) {
      body.className = ''
      body.classList.add('analytics')
    } else {
      body.className = ''
    }

    console.log('apro didupdate----------------------------------------------------------')
    if (this.state.open === false && prevState.open === false) {
      // this.handleDrawerOpen()
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setSelectedTab(this.props.location.pathname)
    }

    if (prevProps.open !== this.props.open) {
      this.setState({ mobileOpen: !this.state.mobileOpen })
    }
  }

  handleDrawerOpen = () => {
    const currentValue = this.state.open
    this.setState({ open: !currentValue })
    if (this.props.onOpen) {
      this.props.onOpen()
    }
  }

  handleDrawerClose = () => {
    console.log('handleDrawerClose')
    this.setState({ open: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  // funzione che viene lanciata per cambiare password
  async changePassword (pass1, pass2) {
    if (pass1 !== pass2) {
      return this.setSnackbar('Impossibile procedere: le password non coincidono', 'error')
    }

    try {
      await axios.put(`${configuration.apiBaseUrl}/accounts/self`, {
        password: pass1,
        updatedAt: Date.now()
      }, {
        headers: {
          authorization: `Bearer ${ls.get('token')}`
        }
      })
      this.setSnackbar('Password cambiata con successo, si verrà rindirizzati al login', 'success')
      setTimeout(() => this.logout(), 1000)
      // this.logout()
    } catch (e) {
      console.log('Error while updating password: ', e)
      this.setSnackbar('Impossibile cambiare la password', 'error')
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  setSelectedTab = tab => {
    this.setState({ selectedTab: tab })
  }

  sidenavEntries = (classes) => (
    <React.Fragment>
      <List style={{ paddingTop: 0 }}>
        <ListItem button
          className={(this.state.selectedTab === '/' ? classes.selectedTab : '')}
          to="/"
          component={CustomLink}
          selected={this.state.selectedTab === '/'}
          onClick={() => this.setSelectedTab('/')}
        >
          <ListItemIcon>
            <HomeIcon className={(this.state.selectedTab === '/' ? classes.selectedIcon : '')} />
          </ListItemIcon>
          <ListItemText primary={this.props.t('sideBar:dashboard')} />
        </ListItem>
        <ListItem button
          className={(this.state.selectedTab === '/companies' ? classes.selectedTab : '')}
          to="/companies"
          component={CustomLink}
          selected={this.state.selectedTab === '/companies'}
          onClick={() => this.setSelectedTab('/companies')}
        >
          <ListItemIcon>
            <GroupIcon className={(this.state.selectedTab === '/companies' ? classes.selectedIcon : '')} />
          </ListItemIcon>
          <ListItemText primary={this.props.t('sideBar:companies')} />
        </ListItem>
        <ListItem button
          className={(this.state.selectedTab === '/facilities' ? classes.selectedTab : '')}
          to="/facilities"
          component={CustomLink}
          selected={this.state.selectedTab === '/facilities'}
          onClick={() => this.setSelectedTab('/facilities')}
        >
          <ListItemIcon>
            <FacilitiesIcon className={(this.state.selectedTab === '/facilities' ? classes.selectedIcon : '')} />
          </ListItemIcon>
          <ListItemText primary={this.props.t('sideBar:facilities')} />
        </ListItem>
        <ListItem button
          className={(this.state.selectedTab === '/products' ? classes.selectedTab : '')}
          to="/products"
          component={CustomLink}
          selected={this.state.selectedTab === '/products'}
          onClick={() => this.setSelectedTab('/products')}
        >
          <ListItemIcon>
            <GroupWorkIcon className={(this.state.selectedTab === '/products' ? classes.selectedIcon : '')} />
          </ListItemIcon>
          <ListItemText primary={this.props.t('sideBar:products')} />
        </ListItem>
        {(!this.state.hasLightLotFunctionality) &&
          <ListItem button
            className={(this.state.selectedTab === '/lots' ? classes.selectedTab : '')}
            to="/lots"
            component={CustomLink}
            selected={this.state.selectedTab === '/lots'}
            onClick={() => this.setSelectedTab('/lots')}
          >
            <ListItemIcon>
              <ViewModuleIcon className={(this.state.selectedTab === '/lots' ? classes.selectedIcon : '')} />
            </ListItemIcon>
            <ListItemText primary={this.props.t('sideBar:lots')} />
          </ListItem>
        }
        {(this.state.hasLightLotFunctionality || this.state.hasBothLotTypeFunctionality) &&
        <ListItem button
          className={(this.state.selectedTab === '/light-lots' ? classes.selectedTab : '')}
          to="/light-lots"
          component={CustomLink}
          selected={this.state.selectedTab === '/light-lots'}
          onClick={() => this.setSelectedTab('/light-lots')}
        >
          <ListItemIcon>
            <ViewModuleIcon className={(this.state.selectedTab === '/light-lots' ? classes.selectedIcon : '')} />
          </ListItemIcon>
          <ListItemText primary={this.props.t('sideBar:light-lots')} />
        </ListItem>
        }
        {/* <ListItem button
          className={(this.state.selectedTab === '/analytics' ? classes.selectedTab : '')}
          to="/analytics"
          component={CustomLink}
          selected={this.state.selectedTab === '/analytics'}
          onClick={() => this.setSelectedTab('/analytics')}
        >
          <ListItemIcon>
            <DashboardIcon className={(this.state.selectedTab === '/analytics' ? classes.selectedIcon : '')} />
          </ListItemIcon>
          <ListItemText primary={this.props.t('sideBar:analytics')} />
        </ListItem> */}
        {(this.state.hasSupplychain || this.state.hasSupplychainFunctionality) &&
         <Accordion className="listMenuAccordion">
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
             <ListItem button
               // className={(this.state.selectedTab === '/supplychains' && '/manage' ? classes.selectedTab : '')}
               // to="/supplychains"
               // component={CustomLink}
               // selected={this.state.selectedTab === '/supplychains'}
               // onClick={() => this.setSelectedTab('/supplychains')}
             >
               <ListItemIcon>
                 <BubbleChartIcon/>
               </ListItemIcon>
               <ListItemText primary={this.props.t('sideBar:supplychains')} />
             </ListItem>
           </AccordionSummary>
           <AccordionDetails>
             <List>
               <ListItem button
                 className={(this.state.selectedTab === '/supplychains' ? classes.selectedTab : '')}
                 to="/supplychains"
                 component={CustomLink}
                 selected={this.state.selectedTab === '/supplychains'}
                 onClick={() => this.setSelectedTab('/supplychains')}
               >
                 <ListItemIcon>
                   <ExploreIcon className={(this.state.selectedTab === '/supplychains' ? classes.selectedIcon : '')} />
                 </ListItemIcon>
                 <ListItemText primary={this.props.t('sideBar:supplychainsExplore')} />
               </ListItem>

               {this.state.hasSupplychainFunctionality &&
                    <ListItem button
                      className={(this.state.selectedTab === '/manage' ? classes.selectedTab : '')}
                      to="/manage"
                      component={CustomLink}
                      selected={this.state.selectedTab === '/manage'}
                      onClick={() => this.setSelectedTab('/manage')}
                    >
                      <ListItemIcon>
                        <SettingsIcon className={(this.state.selectedTab === '/manage' ? classes.selectedIcon : '')} />
                      </ListItemIcon>
                      <ListItemText primary={this.props.t('sideBar:manage')} />
                    </ListItem>
               }
             </List>
           </AccordionDetails>
         </Accordion>

        }
      </List>
    </React.Fragment>
  )

  // funzione che apre la modal del cambio password
  openChangePasswordModal () {
    this.setState({ isChangePasswordModalOpened: true })
  }

  // funzione che chiude la modal del cambia password
  closeChangePasswordModal () {
    this.setState({ isChangePasswordModalOpened: false })
  }

  // funzione che apre la dialog delle FAQ
  openHelpDialog () {
    this.setState({ isHelpDialogOpen: true })
  }

  // funzione che chiude la dialog delle FAQ
  closeHelpDialog () {
    this.setState({ isHelpDialogOpen: false })
  }

  render () {
    const { userData } = this.state
    if (!userData || this.props.history.location.pathname.includes('/opentimestamps') || this.props.history.location.pathname.includes('/signup') || this.props.history.location.pathname.includes('/signin') || this.props.history.location.pathname.includes('/activate') || this.props.history.location.pathname.includes('/resetPassword') || this.props.history.location.pathname.includes('/recover') || this.props.history.location.pathname.includes('/pec/') || this.props.history.location.pathname.includes('/completeinvitation') || this.props.history.location.pathname.includes('/error')) {
      return (<div></div>)
    }

    const { classes } = this.props
    return (
      // <div classes={`${classes.content} customSidebar`}>
      <div classes={classes.content} className='customSidebar'>
        <CssBaseline />
        {/* Snackbar per le notifiche */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
            aria-describedby={this.state.snackbarType === 'error' ? 'error-snackbar' : 'success-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'error' ? <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> : <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} />}
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }
          />
        </Snackbar>
        {/* Dialog per le FAQ */}
        <HelpDialog
          setSnackbar={this.setSnackbar}
          open={this.state.isHelpDialogOpen}
          onClose={this.closeHelpDialog}
          domain={this.state.domain}
        />
        {/* Dialog per il cambio password */}
        <ChangePasswordDialog
          open={this.state.isChangePasswordModalOpened}
          onClose={this.closeChangePasswordModal}
          changePassword={this.changePassword}
        />
        {/* sidebar permanente - per pc */}
        <Hidden smDown implementation="js">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classNames(this.state.open ? classes.drawerPaper : classes.drawerPaperClose)
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                <Grid item xs>
                  <RenderLogo
                    domain={this.state.domain}
                    classes={classes}
                    open={this.state.open}
                  ></RenderLogo>
                  {/* { this.state.currOrganization && this.state.currOrganization.logos && this.state.currOrganization.logos.length > 0 && this.state.currOrganization.logos.filter(
                    x => x.type === 'logo')
                    ? <div className={classes.logoContainer}>
                      <img src={`${this.state.currOrganization.logos.filter(x => x.type === 'logo')[0].original}`} alt={`Logo ${this.state.currOrganization.name}`} style={{ width: '50px', height: '50px' }} />
                      <h4 className='siteName' style={{ marginRight: 'auto' }}>{this.state.currOrganization.name}</h4>
                    </div>
                    : <div className={classes.logoContainer}>
                      <img src={'/images/logo-trusty-minimal.png'} alt={'Logo Trusty'} className={classes.logImg} />
                      <h4 className='siteName' style={{ display: this.state.open ? 'block' : 'none', marginRight: 'auto' }}>Trusty</h4>
                    </div>} */}
                </Grid>
              </Grid>
              <IconButton onClick={this.handleDrawerOpen}>
                {this.getDrawerIcon()}
              </IconButton>
            </div>
            <Divider />
            {this.sidenavEntries(classes)}
            <div className={classes.bottomDrawerContainer}>
              {/* Elemento nella sidebar per il menu */}
              {/* <Box width="100%" pl={1} pr={1} pt={0.5} pb={0.5} className={classes.menuSection}>
                {this.state.open
                  ? <Button className={classes.accountButton} classes={{ textPrimary: classes.white }} size="large" color="primary" aria-label="Menu" onClick={this.handleClick}>
                    <AccountCircle />
                    <Typography component="span" variant="button" noWrap={true} style={{ marginLeft: '8px' }}>MENU</Typography>
                  </Button>
                  : <IconButton onClick={this.handleClick}>
                    <AccountCircle classes={{ colorPrimary: classes.white }} style={{ fontSize: '36px' }} color="primary" />
                  </IconButton>
                }
                <Menu
                  logout={this.logout}
                  openChangePasswordModal={this.openChangePasswordModal}
                  handleClose={this.handleClose}
                  anchorEl={this.state.anchorEl}
                />
              </Box> */}
              {/* <Tooltip title="Menu" placement="left">
                <Fab className={`${classes.fab} userIcon`} onClick={this.handleClick} id='userIconBtn'>
                  <AccountCircle />
                </Fab>
              </Tooltip>
              <Menu
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                logout={this.logout}
                openChangePasswordModal={this.openChangePasswordModal}
                handleClose={this.handleClose}
                anchorEl={this.state.anchorEl}
              /> */}
              {(this.state.domain.type === 'LISTED' && this.state.domain.domain !== 'trusty') &&
                <>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start" onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                      <Typography noWrap={true} className='siteName' variant="body2" component="span">
                        {this.props.t('sideBar:poweredBy')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start">
                      <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                        <div className={classes.logoContainer}>
                          <TrustyLogoBlack width="30px" height="30px"></TrustyLogoBlack>
                          <Typography noWrap={true} className={`${classes.helpText} siteName`} variant="body2" component="span">Trusty</Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </>
              }
              {/* utile solo nel caso di prova in cui sarà sul dominio nostro */}

              {(this.state.domain.type === 'LISTED' && this.state.domain.domain === 'trusty' && this.state.domain.subDomains.length > 1) &&
                <>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start" onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                      <Typography noWrap={true} className='siteName' variant="body2" component="span">
                        {this.props.t('sideBar:poweredBy')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start">
                      <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                        <div className={classes.logoContainer}>
                          <TrustyLogoBlack width="30px" height="30px"></TrustyLogoBlack>
                          <Typography noWrap={true} className={`${classes.helpText} siteName`} variant="body2" component="span">Trusty</Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </>
              }
              <Box width="100%" pt={2} pb={2} pl={1} pr={1}>
                <Box ml={0.5} display="flex" justifyContent="flex-start">
                  <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={this.handleClick}>
                    <PersonIcon className={classes.helpIcon} />
                    <Typography noWrap={true} className={classes.helpText} variant="body2" component="span">
                      {this.props.t('sideBar:settings')}
                    </Typography>
                  </Box>
                  <Menu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    logout={this.logout}
                    openChangePasswordModal={this.openChangePasswordModal}
                    handleClose={this.handleClose}
                    anchorEl={this.state.anchorEl}
                  />
                </Box>
              </Box>
              {/* <Box width="100%" pt={2} pb={2} pl={1} pr={1}>
                <Box ml={0.5} display="flex" justifyContent="flex-start">
                  <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={() => { this.openExternalSite(`${configuration.changeLog}`) }}>
                    <NewReleases className={classes.helpIcon} />
                    <Typography noWrap={true} className={classes.helpText} variant="body2" component="span">
                      {this.props.t('sideBar:changeLog')}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
              <Box width="100%" pt={2} pb={2} pl={1} pr={1}>
                <Box ml={0.5} display="flex" justifyContent="flex-start">
                  <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={this.openHelpDialog}>
                    <Help className={classes.helpIcon} />
                    <Typography noWrap={true} className={classes.helpText} variant="body2" component="span">
                      {this.props.t('sideBar:helpButton')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
          </Drawer>
        </Hidden>
        {/* sidebar nascosta - tablet e mobile */}
        <Hidden mdUp implementation="js">
          <Drawer
            className={classes.drawer}
            id='customMobileSidebar'
            variant="temporary"
            classes={{
              paper:
                classNames(classes.drawerPaper, !this.state.mobileOpen && classes.drawerPaperClose)
            }}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerClose}
          >
            <div className={classes.toolbarIcon}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                <Grid item xs>
                  <RenderLogo
                    domain={this.state.domain}
                    classes={classes}
                    open={true}
                  ></RenderLogo>
                </Grid>
              </Grid>
              <IconButton onClick={this.state.mobileOpen ? this.handleDrawerClose : this.handleDrawerOpen}>
                {this.getMobileDrawerIcon()}
              </IconButton>
            </div>
            <Divider />
            <div className='mobileMenu' id="mobileMenu">
              {this.sidenavEntries(classes)}
            </div>
            <div className={classes.bottomDrawerContainer}>
              {(this.state.domain.type === 'LISTED' && this.state.domain.domain !== 'trusty') &&
                <>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start" onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                      <Typography noWrap={true} className='siteName' variant="body2" component="span">
                        {this.props.t('sideBar:poweredBy')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start">
                      <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                        <div className={classes.logoContainer}>
                          <TrustyLogoBlack width="30px" height="30px"></TrustyLogoBlack>
                          <Typography noWrap={true} className={`${classes.helpText} siteName`} variant="body2" component="span">Trusty</Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </>
              }
              {/* utile solo nel caso di prova in cui sarà sul dominio nostro */}

              {(this.state.domain.type === 'LISTED' && this.state.domain.domain === 'trusty' && this.state.domain.subDomains.length > 1) &&
                <>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start" onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                      <Typography noWrap={true} className='siteName' variant="body2" component="span">
                        {this.props.t('sideBar:poweredBy')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box width="100%" pl={1} pr={1}>
                    <Box ml={0.5} display="flex" justifyContent="flex-start">
                      <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={() => { this.openExternalSite('https://www.trusty.id') }}>
                        <div className={classes.logoContainer}>
                          <TrustyLogoBlack width="30px" height="30px"></TrustyLogoBlack>
                          <Typography noWrap={true} className={`${classes.helpText} siteName`} variant="body2" component="span">Trusty</Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </>
              }
              <Box width="100%" pt={2} pb={2} pl={1} pr={1}>
                <Box ml={0.5} display="flex" justifyContent="flex-start">
                  <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={this.handleClick}>
                    <SettingsIcon className={classes.helpIcon} />
                    <Typography noWrap={true} className={classes.helpText} variant="body2" component="span">
                      {this.props.t('sideBar:settings')}
                    </Typography>
                  </Box>
                  <Menu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    logout={this.logout}
                    openChangePasswordModal={this.openChangePasswordModal}
                    handleClose={this.handleClose}
                    anchorEl={this.state.anchorEl}
                  />
                </Box>
              </Box>
              {/* <Box width="100%" pt={2} pb={2} pl={1} pr={1} className={classes.helpSectionMobile}>
                <Box ml={0.5} display="flex" justifyContent="flex-start">
                  <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={() => { this.openExternalSite('https://www.notion.so/Change-Log-119fe043f71f4f1db4fbd692c2742f11') }} >
                    <NewReleases className={classes.helpIcon} />
                    <Typography noWrap={true} className={classes.helpText} variant="body2" component="span">
                      {this.props.t('sideBar:changeLog')}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
              <Box width="100%" pt={2} pb={2} pl={1} pr={1} className={classes.helpSectionMobile}>
                <Box ml={0.5} display="flex" justifyContent="flex-start">
                  <Box width="100%" display="flex" p={1} alignItems="center" className={classes.helpButton} onClick={this.openHelpDialog}>
                    <Help className={classes.helpIcon} />
                    <Typography noWrap={true} className={classes.helpText} variant="body2" component="span">
                      {this.props.t('sideBar:helpButton')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool
}

export default withRouter(withStyles(styles, { withTheme: true })(withTranslation('sideBar')(Sidebar)))
