import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  TextField
} from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function QuantityComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    componentIndex,
    component,
    isModifyEnabled,
    componentChanged,
    type
  } = props

  return (
    <Grid item xs={12} md={6}>
      <Grid container direction='row' justify='flex-start' alignItems='flex-end' spacing={2}>
        <Grid item xs={8}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id={`${type}-${componentIndex}-quantity`}
            name='quantity'
            label={t('tabs.traceability.inputs.quantity')}
            type='number'
            min='0'
            fullWidth
            value={component.quantity}
            margin='normal'
            onChange={(e) => componentChanged(e, trackIndex, componentIndex)}
            disabled={!isModifyEnabled} />
        </Grid>
        <Grid item xs={2} style={{ marginBottom: '10px' }}>
          {component.uom ? component.uom : ''}
        </Grid>
      </Grid>
    </Grid>
  )
}

QuantityComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  component: PropTypes.object.isRequired, // input or output
  isModifyEnabled: PropTypes.bool.isRequired,
  componentChanged: PropTypes.func.isRequired, // inputChanged or outputChanged
  type: PropTypes.string.isRequired // input or output
}
export default QuantityComponent
