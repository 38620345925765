import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null
export let organizations = ls.get('organizations') || []

// FUNCTIONS
// esport query per i prori ACL
export async function returnSelfACL () {
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/accounts/self/aclrules/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
// esport query per le organizations
export async function returnOrganizations () {
  // console.log('dentro returnOrganizations')
  return axios.get(`${configuration.apiBaseUrl}/organizations/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

export function setLocalStorageSupplychain (supplychain) {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization.supplychains) {
    currOrganization.supplychains.push({
      supplychainId: supplychain.uuid,
      metadata: {}
    })
    ls.set('currOrganization', currOrganization)
  }
}

export function getLocalStorageSupplychain () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.supplychains) {
    return currOrganization.supplychains.map(supplychain => supplychain.supplychainId)
  }
}

export function getLocalStorageSupplychainFiltered () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.supplychains) {
    return currOrganization.supplychains
      .filter(supplychain => supplychain.metadata && !supplychain.metadata.hidden)
      .map(supplychain => supplychain.supplychainId)
  }
}

export function isThisAccountHasSupplychainFunctionality () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.functionality && currOrganization.functionality.length > 0 && currOrganization.functionality.includes('supplychain')) {
    return true
  } else {
    return false
  }
}

export function isThisAccountHasLightLotFunctionality () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.functionality && currOrganization.functionality.length > 0 && currOrganization.functionality.includes('light-lot')) {
    return true
  } else {
    return false
  }
}

export function isThisAccountHasPrintLabelFunctionality () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.functionality && currOrganization.functionality.length > 0 && currOrganization.functionality.includes('print-label')) {
    return true
  } else {
    return false
  }
}

export function isThisAccountHasBothLotTypeFunctionality () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.functionality && currOrganization.functionality.length > 0 && currOrganization.functionality.includes('both-lot-type')) {
    return true
  } else {
    return false
  }
}

export function isThisAccountHasSupplychain () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.supplychains && currOrganization.supplychains.length > 0) {
    if (currOrganization.supplychains.every(supplychain => supplychain.metadata && supplychain.metadata.hidden)) {
      console.log('sono qua:::::: nel return false')
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

export function isThisOrgIsFels () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.uuid === '0f83790a-d927-4eb6-b910-faf2473645b4') {
    return true
  } else {
    return false
  }
}

export function isThisOrgIsFoodItaliae () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.uuid === '5ad4a56c-9326-43e5-a236-f1a99f1edf66') {
    console.log('sono nel true')
    return true
  } else {
    console.log('sono nel false')
    return false
  }
}

export function isThisOrgIsComcap () {
  const currOrganization = ls.get('currOrganization')
  if (currOrganization && currOrganization.uuid === 'df810797-693c-4180-82a1-8a89bdae2de5') {
    return true
  } else {
    return false
  }
}

// funzione che torna/aggiorna l'ACL e l'organizzazione
export async function updateOrganizationACL () {
  console.log('dentro returnOrganizationACL')
  if (ls.get('token')) {
    try {
      // console.log('token => ', ls.get('token'))
      const aclResponse = await returnSelfACL()
      // console.log('aclResponse => ', aclResponse.data)
      // carico gli acl nell'utente
      const user = ls.get('user')
      user.acl = aclResponse.data.data
      // console.log('new user => ', user)
      ls.set('user', user)
      // mi prendo le organizzazioni
      const organizationsResponse = await returnOrganizations()
      // console.log('organizationsResponse => ', organizationsResponse.data)
      organizations = organizationsResponse.data.data
      ls.set('organizations', organizations)
      if (!ls.get('currOrganization')) {
        // nessuna organizzazione seleziono la prima
        currOrganization = organizations[0]
        ls.set('currOrganization', currOrganization)
      } else {
        const currIndex = organizations.findIndex(organization => organization.uuid === ls.get('currOrganization').uuid)
        if (currIndex > -1) {
          currOrganization = organizations[currIndex]
        } else {
          // se c'è un'organizzazione ma non la trovo setto la prima
          currOrganization = organizations[0]
          ls.set('currOrganization', currOrganization)
        }
      }
    } catch (e) {
      // window.alert('Something went wrong')
      console.error('Error while loading organization, e => ', e)
    }
  } else {
    console.log('no user token')
  }
}
