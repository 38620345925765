import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'
import { createSupplychainMembership, modifyResource } from './supplychains.js'
// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnLotsCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}

// esport query per i lotti
export async function returnAllLots () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// esport query per avere alcuni lotti
export async function returnSomeLots (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllLots()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}

// funzione che crea una nuovo lotto
export async function returnNewLot (newLot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  let supplychainIds = []
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è il prodotto
  if (!newLot) {
    return
  }

  if (newLot.supplychains) {
    supplychainIds = newLot.supplychains.map(supplychain => supplychain.id)
    delete newLot.supplychains
  }
  console.log('BEFORE QUERY newLot => ', newLot)
  // mando la query
  const response = await axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/`, newLot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(newLot.organizationId, 'lot', response.data.data.uuid, supplychainIds)
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }
  return response
}
// funzione che crea una nuovo lotto
export async function createDefaultLot (productId) {
  if (!productId) {
    return
  }

  const response = await axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/create`, { productId }, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  return response
}
export async function duplicateLot (uuid) {
  if (!uuid) {
    return
  }

  const response = await axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/duplicate`, { uuid }, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })

  return response
}
// funzione che modifica un lotto
export async function editLot (lot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  let supplychainIds = []
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  if (lot.supplychains) {
    supplychainIds = lot.supplychains.map(supplychain => supplychain.id)
    delete lot.supplychains
  }
  const verified = lot.verified
  delete lot.verified
  console.log('BEFORE QUERY edit lot => ', lot)
  // mando la query
  const response = await axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(lot.organizationId, 'lot', response.data.data.uuid, supplychainIds, verified)
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }
  lot.verified = verified
  return response
}

// funzione che rimpiazza un lotto
export async function replaceLot (lot) {
  /* questo array di supplychainsId mi serve per sapere a quali filiere devo iscrivere la risorsa.
  Me lo definisco qui in quanto poi lo cancello dalla risorsa prima di inviarlo all'update/create (poichè la risorsa non l'ha definita nel modello) */
  let supplychainIds = []

  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  if (lot.supplychains) {
    supplychainIds = lot.supplychains.map(supplychain => supplychain.id)
    delete lot.supplychains
  }
  const verified = lot.verified
  /* cancello verified così da non mandarlo alla replace del Lot ma poi lo ricopio perché in chiusura della modal lo utilizzo
     per la snackbar
  */
  delete lot.verified
  console.log('BEFORE QUERY replace lot => ', lot)

  const response = await axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}/replace/`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
  if ((Array.isArray(supplychainIds))) {
    try {
      await createSupplychainMembership(lot.organizationId, 'lot', response.data.data.uuid, supplychainIds, verified)
      for (const supplychainId of supplychainIds) {
        await modifyResource(response.data.data.uuid, supplychainId, { verified })
      }
    } catch (e) {
      response.error = {
        statusCode: e.response.status,
        textMessage: e.response.data.error.message
      }
    }
  }
  /* */
  lot.verified = verified
  return response
}

// funzione che modifica un'azienda
export async function deleteLot (lot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  console.log('BEFORE QUERY delete lot => ', lot)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// export funzione che mi tira fuori ultimi valori del lotto
export const getLastInputOrOutputInformation = (lot, product, productsIdNameMap) => {
  const traceability = lot.traceability
  if (!traceability || !traceability.length) {
    return
  }
  if (!Object.keys(productsIdNameMap).length) {
    return
  }

  const inputs = traceability[traceability.length - 1].inputs
  // const productLastTrack = product.traceability[product.traceability.length - 1]

  const lastLot = {
    trackName: traceability[traceability.length - 1].name,
    productName: inputs.length > 0 ? productsIdNameMap[inputs[inputs.length - 1].productId] : '',
    quantity: inputs.length > 0 ? inputs[inputs.length - 1].quantity : '',
    lotNumber: inputs.length > 0 ? lot.lotNumber || inputs[inputs.length - 1].lotNumber : '',
    gtin: product.gtin || null
  }
  // console.log('lastLot => ', lastLot)
  // console.log('productLastTrack.outputs => ', productLastTrack.outputs)
  if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length && traceability[traceability.length - 1].outputs.length > 0) {
    const outputs = traceability[traceability.length - 1].outputs
    lastLot.productName = productsIdNameMap[outputs[outputs.length - 1].productId]
    lastLot.quantity = outputs[outputs.length - 1].quantity
    lastLot.lotNumber = lot.lotNumber || outputs[outputs.length - 1].lotNumber
  }

  return lastLot
}

export const getLastInputOrOutputInformationSupplychainField = (lot) => {
  const traceability = lot.traceability
  if (!traceability || !traceability.length) {
    return
  }
  const inputs = traceability[traceability.length - 1].inputs
  let quantity = inputs[inputs.length - 1].quantity
  if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
    const outputs = traceability[traceability.length - 1].outputs
    quantity = outputs[outputs.length - 1].quantity
  }
  return quantity
}

export async function returnAllSupplychainLotProjection (supplychainId, params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
  return response
}
export async function returnSomeSupplyChainLots (supplychainId, params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  const response = await axios.get(`${configuration.apiBaseUrl}/smlinstances/supplychains/${supplychainId}/lots`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
  return response
}
