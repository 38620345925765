import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  Grid,
  Fab,
  Chip
} from '@material-ui/core'

import {
  Add as AddIcon,
  InsertDriveFile as InsertDriveFileIcon
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  input: {
    display: 'none'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  }
}))

function AddDocumentsComponent (props) {
  const classes = useStyles()
  const {
    track,
    trackIndex,
    isModifyEnabled,
    deleteTraceabilityDocument,
    openMedia,
    addTraceabilityDocument,
    translation
  } = props
  const { t } = useTranslation(translation)

  const [title, setTitle] = React.useState('')
  const [tooltip, setTooltip] = React.useState('')
  const [placeholder, setPlaceholder] = React.useState('')
  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setTitle(t('tabs.traceability.docTitle'))
        setTooltip(t('tabs.traceability.docTooltip'))
        setPlaceholder(t('tabs.traceability.docPlaceholder'))
        break
      }
      case 'lotDetails' : {
        setTitle(t('tabs.traceability.docTitle'))
        setTooltip(t('tabs.traceability.docTooltip'))
        setPlaceholder(t('tabs.traceability.docPlaceholder'))
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid item xs={12} style={{ width: '100%', marginTop: 12 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" component="h5">
          {title}
        </Typography>
        <input
          className={classes.input}
          id={`add-new-document-${trackIndex}`}
          type="file"
          accept="image/png, image/jpeg, application/pdf"
          multiple
          onChange={(e) => addTraceabilityDocument(e, trackIndex)} style={{ display: 'none' }}
          disabled={!isModifyEnabled } />
        <label htmlFor={`add-new-document-${trackIndex}`}>
          <Fab
            classes={{ primary: classes.fabPrimary }}
            size="small"
            component="span"
            color="primary"
            aria-label={tooltip}
            disabled={!isModifyEnabled}>
            <AddIcon />
          </Fab>
        </label>
      </div>
      <div style={{ marginTop: 12, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {track.documents ? track.documents.map((document, documentIndex) => <Chip
          title={document.name || placeholder}
          style={{ margin: '4px 8px' }}
          size="small"
          key={`doc-${documentIndex}`}
          icon={<InsertDriveFileIcon />}
          classes={{ colorPrimary: classes.chipPrimary }}
          color="primary"
          label={<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100px' }}>{document.name}</span> || placeholder}
          onClick={() => openMedia(document.fileUrl)}
          onDelete={() => isModifyEnabled ? deleteTraceabilityDocument(trackIndex, documentIndex) : ''}
        />) : ''}
      </div>
    </Grid>
  )
}

AddDocumentsComponent.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  openMedia: PropTypes.func.isRequired,
  addTraceabilityDocument: PropTypes.func.isRequired,
  deleteTraceabilityDocument: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default AddDocumentsComponent
