import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import { makeStyles, Grid, Card, CardHeader, CardContent, Typography } from '@material-ui/core'
import { useHomeStats, normalizeEvents } from './homeStats'
import EventsChart from './EventsChart'
import HomeCard from './HomeCard'
import moment from 'moment'
import ChartFilter from './ChartFilter'
import { useTranslation } from 'react-i18next'
import ls from 'local-storage'
import Loader from '../Loader'
import { isThisAccountHasLightLotFunctionality } from '../../utils/acl-organization'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3)
  }
}))

function Home (props) {
  const root = document.getElementById('root')
  const menu = document.getElementById('customMobileSidebar')
  root.className = ''
  root.classList.add('home')
  if (menu) {
    menu.className = ''
    menu.classList.add('home')
  }
  const userData = ls.get('user')
  const classes = useStyles()
  const [companies, facilities, products, totalLots, events, lightLots] = useHomeStats()
  const { t } = useTranslation('home')
  const [currentPeriod, setCurrentPeriod] = useState('month')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const hasLightLotFunctionality = isThisAccountHasLightLotFunctionality()
  // const history = useHistory()
  const loadedGuide = true

  const normalizedEvents = normalizeEvents(events)

  const filterEvents = (allEvents, filter) => {
    switch (filter) {
      case 'week': {
        const startDate = moment().startOf('isoWeek')
        const endDate = moment().endOf('isoWeek')
        return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
      }
      case 'month': {
        const startDate = moment().startOf('month')
        const endDate = moment().endOf('month')
        return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
      }
      case 'year': {
        const startDate = moment().startOf('year')
        const endDate = moment().endOf('year')
        return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
      }
      case 'custom': {
        if (startDate && endDate) {
          return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
        } else {
          return []
        }
      }
      default: {
        return allEvents
      }
    }
  }
  async function fetchData () {
    // if (props.guidedLoaded) {
    //   const response = await axios({
    //     method: 'GET',
    //     url: `${configuration.apiBaseUrl}/accounts/self/onboardings/last`,
    //     headers: {
    //       authorization: `Bearer ${ls.get('token')}`
    //     }
    //   })

    //   const lastOnboarding = response.data.data
    //   // Quando la guida non esiste e gli step non sono stati completati
    //   if (!lastOnboarding || (!lastOnboarding.completed && !lastOnboarding.guides[onboardingConfig.home.id])) {
    //     let dataToSend = lastOnboarding
    //     if (!dataToSend) {
    //       dataToSend = {
    //         accountId: userData.uuid,
    //         guides: {
    //           [onboardingConfig.home.id]: {
    //             steps: []
    //           }
    //         },
    //         completed: false
    //       }
    //     }

    //     window.userGuidingLayer.push({
    //       event: 'onload',
    //       fn: () => setLoadedGuide(true)
    //     })

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewStart',
    //       fn: () => setLoadedGuide(true)
    //     })

    //     window.userGuiding.previewGuide(onboardingConfig.home.id)
    //     console.log('start guida', onboardingConfig.home.id)

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewStep',
    //       fn: async data => {
    //         const d = new Date()
    //         if (!Object.prototype.hasOwnProperty.call(dataToSend.guides, onboardingConfig.home.id)) {
    //           dataToSend.guides[onboardingConfig.home.id] = { steps: [] }
    //           console.log('creo step', onboardingConfig.home.id)
    //         }

    //         if (typeof dataToSend.guides[onboardingConfig.home.id].steps[data.stepIndex] !== 'object') {
    //           dataToSend.guides[onboardingConfig.home.id].steps[data.stepIndex] = {}
    //           console.log('creo oggetti', onboardingConfig.home.id)
    //         }

    //         dataToSend.guides[onboardingConfig.home.id].steps[data.stepIndex].startedAt = d
    //         console.log('data creazione', onboardingConfig.home.id)
    //         if (data.stepIndex > 0) {
    //           dataToSend.guides[onboardingConfig.home.id].steps[data.stepIndex - 1].completedAt = d
    //           console.log('data termine', onboardingConfig.home.id)
    //         }

    //         if (lastOnboarding) {
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${lastOnboarding.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         } else {
    //           const response = await axios({
    //             method: 'POST',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })

    //           dataToSend = response.data.data
    //         }
    //       }
    //     })

    //     window.userGuidingLayer.push({
    //       event: 'onPreviewEnd',
    //       fn: async data => {
    //         if (data.completed) {
    //           const { steps } = dataToSend.guides[onboardingConfig.home.id]
    //           steps[steps.length - 1].completedAt = new Date()
    //           await axios({
    //             method: 'PUT',
    //             url: `${configuration.apiBaseUrl}/accounts-onboardings/${dataToSend.uuid}`,
    //             data: dataToSend,
    //             headers: {
    //               authorization: `Bearer ${ls.get('token')}`
    //             }
    //           })
    //         }
    //       }
    //     })
    //   } else {
    //     setLoadedGuide(true)
    //   }
    // } else {
    //   setLoadedGuide(true)
    // }

    // // Quando la guida è completa
    // if (lastOnboarding.guides[onboardingConfig.home.id] && lastOnboarding.guides[onboardingConfig.home.id].steps.length === onboardingConfig.home.steps) {
    //   // history.push('/companies')
    //   window.location.replace('/companies')
    //   console.log('Cambio location')
    // }
  }

  useEffect(() => {
    fetchData()
    // setTotalLot(lots + lightLots)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.uuid, loadedGuide])

  return (
    <>
      <Loader
        show={!loadedGuide}
        // text={t('guide.loading')}
      >
      </Loader>
      <Typography className={classes.title} component='h1' variant='h4'>{t('title')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={companies} type='companies' />
        </Grid>
        <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={facilities} type='facilities' />
        </Grid>
        <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={products} type='products' />
        </Grid>
        {!hasLightLotFunctionality && <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={totalLots} type='lots' />
        </Grid>
        }
        {(hasLightLotFunctionality) && <Grid item xs={12} lg={3} md={4} sm={6} className='boxContainer'>
          <HomeCard data={lightLots} type='light-lots' />
        </Grid>
        }
      </Grid>
      {!hasLightLotFunctionality && <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              className='chartFilter'
              title={t('graph.title')}
              action={
                <ChartFilter
                  setCurrentPeriod={setCurrentPeriod}
                  currentPeriod={currentPeriod}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              }
            />
            <CardContent>
              <EventsChart data={filterEvents(normalizedEvents, currentPeriod)} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      }
    </>
  )
}

export default Home
