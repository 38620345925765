import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Typography
} from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function NameComponent (props) {
  // const classes = useStyles()
  const { track } = props
  const { t } = useTranslation('lotDetails')

  return (
    <Grid item xs={10}>
      <Typography variant="h5">
        {track.name ? track.name : t('tabs.traceability.generalName')}
      </Typography>
    </Grid>

  )
}

NameComponent.propTypes = {
  track: PropTypes.object.isRequired
}
export default NameComponent
