import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  TextField
} from '@material-ui/core'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function DescriptionComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const { track, valueChanged, trackIndex, isModifyEnabled } = props

  return (
    <TextField InputLabelProps={{ shrink: true }}
      margin="dense"
      id="description"
      name="description"
      value={track.description}
      label={t('tabs.traceability.inputs.description')}
      onChange={(e) => valueChanged(e, trackIndex)}
      disabled={!isModifyEnabled}
      type="text" fullWidth rowsMax="12" rows="4" multiline />
  )
}

DescriptionComponent.propTypes = {
  track: PropTypes.object.isRequired,
  valueChanged: PropTypes.func.isRequired,
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired

}
export default DescriptionComponent
