/* Componente Padre che forma il blocco productId, LotNumber, Quantity,Uom */
import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  TextField
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function LotNumberComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    componentIndex,
    component,
    isModifyEnabled,
    componentChanged,
    type
  } = props

  return (
    <Grid item xs={12} md={6}>
      <TextField
        required
        InputLabelProps={{ shrink: true }}
        id={`${type}-${trackIndex}-${componentIndex}-lotNumber`}
        name='lotNumber'
        label={t('tabs.traceability.inputs.lotNumber')}
        type='text'
        fullWidth
        value={component.lotNumber}
        margin='normal'
        onChange={(e) => componentChanged(e, trackIndex, componentIndex)}
        disabled={!isModifyEnabled} />
    </Grid>
  )
}

LotNumberComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  component: PropTypes.object.isRequired, // input or output
  isModifyEnabled: PropTypes.bool.isRequired,
  componentChanged: PropTypes.func.isRequired, // inputChanged or outputChanged
  type: PropTypes.string.isRequired // input or output
}
export default LotNumberComponent
