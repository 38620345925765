// import axios from 'axios'
// import ls from 'local-storage'
// import configuration from '../configuration'
import i18n from '../i18n'

// VARIABLES
// variabile con la lingua attuale
export let currLang = getCurrLang()
console.log('currLang => ', currLang)

// FUNCTIONS
// funzione che mi ritorna la lingua attuale
export function getCurrLang () {
  console.log('language:::::', i18n.language)
  return i18n.language
}

// funzione che fa cambiare lingua
export function changeLanguage (lang) {
  console.log('new lang => ', lang)
  if (!lang) {
    lang = 'it-IT'
  }
  i18n.changeLanguage(lang)
  currLang = getCurrLang()
}

export function returnInClassicFormat (lang) {
  let language = ''
  console.log('lang:::::', lang)
  switch (lang) {
    case 'it': {
      language = 'it-IT'
      break
    }
    case 'fr':
      language = 'fr-FR'
      break
    case 'en':
      language = 'en-EN'
      break
    default :
      language = 'en-US'
      break
  }
  return language
}

// funzione che ritorna la lingua leggibile
export function returnAbbr (lang) {
  let abbr = 'Ita'
  if (lang === 'en-GB' || lang === 'en-US') {
    abbr = 'Eng'
  } else if (lang === 'fr-FR') {
    abbr = 'Fra'
  }
  return abbr
}

// funzione che ritorna la lingua leggibile
export function returnLangName (lang) {
  let name = 'Italiano'
  if (lang === 'en-GB' || lang === 'en-US') {
    name = 'English'
  } else if (lang === 'fr-FR') {
    name = 'French'
  }
  return name
}
