import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Tooltip,
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  Delete as DeleteIcon
} from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function DeleteComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    componentIndex,
    isModifyEnabled,
    deleteComponent
  } = props

  return (
    <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
      <Grid item xs={6} md={6}>
        <Grid container direction='row' justify='flex-end' alignItems='flex-start' spacing={2}>
          <Grid item xs={12} lg={12} style={{ display: 'flex', direction: 'row', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
            <Tooltip title={t('tabs.traceability.inputs.deleteInput')} placement='top-end'>
              <Button className="inputCardButton" onClick={() => deleteComponent(trackIndex, componentIndex)} disabled={!isModifyEnabled} size="small" aria-label="elimina input">
                <DeleteIcon />
                <span>{t('tabs.traceability.inputs.delete')}</span>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

DeleteComponent.propTypes = {
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  deleteComponent: PropTypes.func.isRequired // deleteInput or deleteOutput
}
export default DeleteComponent
