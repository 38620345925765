/* Componente che farà il
1. parse dell' url che riceve come props tramite il modulo parse-domain
2. cerca l'immagine giusta da renderizzare per il logo
3. ritorna l'elemento renderizzato con l'immagine giusta
// https://publicsuffix.org/list/public_suffix_list.dat

Mock parseDomain: Object: {
domain: "filierapuntozero"
hostname: "app.filierapuntozero.it"
icann: {subDomains: Array(1), domain: "filierapuntozero", topLevelDomains: Array(1)}
labels: (3) ["app", "filierapuntozero", "it"]
subDomains: ["app"]
topLevelDomains: ["it"]
type: "LISTED"
}
*/
import React from 'react'
import { parseDomain } from 'parse-domain'
import PropTypes from 'prop-types'
import { Grid, GridList, GridListTile } from '@material-ui/core'
import { domainToLogos } from '../../domainToLogos'

import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  emailSent: {
    width: '180px'
  },
  media: {
    height: 108,
    marginTop: '20px',
    backgroundSize: '70%',
    backgroundPosition: 'center center'
  },
  logoContainer: {
    backgroundSize: 'contain!important',
    maxHeight: 50,
    marginTop: 20
  },
  logoContainerCustom: {
    backgroundSize: 'contain!important',
    marginTop: 20
  },
  logImgContainer: {
    textAlign: 'center'
  },
  logImg: {
    maxWidth: '15%',
    height: 'auto',
    width: '15%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logImgCustom: {
    maxWidth: '100%',
    height: 'auto',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }

}))

function MoreLogos (props) {
  /* caso localhost */
  if (props.domain.type === 'INVALID') {
    if (!props.isMoreLogos) {
      return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          {domainToLogos.localhost.centralLogos
            .filter((logo, index) => index > 0)
            .map(logo => (
              <Grid key={logo.img} item xs={12} className={props.classes.logImgContainer}>
                <a href={logo.url}>
                  <img src={logo.img} alt={logo.title} className={props.classes.logImgCustom} />
                </a>
              </Grid>
            ))}
        </Grid>
      )
    } else {
      return (
        <Grid container direction="row">
          <GridList cols= {domainToLogos.localhost.centralLogos.length - 1 }>
            {domainToLogos.localhost.centralLogos
              .filter((logo, index) => index > 0)
              .map(logo => (
                <GridListTile key={logo.img} item xs={12} cols= {logo.cols} className={props.classes.logImgContainer}>
                  <a href={logo.url}>
                    <img src={logo.img} alt={logo.title} className={props.classes.logImgCustom} />
                  </a>
                </GridListTile>
              ))}
          </GridList>
        </Grid>
      )
    }
  } else {
    if (!props.isMoreLogos) {
      return (
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          {domainToLogos[props.domain.domain].centralLogos
            .filter((logo, index) => index > 0)
            .map(logo => (
              <Grid key={logo.img} item xs={12} className={props.classes.logImgContainer}>
                <a href={logo.url}>
                  <img src={logo.img} alt={logo.title} style={{ visibility: `${props.visibility}` }} className={props.classes.logImg} />
                </a>
              </Grid>
            ))}
        </Grid>
      )
    } else {
      return (
        <Grid container direction="row">
          <GridList cols= {domainToLogos[props.domain.domain].centralLogos.length - 1 }>
            {domainToLogos[props.domain.domain].centralLogos
              .filter((logo, index) => index > 0)
              .map(logo => (
                <GridListTile key={logo.img} item xs={12} cols= {logo.cols} className={props.classes.logImgContainer}>
                  <a href={logo.url}>
                    <img src={logo.img} alt={logo.title} style={{ visibility: `${props.visibility}` }} className={props.classes.logImgCustom} />
                  </a>
                </GridListTile>
              ))}
          </GridList>
        </Grid>
      )
    }
  }
}

function DomainLogoDown (props) {
  const domain = parseDomain(props.url)
  const classes = useStyles()
  console.log('classes', classes)
  if (domain.type === 'INVALID') {
    return (
      domainToLogos.localhost.centralLogos.length < 3
        ? (<MoreLogos
          visibility = 'visible'
          domain = {domain}
          classes = {classes}
          isMoreLogos = {false}
        ></MoreLogos>)
        : (<MoreLogos
          visibility = 'visible'
          domain = {domain}
          classes = {classes}
          isMoreLogos = {true}
        ></MoreLogos>)
    )
  } else {
    if (domain.domain === 'trusty') {
      if (domain.subDomains.length > 1) {
        return (
          domainToLogos[domain.subDomains[1]].centralLogos.length < 2
            ? (<MoreLogos
              visibility = 'hidden'
              domain = {domain}
              classes = {classes}
              isMoreLogos = {false}
            ></MoreLogos>)
            : (<MoreLogos
              visibility = 'hidden'
              domain = {domain}
              classes = {classes}
              isMoreLogos = {true}
            ></MoreLogos>)
        )
      }
    }
    return (
      domainToLogos[domain.domain].centralLogos.length < 3
        ? (<MoreLogos
          visibility = 'visible'
          domain = {domain}
          classes = {classes}
          isMoreLogos = {false}
        ></MoreLogos>)
        : (<MoreLogos
          visibility = 'visible'
          domain = {domain}
          classes = {classes}
          isMoreLogos = {true}
        ></MoreLogos>)
    )
  }
}

MoreLogos.propTypes = {
  domain: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isMoreLogos: PropTypes.bool.isRequired
}

/* da decommentare quando si andrà in testing o in production */

DomainLogoDown.propTypes = {
  url: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired

}

export default DomainLogoDown
