import React, { useState } from 'react'
import AccountsService from '../Accounts.service'
import organizationsService from '../Organizations.service'
// import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Card,
  Button,
  CardHeader,
  makeStyles
} from '@material-ui/core'
import {
  Link,
  useParams,
  withRouter
} from 'react-router-dom'
// import CompleteInvitationForm from './CompleteInvitationForm'
import DomainLogo from '../DomainLogo/DomainLogo'
import { acceptInvite } from '../../utils/supplychains'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    },
    '.MuiGrid-container': {
      height: '100vh'
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: 'linear-gradient(45deg, #09A963 40%, #3BD982 100%)'
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))

// window.userGuiding.hideChecklist()
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function CompleteInvitationSupplychain (props) {
  const classes = useStyles()
  const [userData, setUserData] = useState(AccountsService.getCurrentAccount())
  const { token, email, companyId, supplychainId } = useParams()
  const { t } = useTranslation('supplychain')
  // const [statusCode, setStatusCode] = useState(null)
  // window.userGuiding.hideChecklist()

  // const selectErrorCase = (code) => {
  //   switch (code) {
  //     case 400:
  //       return (
  //         <Box width='100%' mb={3} textAlign='center'>
  //           <Message buttonText={t('notifications.badRequest')} action={() => setStatusCode(null)}>{t('notifications.badRequest')}</Message>
  //         </Box>
  //       )
  //     case 500:
  //       return (
  //         <Box width='100%' mb={3} textAlign='center'>
  //           <Message buttonText={t('notifications.applicationError')} action={() => setStatusCode(null)}>{t('notifications.applicationError')}</Message>
  //         </Box>
  //       )
  //     case 404:
  //       return (
  //         <Box width='100%' mb={3} textAlign='center'>
  //           <Message buttonText={t('notifications.notFound')} action={() => setStatusCode(null)}>{t('notifications.notFound')}</Message>
  //         </Box>
  //       )
  //     case 403:
  //       return (
  //         <Box width='100%' mb={3} textAlign='center'>
  //           <Message buttonText={t('notifications.forbidden')} action={() => setStatusCode(null)}>{t('notifications.forbidden')}</Message>
  //         </Box>
  //       )
  //     case 409:
  //       return (
  //         <Box width='100%' mb={3} textAlign='center'>
  //           <Message buttonText={t('notifications.conflict')} action={() => setStatusCode(null)}>{t('notifications.conflict')}</Message>
  //         </Box>
  //       )
  //     default:
  //       return null
  //   }
  // }

  const disconnect = () => {
    AccountsService.removeAll()
    setUserData('')
  }
  const acceptInvitation = async () => {
    await acceptInvite(
      email,
      token,
      supplychainId,
      companyId,
      props.domain.domain
    )

    const currOrganization = organizationsService.getCurrOrganization()
    currOrganization.supplychains.push({
      supplychainId,
      metadata: {}
    })
    organizationsService.setCurrOrganization(currOrganization)
    window.location.pathname = '/'
  }

  return (
    <div className='test'>
      <Grid container justify='center' alignItems='center' style={{ height: '100vh;' }}>
        <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
          <Card className={'signContainer'}>
            <DomainLogo url={window.location.host}></DomainLogo>
            <CardHeader
              className='signInHeader'
              title={t('completeInvitation.title')}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={t('completeInvitation.subtitle', { whitelabel: capitalize(props.domain.domain) })}
            />
            <Button
              className={classes.submitButton}
              color='primary'
              type='submit'
              size='large'
              variant='contained'
              onClick={acceptInvitation}
              disabled={!userData || userData.email.indexOf(email) === -1}
            >
              {t('completeInvitation.join')}
            </Button>
            {!userData &&
            <Link
              to={{
                pathname: '/signin',
                state: {
                  fromCompleteInvitationSupplychain: true,
                  email,
                  token,
                  supplychainId,
                  companyId
                }
              }}
            >
              {t('completeInvitation.loginFirst')}
            </Link>
            }
            {userData && userData.email && userData.email.indexOf(email) === -1 &&
            <Link
              onClick={disconnect}
            >
              {t('completeInvitation.logoutFirst')}
            </Link>
            }
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(CompleteInvitationSupplychain)
