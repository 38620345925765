/* Componente che farà il
1. parse dell' url che riceve come props tramite il modulo parse-domain
2. cerca l'immagine giusta da renderizzare per il logo
3. ritorna l'elemento renderizzato con l'immagine giusta
// https://publicsuffix.org/list/public_suffix_list.dat

Mock parseDomain: Object: {
domain: "filierapuntozero"
hostname: "app.filierapuntozero.it"
icann: {subDomains: Array(1), domain: "filierapuntozero", topLevelDomains: Array(1)}
labels: (3) ["app", "filierapuntozero", "it"]
subDomains: ["app"]
topLevelDomains: ["it"]
type: "LISTED"
}
*/
import React from 'react'
import { parseDomain } from 'parse-domain'
import PropTypes from 'prop-types'
import CardMedia from '@material-ui/core/CardMedia'
import { domainToLogos } from '../../domainToLogos'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  emailSent: {
    width: '180px'
  },
  media: {
    height: 108,
    marginTop: '20px',
    backgroundSize: '70%',
    backgroundPosition: 'center center'
  },
  logoContainer: {
    backgroundSize: 'contain!important',
    maxHeight: 50,
    marginTop: 20
  },
  logoContainerCustom: {
    backgroundSize: 'contain!important',
    marginTop: 20
  }

}))
function DomainLogo (props) {
  const domain = parseDomain(props.url)

  const classes = useStyles()
  if (domain.type === 'INVALID') {
    return (<CardMedia
      className={`${classes.media} ${classes.logoContainer}`}
      image={`${domainToLogos.localhost.centralLogos[0].img}`}
      title={`${domainToLogos.localhost.centralLogos[0].title}`}
    />)
  } else {
    if (domain.domain === 'trusty') {
      if (domain.subDomains.length > 1) {
        return (<CardMedia
          className={`${classes.media} ${classes.logoContainerCustom}`}
          image = {domainToLogos[domain.subDomains[1]].centralLogos[0].img}
          title={domainToLogos[domain.subDomains[1]].centralLogos[0].title}
        />)
      }
    }
    return (<CardMedia
      className={`${classes.media} ${classes.logoContainer}`}
      image = {domainToLogos[domain.domain].centralLogos[0].img}
      title={domainToLogos[domain.domain].centralLogos[0].title}
    />)
  }
}

DomainLogo.propTypes = {
  url: PropTypes.string.isRequired
}

export default DomainLogo
