const manifest = require('../package.json')
const configuration = {
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  environment: process.env.REACT_APP_ENV,
  frontendBaseUrl: process.env.REACT_APP_FRONTEND_BASE_URL || 'https://testdiscover.trusty.id',
  blockchainExplorer: 'https://www.blockchain.com',
  sentry: {
    dsn: 'https://f29b16cf416546f7ae758478d14a067a@sentry.apio.cloud/15'
  },
  smartlook: {
    apiKey: '921799a88e1f5fb5267f7a1bd3a3c63b5c80d642'
  }
}

export default configuration
