import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Tooltip,
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  Add as AddIcon
} from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function AddComponent (props) {
  // const classes = useStyles()
  const { t } = useTranslation('lotDetails')

  const {
    trackIndex,
    addComponent,
    isModifyEnabled,
    toolTipTitle
  } = props

  return (
    <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
      <Grid item xs={6} md={6}>
        <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
          <Grid item xs={12}>
            <Tooltip title={t(`${toolTipTitle}`)} placement='top-end'>
              <Button className="inputCardButton" onClick={() => addComponent(trackIndex)} disabled={!isModifyEnabled} size="small" aria-label="aggiungi input" >
                <AddIcon />
                <span>{t('tabs.traceability.inputs.add')}</span>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

AddComponent.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  addComponent: PropTypes.func.isRequired,
  toolTipTitle: PropTypes.string.isRequired
}
export default AddComponent
