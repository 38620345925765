/* questo componente si occupa di mettere la la cancellazione a fianco della select */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Tooltip,
  Button
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  Clear as ClearIcon
} from '@material-ui/icons'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function DeleteComponentSimple (props) {
  // const classes = useStyles()

  const {
    trackIndex,
    componentIndex,
    isModifyEnabled,
    deleteComponent,
    translation
  } = props
  const { t } = useTranslation(translation)

  const [title, setTitle] = React.useState('')
  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setTitle(t('tabs.traceability.inputs.delete'))
        break
      }
      case 'lotDetails' : {
        setTitle(t('tabs.traceability.inputs.delete'))
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid item xs={4} md={4}>
      <Tooltip title={title} placement='top-end'>
        <Button className="inputCardButton" onClick={() => deleteComponent(trackIndex, componentIndex)} disabled={!isModifyEnabled} size="small" aria-label="elimina input">
          <ClearIcon />
        </Button>
      </Tooltip>
    </Grid>
  )
}

DeleteComponentSimple.propTypes = {
  componentIndex: PropTypes.number.isRequired, // inputIndex or outputIndex
  trackIndex: PropTypes.number.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  deleteComponent: PropTypes.func.isRequired, // deleteInput or deleteOutput
  translation: PropTypes.string.isRequired
}
export default DeleteComponentSimple
