/* Componente Padre che forma il blocco productId, LotNumber, Quantity,Uom */
import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import LocationSourceComponent from '../LocationComponent/LocationSourceComponent'
import DeleteComponentSimple from '../AdditionalBlocks/DeleteComponentSimple'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   },
//   facilityCard: {
//     padding: '30',
//     backgroundColor: '#fafafa',
//     borderRadius: 8,
//     marginBottom: 25,
//     '-webkit-box-shadow': '0px 0px 5px 1px #eaeaea',
//     boxShadow: '0px 0px 5px 1px #eaeaea'
//   }
// }))

function FacilityFromComponent (props) {
  // const classes = useStyles()
  const {
    locationIndex,
    trackIndex,
    track,
    isModifyEnabled,
    locationFrom,
    locationFromChanged,
    facilities,
    deleteFacilityFrom,
    translation
  } = props

  return (
    <div key={`locationFrom-${locationIndex}`}>
      <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
        <LocationSourceComponent
          key={`${locationFrom}-${locationIndex}-${trackIndex}`}
          track={track}
          trackIndex={trackIndex}
          locationFrom={locationFrom}
          locationIndex={locationIndex}
          locationFromChanged={locationFromChanged}
          isModifyEnabled={isModifyEnabled}
          facilities={facilities}
          translation={translation}
        ></LocationSourceComponent>
        <DeleteComponentSimple
          trackIndex={trackIndex}
          componentIndex={locationIndex}
          deleteComponent={deleteFacilityFrom}
          isModifyEnabled={isModifyEnabled}
          translation={translation}
        ></DeleteComponentSimple>
      </Grid>
    </div>
  )
}

FacilityFromComponent.propTypes = {
  track: PropTypes.object.isRequired,
  locationIndex: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  locationFrom: PropTypes.string.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  locationFromChanged: PropTypes.func.isRequired,
  facilities: PropTypes.array.isRequired,
  deleteFacilityFrom: PropTypes.func.isRequired,
  translation: PropTypes.string.isRequired
}
export default FacilityFromComponent
