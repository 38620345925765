import React, { useState } from 'react'
import { Grid, Card, makeStyles, CardHeader, Box, Avatar, Container } from '@material-ui/core'
import { Link } from 'react-router-dom'
import SignupForm from './SignupForm'
import AccountIcon from '@material-ui/icons/AccountCircleTwoTone'
import Message from '../Message'
import { useTranslation } from 'react-i18next'
import DomainLogo from '../DomainLogo/DomainLogo'
import DomainLogoDown from '../DomainLogoDown/DomainLogoDown'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  brand: {
    height: '130px'
  },
  accountAvatar: {
    color: '#fff',
    background: '#3C4858!important'
  },
  logo: {
    margin: theme.spacing(2, 0),
    height: '80px'
  },
  loginLink: {
    color: '#09a963'
  },
  emailSent: {
    width: '180px'
  }
}))

function Signup (props) {
  const classes = useStyles()

  const { t } = useTranslation('signup')
  // codice di errore mandato dal server
  const [statusCode, setStatusCode] = useState(null)

  // Funzione che restituisce messaggio e azione da fornire all'utente in base al codice di errore ricevuto dal server
  const selectErrorCase = (code) => {
    switch (code) {
      case 200:
        return (
          <>
            <Box mb={8} width='100%' display='flex' justifyContent='center'>
              <img className={classes.emailSent} src={'/images/mail_sent.svg'} alt='email sent' />
            </Box>
            <Box width='100%' mb={3}>
              <Message>{t('notifications.USER_ONBOARDING_OK_1')}</Message>
            </Box>
          </>)
      case 409:
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message buttonText={t('buttons.backToSignup')} action={() => setStatusCode(null)}>{t('notifications.USER_ONBOARDING_ERROR_1_1')}</Message>
          </Box>
        )
      case 403:
        return (
          <>
            <Box width='100%' mb={3}>
              <Message buttonText={t('buttons.backToSignup')} action={() => setStatusCode(null)}>{t('notifications.USER_ONBOARDING_ERROR_1_2')}</Message>
            </Box>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
        <Card className={'signContainer'}>
          <DomainLogo
            url = {window.location.host}
          ></DomainLogo>
          {
            statusCode ? (
              <Container>
                {selectErrorCase(statusCode)}
              </Container>
            ) : (
              <>
                <CardHeader
                  className='signInHeader'
                  avatar={
                    <Avatar className={classes.accountAvatar}>
                      <AccountIcon />
                    </Avatar>}
                  title={t('title')}
                  titleTypographyProps={{ variant: 'h6' }}
                  subheader={t('subtitle')}
                />
                <SignupForm domain={props.domain} changeStatusCode={setStatusCode} />
                <Box pr={3} width='100%' mt={2} mb={2} display='flex' justifyContent='flex-end'>
                  <Link className={classes.loginLink} to='/signin'>{t('login')}</Link>
                </Box>
                <DomainLogoDown url={window.location.host}></DomainLogoDown>
              </>)
          }
        </Card>
      </Grid>
    </Grid>
  )
}

export default Signup
