/* Componente Padre che forma il blocco productId, LotNumber, Quantity,Uom */
import React from 'react'
import PropTypes from 'prop-types'
// import { makeStyles } from '@material-ui/core/styles'
import {
  Grid
} from '@material-ui/core'
import ProductComponent from '../IOComponents/ProductComponent'
import UomComponent from '../IOComponents/UomComponent'
import LotNumberComponent from '../IOComponents/LotNumberComponent'
import QuantityComponent from '../IOComponents/QuantityComponent'
import ActionsInBlock from '../AdditionalBlocks/ActionsInBlock'

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }))

function InputComponent (props) {
  // const classes = useStyles()
  const {
    inputIndex,
    trackIndex,
    input,
    isModifyEnabled,
    product,
    products,
    inputChanged,
    deleteInput,
    openUrl,
    verifyInput,
    frontEndUrl
  } = props

  return (
    <div key={`input-${inputIndex}`} className="inputCard">
      <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
        <ProductComponent
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          component={input}
          isModifyEnabled={isModifyEnabled}
          componentChanged={inputChanged}
          product={product}
          products={products}
          type={'input'}
        ></ProductComponent>
        <UomComponent
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          component={input}
          isModifyEnabled={isModifyEnabled}
          componentChanged={inputChanged}
          type={'input'}
        >
        </UomComponent>
        <LotNumberComponent
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          component={input}
          isModifyEnabled={isModifyEnabled}
          componentChanged={inputChanged}
          type={'input'}
        >
        </LotNumberComponent>
        <QuantityComponent
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          component={input}
          isModifyEnabled={isModifyEnabled}
          componentChanged={inputChanged}
          type={'input'}
        >
        </QuantityComponent>
        <ActionsInBlock
          trackIndex={trackIndex}
          componentIndex={inputIndex}
          deleteComponent={deleteInput}
          isModifyEnabled={isModifyEnabled}
          openUrl={openUrl}
          verifyInput={verifyInput}
          component={input}
          frontEndUrl={frontEndUrl}
        ></ActionsInBlock>
      </Grid>
    </div>
  )
}

InputComponent.propTypes = {
  track: PropTypes.object.isRequired,
  inputIndex: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  input: PropTypes.object.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  inputChangedNewSelect: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  inputChanged: PropTypes.func.isRequired
}
export default InputComponent
