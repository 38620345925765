import React from 'react'
import PropTypes from 'prop-types'

import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withWidth,
  Typography,
  IconButton,
  TextField
} from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

const styles = theme => ({
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  navBar: {
    color: 'white'
  },
  primary: {
    color: theme.primary
  }
})

class ChangePasswordDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      pass1: '',
      pass2: ''
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  render () {
    const { open, onClose, width, classes, changePassword } = this.props
    const { pass1, pass2 } = this.state
    return (
      <Dialog
        className='changePassword'
        fullWidth={true}
        fullScreen={width === 'xs' || width === 'sm'}
        maxWidth={'sm'}
        onClose={onClose}
        open={open}>
        <DialogTitle classes={{ root: classes.titleMobile }} disableTypography={true} className={classes.navBar}>
          <Typography style={{ width: '95%' }} noWrap={true} variant="h6" component="h2">Cambia Password</Typography>
          <IconButton style={{ justifySelf: 'flex-end' }} color="inherit" aria-label="close modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off" autoCapitalize="off">
            <TextField
              InputLabelProps={{ shrink: true }}
              id="pass1"
              name="pass1"
              label="Inserisci la nuova password"
              type="password"
              fullWidth
              value={this.state.pass1}
              onChange={this.handleChange}
              margin="normal"
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              id="pass2"
              name="pass2"
              label="Reinserisci la password"
              type="password"
              fullWidth
              value={this.state.pass2}
              onChange={this.handleChange}
              margin="normal"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annulla</Button>
          <Button onClick={() => changePassword(pass1, pass2)} classes={{ textPrimary: classes.primary }} title="conferma" color="primary">Conferma</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ChangePasswordDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(ChangePasswordDialog))
