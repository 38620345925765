/* questo è per il prodotto ed è similare al lotto */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import {
  Grid,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

function LocationComponent (props) {
  const classes = useStyles()
  const {
    track,
    trackIndex,
    locationChanged,
    isModifyEnabled,
    facilities,
    translation
  } = props

  const { t } = useTranslation(translation)

  const [input, setInput] = React.useState('')
  useEffect(() => {
    // Update the document title using the browser API
    switch (translation) {
      case 'productDetails': {
        setInput(t('tabs.traceability.inputs.location'))
        break
      }
      case 'lotDetails' : {
        setInput(t('tabs.traceability.inputs.location'))
        break
      }
      default: {
        break
      }
    }
  }, [])
  return (
    <Grid item xs={12} md={6}>
      <InputLabel shrink htmlFor={`location-${trackIndex}`}>
        {input}
      </InputLabel>
      <Select id={`location-${trackIndex}`}
        name={`location-${trackIndex}`}
        value={track.location}
        onChange={(e) => locationChanged(e, trackIndex)}
        disabled={!isModifyEnabled} fullWidth
      >
        {facilities.map((t, facilityIndex) => {
          if (t.companyData && t.companyData.name) {
            return (
              <MenuItem
                className={t.status === 'deleted' ? classes.deletedStyle : ''}
                value={t.uuid}
                key={`facility-${facilityIndex}`}>
                {t.name} ({t.companyData.name})
              </MenuItem>
            )
          } else {
            return (
              <MenuItem
                className={t.status === 'deleted' ? classes.deletedStyle : ''}
                value={t.uuid}
                key={`facility-${facilityIndex}`}>
                {t.name}
              </MenuItem>
            )
          }
        })}
      </Select>
    </Grid>
  )
}

LocationComponent.propTypes = {
  track: PropTypes.object.isRequired,
  trackIndex: PropTypes.number.isRequired,
  locationChanged: PropTypes.func.isRequired,
  isModifyEnabled: PropTypes.bool.isRequired,
  facilities: PropTypes.array.isRequired,
  translation: PropTypes.string.isRequired
}
export default LocationComponent
